import React from "react";
import {useDispatch} from "react-redux";
import {NumericFormat} from "react-number-format";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import {FormSection} from "../../util/Form";
import {updateConfig} from '../state';


const RowSplitter = ({config, configName}) => {
    const dispatch = useDispatch();
    return (
        <FormSection label="Split Table" help="Split the table onto multiple pages by specifying the number of table rows to show on a single page.">
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <FormControlLabel
                        control={<Checkbox checked={config.split} onChange={evt => dispatch(updateConfig({path:`${configName}.split`, value:evt.target.checked}))}  />}
                        label="Split table across pages"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <NumericFormat value={config.rowsPerPage} decimalScale={0} allowNegative={false} customInput={TextField}
                                  label="Rows per Page" variant="outlined" fullWidth disabled={config.split === false}
                                  onValueChange={v => dispatch(updateConfig({path:`${configName}.rowsPerPage`, value:parseInt(v.floatValue)}))}
                    />
                </Grid>
            </Grid>
        </FormSection>
    )
}

export default RowSplitter;
