import React, {useRef} from "react";
import {useDispatch} from "react-redux";
import {NumericFormat} from "react-number-format";
import {useDrag, useDrop} from "react-dnd";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MenuIcon from "@mui/icons-material/Menu";
import {FormRow} from "../../util/Form";
import {hoverY} from "../../util/dnd";
import {Field} from  "../../model/fields";
import {addRevenue, moveRevenue, removeRevenue, updateRevenue} from '../state';


const DRAG_TYPE_REVENUE = "revenue";

const RevenueRow = ({item, index}) => {
    const dispatch = useDispatch();
    const ref = useRef(null);

    const [, drop] = useDrop({
        accept: DRAG_TYPE_REVENUE,
        hover(item, monitor) {
            hoverY(ref, index, item, monitor, (from, to) => dispatch(moveRevenue({from,to})));
        }
    });
    const [{ isDragging }, drag] = useDrag({
        item: { type: DRAG_TYPE_REVENUE, id: item.id, index },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    const onChange = (name) => (evt) => {
        let value = name !== "title" ? evt.floatValue : evt.target.value;
        dispatch(updateRevenue({item, update:{[name]: value}}));
    }

    return (
        <div ref={ref} style={{opacity}}>
            <FormRow spacing={0} style={{opacity}} sx={{alignItems: "center", flexWrap: "nowrap"}}>
                <Grid item>
                    <Tooltip title="Remove this item">
                        <IconButton onClick={() => dispatch(removeRevenue(item))} size="large">
                            <HighlightOffIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item sx={{flexGrow: 1, p: 0}}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextField value={item.title} size="small" variant="outlined"
                                       onChange={onChange("title")} fullWidth/>
                        </Grid>

                        <Grid item xs={6}>
                            <NumericFormat value={item.value} size="small" variant="outlined"
                                          InputProps={{
                                              startAdornment: <InputAdornment position="start">$</InputAdornment>
                                          }}
                                          onValueChange={ onChange("value") }
                                          customInput={TextField} >
                            </NumericFormat>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Tooltip title="Drag to reorder">
                        <IconButton style={{cursor: "grab"}} size="large">
                            <MenuIcon color="secondary" />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </FormRow>
        </div>
    );
}
const RevenueCalculator = ({comp}) => {
    const dispatch = useDispatch();
    const revenue = Field.FINANCIAL_OTHER_REVENUE.get(comp) || [];
    return <>
        {revenue.map((r, i) => (
            <RevenueRow key={r.id} item={r} index={i}/>
        ))}
        <Grid container spacing={0}>
            <Grid item>
                <Tooltip title="Add a new expense">
                    <IconButton onClick={() => dispatch(addRevenue())} size="large">
                        <AddCircleOutlineIcon color="primary"/>
                    </IconButton>
                </Tooltip>

            </Grid>
        </Grid>
    </>;
};

export default RevenueCalculator;
