import React, {useEffect, useState} from "react";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from "@mui/material/Box";
import {useLocalStorage} from "./useLocalStorage";

const Tip = ({id, title, children, ...props}) => {
    const [show, setShow] = useState(false);
    const [config, setConfig] = useLocalStorage("quickcomps.tips", "{}");

    useEffect(() => {
        try {
            const c = JSON.parse(config);
            setShow(c[id] !== false);
        }
        catch(error) {
            setShow(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config]);

    const hide = () => {
        let c;
        try {
            c = JSON.parse(config);
        }
        catch(error) {
            c = {}
        }
        c[id] = false;
        setConfig(JSON.stringify(c));
    }

    return (
        show ? <Box {...props}>
            <Alert severity="info" onClose={hide} variant="filled">
                {title && <AlertTitle>{title}</AlertTitle>}
                {children}
            </Alert>
        </Box>: null
    )
}

export default Tip;
