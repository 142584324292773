import React from "react";
import {useTheme} from '@mui/material';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import PublicPage from "../util/PublicPage";
import LoginForm from "./LoginForm";


const AuthPage = () => {
    const theme = useTheme();
    return (
        <PublicPage>
            <Box sx={{maxWidth: theme.breakpoints.values.sm}} margin="auto">
                <LoginForm/>
                <Grid container justifyContent="center" alignItems="center" spacing={1} sx={{mt: 2}}>
                    <Grid item>
                        <Typography component="span">Need an account?</Typography>
                    </Grid>
                    <Grid item>
                        <Button href="/signup" size="small" variant="outlined" color="primary">
                            Sign Up
                        </Button>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center" alignItems="center" spacing={1} sx={{
                   mt: 4, textTransform: "uppercase"
                }}>
                    <Typography variant="subtitle2" sx={{mr: 4}}>
                        <Link href="https://quickcomp.io/terms" target="_blank" rel="noreferrer">Terms of Service</Link>
                    </Typography>
                    <Typography variant="subtitle2">
                        <Link href="https://quickcomp.io/privacy" target="_blank" rel="noreferrer">Privacy Policy</Link>
                    </Typography>
                </Grid>
            </Box>
        </PublicPage>
    );
};

export default AuthPage;

