import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {Link} from "react-router-dom";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AddIcon from "@mui/icons-material/Add";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import RefreshIcon from "@mui/icons-material/Refresh";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Page from "../util/Page";
import ToolBar from "../util/ToolBar";
import DataTable2, {Cell} from "../util/DataTable2";
import ToggleButton from "../util/ToggleButton";
import {ConfirmationSource, Field} from "../model/fields";
import {CURRENT_LISTING, UNCONFIRMED} from "../model/colors";
import {UserAvatar} from '../util/UserAvatar';
import TypeAvatar from './TypeAvatar';
import {pageComps, refreshComps, removeFilter, setFilter, sortComps} from './state';
import CartButton from "../cart/CartButton";


const FIELDS =  [Field.TITLE, Field.TYPE, Field.SALES_DATE, Field.CREATED, Field.UPDATED];

const ConfirmStatus = ({comp}) => {
    const isUnconfirmed =  ConfirmationSource.isUnconfirmed(comp);
    const isListing = ConfirmationSource.isCurrentListing(comp)
    return (ConfirmationSource.isUnconfirmed(comp) || ConfirmationSource.isCurrentListing(comp)) ?
        <Tooltip title={isUnconfirmed?"Unconfirmed":(isListing?"Current Listing":"")}>
            <FiberManualRecordIcon sx={{
                verticalAlign: "middle",
                ...(isUnconfirmed && {color:UNCONFIRMED}),
                ...(isListing && {color:CURRENT_LISTING})
            }}/>
        </Tooltip> : null;
}

const CompsPage = () => {
    const userId = useSelector(state => state.auth.user.uid);
    const list = useSelector(state => state.comp.list);
    const numComps = useSelector(state => state.app.org.num_comps);
    const statusFilter = useSelector(state => {
        const f = (state.comp.list.filters||[]).find(it => it.field === Field.CONFIRMATION_SOURCE.path);
        return f ? f.value : [];
    });
    const userFilter = useSelector(state => {
       const f = (state.comp.list.filters||[]).find(it => it.field === "users");
       return !!f;
    });

    const dispatch = useDispatch();
    const history = useHistory();

    const handleStatusFilter = (evt, v) => {
        if (v && v.length > 0) {
            dispatch(setFilter({field: Field.CONFIRMATION_SOURCE, value: v, op: "in"}));
        }
        else {
            dispatch(removeFilter(Field.CONFIRMATION_SOURCE));
        }
    }

    const handleUserFilter = () => {
      if (userFilter) {
        dispatch(removeFilter(Field.USERS));
      }
      else {
        dispatch(setFilter({field: Field.USERS, value: userId, op: "array-contains"}))
      }
    }

    React.useEffect(() => {
        if (list.page === -1) {
            dispatch(pageComps(true));
        }
    }, [list, dispatch]);

    const handlePage = forward => {
        return dispatch(pageComps(forward));
    };

    const handleSort = (field, order) => {
        return dispatch(sortComps({field, order}));
    };

    return (
        <Page fluid={true} className="CompsPage">
            <ToolBar title="Comps">
                <Tooltip title="Add a Comp">
                    <Fab onClick={() => history.push("/add")} color="primary" size="small">
                        <AddIcon />
                    </Fab>
                </Tooltip>
            </ToolBar>
            <Container fixed>
                <DataTable2 data={list} noun="comp" fields={FIELDS} totalCount={numComps} onPage={handlePage} onSort={handleSort}
                    cellRenderer={(comp, field, props) => {
                        if (field === null) {
                            return <Cell primary={<ConfirmStatus comp={comp}/>}/>;
                        }
                        if (field === Field.TITLE) {
                          return <Cell {...props} data-id={comp.id}
                                       primary={<Link to={`/comp/${comp.id}`}>{field.get(comp)}</Link>}
                                       secondary={<Typography variant="subtitle2">{Field.COMP_ID.get(comp)}</Typography>}/>;
                        }
                        if (field === Field.TYPE) {
                          return <Cell avatar={<TypeAvatar type={comp.type} />}/>
                        }

                        if (field === Field.CREATED) {
                          return <Cell {...props}
                                      primary={<Typography>{Field.CREATED.render(comp)}</Typography>}
                                      secondary={<Typography variant="subtitle2">{Field.CREATED.render(comp, {fromNow: true})}</Typography>}
                                      avatar={<UserAvatar user={comp.created_by} sx={{ width: 32, height: 32, fontSize: "1rem" }}/> }
                          />;
                        }
                        if (field === Field.UPDATED) {
                          return <Cell {...props}
                                       primary={<Typography>{Field.UPDATED.render(comp)}</Typography>}
                                       secondary={<Typography variant="subtitle2">{Field.UPDATED.render(comp, {fromNow: true})}</Typography>}
                                       avatar={<UserAvatar user={comp.updated_by || comp.created_by} sx={{ width: 32, height: 32, fontSize: "1rem" }}/> }
                          />;
                        }
                        return null;
                    }}
                    toolbarRenderer={() => {
                        return (
                            <Box width="100%" display="flex" justifyContent="flex-end" alignItems="center">
                                <Tooltip title="Refresh">
                                    <IconButton onClick={() => dispatch(refreshComps())} size="large">
                                        <RefreshIcon/>
                                    </IconButton>
                                </Tooltip>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={statusFilter}
                                    onChange={handleStatusFilter}
                                >
                                    <ToggleButton
                                        size="small"
                                        value={ConfirmationSource.UNCONFIRMED}
                                    >
                                        <Tooltip title="Show unconfirmed comps">
                                            <FiberManualRecordIcon sx={{color: UNCONFIRMED}}/>
                                        </Tooltip>
                                    </ToggleButton>
                                    <ToggleButton
                                        size="small"
                                        value={ConfirmationSource.CURRENT_LISTING}
                                    >
                                        <Tooltip title="Show current listings">
                                            <FiberManualRecordIcon sx={{color: CURRENT_LISTING}}/>
                                        </Tooltip>

                                    </ToggleButton>
                                </ToggleButtonGroup>
                                <ToggleButton size="small" value="user" selected={userFilter} color="primary"
                                            onChange={handleUserFilter} sx={{ml:1}}>
                                  <Tooltip title={userFilter?"Show all comps" : "Show only my comps"}>
                                    <AccountCircleIcon/>
                                  </Tooltip>
                              </ToggleButton>
                            </Box>
                        );
                    }}
                    actionRenderer={(comp) => {
                        return <Box>
                            <CartButton comp={comp}/>
                        </Box>
                    }}
                />
            </Container>
        </Page>
    );
};

export default CompsPage;
