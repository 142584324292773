
export const EXPORT_WORD = {key: "word", title: "Word Document"};
export const EXPORT_WORD_TABLES = {key: "word-vector", title: "Word Document (Tables)"};
export const EXPORT_PDF = {key: "pdf", title: "PDF Document"};
export const EXPORT_ZIP = {key: "zip", title: "Zip Archive"};

export const TYPE_SINGLE = "single";
export const TYPE_SUMMARY_DETAIL = "summary_detail";

export const VIEW_SUMMARY_TABLE = "summary_table";
export const VIEW_DETAIL_SHEET = "detail_sheet";
export const VIEW_COMP_TABLE = "comp_table";
export const VIEW_COMP_MAP = "comp_map";

export const THUMBNAIL_START = "Start";
export const THUMBNAIL_END = "End";
export const THUMBNAIL_NONE = "None"

export const viewTitle = v => {
  switch(v) {
    case VIEW_SUMMARY_TABLE:
      return "Summary Table"
    case VIEW_DETAIL_SHEET:
      return "Detail Sheet"
    case VIEW_COMP_TABLE:
      return "Comp Table"
    case VIEW_COMP_MAP:
      return "Comp Map"
    default:
      return null;
  }
}
