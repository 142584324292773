import nanoid from "nanoid";
import {capitalize} from "../util/utils";
import {
    STYLE_NORMAL,
    TRANSFORM_NONE,
    TRANSFORM_UPPERCASE,
    WEIGHT_BOLD,
    WEIGHT_NORMAL,
    FONTS,
    ALIGN_JUSTIFY,
    ALIGN_LEFT, lookupFont
} from "../model/fonts";
import {Field} from  "../model/fields";
import {ALMOST_BLACK, ALMOST_WHITE, BLUE, GRAY, GREEN, LIGHT_GRAY, RED, WHITE} from "../model/colors";
import {MULTI_FAMILY, VACANT_LAND, LEASE, RESIDENTIAL_RENT} from "../model/propertyTypes";
import {THUMBNAIL_END} from "../model/reportTypes";

export const DEFAULT_FONT_FAMILY = FONTS[0].id;

export const DEFAULT_IMAGE_HEIGHT = 340;

export const newConfig = () => ({
    style: {
        head: {
            backgroundColor: BLUE,
            fontFamily: DEFAULT_FONT_FAMILY,
            fontColor: ALMOST_WHITE,
            fontSize: "16px",
        },
        body: {
            backgroundColor: ALMOST_WHITE,
            fontFamily: DEFAULT_FONT_FAMILY,
            fontColor: ALMOST_BLACK,
            fontSize: "16px"
        },
        defaultFont: {
            family: DEFAULT_FONT_FAMILY,
        }
    },
    header: {
        title: true,
        date: true,
        org: true,
        logo: true,
        style: {
            title: {
                font: {
                    family: DEFAULT_FONT_FAMILY,
                    style: "normal",
                    weight: 600,
                    size: 24,
                    color: ALMOST_BLACK,
                    transform: TRANSFORM_NONE
                }
            },
            date: {
                font: {
                    family: DEFAULT_FONT_FAMILY,
                    style: "italic",
                    weight: 400,
                    size: 18,
                    color: ALMOST_BLACK,
                    transform: TRANSFORM_NONE
                }
            },
            org: {
                font: {
                    family: DEFAULT_FONT_FAMILY,
                    style: "normal",
                    weight: 400,
                    size: 14,
                    color: ALMOST_BLACK,
                    transform: TRANSFORM_NONE
                }
            },
            logo: {
                width: 256
            }
        }
    },
    summary: {
        caption: {
            enabled: false,
            value: "Comparable Sales"
        },
        columns: [],
        style: defaultColumnStyle(),
        split: true,
        rowsPerPage: 5,
        options: {
            noun: "No."
        }
    },
    detail: {
        pages: [{
            sections: [
                {
                    id: nanoid(),
                    type: "photo",
                    span: 1,
                    title: "Photo",
                    border: true,
                    height: DEFAULT_IMAGE_HEIGHT
                },
                {
                    id: nanoid(),
                    type: "map",
                    span: 1,
                    title: "Map",
                    border: true,
                    height: DEFAULT_IMAGE_HEIGHT
                },
                {
                    id: nanoid(),
                    type: "list",
                    span: 2,
                    title: "Location/Legal",
                    fields: [
                        Field.ADDRESS_STREET,
                        Field.GENERAL_LEGAL_DESCRIPTION,
                        Field.PHYSICAL_ZONING_DESC
                    ],
                    border: false
                },
                {
                    id: nanoid(),
                    type: "list",
                    span: 1,
                    title: "Property Description",
                    fields: [
                        Field.PHYSICAL_PROPERTY_TYPE,
                        Field.PHYSICAL_TOTAL_LAND_AREA_ACRE,
                        Field.PHYSICAL_TOTAL_LAND_AREA_SF,
                        Field.IMPROVEMENT_NUM_UNITS,
                        Field.IMPROVEMENT_YEAR_BUILT,
                        Field.IMPROVEMENT_NUM_STORIES
                    ],
                    border: false
                },
                {
                    id: nanoid(),
                    type: "list",
                    span: 1,
                    title: "Value Indicators",
                    fields: [
                        Field.SALES_STATUS,
                        Field.SALES_DATE,
                        Field.SALES_DAYS_ON_MARKET,
                        Field.SALES_PRICE,
                        Field.CONFIRMATION_SOURCE
                    ],
                    border: false
                },
                {
                    id: nanoid(),
                    type: "list",
                    span: 1,
                    title: "Financial Data",
                    fields: [
                        Field.FINANCIAL_POTENTIAL_GROSS_INCOME,
                        Field.FINANCIAL_VACANCY_COLLECTION_LOSS_VALUE,
                        Field.FINANCIAL_EFFECTIVE_GROSS_INCOME,
                        Field.FINANCIAL_TOTAL_EXPENSES,
                        Field.FINANCIAL_NET_OPERATING_INCOME
                    ],
                    border: false
                },
                {
                    id: nanoid(),
                    type: "list",
                    span: 1,
                    title: "Value Indicators/Analysis",
                    fields: [
                        Field.FINANCIAL_CAP_RATE,
                        Field.FINANCIAL_POTENTIAL_GROSS_INCOME_MULTIPLIER,
                        Field.FINANCIAL_EFFECTIVE_GROSS_INCOME_MULTIPLIER,
                        Field.FINANCIAL_RENTABLE_AREA_PRICE
                    ],
                    border: false
                },
                {
                    id: nanoid(),
                    type: "single",
                    span: 2,
                    title: "Comments",
                    field: Field.CONFIRMATION_COMMENTS,
                    border: false,
                    text: {
                        align: ALIGN_JUSTIFY
                    }
                }
            ]
        }],
        style: {
            margin: 4,
            border: {
                width: 1,
                color: LIGHT_GRAY
            },
            banner: {
                font: {
                    family: DEFAULT_FONT_FAMILY,
                    style: STYLE_NORMAL,
                    weight: WEIGHT_BOLD,
                    size: 14,
                    color: ALMOST_WHITE,
                    transform: TRANSFORM_NONE
                },
                background: BLUE
            },
            section: {
                padding: 1,
                heading: {
                    font: {
                        family: DEFAULT_FONT_FAMILY,
                        style: STYLE_NORMAL,
                        weight: WEIGHT_BOLD,
                        size: 14,
                        color: ALMOST_BLACK,
                        transform: TRANSFORM_UPPERCASE
                    },
                    background: "white",
                    underline: {
                        enabled: true,
                        color: ALMOST_BLACK,
                        width: 2
                    }
                },
                body: {
                    font: {
                        family: DEFAULT_FONT_FAMILY,
                        style: STYLE_NORMAL,
                        weight: WEIGHT_NORMAL,
                        size: 14,
                        color: ALMOST_BLACK,
                        transform: TRANSFORM_NONE
                    },
                    background: "white"
                },
                border: {
                    width: 1,
                    color: LIGHT_GRAY
                }
            },
            divider: {
                color: LIGHT_GRAY,
                height: 10
            }
        },
        options: {
            skipEmptyFields: false,
            noun: "Index",
        }
    },
    map: {
        aspect: "",
        include: true,
        view: {
            zoom: null,
            center: null,
        },
        popup: {
            enabled: true,
            fields: [{
                path: Field.TITLE.path,
                style: {
                    font: {
                        family: DEFAULT_FONT_FAMILY,
                        style: "normal",
                        weight: 600,
                        size: 9,
                        unit: 'pt',
                        color: ALMOST_BLACK,
                        transform: TRANSFORM_NONE
                    }
                }
            }, {
                path: Field.ADDRESS_CITY.path,
                style: {
                    font: {
                        family: DEFAULT_FONT_FAMILY,
                        style: "normal",
                        weight: 500,
                        size: 8,
                        unit: 'pt',
                        color: ALMOST_BLACK,
                        transform: TRANSFORM_NONE
                    }
                }
            }, {
                path: Field.VALUE.path,
                style: {
                    font: {
                        family: DEFAULT_FONT_FAMILY,
                        style: "normal",
                        weight: 400,
                        size: 7,
                        unit: 'pt',
                        color: ALMOST_BLACK,
                        transform: TRANSFORM_NONE
                    }
                }
            }],
            index: {
                enabled: true,
                noun: 'Index',
                style: {
                    font: {
                        family: DEFAULT_FONT_FAMILY,
                        style: "normal",
                        weight: 600,
                        size: 10,
                        unit: 'pt',
                        color: ALMOST_BLACK,
                        transform: TRANSFORM_NONE
                    }
                }
            },
            distance: {
                enabled: true,
                style: {
                    font: {
                        family: DEFAULT_FONT_FAMILY,
                        style: "normal",
                        weight: 400,
                        size: 7,
                        unit: 'pt',
                        color: ALMOST_BLACK,
                        transform: TRANSFORM_NONE
                    }
                }
            },
            positions: {}
        },
        style: {
            popup: {
                subject: {
                    color: BLUE
                },
                listing: {
                    color: GREEN,
                },
                default: {
                    color: WHITE,
                },
            },
            dot: {
                subject: {
                    color: BLUE
                },
                listing: {
                    color: GREEN,
                },
                default: {
                    color: RED,
                },
            }
        },
    },
    single: {
        columns: [],
        thumbnail: THUMBNAIL_END,
        split: true,
        rowsPerPage: 3,
        style: defaultColumnStyle(),
        styles: {},
        options: {
            noun: "No."
        }
    },
    options: {
        metric: false,
        offset: 1,
        emptyField: "n/a",
        zeroAsEmpty: false,
        margin: {
            top: 1,
            bottom: 1,
            left: 1,
            right: 1
        }
    }
});

export const defaultColumnStyle = () => {
    return {
        caption: {
            font: {
                family: DEFAULT_FONT_FAMILY,
                style: "normal",
                weight: 400,
                size: 10.5,
                unit: 'pt',
                color: ALMOST_BLACK,
                transform: TRANSFORM_NONE,
            },
            background: GRAY
        },
        header: {
            font: {
                family: DEFAULT_FONT_FAMILY,
                style: "normal",
                weight: 600,
                size: 10.5,
                unit: 'pt',
                color: ALMOST_WHITE,
                transform: TRANSFORM_NONE,
                align: ALIGN_LEFT
            },
            background: BLUE
        },
        body: {
            font: {
                family: DEFAULT_FONT_FAMILY,
                style: "normal",
                weight: 400,
                size: 10.5,
                unit: 'pt',
                color: ALMOST_BLACK,
                align: ALIGN_LEFT
            },
            background: null,
            alt: {
                enabled: false,
                background: LIGHT_GRAY
            }
        },
        index: {
            font: {
                family: DEFAULT_FONT_FAMILY,
                style: "normal",
                weight: 400,
                size: 10.5,
                unit: 'pt',
                color: ALMOST_BLACK,
                align: ALIGN_LEFT
            },
            background: null,
            alt: {
                enabled: false,
                background: LIGHT_GRAY
            }
        },
        border: {
            width: 1,
            color: LIGHT_GRAY
        },
        padding: 1
    }
}

export const defaultColumnsForType = (t, extra) => {
    switch(t) {
        case VACANT_LAND:
            return [{
                id: nanoid(),
                name: "Column 1",
                fields:  [
                    Field.ADDRESS_STREET,
                    Field.ADDRESS_CITY
                ]
            }, {
                id: nanoid(),
                name: "Column 2",
                fields:  [
                    Field.TYPE,
                    Field.PHYSICAL_PROPERTY_TYPE,
                    Field.PHYSICAL_ZONING_DESC
                ]
            }, {
                id: nanoid(),
                name: "Column 3",
                fields:  [
                    Field.SALES_PRICE,
                    Field.SALES_DATE,
                    Field.PHYSICAL_TOTAL_LAND_AREA_ACRE,
                    Field.PHYSICAL_EFFECTIVE_LAND_AREA_ACRE
                ]
            }, {
                id: nanoid(),
                name: "Column 4",
                fields:  [
                    Field.SALES_PRICE_PER_ACRE_TOTAL,
                    Field.SALES_PRICE_PER_ACRE_EFFECTIVE,
                    Field.SALES_PRICE_PER_SF_TOTAL,
                    Field.SALES_PRICE_PER_SF_EFFECTIVE
                ]
            }, ...(extra||[])];

        case RESIDENTIAL_RENT:
            return  [{
                id: nanoid(),
                name: "Column 1",
                fields:  [
                    Field.TITLE,
                    Field.ADDRESS_CITY
                ]
            },
            {
                id: nanoid(),
                name: "Column 2",
                fields:  [
                    Field.TYPE,
                    Field.PHYSICAL_PROPERTY_TYPE,
                    Field.IMPROVEMENT_YEAR_BUILT,
                ]
            },
            {
                id: nanoid(),
                name: "Column 3",
                fields:  [
                    Field.IMPROVEMENT_PROPERTY_CONDITION,
                    Field.IMPROVEMENT_NUM_BEDROOMS,
                    Field.IMPROVEMENT_NUM_BATHROOMS,
                ]
            },
            {
                id: nanoid(),
                name: "Column 3",
                fields: [
                    Field.IMPROVEMENT_RENTABLE_AREA,
                    Field.FINANCIAL_MONTHLY_RENT,
                    Field.FINANCIAL_UTILITIES_INCLUDED,
                ]
            }, ...(extra||[])];
        case LEASE:
            return [{
                id: nanoid(),
                name: "Column 1",
                fields: [
                    Field.TITLE,
                    Field.ADDRESS_CITY
                ]
            }, {
                id: nanoid(),
                name: "Column 2",
                fields: [
                    Field.TYPE,
                    Field.PHYSICAL_PROPERTY_TYPE,
                    Field.PHYSICAL_EXISTING_USE,
                    Field.IMPROVEMENT_YEAR_BUILT
                ]
            }, {
                id: nanoid(),
                name: "Column 3",
                fields: [
                    Field.IMPROVEMENT_LEASED_AREA,
                    Field.IMPROVEMENT_LEASED_AREA_SOURCE,
                    Field.LEASE_TYPE,
                    Field.LEASE_RECOVERY
                ]
            }, {
                id: nanoid(),
                name: "Column 4",
                fields: [
                    Field.LEASE_START_DATE,
                    Field.LEASE_TERM,
                    Field.LEASE_YEAR1_RATE,
                    Field.LEASE_AVERAGE_RATE_OVER_TERM
                ]
            }, ...(extra||[])];
        case MULTI_FAMILY:
            return [{
                id: nanoid(),
                name: "Column 1",
                fields: [
                    Field.TITLE,
                    Field.ADDRESS_CITY
                ]
            }, {
                id: nanoid(),
                name: "Column 2",
                fields: [
                    Field.TYPE,
                    Field.PHYSICAL_PROPERTY_TYPE,
                    Field.PHYSICAL_ZONING_DESC,
                ]
            }, {
                id: nanoid(),
                name: "Column 3",
                fields: [
                    Field.SALES_PRICE,
                    Field.SALES_DATE,
                    Field.SALES_PRICE_PER_UNIT
                ]
            }, {
                id: nanoid(),
                name: "Column 4",
                fields: [
                    Field.FINANCIAL_POTENTIAL_GROSS_INCOME_MULTIPLIER,
                    Field.FINANCIAL_NET_OPERATING_INCOME,
                    Field.FINANCIAL_CAP_RATE
                ]
            }, ...(extra||[])];
        default:
            return [
                {
                    id: nanoid(),
                    name: "Column 1",
                    fields:  [
                        Field.ADDRESS_STREET,
                        Field.ADDRESS_CITY
                    ]
                }, {
                    id: nanoid(),
                    name: "Column 2",
                    fields: [
                        Field.TYPE,
                        Field.PHYSICAL_PROPERTY_TYPE,
                        Field.PHYSICAL_ZONING_DESC
                    ]
                }, {
                    id: nanoid(),
                    name: "Column 3",
                    fields: [
                        Field.SALES_PRICE,
                        Field.SALES_DATE,
                        Field.FINANCIAL_GROSS_BUILDABLE_AREA_PRICE
                        //...(type === MULTI_FAMILY ? [Field.SALES_PRICE_PER_UNIT] : [])
                    ]
                }, {
                    id: nanoid(),
                    name: "Column 4",
                    fields: [
                        Field.FINANCIAL_NET_OPERATING_INCOME,
                        Field.FINANCIAL_POTENTIAL_GROSS_INCOME_MULTIPLIER,
                        Field.FINANCIAL_CAP_RATE
                    ]
                },
                ...(extra||[])
            ]
    }

}

export const spacingAsObject = (s, t, r, b, l) => {
    let v = 8 * (s||1);
    return {
        paddingTop: v/(t||1),
        paddingBottom: v/(b||1),
        paddingLeft: v/(l||1),
        paddingRight: v/(r||1)
    }
};

export const borderAsObject = (b,s) => {
    const prefix = "border" + (s?capitalize(s):"");
    return {
        [`${prefix}Width`]: `${b.width}px`,
        [`${prefix}Color`]: b.color,
        [`${prefix}Style`]: "solid"
    }
};

export const fontAsObject = (f,unit='pt') => {
    const obj = {};
    for (const key in f) {
        if (f.hasOwnProperty(key)) {
            let k = key;
            let v = f[key];

            switch(key) {
                case "color":
                    break;
                case "transform":
                    k = "textTransform";
                    break;
                case "decoration":
                    k = "textDecoration";
                    break;
                case "align":
                    k = "textAlign";
                    break;
                case "family":
                    k = "fontFamily";
                    let font = FONTS.find(it => it.id === v);
                    if (!font) continue;

                    if (font.previewId) font = lookupFont(font.previewId);
                    v = font.family || font.name;
                    break;
                case "size":
                    k = "fontSize";
                    v = `${v}${unit}`;
                    break;
                default:
                    k = `font${capitalize(key)}`;
            }
            obj[k] = v;
        }
    }
    return obj;
};

export const underlineAsObject = ul => {
    return ul.enabled === true ? {
        borderBottom: `${ul.width||1}px solid ${ul.color}`
    } : {}
};

export const applyTextStyle = (base, style, path) => {
    if (!style) return base;

    const s = style[path] || style;
    return {
        ...base,
        ...(s?.bold === true ? {fontWeight: "bold"} :
            s?.bold === false ? {fontWeight: "normal"} : {}),

        ...(s?.italic === true ? {fontStyle: "italic"} :
            s?.italic === false ? {fontStyle: "normal"} : {}),

        ...(s?.underline === true ? {textDecoration: "underline"} :
            s?.underline === false ? {textDecoration: "none"} : {}),

        ...(s?.align ? {textAlign: s.align } : {})
    };
}

