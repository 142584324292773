import { useDispatch, useSelector } from "react-redux";
import {Box, FormControlLabel, Grid, Radio, RadioGroup, Stack, Typography} from "@mui/material";
import FontControl from "../../util/FontControl";
import { SHEET_LAYOUT_COL, SHEET_LAYOUT_ROW, SHEET_TYPE_ADJUST, SHEET_TYPE_SIMPLE } from "../constants";
import {FormLabel, FormSection} from "../../util/Form";
import TextBuilder from "../../util/TextBuilder";
import SheetEditorPanel from "./SheetEditorPanel";
import { FieldStyles as SimpleFieldStyles } from "./SimpleSheet";
import { FieldStyles as AdjustFieldStyles } from "./AdjustSheet";
import { MICROSOFT_FONTS } from "../../model/fonts";
import {setValue, updateStyle} from "../state"


const LayoutPanel = ({sheet, ...props}) => {
    const dispatch = useDispatch();
    const styles = useSelector(state => state.sheet.curr.styles);
    const font = styles.base.font.family;

    return (
        <SheetEditorPanel {...props} sheet={sheet} name="LayoutPanel">
            <FormSection label="Orientation" help="Choose the ortientation of the spreadsheet.">
                <RadioGroup value={sheet.layout} onChange={evt => dispatch(setValue({key:"layout", value:evt.target.value}))}>
                    <Grid container>
                        <Grid item md={6}>
                            <FormControlLabel value={SHEET_LAYOUT_COL} label={"Column Oriented"} control={<Radio/>}/>
                            <Typography variant="subtitle1">Default layout with comps as columns, and fields as rows.</Typography>
                        </Grid>
                        <Grid item md={6}>
                            <FormControlLabel value={SHEET_LAYOUT_ROW} label={"Row Oriented"} control={<Radio />}/>
                            <Typography variant="subtitle1">Transposed layout with comps as rows, and fields as columns.</Typography>
                        </Grid>
                    </Grid>
                </RadioGroup>
            </FormSection>
            <FormSection label="Styles" help="Configure the display of the spreadsheet.">
                <Grid container>
                    <Grid item xs={12}>
                        <Box mb={2}>
                            <FormLabel>Base Font</FormLabel>
                            {/*<Typography variant="caption">Font to use for the spreadsheet.</Typography>*/}
                        </Box>
                        <Stack direction="row">
                            <FontControl showAlign={false} showStyle={false} showColor={false} showFamily={true} font={styles.base.font} withFonts={MICROSOFT_FONTS}
                                         onChange={(k, v) => dispatch(updateStyle({path:`base.font.${k}`, value:v}))}/>
                            {/*<FontFamilySelect fonts={EXCEL_FONTS} value={font} size="small"*/}
                            {/*                  onChange={(evt) => dispatch(updateStyle({path:"base.font.family", value:evt.target.value}))}/>*/}
                            {/*<FontSizeSelect value={styles.base.font.size} onChange={} size="small"/>*/}
                        </Stack>

                    </Grid>
                </Grid>
                <Box mt={2}>
                    {sheet.type === SHEET_TYPE_SIMPLE && <SimpleFieldStyles sheet={sheet}/>}
                    {sheet.type === SHEET_TYPE_ADJUST && <AdjustFieldStyles sheet={sheet}/>}
                </Box>
                <Box mt={2}>
                    <Grid container>
                        <Grid item md={6}>
                            <FormLabel>Header</FormLabel>
                            <Box py={2}>
                                <TextBuilder config={styles.header} size="small" showBackground={true} showAlign={true} showFamily={false} withFamily={font}
                                    onFontChange={(k, v) => dispatch(updateStyle({ path: `header.font.${k}`, value: v }))}
                                    onBackgroundChange={(v) => dispatch(updateStyle({ path: `header.background`, value: v }))}
                                    colorPickerOpts={{anchor: 'bottom'}}/>
                            </Box>
                        </Grid>
                        <Grid item md={6}>
                            <FormLabel>Footer</FormLabel>
                            <Box py={2}>
                                <TextBuilder config={styles.footer} size="small" showBackground={true} showAlign={true} showFamily={false} withFamily={font}
                                    onFontChange={(k, v) => dispatch(updateStyle({ path: `footer.font.${k}`, value: v }))}
                                    onBackgroundChange={(v) => dispatch(updateStyle({ path: `footer.background`, value: v }))}
                                    colorPickerOpts={{anchor: 'bottom'}}/>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </FormSection>

        </SheetEditorPanel>
    )
};

export default LayoutPanel;
