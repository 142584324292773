import Box from '@mui/material/Box';
import React, {forwardRef, Fragment, useImperativeHandle} from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";


export default forwardRef(({
        steps, stepContent, stepInstruct, stepLabel, stepIsComplete, nextStep, prevStep, finish, hideStepper, hideNav,
        hideNavOnLastStep
    }, ref) => {

    const [activeStep, setActiveStep] = React.useState(0);
    useImperativeHandle(ref, () => ({
        reset: () => setActiveStep(0),
        next: handleNext
    }));

    const moveToNextStep = nextStep ? nextStep : lastActiveStep => lastActiveStep + 1;
    const handleNext = () => {
        if (activeStep === (steps.length-1)) {
            finish();
        }
        else {
            setActiveStep(moveToNextStep);
        }
    };

    const moveToPrevStep = prevStep ? prevStep : lastActiveStep => lastActiveStep - 1;
    const handleBack = () => {
        setActiveStep(moveToPrevStep);
    };

    const buttonStyle = {
        display: "flex",
        "& > *": {
            flexGrow: 1
        },
        "& > *:last-child": {
            textAlign: "right"
        },
        ...((hideNav || (hideNavOnLastStep && activeStep === steps.length-1)) && {display:'none'})
    }
    const isLastStep = activeStep === steps.length - 1;
    return (
        <Fragment>
            {hideStepper !== true && <Stepper activeStep={activeStep} sx={{backgroundColor: "transparent"}}>
                {steps.map((_, index) => {
                    const stepProps = {};
                    const labelProps = {};

                    const label = stepLabel(index, activeStep);
                    return (
                        <Step key={index} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>}
            <div>
                <Box display="flex" alignItems="center" sx={{mt: 5}}>
                    <Button disabled={activeStep === 0} onClick={handleBack} sx={buttonStyle}>
                        Back
                    </Button>
                    <WizardInstruction>{stepInstruct(activeStep)}</WizardInstruction>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={`NextStepButton`}
                        disabled={!stepIsComplete(activeStep)}
                        sx={buttonStyle}
                    >
                        {isLastStep ? 'Finish' : 'Next'}
                    </Button>
                </Box>
                <Box sx={{my:5}}>
                    {stepContent(activeStep)}
                </Box>
            </div>
        </Fragment>
    )
});

export const WizardInstruction = ({children, ...props}) => {
    return (<Typography variant="h3" {...props} sx={{
        mt: 5,
        textAlign: "center",
        verticalAlign: "middle",
        flexGrow: 1
    }}>{children}</Typography>)
};
