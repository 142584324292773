import React, {Fragment} from "react";
import {useDispatch, useSelector} from "react-redux";
import {find, uniq} from "lodash";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {pushSnack} from '../app/state';
import {hasRole, ROLE_EDITOR} from '../auth/roles';
import Tip from '../util/Tip';
import {chooseTemplate, loadTemplates, removeTemplate, setNewTemplate, setTemplateTitle} from './state';


const templatesSelector = state => state.report.templates.all?.filter(it => it.archived !== true) || [];

const SaveTemplate = () => {
    const dispatch = useDispatch();
    const templateTitle = useSelector(state => state.report.curr.title);

    const handleChange = evt => dispatch(setTemplateTitle(evt.target.value));
    return (
        <Grid container justifyContent="center" sx={{textAlign: "center", mb: 4}}>
            <Grid item xs={6}>
                <Typography variant="subtitle1" gutterBottom>Please specify a name for the new template</Typography>
                <TextField variant="outlined" name="template_title" value={templateTitle} onChange={handleChange} fullWidth/>
            </Grid>
        </Grid>
    );
};

const LoadTemplate = () => {
    const [showConfirmRemove, setShowConfirmRemove] = React.useState(false);
    const isEditor = useSelector(state => hasRole(state.auth, ROLE_EDITOR));
    const template = useSelector(state => state.report.templates.curr);
    const templates = useSelector(templatesSelector);
    const compTypes = useSelector(state => uniq(state.cart.comps.map(it => it.type)));
    const dispatch = useDispatch();

    const handleChoose = e => {
        dispatch(chooseTemplate(find(templates, t => t.id === e.target.value)));
    };

    const compTypeMatchesTemplate = t => {
        for (const ct of compTypes) {
            if (!(t.types||[]).includes(ct)) return false;
        }
        return true;
    };

    React.useEffect(() => {
        if (!template.id) {
            const t = find(templates, t => compTypeMatchesTemplate(t));
            if (t) handleChoose({target: {value: t.id}})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template, templates, compTypes]);

    const handleRemove = () => setShowConfirmRemove(true);

    const handleClose = proceed => {
        if (proceed === true) {
            dispatch(removeTemplate(template.id)).then(() => {
                dispatch(pushSnack({message: `Template ${template.title} removed.`}));
                dispatch(chooseTemplate(null));
                dispatch(loadTemplates()).then((templates) => {
                    if (templates.length === 0) dispatch(setNewTemplate(true));
                });
            });
        }
        setShowConfirmRemove(false);
    };

    return (
        <Fragment>
            <Grid container justifyContent="center" sx={{textAlign: "center", mb: 4}}>
                <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                        <Select value={template.id} onChange={handleChoose} sx={{textAlign: "left"}}>
                            {templates.map(t => <MenuItem key={t.id} value={t.id}>{t.title}</MenuItem>)}
                        </Select>
                    </FormControl>
                    {isEditor && template.id && <Button onClick={handleRemove} sx={{
                        fontSize: 'caption.fontSize',
                        fontWeight: 'caption.fontWeight',
                    }}>Delete</Button>}
                    <Tip id="newTemplate" mt={4}>
                        To create a new template, start with an existing template and modify as required. After you generate the report
                        you can save the changes as a new template.
                    </Tip>
                </Grid>
            </Grid>
            <Dialog
                open={showConfirmRemove}
                onClose={handleClose}
            >
                <DialogTitle>{`Delete ${template.title}?`}</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        Are you sure you want to delete this template? This action is permanent and can't be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose(true)} color="primary">
                        Proceed
                    </Button>
                    <Button onClick={() => handleClose(false)} color="primary" autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

const TemplateStep = () => {
    const isEditor = useSelector(state => hasRole(state.auth, ROLE_EDITOR));
    const isNewTemplate = false;

    return (
        isEditor ?
        <Box>
            <Box>
                {isNewTemplate === true && <SaveTemplate />}
                {isNewTemplate === false && <LoadTemplate />}
            </Box>
        </Box>
        :
        <Box>
            <LoadTemplate/>
        </Box>
    );
};
export default TemplateStep;

