import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import {capitalize} from "./utils";
import {Fields} from "../model/fields";

const TableHeader = ({numSelected, onFilter}) => {
    const [filter, setFilter] = React.useState("");
    const [filterTimeout, setFilterTimeout] = React.useState(0);
    const handleFilter = e => {
        const value = e.target.value;
        setFilter(value);
        if (filterTimeout) {
            clearTimeout(filterTimeout);
        }
        setFilterTimeout(setTimeout(() => onFilter(value), 1000));
    };

    return (
        <Toolbar sx={{ pl: 2, pr: 1}}>
            {numSelected > 0 ? (
                <Typography color="inherit" variant="subtitle1" sx={{flex: '1 1 100%'}}>
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography variant="h6" sx={{flex: '1 1 100%',}}>
                    Fields
                </Typography>
            )}

            <TextField value={filter} name="filter" onChange={handleFilter} placeholder="Search for a field" size="small"
                       InputProps={{
                           startAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>,
                       }}
                       fullWidth
            >

            </TextField>
            {/*{numSelected > 0 ? (*/}
            {/*    <Tooltip title="Delete">*/}
            {/*        <IconButton>*/}
            {/*            <DeleteIcon />*/}
            {/*        </IconButton>*/}
            {/*    </Tooltip>*/}
            {/*) : (*/}
            {/*    <Tooltip title="Filter list">*/}
            {/*        <IconButton>*/}
            {/*            <FilterListIcon />*/}
            {/*        </IconButton>*/}
            {/*    </Tooltip>*/}
            {/*)}*/}
        </Toolbar>
    );
};

const FieldTable = ({onSelect}) => {
    const [fields, setFields] = React.useState(Fields.list());

    const [selected, setSelected] = React.useState([]);
    const doSetSelected = selected => {
        setSelected(selected);
        onSelect(selected);
    };
    const isSelected = f => selected.includes(f);
    const handleSelectAll = event => {
        doSetSelected(event.target.checked ? fields : []);
    };
    const handleSelect = (event, f) => {
        const i = selected.indexOf(f);
        let newSelected = [];

        if (i === -1) {
            newSelected = newSelected.concat(selected, f);
        } else if (i === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (i === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (i > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, i),
                selected.slice(i + 1),
            );
        }

        doSetSelected(newSelected);
    };

    const handleFilter = filter => {
      if (filter) {
          setFields(Fields.list().filter(f => f.label().toLowerCase().indexOf(filter.toLowerCase()) > -1));
      }
      else {
          setFields(Fields.list());
      }
    };

    const numSelected = selected.length;
    const rowCount = fields.length;

    return (
        <div>
            <TableHeader numSelected={numSelected} onFilter={handleFilter}/>
            <TableContainer>
                <Table sx={{minWidth: 750,}}>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    indeterminate={numSelected > 0 && numSelected < rowCount}
                                    checked={rowCount > 0 && numSelected === rowCount}
                                    onChange={handleSelectAll}
                                />
                            </TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Category</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fields.map((f, i) => {
                            const fieldIsSelected = isSelected(f);
                            return (
                                <TableRow
                                    key={f.path}
                                    onClick={event => handleSelect(event, f)}
                                    selected={fieldIsSelected}
                                    role="checkbox"
                                    tabIndex={-1}
                                    hover
                                >
                                    <TableCell padding="checkbox">
                                        <Checkbox checked={fieldIsSelected}
                                        />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {f.label()}
                                    </TableCell>
                                    <TableCell>{f.group && capitalize(f.group)}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default FieldTable;
