import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Logo from "../app/Logo";

const PublicPage = ({children}) => {
    return (
        <Container fixed>
            <Box sx={{mt: 16, textAlign: 'center'}}>
                <Box component="a" display="block" href="https://app.quickcomp.io" sx={{mb:4}}>
                    <Logo/>
                </Box>
                {children}
            </Box>
        </Container>
    );
};

export default PublicPage;
