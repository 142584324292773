import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getFirestore, doc} from 'firebase/firestore';

export const saveOrg = createAsyncThunk(
  'app/saveOrg',
  async (org, {rejectWithValue}) => {
    try {
      await doc(getFirestore(), 'orgs', org.id).set(org, {merge: true});
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

const app = createSlice({
  name: 'app',
  initialState: {
    drawerIsOpen: true,
    snacks: [],
    org: {},
    error: null
  },
  reducers: {
    toggleDrawer: (state, action) => {
      state.drawerIsOpen = !state.drawerIsOpen;
    },
    pushSnack: (state, action) => {
      state.snacks.push(action.payload);
    },
    popSnack: (state, action) => {
      state.snacks.pop();
    },
    updateOrg: (state, action) => {
      state.org = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    }
  }
});

export const { toggleDrawer, pushSnack, popSnack, updateOrg, setError } = app.actions;

export default app.reducer;
