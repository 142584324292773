import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const ShowAllFieldsToggle = ({value, onChange, label}) => {
    return (
      <FormControlLabel
        control={
          <Switch checked={value} onChange={onChange} color="primary"/>
        }
        label={label || "Show Additional Fields"}
      />
    )
};

export default ShowAllFieldsToggle;
