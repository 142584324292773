export const SECTION_SINGLE_FIELD = "single";
export const SECTION_FIELD_LIST = "list";
export const SECTION_PHOTO = "photo";
export const SECTION_MAP = "map";
export const SECTION_DIVIDER = "divider";
export const SECTION_TYPES = [SECTION_SINGLE_FIELD, SECTION_FIELD_LIST, SECTION_PHOTO, SECTION_MAP, SECTION_DIVIDER];

export const sectionTypeName = s => {
    switch(s) {
        case SECTION_SINGLE_FIELD:
            return "Single Field";
        case SECTION_FIELD_LIST:
            return "Field List";
        case SECTION_PHOTO:
            return "Photo";
        case SECTION_MAP:
            return "Map";
        case SECTION_DIVIDER:
            return "Divider";
        default:
            return "";
    }
}
