import React from "react";
import {FieldLabel} from "../../model/fields";

const FieldHeader = ({field, column, options}) => {
    return (
        <>
            {(column.labels||{})[field.path] || field.label(FieldLabel.REPORT_HEADER, options)}
        </>
    )
}
export default FieldHeader
