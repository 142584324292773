import React from "react";
import Skeleton from '@mui/material/Skeleton';


const ImagePlaceholder = ({message, sx, ...props}) => {
    return (
        <Skeleton variant="rectangular" animation={false} sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            color: 'grey.500',
            maxWidth: "100%",
            ...sx
        }} {...props}>
            { message || "No Image Available"}
        </Skeleton>
    );
};

export default ImagePlaceholder;
