import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {padStart} from "lodash";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import {TabPanel} from "../../util/Tab";
import {HeadCell, TableToolbar} from "../../util/Table";
import Spinner from "../../util/Spinner";
import CreditCardIcon from "../../util/CreditCardIcon";
import {Form, FormRow} from "../../util/Form";
import CardControl from "../../util/CardControl";
import ErrorAlert from "../../util/ErrorAlert";
import {planSelector} from "../selectors";
import {loadCards, changeCard, setBillingName} from '../state';


const CardPanel = (props) => {
    const dispatch = useDispatch();
    const plan = useSelector(planSelector);
    const cards = useSelector(state => state.pref.billing.cards);
    const billingName = useSelector(state => state.pref.billing.name);
    const error = useSelector(state => state.pref.error);
    const cardChanged = useSelector(state => state.pref.billing.cardChanged);
    const [newCard, setNewCard] = useState(null);
    const [stripe, setStripe] = useState(null);
    const [isWorking, setIsWorking] = useState(false);

    useEffect(() => {
        plan && dispatch && dispatch(loadCards());
    }, [plan, dispatch]);

    const handleChangeCard = (card, stripe) => {
        setNewCard(card);
        setStripe(stripe);
    };

    const handleUpdate = () => {
        setIsWorking(true);
        dispatch(changeCard({newCard, billingName, stripe}))
          .unwrap()
          .then(() => {
            setIsWorking(false);
            dispatch(loadCards());
        });
    };

    return (
        <TabPanel {...props}>
            {!cards && <Spinner message="Loading Card Information"/>}
            {cards && <>
                <Paper sx={{mb:4}}>
                    <TableToolbar title="Credit Card"/>
                    <TableContainer>
                        <Table>
                            {cards.length > 0 && <TableHead>
                                <TableRow>
                                    <HeadCell width="20%"/>
                                    <HeadCell width="40%">Number</HeadCell>
                                    <HeadCell width="40%">Expiry</HeadCell>
                                </TableRow>
                            </TableHead>}
                            <TableBody>
                                {cards.length === 0 &&
                                <TableRow>
                                    <TableCell>No card on file</TableCell>
                                </TableRow>
                                }
                                {cards.map(c =>
                                    <TableRow key={c.id}>
                                        <TableCell component="th" scope="row">
                                            <CreditCardIcon height="35px" type={c.card.brand}/>
                                        </TableCell>
                                        <TableCell>
                                            {`**** **** **** ${c.card.last4}`}
                                        </TableCell>
                                        <TableCell>
                                            {`${padStart(c.card.exp_month, 2, "0")}/${c.card.exp_year}`}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>

                <Typography gutterBottom>Update Credit Card</Typography>
                <Form>
                    <FormRow>
                        <Grid item xs={12}>
                            <TextField label="Name on Card" value={billingName} variant="outlined" fullWidth
                                       onChange={evt => dispatch(setBillingName(evt.target.value))}/>
                        </Grid>
                    </FormRow>
                    <FormRow verticalAlignment="center">
                        <Grid item style={{flexGrow: 1}}>
                            <CardControl onChange={handleChangeCard}/>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" disabled={isWorking || !newCard || !billingName} onClick={handleUpdate}>
                                Update
                            </Button>
                        </Grid>
                    </FormRow>
                    {cardChanged && <Alert severity="success">
                        <AlertTitle>Success</AlertTitle>
                        Your credit card has been successfully updated.
                    </Alert>}
                    {error && <ErrorAlert error={error}/>}
                </Form>

            </>}
        </TabPanel>
    )
};

export default CardPanel;
