export const MODE_SAVED = "saved";
export const MODE_QUERY = "query";
export const MODE_ID = "id";

export const NUMBER_BETWEEN = "Between";
export const NUMBER_GREATER = "Greater Than";
export const NUMBER_LESSER = "Less Than";

export const DATE_CUSTOM = "custom";
export const DATE_WITHIN = "within";

export const SHORTCUT_ICI = "IC&I Sales by Price & Date";
export const SHORTCUT_FAMILY = "Multi-Family Sales by Price & Date";
export const SHORTCUT_LAND = "Land Sales by Price & Date";
export const SHORTCUT_LEASE = "Leases by Rate & Entry Date";
export const SHORTCUT_RENT = "Rentals by Rent & Entry Date";


