import React from "react";
import {useSelector} from "react-redux";
import TocIcon from "@mui/icons-material/Toc";
import DescriptionIcon from "@mui/icons-material/Description";
import SettingsIcon from '@mui/icons-material/Settings';
import MapIcon from "@mui/icons-material/Map";
import {TabBar} from "../../util/Tab";
import SummaryPanel from "./SummaryPanel";
import DetailPanel from "./DetailPanel";
import MapPanel from "./MapPanel";
import GeneralPanel from "./GeneralPanel";
import SinglePanel from "./SinglePanel";
import {TYPE_SINGLE, TYPE_SUMMARY_DETAIL, VIEW_COMP_MAP, VIEW_COMP_TABLE, VIEW_DETAIL_SHEET, VIEW_SUMMARY_TABLE, viewTitle} from "../../model/reportTypes";


const ReportBuilder = () => {
    const config = useSelector(state => state.report.curr);
    const tabs = [
        // [{label: "Title Page", icon: <TitleIcon/>}, (p) => <TitlePanel {...p} />],
        [{label: "General", icon: <SettingsIcon/>}, (p) => <GeneralPanel {...p} />]
    ];

    if (config.type === TYPE_SUMMARY_DETAIL) {
        tabs.push([{label: viewTitle(VIEW_SUMMARY_TABLE), icon: <TocIcon/>}, (p) => <SummaryPanel {...p} />],
            [{label: viewTitle(VIEW_DETAIL_SHEET), icon: <DescriptionIcon/>}, (p) => <DetailPanel {...p} />]);
    }
    else if (config.type === TYPE_SINGLE) {
        tabs.push([{label: viewTitle(VIEW_COMP_TABLE), icon: <TocIcon/>}, (p) => <SinglePanel {...p} />]);
    }

    if (config.map?.include !== false) {
        tabs.push([{label: viewTitle(VIEW_COMP_MAP), icon: <MapIcon/>}, (p) => <MapPanel {...p} />]);
    }

    return (
        <TabBar tabs={tabs} centered={true} className="ReportBuilder"/>
    );
};

export default ReportBuilder;

