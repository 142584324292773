import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from 'react-router-dom';
import Box from '@mui/material/Box';
import Tooltip from "@mui/material/Tooltip";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import DeleteIcon from "@mui/icons-material/Delete";
import PersonIcon from '@mui/icons-material/Person';
import RefreshIcon from '@mui/icons-material/Refresh';
import ShareIcon from '@mui/icons-material/Share';
import Stack from '@mui/material/Stack';
import ToolBar from "../util/ToolBar";
import Page from "../util/Page";
import DataTable2, {Cell} from '../util/DataTable2';
import {UserAvatar} from '../util/UserAvatar';
import ToggleButton from '../util/ToggleButton';
import AssignDialog from './AssignDialog';
import ShareDialog from './ShareDialog';
import DeleteDialog from './DeleteDialog';
import {pluralize} from '../util/utils';
import {Field} from "../model/fields";
import {pushSnack} from '../app/state';
import {pageLists, popList, refreshLists, sortLists, setFilter, removeFilter} from './state';
import {hasRole, ROLE_ADMIN} from '../auth/roles';


const FIELDS =  [Field.TITLE, Field.CREATED, Field.UPDATED];

const ListActions = ({list}) => {
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [showAssign, setShowAssign] = useState(false);
    const [showShare, setShowShare] = useState(false);

    const isAdmin = hasRole(auth, ROLE_ADMIN);
    const hasOwner = list.users?.length > 0;
    const isMine = isAdmin || !hasOwner || list.users?.includes(auth.user.uid) === true;

    const handleAssign = (success) => {
      if (success) dispatch(pushSnack({message: `List ${list.title} has been assigned.`}));
      setShowAssign(false);
    }

    const handleShare = (success) => {
      if (success) dispatch(pushSnack({message: `List ${list.title} has been shared.`}));
      setShowShare(false);
    }

    const handleDelete = () => {
        setShowConfirmDelete(true);
    }

    return <>
        <IconButton size="large">
            <Tooltip title="Add List To Cart">
                <AddShoppingCartIcon onClick={() => dispatch(popList({list, appendToCart: true}))}/>
            </Tooltip>
        </IconButton>
        <IconButton size="large">
            <Tooltip title="Replace Cart with List">
                <RemoveShoppingCartIcon onClick={() => dispatch(popList({list, appendToCart:false}))}/>
            </Tooltip>
        </IconButton>
        {isMine && <>
          <IconButton onClick={handleDelete} size="large">
            <Tooltip title="Delete List">
              <DeleteIcon />
            </Tooltip>
          </IconButton>
          {hasOwner && <IconButton onClick={() => setShowShare(true)} size="large">
            <Tooltip title="Share List with a Team Member">
              <ShareIcon/>
            </Tooltip>
          </IconButton>}
        </>}
        {!hasOwner && <IconButton onClick={() => setShowAssign(true)} size="large">
            <Tooltip title="Assign List to Owner">
              <PersonIcon/>
            </Tooltip>
        </IconButton>}
        {showConfirmDelete && <DeleteDialog isOpen={showConfirmDelete} onClose={result => {
          if (result === true) {
            dispatch(pushSnack({message: `List ${list.title} has been deleted.`}));
          }
          setShowConfirmDelete(false)
        }} list={list}/>}
        {showAssign && <AssignDialog isOpen={showAssign} onClose={handleAssign} list={list}/>}
        {showShare && <ShareDialog isOpen={showShare} onClose={handleShare} list={list}/>}
    </>;
}

const AuthorCell = ({field, list, ...props}) => {
    return <Cell {...props}
         primary={<Typography>{field.render(list)}</Typography>}
         secondary={<Typography variant="subtitle2">{field.render(list, {fromNow: true})}</Typography>}
         avatar={!!list[`${field.path}_by`] ? <UserAvatar user={list.created_by} sx={{ width: 32, height: 32, fontSize: "1rem" }}/> : null }
    />;
}

const ListsPage = () => {
    const userId = useSelector(state => state.auth.user.uid);
    const list = useSelector(state => state.list.list);
    const numLists = useSelector(state => state.app.org.num_lists || 0);
    const userFilter = useSelector(state => {
        const f = (state.list.list.filters||[]).find(it => it.field === "users");
        return !!f;
    });
    const dispatch = useDispatch();

    useEffect(() => {
        if (list.page === -1) {
            dispatch(pageLists(true));
        }
    }, [list, dispatch]);

  const handleUserFilter = () => {
    if (userFilter) {
      dispatch(removeFilter(Field.USERS));
    }
    else {
      dispatch(setFilter({field: Field.USERS, value: userId, op: "array-contains"}))
    }
  }
    const handlePage = forward => {
        return dispatch(pageLists(forward));
    };

    const handleSort = (field, order) => {
        return dispatch(sortLists({field, order}));
    };
    return (
        <Page fluid={true} className="ListsPage">
            <ToolBar title="Lists">
            </ToolBar>
            <Container fixed>
                <DataTable2 data={list} noun="list" fields={FIELDS} totalCount={numLists} onPage={handlePage} onSort={handleSort}
                    cellRenderer={(list, field, props) => {
                        if (field === Field.TITLE) {
                            return <Cell {...props} data-id={list.id}
                                         primary={<Link to={`/list/${list.id}`}>{field.get(list)}</Link>}
                                         secondary={<Typography variant="subtitle2">{pluralize((list.comps||[]).length, 'comp')}</Typography>}
                            />;
                        }
                        if (field === Field.CREATED) {
                            return <AuthorCell {...props} field={Field.CREATED} list={list}/>;
                        }
                        if (field === Field.UPDATED) {
                            return <AuthorCell {...props} field={Field.UPDATED} list={list}/>;
                        }
                        return <Cell/>;
                    }}
                    toolbarRenderer={() => {
                        return (
                            <Box width="100%" display="flex" justifyContent="flex-end" alignItems="center" py={1}>
                              <Stack direction="row">
                                <Tooltip title="Refresh">
                                  <IconButton onClick={() => dispatch(refreshLists())} size="large">
                                    <RefreshIcon/>
                                  </IconButton>
                                </Tooltip>
                                <ToggleButton size="small" value="user" selected={userFilter} color="primary"
                                              onChange={handleUserFilter} sx={{ml:1}}>
                                  <Tooltip title={userFilter?"Show All Lists" : "Show only My Lists"}>
                                    <AccountCircleIcon/>
                                  </Tooltip>
                                </ToggleButton>
                              </Stack>
                            </Box>
                        );
                    }}
                    actionRenderer={(list) => <ListActions list={list}/>}
                    // styleFieldCell={(field, i) => i === 0 ? {width: "50%"}: {}}
                />
            </Container>
        </Page>
    )
}

export default ListsPage;

