import {createSlice} from '@reduxjs/toolkit';
import {FieldLabel, Fields} from '../model/fields';

const master = createSlice({
  name: 'master',
  initialState: {
    fields: null
  },
  reducers: {
    exportFieldsAsCSV: (state, action) => {
      const rows = Fields.list().map(f => {
        return [f.path, f.type, f.group, f.label(FieldLabel.FORM), f.label(), f.label(FieldLabel.REPORT_HEADER), f.label(FieldLabel.REPORT_BODY)];
      });

      rows.unshift(["Path", "Type", "Group", "Default", "Short", "Report Header", "Report Body"]);

      const csv = btoa(rows.map(row => row.join(",")).join("\n"));
      state.fields = encodeURI(`data:text/csv;charset=utf-8,${csv}`);

    },
    exportFieldsAsJSON: (state, action) => {
      const fields = Fields.list().map(f => {
        return {
          path: f.path,
          type: f.type,
          group: f.group,
          labels: {
            default: f.label(FieldLabel.DEFAULT),
            short: f.label(FieldLabel.SHORT),
            form: f.label(FieldLabel.FORM),
            report_header: f.label(FieldLabel.REPORT_HEADER),
            report_body: f.label(FieldLabel.REPORT_BODY),
          }
        }
      });

      const json = btoa(JSON.stringify(fields));
      state.fields = encodeURI(`data:application/json;charset=utf-8,${json}`);
    },
  }
  },
);

export const {
  exportFieldsAsCSV, exportFieldsAsJSON
} = master.actions;

export default master.reducer;
