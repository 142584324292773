import React, {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import EmailIcon from "@mui/icons-material/Email";

const EmailField = ({value, onChange}) => {
    const [email, setEmail] = useState(value||"");
    const [invalid, setInvalid] = useState(false);

    useEffect(() => {
        const isInvalid = email && !/^[a-zA-Z0-9\\-\\._]+@[a-zA-Z0-9\\-]+\.[A-Za-z]+$/.test(email);
        setInvalid(isInvalid);
        onChange(isInvalid ? "" : email);
    }, [email, onChange]);

    return (
        <TextField label="Email" name="email" value={email} onChange={e => setEmail(e.target.value)}
           variant="outlined" fullWidth helperText={invalid ? "Please enter a valid email address":null}
           InputProps={{
                startAdornment: <InputAdornment position="start">
                    <EmailIcon />
                </InputAdornment>
           }}/>
    )
};

export default EmailField;
