import React from "react";
import Box from "@mui/material/Box";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import Typography from "@mui/material/Typography";

const MoodPanel = ({mood, message, sx, ...props}) => {
    return <Box sx={{
        textAlign: 'center',
        ...sx
    }} {...props}>
        <Box>
            {mood === "sad" ?
                <SentimentVeryDissatisfiedIcon sx={{
                    fontSize: 65,
                    color: '#f44336'
                }}/>
                :
                <SentimentVerySatisfiedIcon sx={{
                    fontSize: 65,
                    color: '#4caf50'
                }}/>
            }
        </Box>
        <Typography variant="h3" sx={{mt:2}}>{message}</Typography>
    </Box>
};

export default MoodPanel;
