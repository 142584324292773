import React, {useMemo, useEffect} from 'react';
import {throttle} from 'lodash';
import { Loader } from '@googlemaps/js-api-loader';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Typography from '@mui/material/Typography';


const GooglePlaceInput = ({attributionEl, onPlace}) => {
    const [input, setInput] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const [autoService, setAutoService] = React.useState(null);
    const [placeService, setPlaceService] = React.useState(null);

    useEffect(() => {
        if (attributionEl) {
            new Loader({
                apiKey: 'AIzaSyD62q1tdgIlDCtp1rxZFSzzRqU2JPgr69k',
                version: "weekly",
                libraries: ["places"]
            }).importLibrary("places").then(({AutocompleteService, PlacesService}) => {
                setAutoService(new AutocompleteService());
                setPlaceService(new PlacesService(attributionEl));
            });
        }
    }, [attributionEl]);

    const handleChange = event => {
        setInput(event.target.value);
    };

    const fetch = useMemo( () => throttle((value, callback) => {
        if (!autoService) return;

        autoService.getPlacePredictions({
            input: value,
            componentRestrictions: {country: 'ca'},
            types: ['address'],
            locationBias: 'IP_BIAS'
        }).then(({predictions}) => {
            callback(predictions);
        });
    }, 200), [autoService]);

    const parseOption = option => {
        const f = option.structured_formatting;
        return {
            id: option.place_id,
            street: f.main_text,
            place: f.secondary_text,
            description: option.description,
            match: f.main_text_matched_substrings
        }
    };

    useEffect(() => {
        let active = true;

        if (input === '') {
            setOptions([]);
            return undefined;
        }

        fetch(input, results => {
            if (active) {
                setOptions(results.map(parseOption) || []);
            }
        });

        return () => {
            active = false;
        };
    }, [input, fetch]);

    const handlePlace = (place) => {
        // option from autocomplete
        placeService.getDetails({
            placeId: place.id || place.place_id,
        }, (result, status) => {
            const address = result.address_components.reduce((acc, it) => {
                acc[it.types[0]] = it.long_name;
                return acc;
            }, {});
            onPlace({
                type: 'address',
                street: [address.street_number, address.route].filter(it => !!it).join(" "),
                place: address.locality,
                region: address.administrative_area_level_1,
                postcode: address.postal_code,
                country: address.country,
                center: {
                    latitude: result.geometry.location.lat(),
                    longitude: result.geometry.location.lng(),
                }
            })
        });
    }

    const handleInput = input => {
        if (!input) {
            onPlace(null);
        }
        else {
            if (!placeService) return;

            if (typeof input === 'string') {
                // free solo mode
                placeService.textSearch({
                    query: input,
                    region: 'ca',
                    type: 'address'
                }, (results, status) => {
                    if (results.length > 0) {
                        console.log(results[0]);
                        handlePlace(results[0]);
                    }
                });
            }
            else {
                handlePlace(input);
            }
        }
    }

    return (
        <Autocomplete
            options={options}
            autoComplete
            includeInputInList
            freeSolo
            onChange={(e,v) => handleInput(v)}
            getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
            renderInput={params => (
                <TextField
                    {...params}
                    label="Enter a property address or a place name"
                    variant="outlined"
                    fullWidth
                    autoFocus
                    onChange={handleChange}
                />
            )}
            renderOption={(props, option) => {
                return (
                    <li {...props}>
                        <Grid container alignItems="center">
                            <Grid item>
                                <LocationOnIcon sx={{color: 'text.secondary', mr: 2}}/>
                            </Grid>
                            <Grid item xs>
                                <span>{option.street}</span>
                                <Typography variant="body2" color="textSecondary">
                                    {option.place}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
}

export default GooglePlaceInput;