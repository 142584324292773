import React from "react";
import {applyTextStyle, spacingAsObject} from '../config';
import FieldValue from "./FieldValue";
import {FieldLabel} from "../../model/fields";

const FieldList = ({section, comp, bodyStyle, config, options}) => {
    const cellStyle = {
        ...bodyStyle,
        paddingBottom: 0,
        verticalAlign: "top"
    };

    const splitCellStyle = {
        paddingLeft: spacingAsObject(config.style.margin, 0, 0, 0, 2).paddingLeft + cellStyle.paddingLeft
    }

    const skipIfNull = (config.options||{}).skipEmptyFields === true;

    const rows = [[]];
    let room = section.span;
    section.fields.forEach(f => {
        if (skipIfNull) {
            const v = f.get(comp, options);
            if (!v) {
                if (v === 0 && options.zeroAsEmpty) return null;
            }
            if (v == null) return null;
        }

        const span = ((section.spans||{})[f.path] || 2);
        if (room < span) {
            rows.push([]);
            room = section.span;
        }
        rows[rows.length - 1].push(f);
        room -= span;
    });

    return (
      <>
          {rows.map((r,i) => {
              const numCols = section.span*2;
              const cols = [];
              const colWidth = 100 / numCols;

              r.forEach((f, j) => {
                  const style = (section.styles ||{})[f.path]
                  const label = (section.labels ||{})[f.path];
                  const span = (section.spans || {})[f.path] || 2;

                  const thisFieldCellStyle = applyTextStyle(((span===1 && j>0) ? {
                      ...cellStyle,
                      ...splitCellStyle,
                  } : cellStyle), style, "label");

                  const thisCellStyle = applyTextStyle(cellStyle, style, "value");

                  cols.push(<td key={`${f.path}_${j}_k`} width={`${colWidth}%`} style={thisFieldCellStyle} colSpan={1}>{label || f.label(FieldLabel.REPORT_BODY)}:</td>);
                  cols.push(<td key={`${f.path}_${j}_v`} width={`${colWidth*(2*span-1)}%`} style={{textAlign: section.span===2?"left":"right", ...thisCellStyle}} colSpan={2*span-1}>
                      <FieldValue field={f} comp={comp} options={options}/>
                  </td>);
              })

              return <tr key={i}>
                  {cols}
              </tr>
          })}
      </>
    );
};

export default FieldList;
