import React from "react";
import {useDispatch, useSelector} from "react-redux";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Alert from '@mui/material/Alert';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AdjustIcon from '@mui/icons-material/Adjust';
import FieldSelect from "../util/FieldSelect";
import SelectWithLabel from "../util/SelectWithLabel";
import DateRangeChooser from "./DateRangeChooser";
import NumberRangeChooser from "./NumberRangeChooser";
import {FieldLabel, FieldType} from "../model/fields";
import {removeFilter, updateFilter} from './state';


const DateFilter = ({filter, onChange}) => {
    return (
        <DateRangeChooser value={filter.value} onChange={onChange}/>
    )
};

const NumericFilter = ({filter, onChange}) => {
    return (
        <NumberRangeChooser onChange={onChange} value={filter.value}
            type={filter.field.type} inputProps={{ sx: {width: "100px"} }}/>
    );
};

const SelectFilter = ({filter, onChange}) => {
    // const typeFilter = useSelector(state => state.find.search.filters[0]);
    const filters = useSelector(state => state.find.search.filters);
    const comp = filters.filter(f => !!f.field).filter(f => f.field.path !== filter.field.path).reduce((obj, f) => {
        let v = f.value;
        if (Array.isArray(v) && v.length > 0) v = v[0];
        f.field.set(obj, v);
        return obj;
    }, {});

    const defaultVal = filter.multi === true ? [] : ""
    return (
        <SelectWithLabel value={filter.value||defaultVal} values={filter.field.values(comp)}
                         onChange={evt => onChange(evt.target.value)} size="small" className={`${filter.field.path}-Filter`} multiple={filter.multi}/>
    )
}

const BooleanFilter = ({filter, onChange}) => {
    return (
        <SelectWithLabel value={filter.value} values={[[true, "Yes"], [false, "No"]]}
                         onChange={evt => onChange(evt.target.value)} size="small" />
    )
};

const GeoFilter = ({filter, onChange}) => {
    return (
        <Typography>Define a search area on the map.</Typography>
    )
}

const Filter = ({filter}) => {
    const filters = useSelector(state => state.find.search.filters);
    const dispatch = useDispatch();

    const handleChange = v => dispatch(updateFilter({filter, key: "value", value:v}));

    let missingReqs = false;
    if ((filter.requires||[]).length > 0) {
        for (const r of filter.requires) {
            const it = filters.find(f => (f.field === r) && f.value);
            if (!it) {
                missingReqs = true;
                break;
            }
        }
    }

    const f = filter.field;
    return (
        <>
            <Typography variant="subtitle2">{f ? f.label() : "Other"}</Typography>
            <Grid container alignItems="center">
                {missingReqs ? <Grid item>
                    <Alert severity="warning" variant="outlined" sx={{py:0.1, px: 0.5}}>
                        Requires <em>{filter.requires.map(r => r.label(FieldLabel.SHORT)).join(',')}</em> filter(s).
                    </Alert>
                </Grid> : <>
                    {!f && <Grid item>
                        <FieldSelect inputSize="small" onChange={(f) => dispatch(updateFilter({filter, key:"field", value:f}))}/>
                    </Grid>}
                    {f && <Grid item>
                        {f.hasValues ? <SelectFilter filter={filter} onChange={handleChange}/> :
                            f.type === FieldType.DATE ? <DateFilter filter={filter} onChange={handleChange}/> :
                                f.type === FieldType.BOOLEAN ? <BooleanFilter filter={filter} onChange={handleChange}/> :
                                    f.type === FieldType.GEO ? <GeoFilter filter={filter} onChange={handleChange}/> :
                                        FieldType.isNumeric(f.type) ? <NumericFilter filter={filter} onChange={handleChange}/> :
                                            <TextField variant="outlined" size="small" fullWidth={true} value={filter.value}
                                                       onChange={evt => handleChange(evt.target.value)} />}
                    </Grid>}
                    <Grid item>
                        {filter.other &&
                        <Tooltip title="Change Field">
                            <IconButton onClick={() => dispatch(updateFilter({filter, key:"field", value:null}))} size="small">
                                <AdjustIcon/>
                            </IconButton>
                        </Tooltip>
                        }
                        {!filter.fixed &&
                        <Tooltip title="Remove Search Filter">
                            <IconButton color="secondary" onClick={() => dispatch(removeFilter(filter))} size="small">
                                <HighlightOffIcon/>
                            </IconButton>
                        </Tooltip>
                        }
                    </Grid>
                </>}
            </Grid>
        </>

    );
}

export default Filter;
