import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import LockIcon from "@mui/icons-material/Lock";
import {BadIcon, GoodIcon} from "./icons";

const PasswordInput = ({onChange}) => {
    const testPass = pass => {
        const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        return re.test(pass);
    };
    const [pass, setPass] = useState("");
    const [passOk, setPassOk] = useState(false);
    const handlePass = e => {
        const p = e.target.value.trim();
        setPass(p);
        setPassOk(testPass(p));
    };

    const [passMatch, setPassMatch] = useState(false);
    const [passConfirm, setPassConfirm] = useState("");
    const handlePassConfirm = e => {
        const p = e.target.value.trim();
        setPassConfirm(p);
        setPassMatch(p === pass);
    };

    useEffect(() => {
        if (pass && passOk && passMatch) {
            onChange && onChange(pass);
        }
        else {
            onChange && onChange("");
        }
    }, [pass, passOk, passMatch, onChange]);

    return (
        <>
            <Grid item md={6}>
                <TextField label="Password" name="pass" type="password" value={pass}
                           onChange={handlePass}
                           helperText="At least 8 characters with a mix of numbers, symbols, lower, and upper case."
                           variant="outlined" fullWidth
                           InputProps={{
                               startAdornment: <InputAdornment position="start">
                                    <LockIcon/>
                               </InputAdornment>,
                               endAdornment: pass && (
                                   <InputAdornment position="end">
                                       {passOk ? <GoodIcon/> : <BadIcon/> }
                                   </InputAdornment>
                               )
                           }}
                />
            </Grid>
            <Grid item md={6}>
                <TextField disabled={!passOk} label="Confirm Password" name="passConfirm" type="password"
                           onChange={handlePassConfirm}
                           variant="outlined" fullWidth
                           InputProps={{
                               endAdornment: passConfirm && (
                                   <InputAdornment position="end">
                                       {passMatch ? <GoodIcon/> : <BadIcon/> }
                                   </InputAdornment>
                               )
                           }}
                />
            </Grid>
        </>
    )
};

export default PasswordInput;
