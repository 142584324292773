import React, {useState} from "react";
import {Grid} from "@mui/material";
import PublicPage from "../util/PublicPage";
import Typography from "@mui/material/Typography";
import {Form, FormRow} from "../util/Form";
import EmailField from "../util/EmailField";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CircularProgress from "@mui/material/CircularProgress";
import {ExitToApp} from "@mui/icons-material";

const SignupPage = () => {
    const [email, setEmail] = useState("");
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [isWorking, setIsWorking] = useState(false);

    const functionHost = window.location.hostname === "localhost" ?
        "http://localhost:5001/mastercomp-c3960/us-central1" :
        "https://us-central1-mastercomp-c3960.cloudfunctions.net";

    const handleSubmit = evt => {
        setIsWorking(true);

        evt.preventDefault();
        evt.stopPropagation();

        const payload = {
            email,
            origin: window.location.origin
        };
        fetch(`${functionHost}/signup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        }).then(res => res.json()).then((body) => {
            if (body.status === "failure") {
                setError(typeof body.error === "string" ? body.error : "Oops");
            }
            else {
                window.localStorage.setItem("quickcomp.signupEmail", email);
                setSuccess(true);
            }
        }).catch(error => {
            setError("Oops");
        });
    };

    return (
        <PublicPage>
            <Typography variant="h3" gutterBottom>Thank you for choosing QuickComp. We just need your email address to get started.</Typography>
            <Form size="md" onSubmit={handleSubmit} sx={{mt: 2}}>
                <FormRow>
                    <Grid item xs={12}>
                        <EmailField value={email} onChange={setEmail}/>
                    </Grid>
                </FormRow>
                <FormRow alignment="center">
                    <Grid item xs={12}>
                        {error && <Alert severity="error" fullWidth sx={{textAlign: 'left'}}>
                            <AlertTitle>{error}</AlertTitle>
                            <div>
                                We're so sorry but there was a problem handling your signup. Please try again or feel free to email us at <Link href="mailto:hello@quickcomp.io">hello@quickcomp.io</Link> and we'll be happy to set up your account.
                            </div>
                        </Alert>}
                        {success && <Alert severity="success" fullWidth sx={{textAlign: 'left'}}>
                            <AlertTitle>Success</AlertTitle>
                            <div>
                                Check your inbox. You should receive an email from us within the next five minutes. If nothing shows up be sure to check your Spam folder.
                            </div>
                        </Alert>}
                        {!error && !success && <Button
                            type="submit"
                            disabled={isWorking || !email}
                            variant="contained"
                            color="primary"
                            size="large"
                            startIcon={isWorking ? <CircularProgress size={24}/> : <ExitToApp />}
                        >
                            Let's Go
                        </Button>}
                    </Grid>
                </FormRow>
            </Form>
        </PublicPage>
    );
};

export default SignupPage;
