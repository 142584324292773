import React, {Fragment} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Route} from "react-router-dom";
import {ErrorBoundary} from 'react-error-boundary'
import {collection, doc, onSnapshot, query, where, orderBy, getFirestore} from 'firebase/firestore';
import Box from '@mui/material/Box';
import AuthPage from "../auth/AuthPage";
import DashPage from "../dash/DashPage";
import AddPage from "../comp/AddPage";
import CompPage from "../comp/CompPage";
import FindPage from "../find/FindPage";
import SearchPage from "../search/SearchPage";
import ProfilePage from '../profile/ProfilePage';
import ReportPage from "../report/ReportPage";
import PrefPage from "../pref/PrefPage";
import MasterPage from "../master/MasterPage";
import SupportPage from '../support/SupportPage';
import useHasFeature from '../util/useHasFeature';
import {unsubscribe} from '../util/utils';
import Snacker from "./Snacker";
import Header from "./Header";
import {hasRole, ROLE_ADMIN, ROLE_EDITOR, ROLE_MASTER} from "../auth/roles";
import CompsPage from "../comp/CompsPage";
import ReportsPage from "../report/ReportsPage"
import SheetsPage from "../sheet/SheetsPage";
import SheetPage from "../sheet/SheetPage";
import ListsPage from "../list/ListsPage";
import ListPage from "../list/ListPage";
import TemplatesPage from "../template/TemplatesPage";
import {Comp, Org} from "../model/objects";
import {Field} from "../model/fields";
import ErrorHandler from "./ErrorHandler";
import {compAdded, compModified, compRemoved} from '../comp/state';
import {setError, updateOrg} from './state';

const Main = () => {
    const hasSupport = useHasFeature("support");
    const isEditor = useSelector(state => hasRole(state.auth, ROLE_EDITOR));
    const isAdmin = useSelector(state => hasRole(state.auth, ROLE_ADMIN));
    const isMaster = useSelector(state => state.auth && (state.auth.roles||[]).includes(ROLE_MASTER));
    //const isOwner = useSelector(state => state.auth && (state.auth.roles||[]).includes(ROLE_OWNER));
    const userId = useSelector(state => state.auth && state.auth.user && state.auth.user.uid);
    const orgId = useSelector(state => state.auth != null && state.auth.org != null ? state.auth.org.id : null);
    const dispatch = useDispatch();

    // listen to org changes
    React.useEffect(() => {
        if (orgId) {
            return unsubscribe("org changes",
              onSnapshot(doc(getFirestore(), 'orgs', orgId),doc => dispatch(updateOrg(Org.create(doc)))));
        }
    }, [orgId, dispatch]);

    // listen to comp changes
    React.useEffect(() => {
        if (orgId && userId) {
            return unsubscribe("comp changes", onSnapshot(
              query(
                collection(getFirestore(), 'orgs', orgId, 'comps'),
                where(Field.UPDATED.name, ">", new Date()),
                orderBy(Field.UPDATED.name, "asc")
              ),
              snap => {
                  snap.docChanges().forEach(delta => {
                      const comp = Comp.create(delta.doc);
                      switch(delta.type) {
                          case "added":
                              dispatch(compAdded({comp, userId})); break;
                          case "removed":
                              dispatch(compRemoved({comp ,userId})); break;
                          default:
                              dispatch(compModified({comp, userId}));
                      }
                  });
            }));
        }
    }, [orgId, userId, dispatch]);

    return (
        <Fragment>
            <Snacker/>
            <Header/>
            {/*<Sidebar/>*/}
            <Box component="main" width="100%" flexGrow={1} sx={{minHeight: '100vh', display: 'flex', flexDirection: 'column'}}>
                <ErrorBoundary FallbackComponent={ErrorHandler}
                               onError={(error, info) => dispatch(setError(info))}>
                    <Route exact path="/" component={DashPage}/>
                    <Route exact path="/login" component={AuthPage}/>
                    <Route exact path="/add" component={AddPage}/>
                    <Route exact path="/comps" component={CompsPage} />
                    <Route exact path="/comp/:id" component={CompPage} />
                    <Route exact path="/find" component={FindPage} />
                    <Route exact path="/search" component={SearchPage} />
                    <Route exact path="/report" component={ReportPage} />
                    <Route exact path="/reports" component={ReportsPage} />
                    <Route exact path="/sheets" component={SheetsPage} />
                    <Route exact path="/sheet" component={SheetPage} />
                    <Route exact path="/sheet/:id" component={SheetPage} />
                    <Route exact path="/lists/" component={ListsPage} />
                    <Route exact path="/list/:id" component={ListPage} />
                    <Route exact path="/profile" component={ProfilePage} />
                    {hasSupport && <Route extact path="/support" component={SupportPage}/>}
                    {isEditor && <Route exact path="/templates" component={TemplatesPage} />}
                    {isAdmin && <Route exact path="/settings" component={PrefPage} />}
                    {isMaster && <Route exact path="/master" component={MasterPage} />}
                    {/*{isAdmin && <Route exact path="/backups" component={BackupPage} />}*/}
                </ErrorBoundary>
            </Box>
        </Fragment>
    )
};

export default Main;
