import React from "react";
import Grid from "@mui/material/Grid";
import FormHelperText from "@mui/material/FormHelperText";
import {FormRow} from "../../util/Form";
import CompEditorPanel from './CompEditorPanel';
import CompField from "./CompField";
import {LEASE, RESIDENTIAL_RENT} from "../../model/propertyTypes";
import {Field} from  "../../model/fields";

const ConfirmationPanel = ({comp, highlight, ...props}) => {

    const fieldProps = {comp, highlight};
    const isCurrentListing = Field.CONFIRMATION_SOURCE.get(comp) === "Current Listing";

    return (
        <CompEditorPanel name="ConfirmationPanel" comp={comp} {...props}>
            <FormRow>
                <Grid item md={6}>
                    <CompField field={Field.CONFIRMATION_SOURCE} {...fieldProps}/>
                </Grid>
                <Grid item md={6}>
                    {isCurrentListing ? <CompField field={Field.CONFIRMATION_LISTING_DATE} {...fieldProps}/> :
                                        <CompField field={Field.CONFIRMATION_DATE} {...fieldProps}/>}
                </Grid>
            </FormRow>
            <FormRow>
                <Grid item md={12}>
                    <CompField field={Field.CONFIRMATION_COMMENTS} multiline={true} {...fieldProps}/>
                </Grid>
            </FormRow>
            {comp.type === RESIDENTIAL_RENT ?
            <FormRow>
                <Grid item md={6}>
                    <CompField field={Field.CONFIRMATION_MLS_NUM} {...fieldProps}/>
                </Grid>
            </FormRow>
            : null}
            {(comp.type === LEASE || comp.type === RESIDENTIAL_RENT) &&
            <FormRow>
                <Grid item md={6}>
                    <CompField field={Field.CONFIRMATION_IS_CONFIDENTIAL} {...fieldProps}/>
                    <FormHelperText>Mark as confidential to hide the address of the comp in an output report.</FormHelperText>
                </Grid>
            </FormRow>
            }
        </CompEditorPanel>
    )
};

export default ConfirmationPanel;
