import React from "react";
import Grid from "@mui/material/Grid";
import {FormRow} from "../../util/Form";
import CompEditorPanel from './CompEditorPanel';
import CompField from "./CompField";
import {Field} from  "../../model/fields";

const UtilityPanel = ({comp, highlight, ...props}) => {
    const fieldProps = {comp, highlight };
    const waterIsOther = Field.UTILITIES_WATER.get(comp) === "Other";
    const sewerIsOther = Field.UTILITIES_SEWER.get(comp) === "Other";
    return (
        <CompEditorPanel name="UtilityPanel" comp={comp} {...props}>
            <FormRow>
                <Grid item md={6}>
                    <CompField field={Field.UTILITIES_WATER} {...fieldProps}/>
                </Grid>
                <Grid item md={6}>
                    <CompField field={Field.UTILITIES_SEWER} {...fieldProps}/>
                </Grid>
            </FormRow>
            {(waterIsOther || sewerIsOther) && <FormRow>
                <Grid item md={6}>
                    {waterIsOther && <CompField field={Field.UTILITIES_WATER_OTHER} {...fieldProps}/>}
                </Grid>
                {sewerIsOther && <Grid item md={6}>
                    <CompField field={Field.UTILITIES_SEWER_OTHER} {...fieldProps}/>
                </Grid>}
            </FormRow>}
            <FormRow>
                <Grid item md={6}>
                    <CompField field={Field.UTILITIES_HYDRO} {...fieldProps}/>
                </Grid>
                <Grid item md={6}>
                    <CompField field={Field.UTILITIES_NATURAL_GAS} {...fieldProps}/>
                </Grid>
            </FormRow>
            <FormRow>
                <Grid item md={12}>
                    <CompField field={Field.UTILITIES_DESCRIPTION} {...fieldProps}/>
                </Grid>
            </FormRow>
        </CompEditorPanel>
    )
};

export default UtilityPanel;
