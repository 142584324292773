import CardHeader from '@mui/material/CardHeader';
import React from "react";
import Box from '@mui/material/Box';
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import TableContainer from "@mui/material/TableContainer";
import Grid from "@mui/material/Grid";
import TablePagination from "@mui/material/TablePagination";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import Spinner from "./Spinner";
import {FieldType} from "../model/fields";
import {pluralize} from "./utils";


const DataTable2 = ({data, noun, fields, totalCount = -1, cellRenderer, actionRenderer, toolbarRenderer, onPage, onSort, canSort}) => {
    const [isPaging, setIsPaging] = React.useState(false);

    const hasFilters = data.filters && data.filters.length > 0;
    const isLastPage = !data.last;
    const count = isLastPage ? (data.size * data.page + data.values.length) : (!hasFilters ? totalCount : -1);

    React.useEffect(() => {
        if (data.page === -1) {
            onPage(true);
        }
    }, [data, onPage]);

    const handleChangePage = (evt, newPage) => {
        if (isPaging) return;
        setIsPaging(true);
        onPage(newPage > data.page).then(() => setIsPaging(false));
    };

    const renderCell = (item, field, props) => {
        const cell = cellRenderer(item, field, props);
        if (cell) return cell;

        const p = <Typography component="div">{field.render(item)}</Typography>;
        if (field.type === FieldType.DATE) {
            const fromNow = field.render(item, {fromNow: true});
            return <Cell primary={p} secondary={<Typography variant="subtitle2">{fromNow}</Typography>} {...props}/>

        }
        return <Cell primary={<Typography component="div">{field.render(item)}</Typography>} {...props}/>;
    }

    return (
        <Box sx={{width: '100%',}}>
            <Paper sx={{
                display: "flex",
                flexDirection: "column",
                width: '100%',
                mb: 2,
                minHeight: "75vh"
            }}>
                {toolbarRenderer && <TableToolbar page={data.page} noun={noun} renderer={toolbarRenderer}/>}
                <TableContainer sx={{flexGrow: 1}}>
                    <Table className={`DataTable`}>
                        <TableHeader {...{fields, sort: data.sort, onSort, canSort, withActions: actionRenderer !== null}} />
                        {data.page > -1 &&
                        <TableBody>
                            {data.values.map(item => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={item.id}
                                    >
                                        {cellRenderer(item, null)}
                                        {fields.map(f => renderCell(item, f, {key: `${item.id}-${f.path}`})) }
                                        {actionRenderer && <TableCell>{actionRenderer(item)}</TableCell>}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                        }
                    </Table>
                </TableContainer>
                <Grid container sx={{bgcolor: 'grey.100'}}>
                    {isPaging && <Spinner/>}
                    {data.page > -1 &&
                    <TablePagination
                        rowsPerPageOptions={[]}
                        component="div"
                        count={count}
                        rowsPerPage={data.size}
                        page={data.page}
                        onPageChange={handleChangePage}
                        nextIconButtonProps={isLastPage ? {disabled: true} : null}
                        sx={{flexGrow: 1}}
                    />
                    }
                </Grid>
            </Paper>
        </Box>
    );
}

export const Cell = ({primary, secondary, avatar, sx, ...props}) => {
    return (
        <TableCell {...props}>
            <CardHeader title={primary} subheader={secondary} avatar={avatar} sx={{
                p:0,
                ...sx
            }}/>
        </TableCell>
    )
}

const TableToolbar = ({page, noun, renderer}) => {
    return (
        <Toolbar variant="dense" sx={{ pl: 2, pr: 1, bgcolor: 'grey.100'}}>
            {page > -1 && renderer && renderer()}
            {page < 0 && <Spinner message={`Loading ${pluralize(2, noun, {withCount: false})}`}/> }
        </Toolbar>
    )
}

const TableHeader = ({ fields, sort, onSort, canSort = () => true, withActions}) => {

    const createSortHandler = field => () => {
        const isAsc = sort.field === field.path && sort.order === "asc";
        onSort(field, isAsc ? "desc" : "asc");
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                </TableCell>
                {fields.map((f) => {
                    const l = <Typography variant="subtitle2" sx={{
                        textTransform: "uppercase",
                        fontSize: "0.75rem"
                    }}>{f.label()}</Typography>;
                    return <TableCell key={f.path}>
                        {canSort(f) ? <TableSortLabel active={sort && sort.field === f.path}
                                                      direction={(sort && sort.field === f.path) ? sort.order : 'asc'}
                                                      onClick={sort && createSortHandler(f)}>
                            {l}
                        </TableSortLabel> : l}
                    </TableCell>
                })}
                {withActions && <TableCell key="actions"/>}
            </TableRow>
        </TableHead>
    );
};

export default DataTable2;
