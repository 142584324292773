import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {CircularProgress} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import ErrorAlert from '../util/ErrorAlert';
import {deleteList} from './state';

const DeleteDialog = ({list, isOpen, onClose}) => {
  const dispatch = useDispatch();
  const [isWorking, setWorking] = useState(false);
  const [error, setError] = useState();

  const handleDelete = () => {
    dispatch(deleteList(list))
      .then(() => onClose(true))
      .catch(setError)
      .finally(() => {
        setWorking(true);
      });
  }

  return (
    <Dialog open={isOpen} fullWidth maxWidth={"sm"} onClose={onClose}>
      <DialogTitle>
        Delete List
      </DialogTitle>
      <DialogContent dividers>
        {error && <ErrorAlert error={error}/>}
        <Typography>
          Are you sure you want to delete the list {list.title}?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} disabled={isWorking}>
          Cancel
        </Button>
        <Button onClick={handleDelete} color="primary" variant="outlined" disabled={isWorking}
                startIcon={isWorking ? <CircularProgress size={24}/> : <DeleteIcon/>}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default DeleteDialog;
