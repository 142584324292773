import React from "react";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import TextFieldsIcon from "@mui/icons-material/TextFields";
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import SelectWithLabel from "./SelectWithLabel";
import ColorPicker from "./ColorPicker";
import ToggleButton from "./ToggleButton";
import {
    STYLE_ITALIC,
    STYLE_NORMAL,
    WEIGHT_BOLD,
    WEIGHT_NORMAL,
    TRANSFORM_NONE,
    TRANSFORM_UPPERCASE,
    FONTS,
    DECORATION_UNDERLINE,
    DECORATION_NONE,
    ALIGN_CENTER,
    ALIGN_RIGHT,
    ALIGN_LEFT,
    ALIGN_JUSTIFY
} from "../model/fonts";


export const FontFamilySelect = ({fonts=FONTS, ...props}) => {
    return (<SelectWithLabel label={props.size === "small" ? "Family" : "Font Family"} values={fonts.map(f => f.type === 'divider' ? f : [f.id, f.name])} {...props}/>)
};

export const FontSizeSelect = (props) => {
    let values =new Array(40).fill(0).map((_,i) => i*0.5+6).map(it => [it, it]);
    return (<SelectWithLabel label={props.size === "small" ? "Size" : "Font Size"} values={values} {...props}/>)
};

const FontControl = ({font, onChange, size, disabled = false, showAlign=false, showFamily=true, showStyle=true, showColor=true, withFonts=FONTS, colorPickerOpts}) => {
    const sz = size || "medium";

    const handleChange = key => {
        return evt => {
            onChange(key, typeof evt === "string" ? evt : evt.target.value);
        }
    };
    const formats = [
        font.weight===WEIGHT_BOLD?"b":"",
        font.style===STYLE_ITALIC?"i":"",
        font.decoration===DECORATION_UNDERLINE?"u":"",
        font.transform===TRANSFORM_UPPERCASE?"uc":""
    ].filter(it => it !== "");

    const handleFormatChange = (evt, newFormats) => {
        onChange("weight", newFormats.includes("b")?WEIGHT_BOLD:WEIGHT_NORMAL);
        onChange("style", newFormats.includes("i")?STYLE_ITALIC:STYLE_NORMAL);
        onChange("decoration", newFormats.includes("u")?DECORATION_UNDERLINE:DECORATION_NONE);
        onChange("transform", newFormats.includes("uc")?TRANSFORM_UPPERCASE:TRANSFORM_NONE);
    };

    const handleAlignChange = (evt, newAlign) => {
        onChange("align", newAlign);
    }

    return (
        <Grid container spacing={2}>
            {showFamily && <Grid item xs={3}>
                <FontFamilySelect fonts={withFonts} value={font.family} onChange={handleChange("family")} size={sz} disabled={disabled}/>
            </Grid>}
            <Grid item xs={2}>
                <FontSizeSelect value={font.size} onChange={handleChange("size")} size={sz} disabled={disabled}/>
            </Grid>
            {showStyle && <Grid item>
                <ToggleButtonGroup size={sz} value={formats} onChange={handleFormatChange} disabled={disabled}>
                    <ToggleButton value="b" size={sz}>
                        <Tooltip title="Bold">
                            <FormatBoldIcon/>
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="i" size={sz}>
                        <Tooltip title="Italic">
                            <FormatItalicIcon/>
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="u" size={sz}>
                        <Tooltip title="Underline">
                            <FormatUnderlinedIcon/>
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="uc" size={sz}>
                        <Tooltip title="Uppercase">
                            <TextFieldsIcon/>
                        </Tooltip>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid>}
            {showAlign && <Grid item>
                <ToggleButtonGroup size={sz} value={font.align} onChange={handleAlignChange} exclusive={true} disabled={disabled}>
                    <ToggleButton value={ALIGN_LEFT} size={sz}>
                        <Tooltip title="Align left">
                            <FormatAlignLeftIcon/>
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value={ALIGN_CENTER} size={sz}>
                        <Tooltip title="Align center">
                            <FormatAlignCenterIcon/>
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value={ALIGN_RIGHT} size={sz}>
                        <Tooltip title="Align right">
                            <FormatAlignRightIcon/>
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value={ALIGN_JUSTIFY} size={sz}>
                        <Tooltip title="Align justified">
                            <FormatAlignJustifyIcon/>
                        </Tooltip>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid>}
            {showColor && <Grid item xs={2}>
                <ColorPicker value={font.color} onChange={handleChange("color")} size={sz} {...colorPickerOpts} disabled={disabled}/>
            </Grid>}
        </Grid>
    );
};

export default FontControl;
