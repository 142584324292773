import { green, grey, red } from '@mui/material/colors';

export const BLACK = "#000000";
export const ALMOST_BLACK = "#141414";
export const WHITE = "#ffffff";
export const ALMOST_WHITE = "#f7f7f7";
export const BLUE = "#4a90e2";
export const GREEN = "#388e3c";
export const RED = "#e53935";
export const LIGHT_GRAY = "#cccccc";
export const GRAY = "#bdbdbd";

export const CONFIRMED = red["500"];
export const CURRENT_LISTING = green["500"];
export const UNCONFIRMED = grey["500"];
