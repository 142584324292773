import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import {addToCart} from '../cart/state';
import {resultsSelector} from "./selectors";
import ListItemIcon from "@mui/material/ListItemIcon";
import {MODE_QUERY} from "./constants";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";


export const ResultsLabel = ({onSaveSearch, className}) => {
    const results = useSelector(resultsSelector);
    const search = useSelector(state => state.find.search);
    const compsInCart = useSelector(state => state.cart.comps.map(c => c.id));
    const dispatch = useDispatch();
    const [anchor, setAnchor] = useState(null);

    const isCompInCart = (compId) => (compsInCart||[]).includes(compId);

    const handleAddAllToCart = () => {
        setAnchor(null);
        dispatch(addToCart(results.comps.filter(comp => !isCompInCart(comp.id))));
    }

    const handleSaveSearch = () => {
        setAnchor(null);
        onSaveSearch();
    }

    const handleClick = (event) => {
        setAnchor(event.currentTarget);
    };
    const handleClose = () => {
        setAnchor(null);
    };

    return (
        <Grid container wrap="nowrap" alignItems="center" className={`${className} ResultsLabel`}>
            <Grid item>
                <Typography>Found {results.found}{results.overflow?"+":""} results.</Typography>
            </Grid>
            <Grid item>
                <IconButton size="small" disabled={results.found < 1} onClick={handleClick}>
                    <ArrowDropDownIcon/>
                </IconButton>
                <Menu
                    anchorEl={anchor}
                    // keepMounted
                    open={Boolean(anchor)}
                    onClose={handleClose}
                >
                    {results.found > -1 &&
                    <MenuItem onClick={handleAddAllToCart}>
                        <ListItemIcon>
                            <AddShoppingCartIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="inherit" noWrap>
                            Add all to Cart
                        </Typography>
                    </MenuItem>
                    }
                    {results.found > -1 && search.dirty && search.mode === MODE_QUERY &&
                    <MenuItem onClick={handleSaveSearch}>
                        <ListItemIcon>
                            <BookmarkBorderIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="inherit" noWrap>
                            Save this Search
                        </Typography>
                    </MenuItem>
                    }
                </Menu>
            </Grid>
        </Grid>
    )
}
