import {Stack} from '@mui/material';
import React from "react";
import {useSelector} from "react-redux";
import Box from "@mui/material/Box";
import Table from "./Table";
import Banner from "./Banner";
import Row from "./Row";
import PhotoPicker from "./PhotoPicker";
import {borderAsObject, spacingAsObject} from "../config";

export const SheetContent = ({padding, comp, config, options, ...props}) => {
    const tableStyle = {
        ...borderAsObject(config.style.border),
        ...spacingAsObject(config.style.margin, 2),
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0
    };

    const index = props.index;
    const offset = options.offset || 1;

    const photoPicker = new PhotoPicker(comp.photos);
    return (
      comp ? <Stack spacing={1}>
          {config.pages.map((page, p) => {
              const rows = [[]];
              let room = 2;
              page.sections.forEach(s => {
                  if (room < s.span) {
                      rows.push([]);
                      room = 2;
                  }
                  rows[rows.length - 1].push(s);
                  room -= s.span;
              });

              return (
                <Box p={padding} data-section="detail" data-comp-title={`${comp.title} ${index+offset}${p+1}`} key={p}>
                    <Table style={tableStyle}>
                        <Banner comp={comp} style={config.style} noun={config.options?.noun} {...props} offset={offset} page={p}/>
                        <tbody>
                        {rows.map((row, i) =>
                          <Row key={i} comp={comp} {...props} sheetIndex={index} row={row} index={i} rows={rows}
                               style={config.style} config={config} {...{photoPicker}} options={options}>
                          </Row>
                        )}
                        </tbody>
                    </Table>
                </Box>
              )
          })}
      </Stack> : <div/>
    );
}

const Sheet = ({comp, padding, ...props}) => {
    const config = useSelector(state => state.report.curr.detail);
    const options = useSelector(state => state.report.curr.options || {});

    return (<SheetContent comp={comp} config={config} options={options} padding={padding} {...props}/>)
};

export default Sheet;
