import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { max, min } from "lodash";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { MICROSOFT_FONTS, lookupFont } from '../../model/fonts';
import { SHEET_LAYOUT_COL, SHEET_LAYOUT_ROW, SHEET_STAT_AVG, SHEET_STAT_MAX, SHEET_STAT_MIN } from '../constants';
import { FieldLabel } from "../../model/fields";
import { ALMOST_BLACK, ALMOST_WHITE, BLUE, GREEN, RED } from "../../model/colors";
import { Comp } from "../../model/objects";
import { cellStyle, isUnitField } from '../utils';
import SheetEditorPanel from "./SheetEditorPanel";
import Fonts from '../../util/Fonts';

const styles = {
    headCell: {
        textTransform: "uppercase",
        whiteSpace: "nowrap",
        fontWeight: "bold",
        color: ALMOST_WHITE,
    },
    fieldCell: {
        backgroundColor: BLUE,
    },
    adjustCell: {
        backgroundColor: GREEN,
    },
    finalCell: {
        backgroundColor: RED,
    },
}

const timeAdjustmentValue = (comp, time, unitField, raw = false) => {
    if (unitField) {
        let value = unitField.get(comp);
        if (value && time.appreciation && time.date && time.field) {
            const date = Comp.date(time.field.get(comp));

            const monthsBetween = moment(time.date).diff(moment(date), 'days') / 30.5;
            value = (value * (Math.pow(1 + time.appreciation / 100.0, monthsBetween)));
        }

        return raw ? value : unitField.render(value);
    }
    return "";
};

const finalValue = (comp, time, unitField, raw = false) => {
    return timeAdjustmentValue(comp, time, unitField, raw);
};

const isMetricField = (field, metrics) => {
    return (metrics.fields || []).map(f => f.path).includes(field.path);
}

const calculateStat = (field, stat, values) => {
    let v;
    switch (stat.type) {
        case SHEET_STAT_MIN:
            v = min(values);
            break;
        case SHEET_STAT_MAX:
            v = max(values);
            break;
        case SHEET_STAT_AVG:
            v = values.reduce((p, c) => p + c, 0) / values.length;
            break;
        default:
            v = 0;
    }
    return field.render(v);
}

const calculateStatForField = (field, stat, comps) =>
    calculateStat(field, stat, comps.map(c => field.get(c)).filter(v => v !== null && typeof v !== 'undefined'));

const calculateStatForFinal = (unitField, time, stat, comps) =>
    calculateStat(unitField, stat, comps.map(c => finalValue(c, time, unitField, true)));

const metricStats = (sheet) => (sheet.metrics.stats||[]).filter(it => it.enabled===true);

const fieldLabel = (field, sheet) => {
    const label = sheet.labels && sheet.labels[field.path];
    return label || field.label(FieldLabel.SHORT);
}

export const RowLayout = ({ sheet, comps }) => {
    const time = sheet.time;
    const unit = sheet.unit;
    const isAdjustsEnabled = sheet.adjusts.enabled;
    const isTimeEnabled = time.enabled === true;
    const isMetricsEnabled = sheet.metrics && sheet.metrics.enabled === true;
    const isSubjectEnabled = sheet.subject?.enabled === true;
    const font = lookupFont(sheet.styles.base.font.family, MICROSOFT_FONTS);

    const borderRight = {
        borderRight: `1px solid ${ALMOST_BLACK}`
    };
    const borderLeft = {
        borderLeft: `1px solid ${ALMOST_BLACK}`
    };
    const borderBottom = {
        borderBottom: `1px solid ${ALMOST_BLACK}`
    };
    const indexHeadStyle = {
        ...cellStyle(sheet.styles.index.head, font),
        ...borderRight
    };
    const fieldHeadStyle = {
        ...cellStyle(sheet.styles.field.head, font),
        ...borderBottom,
    }
    const fieldBodyStyle = cellStyle(sheet.styles.field.body, font);

    const extraFieldStyles = sheet.styles.extra
        .map(it => ({field: it.field, head: {
            ...cellStyle(it.config.head, font),
            ...borderBottom
        }, body: cellStyle(it.config.body, font)}))
        .reduce((map, it) => {
            map[it.field] = it;
            return map;
        }, {});

    const pickFieldHeadStyle = f => {
        if (f.path in extraFieldStyles) return extraFieldStyles[f.path].head;
        return fieldHeadStyle;
    };

    const pickFieldBodyStyle = f => {
        if (f.path in extraFieldStyles) return extraFieldStyles[f.path].body;
        return fieldBodyStyle;
    };

    const unitHeadStyle = {
        ...cellStyle(sheet.styles.unit.head, font),
        ...borderBottom,
        ...borderRight
    };
    const unitBodyStyle = {
        ...cellStyle(sheet.styles.unit.body, font),
        ...borderRight
    };
    const adjustHeadStyle = {
        ...cellStyle(sheet.styles.adjust.head, font),
        ...borderBottom,
    }
    const adjustBodyStyle = cellStyle(sheet.styles.adjust.body, font);

    const totalAdjustHeadStyle = {
        ...cellStyle(sheet.styles.total.head, font),
        ...borderBottom,
    }
    const totalAdjustBodyStyle = {
        ...cellStyle(sheet.styles.total.body, font),
    }

    const timeHeadStyle = {
        ...cellStyle(sheet.styles.time.head, font),
        ...borderBottom
    }
    const timeBodyStyle = cellStyle(sheet.styles.time.body, font);
    const finalHeadStyle = {
        ...cellStyle(sheet.styles.final.head, font),
        ...borderBottom,
        ...borderLeft
    };
    const finalBodyStyle = {
        ...cellStyle(sheet.styles.final.body, font),
        ...borderLeft
    };

    const headerStyle = {
        ...cellStyle(sheet.styles.header, font)
    };
    const footerStyle = {
        ...cellStyle(sheet.styles.footer, font)
    };

    const numCols = 2 + (sheet.fields?.length??0) + (isAdjustsEnabled ? (sheet.adjusts?.columns?.length??0):0) + (isTimeEnabled?1:0);
    return (
        <>
            <TableHead>
                <TableRow>
                    <TableCell component="th" colSpan={numCols} style={headerStyle}>
                        {sheet.title}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell align="right" sx={{ ...styles.headCell, ...styles.fieldCell, ...borderBottom }} style={indexHeadStyle}></TableCell>
                    {sheet.fields.map((f, i) =>
                        <TableCell key={f.path} align={i > 0 ? "right" : "left"} sx={{ ...styles.headCell, ...styles.fieldCell }}
                            style={isUnitField(f, sheet) ? unitHeadStyle : pickFieldHeadStyle(f)}>
                            {fieldLabel(f, sheet)}
                        </TableCell>)}
                    {isTimeEnabled &&
                        <TableCell align="right" sx={{ ...styles.headCell, ...styles.adjustCell }} style={timeHeadStyle}>
                            {sheet.time.name}
                        </TableCell>}
                    {isAdjustsEnabled && sheet.adjusts.columns.map((a, i) =>
                        <TableCell key={a.id} align="right" sx={{ ...styles.headCell, ...styles.adjustCell }} style={adjustHeadStyle}>
                            {a.name}
                        </TableCell>)}
                    {isAdjustsEnabled && <TableCell align="right" sx={{ ...styles.headCell, ...styles.adjustCell }} style={totalAdjustHeadStyle}>
                        {sheet.labels?.total_quantitative||"Total Adjustments"}
                    </TableCell>}
                    {(isAdjustsEnabled || isTimeEnabled) && <TableCell align="right" sx={{ ...styles.headCell, ...styles.finalCell }} style={finalHeadStyle}>
                        Final {unit ? fieldLabel(unit, sheet) : "?"}
                    </TableCell>}
                </TableRow>
            </TableHead>
            <TableBody>
                {isSubjectEnabled && <TableRow>
                    <TableCell sx={{ ...styles.headCell, ...styles.fieldCell, ...borderBottom }} style={{
                        ...indexHeadStyle,
                    }}>Subject</TableCell>
                    {sheet.fields.map((f, i) =>
                        <TableCell key={f.path} component={i === 0 ? "th" : "td"} align={i > 0 ? "right" : "left"} style={{
                            ...(isUnitField(f, sheet) ? unitBodyStyle : pickFieldBodyStyle(f)), ...borderBottom
                        }}>
                        </TableCell>)}
                    {isTimeEnabled &&
                        <TableCell align="right" style={{ ...timeBodyStyle, ...borderBottom}}>
                        </TableCell>}
                    {isAdjustsEnabled && sheet.adjusts.columns.map((a, i) =>
                        <TableCell key={a.id} align="right" style={{ ...adjustBodyStyle }}>
                        </TableCell>)}
                    {isAdjustsEnabled && <TableCell align="right" style={{ ...totalAdjustBodyStyle, ...borderBottom }}>
                    </TableCell>}
                    {(isAdjustsEnabled||isTimeEnabled) && <TableCell align="right" style={{
                        ...finalBodyStyle, ...borderBottom
                    }}>
                    </TableCell>}
                </TableRow>}
                {comps.slice(0, 3).map((comp, i) => {
                    const isLastRow = i === (comps.slice(0, 3).length - 1);
                    return <TableRow key={comp.id}>
                        <TableCell sx={{ ...styles.headCell, ...styles.fieldCell }} style={{
                            ...indexHeadStyle,
                            ...(isLastRow && borderBottom)
                        }}>{`${sheet.noun??''} ${i + 1}`}</TableCell>
                        {sheet.fields.map((f, i) =>
                            <TableCell key={f.path} component={i === 0 ? "th" : "td"} align={i > 0 ? "right" : "left"} style={{
                                ...(isUnitField(f, sheet) ? unitBodyStyle : pickFieldBodyStyle(f)),
                                ...(isLastRow && borderBottom)
                            }}>
                                {f.render(comp)}
                            </TableCell>)}
                        {isTimeEnabled &&
                            <TableCell align="right" style={{ ...timeBodyStyle, ...(isLastRow && borderBottom) }}>
                                {finalValue(comp, time, unit)}
                            </TableCell>}
                        {isAdjustsEnabled && sheet.adjusts.columns.map((a, i) =>
                            <TableCell key={a.id} align="right" style={{ ...adjustBodyStyle, ...(isLastRow && borderBottom) }}>
                                0.00%
                            </TableCell>)}
                        {isAdjustsEnabled && <TableCell align="right" style={{ ...totalAdjustBodyStyle, ...(isLastRow && borderBottom) }}>
                            0.00%
                        </TableCell>}
                        {(isAdjustsEnabled||isTimeEnabled) && <TableCell align="right" style={{
                            ...finalBodyStyle,
                            ...(isLastRow && borderBottom)
                        }}>
                            {finalValue(comp, time, unit)}
                        </TableCell>}
                    </TableRow>
                })}
                {isMetricsEnabled && metricStats(sheet).map((s, i) => {
                    return (
                        <TableRow>
                            <TableCell align="right" style={{
                                ...indexHeadStyle
                            }}>
                                {s.label}
                            </TableCell>
                            {sheet.fields.map((f, j) => <TableCell key={f.path} align={j > 0 ? "right" : "left"}
                                style={isUnitField(f, sheet) ? unitBodyStyle : fieldBodyStyle}>
                                {isMetricField(f, sheet.metrics) ? calculateStatForField(f, s, comps) : ""}
                            </TableCell>)}
                            {isTimeEnabled && <TableCell />}
                            {isAdjustsEnabled && sheet.adjusts.columns.map((a, i) => <TableCell key={a.id} />)}
                            {isAdjustsEnabled && <TableCell style={totalAdjustBodyStyle}>0.00%</TableCell>}
                            {(isAdjustsEnabled||isTimeEnabled) && <TableCell align="right" style={finalBodyStyle}>
                                {unit && calculateStatForFinal(unit, time, s, comps)}
                            </TableCell>}
                        </TableRow>
                    )
                })}
                {sheet.footer && <TableRow>
                    <TableCell  colSpan={numCols} style={footerStyle}>
                        {sheet.footer}
                    </TableCell>
                </TableRow>}
            </TableBody>
        </>
    )
}

export const ColLayout = ({ sheet, comps }) => {
    const time = sheet.time;
    const unit = sheet.unit;
    const isAdjustsEnabled = sheet.adjusts.enabled;
    const isTimeEnabled = time.enabled === true;
    const isMetricsEnabled = sheet.metrics && sheet.metrics.enabled === true;
    const isSubjectEnabled = sheet.subject?.enabled === true;
    const font = lookupFont(sheet.styles.base.font.family, MICROSOFT_FONTS);

    const borderBottom = {
        borderBottom: `1px solid ${ALMOST_BLACK}`
    };
    const borderRight = {
        borderRight: `1px solid ${ALMOST_BLACK}`
    };
    const borderLeft = {
        borderLeft: `1px solid ${ALMOST_BLACK}`
    }

    const fieldHeadStyle = {
        ...cellStyle(sheet.styles.field.head, font),
        ...borderRight
    };
    const fieldBodyStyle = {
        ...cellStyle(sheet.styles.field.body, font)
    };
    const extraFieldStyles = sheet.styles.extra
        .map(it => ({field: it.field, head: {
            ...cellStyle(it.config.head, font),
            ...borderRight
        }, body: cellStyle(it.config.body, font)}))
        .reduce((map, it) => {
            map[it.field] = it;
            return map;
        }, {});

    const pickFieldHeadStyle = f => {
        if (f.path in extraFieldStyles) return extraFieldStyles[f.path].head;
        return fieldHeadStyle;
    };

    const pickFieldBodyStyle = f => {
        if (f.path in extraFieldStyles) return extraFieldStyles[f.path].body;
        return fieldBodyStyle;
    };

    const indexHeadStyle = {
        ...cellStyle(sheet.styles.index.head, font),
        ...borderRight,
        ...borderBottom
    };
    const indexBodyStyle = {
        ...cellStyle(sheet.styles.index.body, font),
        ...borderBottom
    };

    const unitHeadStyle = {
        ...cellStyle(sheet.styles.unit.head, font),
        ...borderBottom,
        ...borderRight
    };
    const unitBodyStyle = {
        ...cellStyle(sheet.styles.unit.body, font),
        ...borderBottom
    };
    const adjustHeadStyle = {
        ...cellStyle(sheet.styles.adjust.head, font),
        ...borderRight
    };
    const adjustBodyStyle = {
        ...cellStyle(sheet.styles.adjust.body, font)
    };

    const adjustTotalHeadStyle = {
        ...cellStyle(sheet.styles.total.head, font),
        ...borderRight,
        ...borderBottom
    };
    const adjustTotalBodyStyle = {
        ...cellStyle(sheet.styles.total.body, font),
        ...borderBottom,
    };

    const lastAdjustHeadStyle = {
        ...adjustHeadStyle,
        // ...borderBottom,
        ...borderRight
    }
    const lastAdjustBodyStyle = {
        ...adjustBodyStyle,
        // ...borderBottom
    }

    const timeHeadStyle = {
        ...cellStyle(sheet.styles.time.head, font),
        ...borderRight
    };
    const timeBodyStyle = {
        ...cellStyle(sheet.styles.time.body, font)
    };

    const finalHeadStyle = {
        ...cellStyle(sheet.styles.final.head, font),
        ...borderRight
    };

    const finalBodyStyle = {
        ...cellStyle(sheet.styles.final.body, font),
    };

    const headerStyle = {
        ...cellStyle(sheet.styles.header, font),
    };
    const footerStyle = {
        ...cellStyle(sheet.styles.footer, font)
    };

    comps = comps.slice(0, 3);
    const numCols = 1 + comps.length + (isMetricsEnabled ? sheet.metrics.stats?.length??0 : 0) + (isSubjectEnabled?1:0);
    return (
        <>
            <TableHead>
                <TableRow>
                    <TableCell component="th" colSpan={numCols} style={headerStyle}>
                        {sheet.title}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" sx={{ ...styles.headCell, ...styles.fieldCell }} style={indexHeadStyle}></TableCell>
                    {isSubjectEnabled && <TableCell style={{...indexBodyStyle, ...borderRight}}>Subject</TableCell>}
                    {comps.map((c, i) => <TableCell key={c.id} style={indexBodyStyle}>
                        {`${sheet.noun??''} ${i + 1}`}
                    </TableCell>)}
                    {isMetricsEnabled && metricStats(sheet).map((s, i) =>
                        <TableCell key={s.type} sx={styles.headCell} style={{
                            ...indexBodyStyle,
                            // ...metricsHeadStyle,
                            ...(i === 0 && borderLeft)
                        }}>
                            {s.label}
                        </TableCell>
                    )}
                </TableRow>
            </TableHead>
            <TableBody>
                {sheet.fields.map(f => {
                    const fieldBodyStyle = isUnitField(f, sheet) ? unitBodyStyle : pickFieldBodyStyle(f);
                    return <TableRow key={f.path}>
                        <TableCell component="th" sx={{ ...styles.headCell, ...styles.fieldCell }}
                                   style={isUnitField(f, sheet) ? unitHeadStyle : pickFieldHeadStyle(f)}>
                            {fieldLabel(f, sheet)}
                        </TableCell>
                        {isSubjectEnabled && <TableCell style={{...fieldBodyStyle, ...borderRight}}></TableCell>}
                        {comps.map(comp => <TableCell key={comp.id} style={fieldBodyStyle}>
                            {f.render(comp)}
                        </TableCell>)}
                        {isMetricsEnabled && metricStats(sheet).map((s, i) => <TableCell key={s.type} style={{
                            ...(i === 0 && borderLeft),
                            ...(fieldBodyStyle)
                            // ...(isUnitField(f, sheet) && borderBottom)
                        }}>
                            {isMetricField(f, sheet.metrics) ? calculateStatForField(f, s, comps) : ""}
                        </TableCell>)}
                    </TableRow>
                })}
                {isTimeEnabled && <TableRow>
                    <TableCell component="th" sx={{ ...styles.headCell, ...styles.adjustCell }} style={timeHeadStyle}>
                        {sheet.time.name}
                    </TableCell>
                    {isSubjectEnabled && <TableCell style={{...timeBodyStyle, ...borderRight}}></TableCell>}
                    {comps.map(comp => <TableCell key={comp.id} style={timeBodyStyle}>
                        {finalValue(comp, time, unit)}
                    </TableCell>)}
                    {isMetricsEnabled && metricStats(sheet).map((s, j) => <TableCell key={s.type} style={{
                        ...timeBodyStyle,
                        ...(j === 0 && borderLeft),
                    }}></TableCell>)}
                </TableRow>}
                {isAdjustsEnabled && sheet.adjusts.columns.map((a, i) => {
                    const fieldBodyStyle = i < (sheet.adjusts.columns.length - 1) ? adjustBodyStyle : lastAdjustBodyStyle;
                    return (
                        <TableRow key={a.id}>
                            <TableCell component="th" sx={{ ...styles.headCell, ...styles.adjustCell }}
                                style={i < (sheet.adjusts.columns.length - 1) ? adjustHeadStyle : lastAdjustHeadStyle}>{a.name}</TableCell>
                            {isSubjectEnabled && <TableCell style={{...fieldBodyStyle, ...borderRight}}></TableCell>}
                            {comps.map(comp => <TableCell key={comp.id} style={fieldBodyStyle}>
                                0.00%
                            </TableCell>)}
                            {isMetricsEnabled && metricStats(sheet).map((s, j) => <TableCell key={s.type} style={{
                                ...adjustBodyStyle,
                                ...(j === 0 && borderLeft),
                                // ...(i === (sheet.adjusts.columns.length - 1) && borderBottom)
                            }}>
                            </TableCell>)}
                        </TableRow>
                    )
                })}
                {(isAdjustsEnabled)&&<TableRow>
                    <TableCell component="th" sx={{ ...styles.headCell, ...styles.adjustCell }} style={adjustTotalHeadStyle}>{sheet.labels?.total_quantitative}</TableCell>
                    {isSubjectEnabled && <TableCell style={{...adjustTotalBodyStyle, ...borderRight}}></TableCell>}
                    {comps.map(comp => <TableCell key={comp.id} style={adjustTotalBodyStyle}>
                        0.00%
                    </TableCell>)}
                    {isMetricsEnabled && metricStats(sheet).map((s, j) => <TableCell key={s.type} style={{
                        ...adjustTotalBodyStyle,
                        ...(j === 0 && borderLeft),
                        ...borderBottom
                    }}>
                        0.00%
                    </TableCell>)}
                </TableRow>}
                {(isAdjustsEnabled||isTimeEnabled)&&<TableRow>
                    <TableCell component="th" sx={{ ...styles.headCell, ...styles.finalCell }}
                        style={finalHeadStyle}>Final {unit ? fieldLabel(unit, sheet) : "?"}</TableCell>
                    {isSubjectEnabled && <TableCell style={{...finalBodyStyle, ...borderRight}}></TableCell>}
                    {comps.map(comp => <TableCell key={comp.id} style={finalBodyStyle}>
                        {finalValue(comp, time, unit)}
                    </TableCell>)}
                    {isMetricsEnabled && metricStats(sheet).map((s, i) => <TableCell key={s.type} style={{
                        ...finalBodyStyle,
                        ...(i === 0 && borderLeft),
                    }}>{unit && calculateStatForFinal(unit, time, s, comps)}</TableCell>)}
                </TableRow>}
                {sheet.footer && <TableRow>
                    <TableCell colSpan={numCols} style={footerStyle}>
                        {sheet.footer}
                    </TableCell>
                </TableRow>}
            </TableBody>
        </>
    )
}

const PreviewPanel = ({sheet, ...props}) => {
    const comps = useSelector(state => state.cart.comps);
    
    return (
        <SheetEditorPanel {...props} sheet={sheet} name="PreviewPanel">
            <Fonts families={MICROSOFT_FONTS.map(it => it.previewId)}/>
            {/* <Box display="flex" justifyContent="flex-end">
                <Tooltip title="Jump to previous tab">
                    <IconButton onClick={() => dispatch(popTab())}>
                        <UndoIcon/>
                    </IconButton>
                </Tooltip>
            </Box> */}
            <TableContainer component={Paper} sx={{mt:2}}>
                <Table size="small" sx={{overflowX: 'scroll'}}>
                    {sheet.layout === SHEET_LAYOUT_ROW && <RowLayout sheet={sheet} comps={comps}/>}
                    {sheet.layout === SHEET_LAYOUT_COL && <ColLayout sheet={sheet} comps={comps}/>}
                </Table>
            </TableContainer>
        </SheetEditorPanel>
    );
}

export default PreviewPanel;