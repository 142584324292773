import React from "react";
import {useDispatch, useSelector} from "react-redux";
import mapboxgl from "mapbox-gl";
import request from "superagent";
import Box from "@mui/material/Box";
import {pushSnack} from '../../app/state';
import {MapWithMarker} from "../../util/Map";
import {renderMap, setGeo} from '../state';
import CompEditorPanel from './CompEditorPanel';
import {Field} from "../../model/fields";


const mapHeight = 384;

const MapPanel = ({comp, ...props}) => {
    const org = useSelector(state => state.app.org);
    const geo = useSelector(state => state.comp.curr.geo);
    const dispatch = useDispatch();
    const [map, setMap] = React.useState();
    const [place, setPlace] = React.useState();
    const [requestRenderMap, setRequestRenderMap] = React.useState(false);
    const [isMoving, setMoving] = React.useState(false);

    const handleGeoChange = ll => {
        dispatch(setGeo(ll.toArray()));
        setRequestRenderMap(true);
    };

    React.useEffect(() => {
        if (place) {
            // console.log(place);
            handleGeoChange(new mapboxgl.LngLat(place.center[0], place.center[1]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [place]);

    React.useEffect(() => {
        mapboxgl.accessToken = 'pk.eyJ1IjoiamRlb2xpdmUiLCJhIjoiY2p6aGNvb25uMHR0MDNucGlka2V1NHRpOCJ9.yKALxNb3NLxGBY9aL8Ofqg'
    }, []);

    React.useEffect(() => {
        if (!isMoving && requestRenderMap) {
            dispatch(renderMap());
            setRequestRenderMap(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMoving]);

    React.useEffect(() => {
        window.map = map;
        return () => window.map = null;
    }, [map]);

    const handleGeocodeResult = e => {
        setPlace(e.result);
    };

    const locateProperty = m => {
        const addr = `${Field.ADDRESS_STREET.get(comp)}, ${Field.ADDRESS_CITY.get(comp)}`;
        request.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${addr}.json`)
            .query({
                access_token: mapboxgl.accessToken,
                autocomplete: true,
                country: 'ca',
                types: 'address,place',
                limit: 1
            })
            .then(res => {
                const features = res.body.features;
                if (features.length > 0) {
                    const ll = features[0].geometry.coordinates;
                    m.flyTo({center: ll, zoom: 16});
                    setTimeout(() => {
                        handleGeoChange(new mapboxgl.LngLat(ll[0], ll[1]));
                    }, 500);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }
    const initMap = m => {
        setMap(m);
        m.addControl(new SaveControl(() => dispatch(renderMap()).unwrap().then(() => {
            dispatch(pushSnack({message: "Map thumbnail updated. Save comp to update."}))
        })), "top-left");
        m.addControl(new LocateControl(() => locateProperty(m)), "top-left");
    };

    const mapOpts = {
        ...(org.settings||{}).map
    };
    return (
        <CompEditorPanel name="MapPanel" comp={comp} {...props}>
            <Box sx={{position: "relative", height: mapHeight + "px"}}>
                <MapWithMarker geo={geo} onGeoChange={handleGeoChange} onMove={setMoving} height={mapHeight}
                       preserveDrawingBuffer={true} withGeocoder={true} onGeocodeResult={handleGeocodeResult}
                       initMap={initMap} {...mapOpts}/>
            </Box>
        </CompEditorPanel>
    );
};

export default MapPanel;


export class SaveControl {
    constructor(onSave) {
        this.onSave = onSave;
    }

    onAdd(map){
        this.map = map;

        const button = document.createElement("button");
        button.className = "mapbox-gl-draw_ctrl-draw-btn save-ctrl";
        button.style.setProperty("background-image", 'url(' + SaveIcon + ')');
        button.title = "Save Map Thumbnail";
        button.addEventListener("click", (evt) => {
            this.onSave(evt);
            evt.preventDefault();
            evt.stopPropagation();
        });

        this.container = document.createElement("div");
        this.container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
        this.container.appendChild(button);

        return this.container;
    }

    onRemove(){
        this.container.parentNode.removeChild(this.container);
        this.map = undefined;
        this.marker = undefined;
    }
}
export const SaveIcon = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIG1lZXQiIHZpZXdCb3g9IjAgMCAxMDI0IDEwMjQiIHN0eWxlPSItbXMtdHJhbnNmb3JtOiByb3RhdGUoMzYwZGVnKTsgLXdlYmtpdC10cmFuc2Zvcm06IHJvdGF0ZSgzNjBkZWcpOyB0cmFuc2Zvcm06IHJvdGF0ZSgzNjBkZWcpOyI+PHBhdGggZD0iTTg5My4zIDI5My4zTDczMC43IDEzMC43Yy03LjUtNy41LTE2LjctMTMtMjYuNy0xNlYxMTJIMTQ0Yy0xNy43IDAtMzIgMTQuMy0zMiAzMnY3MzZjMCAxNy43IDE0LjMgMzIgMzIgMzJoNzM2YzE3LjcgMCAzMi0xNC4zIDMyLTMyVjMzOC41YzAtMTctNi43LTMzLjItMTguNy00NS4yek0zODQgMTg0aDI1NnYxMDRIMzg0VjE4NHptNDU2IDY1NkgxODRWMTg0aDEzNnYxMzZjMCAxNy43IDE0LjMgMzIgMzIgMzJoMzIwYzE3LjcgMCAzMi0xNC4zIDMyLTMyVjIwNS44bDEzNiAxMzZWODQwek01MTIgNDQyYy03OS41IDAtMTQ0IDY0LjUtMTQ0IDE0NHM2NC41IDE0NCAxNDQgMTQ0czE0NC02NC41IDE0NC0xNDRzLTY0LjUtMTQ0LTE0NC0xNDR6bTAgMjI0Yy00NC4yIDAtODAtMzUuOC04MC04MHMzNS44LTgwIDgwLTgwczgwIDM1LjggODAgODBzLTM1LjggODAtODAgODB6IiBmaWxsPSIjNjI2MjYyIi8+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMjQiIGhlaWdodD0iMTAyNCIgZmlsbD0icmdiYSgwLCAwLCAwLCAwKSIgLz48L3N2Zz4K"

export class LocateControl {
    constructor(onLocate) {
        this.onLocate = onLocate;
    }

    onAdd(map){
        this.map = map;

        const button = document.createElement("button");
        button.className = "mapbox-gl-draw_ctrl-draw-btn locate-ctrl";
        button.style.setProperty("background-image", 'url(' + LocateIcon + ')');
        button.title = "Locate Property";
        button.addEventListener("click", evt => {
            this.onLocate(evt);
            evt.preventDefault();
            evt.stopPropagation();
        });

        this.container = document.createElement("div");
        this.container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
        this.container.appendChild(button);

        return this.container;
    }

    onRemove(){
        this.container.parentNode.removeChild(this.container);
        this.map = undefined;
        this.marker = undefined;
    }
}

export const LocateIcon = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij4KICAgIDxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiIGZpbGw9Im5vbmUiIC8+CiAgICA8cGF0aCBkPSJNMjAuOTQgMTFjLS40Ni00LjE3LTMuNzctNy40OC03Ljk0LTcuOTRWMWgtMnYyLjA2QzYuODMgMy41MiAzLjUyIDYuODMgMy4wNiAxMUgxdjJoMi4wNmMuNDYgNC4xNyAzLjc3IDcuNDggNy45NCA3Ljk0VjIzaDJ2LTIuMDZjNC4xNy0uNDYgNy40OC0zLjc3IDcuOTQtNy45NEgyM3YtMmgtMi4wNnpNMTIgMTljLTMuODcgMC03LTMuMTMtNy03czMuMTMtNyA3LTcgNyAzLjEzIDcgNy0zLjEzIDctNyA3eiIgZmlsbD0iIzYyNjI2MiIvPgo8L3N2Zz4K";
