import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import SvgIcon from "@mui/material/SvgIcon";
import Fab from "@mui/material/Fab";
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import {SpeedDial as MUISpeedDial, SpeedDialAction, styled} from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EventIcon from '@mui/icons-material/Event';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import ScheduleIcon from '@mui/icons-material/Schedule';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ApartmentIcon from '@mui/icons-material/Apartment';
import LandscapeIcon from '@mui/icons-material/Landscape';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import MenuIcon from '@mui/icons-material/Menu';
import TabIcon from '@mui/icons-material/Tab';
import HotelIcon from '@mui/icons-material/Hotel';
import BathtubIcon from '@mui/icons-material/Bathtub';
import MoneyIcon from '@mui/icons-material/Money';
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import PhotoSizeSelectSmallIcon from '@mui/icons-material/PhotoSizeSelectSmall';
import StoreIcon from '@mui/icons-material/Store';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import LocationDisabledIcon from '@mui/icons-material/LocationDisabled';
import { ReactComponent as PricePerSquareFootIcon } from '../util/price-psq.svg';
import { ReactComponent as PricePerUnit } from '../util/house-money.svg';
import Filter from "./Filter";
import {ICI, LEASE, MULTI_FAMILY, RESIDENTIAL_RENT, VACANT_LAND} from "../model/propertyTypes";
import {Field, FieldLabel} from "../model/fields";
import {splice} from "../util/utils";
import {addFilter, removeFilter} from './state';



const SpeedDial = styled(MUISpeedDial)(({theme}) => ({
    '&&': {
        alignItems: "flex-start"
    },
    '&& .MuiSpeedDial-actions': {
        flexWrap: "wrap"
    },
    '&& .MuiSpeedDial-fab': {
        flexShrink: 0
    },
    '&& .MuiSpeedDialAction-staticTooltip': {
        flexDirection:"row-reverse",
        marginRight: theme.spacing(2)
    },
    '&& .MuiSpeedDialAction-staticTooltipLabel': {
        position: "relative",
        left: "0 !important",
        marginLeft: "0 !important",
        whiteSpace: "nowrap"
    }
}));

const COMMON_FILTERS = [{
    field: Field.SALES_PRICE, icon: <AttachMoneyIcon/>
}, {
    field: Field.SALES_DATE, icon: <EventIcon/>
}, {
    field: Field.CREATED, icon: <ScheduleIcon/>
}, {
    field: Field.PHYSICAL_PROPERTY_TYPE, icon: <HomeWorkIcon/>
}, {
    field: Field.CONFIRMATION_SOURCE, icon: <FiberManualRecordIcon/>
}]
const FILTERS = {
    [ICI]: [...splice(
        COMMON_FILTERS, 4, 0, {
            field: Field.PHYSICAL_PROPERTY_SUBTYPE,
            icon: <StoreIcon/>,
            requires: [Field.PHYSICAL_PROPERTY_TYPE]
        }), {
        field: Field.IMPROVEMENT_GROSS_BUILDING_AREA, icon: <PhotoSizeSelectSmallIcon/>
    },{
        field: Field.FINANCIAL_CAP_RATE, icon: <TrendingUpIcon/>
    }],
    [MULTI_FAMILY]: [...COMMON_FILTERS, {
        field: Field.IMPROVEMENT_NUM_UNITS, icon: <ApartmentIcon/>
    }, {
        field: Field.SALES_PRICE_PER_UNIT, icon: <SvgIcon component={PricePerUnit}/>
    }, {
        field: Field.IMPROVEMENT_GROSS_BUILDING_AREA, icon: <PhotoSizeSelectSmallIcon/>
    }, {
        field: Field.FINANCIAL_CAP_RATE, icon: <TrendingUpIcon/>
    }],
    [VACANT_LAND]: [...COMMON_FILTERS, {
        field: Field.PHYSICAL_TOTAL_LAND_AREA, icon: <LandscapeIcon/>
    }, {
        field: Field.SALES_PRICE_PER_SF_TOTAL, icon: <SvgIcon component={PricePerSquareFootIcon}/>
    }, {
        field: Field.SALES_PRICE_PER_ACRE_TOTAL, icon: <SvgIcon component={PricePerSquareFootIcon}/>
    }, {
        field: Field.SALES_PRICE_PER_SF_BUILDING_AREA, icon: <SvgIcon component={PricePerSquareFootIcon}/>
    }],
    [LEASE]: [...COMMON_FILTERS, {
        field: Field.IMPROVEMENT_LEASED_AREA, icon: <TabIcon/>
    }, {
        field: Field.LEASE_AVERAGE_RATE_OVER_TERM, icon: <ShowChartIcon/>
    }],
    [RESIDENTIAL_RENT]: [...COMMON_FILTERS, {
        field: Field.IMPROVEMENT_NUM_BEDROOMS, icon: <HotelIcon/>
    }, {
        field: Field.IMPROVEMENT_NUM_BATHROOMS, icon: <BathtubIcon/>
    }, {
        field: Field.FINANCIAL_MONTHLY_RENT, icon: <MoneyIcon/>
    }]
};

const QueryPanel = () => {
    const filters = useSelector(state => state.find.search.filters);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    const filterChoices = FILTERS[filters[0].value].filter(it => !filters.filter(f => f.field).some(f => f.field.path === it.field.path))

    const handleOpen = evt => {
        setOpen(evt.type === "click");
    }
    const handleClose = evt => {
        if (open && evt.type === "click") {
            setOpen(false);
        }
    }
    const handleAdd = (field,requires) => {
        dispatch(addFilter({field, requires}));
        setOpen(false);
    }

    const geoFilter = filters.find(f => f.field === Field.GEO);
    return (
        <>
            <Grid container spacing={2} alignItems="flex-start">
                {filters.filter(f => f.field !== Field.GEO).map(f =>
                    <Grid item key={f.id}>
                        <Filter filter={f}/>
                    </Grid>)}
            </Grid>
            <Box pt={1}>
                <Grid container spacing={1}>
                    <Grid item>
                        <Tooltip title={geoFilter?"Remove Search Area":"Add Search Area"} placement="left">
                            <Fab size="medium" color={geoFilter?"primary":"inherit"}
                                 onClick={() => dispatch(geoFilter ? removeFilter(geoFilter) : addFilter({field:Field.GEO}))}>
                                {geoFilter ? <LocationSearchingIcon/> : <LocationDisabledIcon/>}
                            </Fab>
                        </Tooltip>
                    </Grid>
                    <Grid item style={{flexGrow: 1}}>
                        <Box sx={{position: "relative", width: "100%", height: "50px"}}>
                            {/*<Tooltip>*/}
                            {/*    <Fab size="medium">*/}
                            {/*        <LocationSearchingIcon/>*/}
                            {/*    </Fab>*/}
                            {/*</Tooltip>*/}
                            <Tooltip title="Add Search Criteria" placement="left">
                                <SpeedDial
                                    ariaLabel="Add Search Criteria"
                                    className={`FilterDial`}
                                    icon={<SpeedDialIcon/>}
                                    onClose={handleClose}
                                    // onOpen={() => setOpen(true)}
                                    onOpen={handleOpen}
                                    open={open}
                                    direction="right"
                                    sx={{
                                        position: 'absolute',
                                        "& > button": {
                                            width: 48,
                                            height: 48
                                        }
                                    }}
                                >
                                    {filterChoices.map(c =>
                                        <SpeedDialAction key={c.field.path} icon={c.icon} onClick={() => handleAdd(c.field,c.requires)}
                                                         tooltipTitle={c.field.label(FieldLabel.SEARCH)} className={`${c.field.path}_Action`}
                                                         tooltipOpen tooltipPlacement="right"/>
                                    )}
                                    {/*<SpeedDialAction icon={<MapIcon/>} onClick={() => handleAdd(Field.GEO)} tooltipTitle="Location" tooltipOpen tooltipPlacement="right"/>*/}
                                    <SpeedDialAction icon={<MenuIcon/>} onClick={() => handleAdd(null)} tooltipTitle="Other" tooltipOpen tooltipPlacement="right"/>
                                </SpeedDial>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default QueryPanel;
