import React, {useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useDrag, useDrop} from "react-dnd";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
    addFieldsToColumn,
    moveColumn,
    moveFieldInColumn,
    removeColumn,
    removeFieldFromColumn, setFieldLabelInColumn,
    setFieldStyleInColumn
} from '../state';
import FieldDialog from "./FieldDialog";
import Field from "./Field";
import {hoverX} from "../../util/dnd";
import {DECORATION_UNDERLINE, STYLE_ITALIC, WEIGHT_BOLD} from '../../model/fonts';

const DRAG_TYPE_COLUMN = "column";

const Column = ({column, index, configName}) => {
    const dispatch = useDispatch();
    const config = useSelector(state => state.report.curr[configName]);
    const ref = useRef(null);
    const [, drop] = useDrop({
        accept: DRAG_TYPE_COLUMN,
        hover(item, monitor) {
            hoverX(ref, index, item, monitor, (from, to) => dispatch(moveColumn({from,to,config:configName})));
        }
    });
    const [{ isDragging }, drag] = useDrag({
        item: { type: DRAG_TYPE_COLUMN, id: column.id, index },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    const [showFields, setShowFields] = React.useState(-1);
    const handleAddFields = fields => {
        if (fields) {
            dispatch(addFieldsToColumn({column: showFields, fields, config: configName}));
        }
        setShowFields(-1);
    };
    return (
        <Card ref={ref} style={{opacity}} sx={{width: 275, cursor: "grab"}}>
            <CardHeader title={`Column ${index+1}`} titleTypographyProps={{variant: "h6"}}
                        sx={{
                            py: 1,
                            px: 2,
                            bgcolor: 'grey.100',
                            fontSize: 'caption.fontSize'
                        }}
                        action={
                            <Tooltip title="Delete column">
                                <IconButton size="small"  onClick={() => dispatch(removeColumn({column:index, config:configName}))}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        }
            />
            <CardContent sx={{padding: 0, minHeight: 200}}>
                {column.fields.map((f, i) => {
                    const style = ((column.styles||{})[f.path]) || {};
                    const label = ((column.labels||{})[f.path]) || null;
                    return <Field key={f.path} field={f} index={i} style={style} label={label} withAlign={true} defaultAlign={config.style.body.font.align}
                          onStyle={(style, value) => dispatch(setFieldStyleInColumn({
                            field:f, column:index, style, value, config:configName
                          }))}
                          onEdit={value => dispatch(setFieldLabelInColumn({
                            field:f, column: index, value, config:configName
                          }))}
                          onMove={(from,to) => dispatch(moveFieldInColumn({
                            from, to, column:index, config:configName
                          }))}
                          onRemove={() => dispatch(removeFieldFromColumn({
                            field:i, column:index, config:configName
                          }))}
                          isLabelStyleSet={(s) => {
                              const f = config.style?.header?.font;
                              if (f) {
                                  switch(s) {
                                      case "bold": return f.weight >= WEIGHT_BOLD;
                                      case "italic": return f.style === STYLE_ITALIC;
                                      case "underline": return f.decoration === DECORATION_UNDERLINE;
                                      default:
                                          return false;
                                  }
                              }
                              return false;
                          }}
                    />
                })}
            </CardContent>
            <CardActions sx={{bgcolor: 'grey.100'}}>
                <Tooltip title={"Add field"}>
                    <IconButton size="small" onClick={() => setShowFields(index)}>
                        <AddCircleOutlineIcon />
                    </IconButton>
                </Tooltip>
            </CardActions>
            <FieldDialog isOpen={showFields > -1} onClose={handleAddFields}/>
        </Card>
    )
};

export default Column;
