import React from "react";
import Image from "./Image";

const Photo = ({comp, section, photoPicker, isPreview, ...props}) => {
    return (
        <Image {...props} section={section}
               src={photoPicker.pick(section.tags, section.category, section.orientation)} alt={comp.title}
            placeholder={isPreview ? "Photo not available" : null}/>
    );
};

export default Photo;
