import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";

const CustomFormField = ({label, fullWidth, disabled, onClick, children}) => {
    return (
        <FormControl variant="outlined" fullWidth={fullWidth} disabled={disabled}>
            <InputLabel shrink={true} sx={{
                bgcolor: 'background.default',
                padding: "0 5px",
                whiteSpace: "nowrap"
            }}>{label}</InputLabel>
            <Box onClick={onClick} sx={{
                position: "relative",
                padding: "8px",
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'grey.400',
                borderRadius: "4px",
                ...(disabled!==true && {
                    "&:hover": {
                        borderColor: 'grey.900',
                        cursor: "pointer"
                    }
                })
            }}>
                {children}
            </Box>
        </FormControl>
    );
};

export default CustomFormField;
