import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getFunctions, httpsCallable} from 'firebase/functions';

export const setup = createAsyncThunk(
  'signup/setup',
  async ({info, card, stripe}, {getState, rejectWithValue}) => {
    try {
      const result = await (card ? stripe.createPaymentMethod({
        type: 'card',
        card,
        billing_details: {
          email: info.email,
          name: `${info.firstName} ${info.lastName}`
        },
      }) : Promise.resolve({}));
      if (!result.error) {
        const setup = await httpsCallable(getFunctions(), 'setup')({
          ...info, payment: result.paymentMethod && result.paymentMethod.id
        });
        console.debug(setup);
        if (setup.data.status === 'success') {
          return setup;
        }
        else {
          return rejectWithValue(setup.data.error);
        }
      }
      else {
        return rejectWithValue(result.error);
      }
    }
    catch(err) {
      console.error(err);
      return rejectWithValue(err);
    }
  }
);

const signup = createSlice({
  name: 'signup',
  initialState: {
    info: {
      firstName: "",
      lastName: "",
      orgName: "",
      password: "",
      plan: "Starter",
      agreeToTerms: false
    }
  },
  reducers: {
    setInfo: (state, action) => {
      const {key, value} = action.payload;
      state.info[key] = value;
    }
  }
});

export const {setInfo} = signup.actions;

export default signup.reducer;
