import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import FieldTable from "../../util/FieldTable";


const FieldDialog = ({isOpen, onClose}) => {
    const [selected, setSelected] = React.useState([]);

    return (
        <Dialog open={isOpen} maxWidth={"lg"} sx={{height: "75vh"}}>
            <DialogTitle>
                Add Fields
            </DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                    Choose the set of fields from below you wish to add.
                </Typography>
                <FieldTable onSelect={setSelected}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose(null)} color="secondary" autoFocus>
                    Cancel
                </Button>
                <Button onClick={() => onClose(selected)} color="primary" disabled={selected.length === 0} autoFocus>
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default FieldDialog;
