import React from "react";
import Image from "./Image";

const MapThumb = ({comp, photoPicker, isPreview, ...props}) => {
    return (
        <Image {...props} src={comp.map} alt={comp.title} placeholder={isPreview?"Map not available":null}/>
    );
};

export default MapThumb;
