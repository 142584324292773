import React from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import {hasRole, ROLE_OWNER} from '../auth/roles';


const PlanLimitAlert = ({title, message, upgradeMessage, gutterTop, gutterBottom, ...props}) => {
    const isOwner = useSelector(state => hasRole(state.auth, ROLE_OWNER));
    return (
        <Alert severity="error" variant="filled"
               action={isOwner ? <Link to="/settings?open=2" sx={{color: 'inherit'}}>
                   <Button color="inherit" size="small">Upgrade</Button>
               </Link> : null}
               sx={{
                 ...(gutterTop && {mt:1}),
                 ...(gutterBottom && {mb:1})
               }}
               {...props}>
            <AlertTitle>{title}</AlertTitle>
            {message}
            {isOwner && ` ${(upgradeMessage ? upgradeMessage : "Upgrade your plan to get more capacity.")}`}
            {!isOwner && " Contact the owner of the account to upgrade your plan."}
        </Alert>
    )
};

export default PlanLimitAlert;
