import React from 'react';
import {pick} from 'lodash';
import { initializeApp, FirebaseError } from 'firebase/app';
import { getAuth, connectAuthEmulator, getIdTokenResult, onAuthStateChanged } from 'firebase/auth';
import {doc, getDoc, getFirestore, connectFirestoreEmulator, Timestamp, GeoPoint} from 'firebase/firestore';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import {authChange} from './auth/state';
import {Org} from "./model/objects";

const app = initializeApp({
    apiKey: "AIzaSyCGHfhmV5gIPCfUMUT-xn10J71EchJ4yqM",
    authDomain: "quickcomp-dev.firebaseapp.com",
    databaseURL: "https://quickcomp-dev.firebaseio.com",
    projectId: "quickcomp-dev",
    storageBucket: "quickcomp-dev.appspot.com",
    messagingSenderId: "419461294168",
    appId: "1:419461294168:web:167b7fbe71fad7868cc22d"
});

class Firebase {
    constructor(store) {

        const useEmulator = true;
        if (useEmulator && window.location.hostname === "localhost") {
            connectFirestoreEmulator(getFirestore(app), "localhost", 6001);
            connectFunctionsEmulator(getFunctions(app), "localhost", 6002);
            connectStorageEmulator(getStorage(app), "localhost", 6003);
            connectAuthEmulator(getAuth(app), "http://localhost:6004");
        }

        onAuthStateChanged(getAuth(app), (user) => {
            if (user) {
                const u = pick(user, ['uid', 'displayName', 'email']);
                getIdTokenResult(user)
                  .then((token) => {
                      const org = token.claims.org;
                      if (org) {
                          getDoc(doc(getFirestore(app), 'orgs', org))
                            .then((doc) => {
                                store.dispatch(authChange({
                                    user: u,
                                    org: Org.create(doc),
                                    token,
                                }));
                            })
                      }
                      else store.dispatch(authChange({user: u}));
                  })
            }
            else store.dispatch(authChange({user:null}));
        });
    }
}

const FirebaseContext = React.createContext(null);

// const withFirebase = Component => props => (
//     <FirebaseContext.Consumer>
//         {firebase => <Component {...props} firebase={firebase} />}
//     </FirebaseContext.Consumer>
// );

const firebaseErrorMiddleware = store => next => action => {
    if (action.payload instanceof FirebaseError) {
        const {code, message, stack} = action.payload;
        action.payload = {code, message, stack};
    }
    next(action);
}

const firebaseSerializableCheck = v => {
  return v instanceof Timestamp || v instanceof GeoPoint;
}

export { Firebase, FirebaseContext, firebaseErrorMiddleware, firebaseSerializableCheck/* withFirebase*/ }

