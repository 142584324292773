import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "./MapboxPopup.css";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import RestoreIcon from '@mui/icons-material/Restore';
import SearchIcon from "@mui/icons-material/Search";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import HorizontalRadioGroup from "../util/HorizontalRadioGroup";
import Page from "../util/Page";
import ToolBar from "../util/ToolBar";
import SelectWithLabel from "../util/SelectWithLabel";
import SearchMap from "./SearchMap";
import QueryPanel from "./QueryPanel";
import IdPanel from "./IdPanel";
import SearchPanel from "./SearchPanel";
import SaveSearchDialog from "./SaveSearchDialog";
import { MODE_ID, MODE_QUERY, MODE_SAVED, SHORTCUT_ICI, SHORTCUT_FAMILY, SHORTCUT_LAND, SHORTCUT_LEASE, SHORTCUT_RENT } from "./constants";
import {resultsSelector} from "./selectors";
import {ResultsLabel} from "./ResultsLabel";
import {clearSearch, loadSearches, runSearch, setMode, updateShortcut} from './state';
import useHasFeature from '../util/useHasFeature';


const FindPage = () => {
    const results = useSelector(resultsSelector);
    const search = useSelector(state => state.find.search);
    const searchCount = useSelector(state => state.find.searches || []).length;
    const isSearching = useSelector(state => state.find.searching);
    const [showSaveDialog, setShowSaveDialog] = useState(false);
    const dispatch = useDispatch();
    const hasSearchFeature = useHasFeature("search");

    useEffect(() => {
        dispatch(loadSearches())
          .unwrap()
          .then((searches) => dispatch(setMode(searches.length > 0 ? MODE_SAVED : MODE_QUERY)))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleSearch = () => {
        dispatch(runSearch(search));
    };

    const handleReset = () => {
        dispatch(clearSearch());
    };

    const mode = search.mode || MODE_QUERY;
    const searchEnabled = mode === MODE_ID ? !!search.compId : mode === MODE_SAVED ? !!search.name : true;
    return (
        <Page>
            <ToolBar title="Find a Comp" alert={hasSearchFeature && <Alert severity="info" action={<Button href="/search">
                Try It
            </Button>}>
                We are working on a new search experience for QuickComp, currently in beta. Give it a try. You can always
                switch back to the existing search experience when needed.
            </Alert>}>
                <Tooltip title="Reset Search">
                    <IconButton onClick={handleReset} size="large" className="ResetButton">
                        <RestoreIcon />
                    </IconButton>
                </Tooltip>
            </ToolBar>
            <Container maxWidth={false}>
                <Grid container alignItems="center" spacing={1} sx={{mb: 2}}>
                    <Grid item>
                        <Paper variant="outlined" sx={{py: 0.5, px: 2, display: "inline-block"}}>
                            <HorizontalRadioGroup value={mode} onChange={(evt) => dispatch(setMode(evt.target.value))} className="SearchMode">
                                <FormControlLabel value={MODE_SAVED} control={<Radio color="primary"/> } label="Saved Searches" disabled={searchCount === 0}/>
                                <FormControlLabel value={MODE_QUERY} control={<Radio color="primary"/> } label="Search by Query"/>
                                <FormControlLabel value={MODE_ID} control={<Radio color="primary"/>} label="Search by ID or Address" />
                            </HorizontalRadioGroup>
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <SelectWithLabel value={search.shortcut} label="Search Shortcuts"
                             onChange={evt => dispatch(updateShortcut(evt.target.value))}
                             values={[SHORTCUT_ICI, SHORTCUT_FAMILY, SHORTCUT_LAND, SHORTCUT_LEASE, SHORTCUT_RENT]} fullWidth
                             sx={{
                                 "& > .MuiInputBase-root > .MuiSelect-root": {
                                     py: 2
                                 },
                                 "& > label.MuiInputLabel-outlined": {
                                     transform: "translate(14px, 18px) scale(1)"
                                 },
                                 "& > label.MuiInputLabel-outlined.MuiInputLabel-shrink": {
                                     transform: "translate(14px, -6px) scale(0.75)"
                                 }
                             }}/>
                    </Grid>
                </Grid>

                {search.mode && <Grid container sx={{mb: 4, flexWrap: "nowrap"}}>
                    <Grid item sx={{flexGrow: 1}}>
                        {mode === MODE_SAVED && <SearchPanel/>}
                        {mode === MODE_QUERY && <QueryPanel/>}
                        {mode === MODE_ID && <IdPanel/>}
                    </Grid>
                    <Grid item sx={{textAlign: "right"}}>
                        {search.name && <Typography sx={{display: "inline-block", pr: 1}}>{search.dirty?"* ":""}{search.name}</Typography>}
                        <Button
                            component="span"
                            variant="contained"
                            color="primary"
                            size="large"
                            className="SearchButton"
                            startIcon={ isSearching ? <CircularProgress size={24} sx={{color: 'primary.contrastText'}}/> : <SearchIcon/> }
                            onClick={handleSearch} disabled={isSearching || !searchEnabled}
                        >
                            Search
                        </Button>
                        {results.found > -1 && <ResultsLabel onSaveSearch={() => setShowSaveDialog(true)} sx={{pt: 1}}/>}
                    </Grid>
                </Grid>}
                {results.overflow &&
                    <Alert severity="warning" sx={{mb: 1}}>
                        <AlertTitle>Too Many Results</AlertTitle>
                        <Typography>Your search resulted in a large number of hits, you are being shown the first {results.features.features.length}. Please refine your search criteria.</Typography>
                    </Alert>
                }
                <Grid container>
                    <Grid item md={12}>
                        <Box sx={{position: "relative", height: "600px"}}>
                            <SearchMap/>
                        </Box>
                    </Grid>
                </Grid>
                <SaveSearchDialog open={showSaveDialog} onClose={() => setShowSaveDialog(false)}/>
            </Container>
        </Page>
    );
};

export default FindPage;

