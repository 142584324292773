import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isEqual} from 'lodash';
import {NumericFormat} from 'react-number-format';
import {useTheme, styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2';
import Filter3Icon from '@mui/icons-material/Filter3';
import Filter4Icon from '@mui/icons-material/Filter4';
import Filter5Icon from '@mui/icons-material/Filter5';
import Filter6Icon from '@mui/icons-material/Filter6';
import Filter7Icon from '@mui/icons-material/Filter7';
import Filter8Icon from '@mui/icons-material/Filter8';
import Filter9Icon from '@mui/icons-material/Filter9';
import Filter9PlusIcon from '@mui/icons-material/Filter9Plus';
import TuneIcon from '@mui/icons-material/Tune';
import {TreeItem, TreeView} from '@mui/lab';
import {Fields, FieldType} from '../model/fields';
import {runAggs, setAggSetting, toggleAggFilter} from './state';
import SelectWithLabel from '../util/SelectWithLabel';

const TreeRoot = styled(TreeItem)(({theme}) => ({
  '& > .MuiTreeItem-content .MuiTreeItem-label .MuiTypography-root': {
      fontWeight: 400,
      textTransform: 'uppercase'
  },
  '& > .MuiTreeItem-content': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    // borderRadius: "4px"
    marginBottom: theme.spacing(0.5),
    '&, &.Mui-selected, &.Mui-expanded, &.Mui-selected.Mui-focused': {
      backgroundColor: `${theme.palette.primary.main}`,
      color: `${theme.palette.getContrastText(theme.palette.primary.main)}`
    },
    '&.Mui-selected:hover, &.Mui-expanded:hover': {
      backgroundColor: `${theme.palette.primary.dark}`,
      color: `${theme.palette.getContrastText(theme.palette.primary.dark)}`
    }
  },
  '& > .MuiTreeItem-content:hover': {
    backgroundColor: `${theme.palette.primary.dark}`,
    color: `${theme.palette.getContrastText(theme.palette.primary.dark)}`
  },
  '& .MuiTreeItem-group': {
    marginLeft: theme.spacing(1)
  }
}));

const AggSettingField = ({setting, value, onChange}) => {
  switch(setting.type) {
    case FieldType.CURRENCY:
    case FieldType.NUMBER:
    case FieldType.INTEGER:
      return <NumericFormat label={setting.name} value={value} onValueChange={v => onChange(v.floatValue)}
              thousandSeparator="," helperText={setting.note} customInput={TextField}
              InputProps={{
                startAdornment: setting.type === FieldType.CURRENCY ? <InputAdornment position="start">$</InputAdornment> : null
              }}/>
    default:
      if (Array.isArray(setting.values)) {
        return <SelectWithLabel label={setting.name} values={setting.values} value={value}
                  onChange={evt => onChange(evt.target.value)}/>
      }
      else {

      }
  }
}

const AggTreeItemIcon = ({count}) => {
  if (count === 0) return null;
  const props = {
    sx: {width: '0.75em', height: '0.75em'}
  }
  switch (count) {
    case 1:
      return <Filter1Icon {...props}/>
    case 2:
      return <Filter2Icon {...props}/>
    case 3:
      return <Filter3Icon {...props}/>
    case 4:
      return <Filter4Icon {...props}/>
    case 5:
      return <Filter5Icon {...props}/>
    case 6:
      return <Filter6Icon {...props}/>
    case 7:
      return <Filter7Icon {...props}/>
    case 8:
      return <Filter8Icon {...props}/>
    case 9:
      return <Filter9Icon {...props}/>
    default:
      return <Filter9PlusIcon {...props}/>
  }
}
const AggTreeItem = ({agg, ...props}) => {
  const dispatch = useDispatch();
  const filters = useSelector(state => state.search.query.filters || []);
  const queryAgg = useSelector(state => (state.search.query.aggs || []).find(it => it.field === agg.field));
  const [showSettings, setShowSettings] = React.useState(false);
  const [settings, setSettings] = React.useState(queryAgg?.settings?.reduce((obj, s) => {
    obj[s.path] = queryAgg.agg[queryAgg.type][s.path];
    return obj;
  }, {}));

  const field = Fields.lookup(agg.field);

  const aggIsFilter = bucket => {
    const filter = filters.find(f => f.field === agg.field);
    if (!filter) return false;

    return filter.values.findIndex(v => isEqual(v, bucket.value)) > -1;
  }

  const numFilters = agg.buckets.filter(aggIsFilter).length;
  const hasSettings = queryAgg?.settings?.length > 0;

  const openSettings = (evt) => {
    evt.stopPropagation();
    setShowSettings(true);
  }

  const closeSettings = () => {
    setShowSettings(false);
  }

  const updateSettings = () => {
    dispatch(setAggSetting({field: agg.field, values: settings}))
    setShowSettings(false);
  }

  const updateSetting = (path, value) => {
    setSettings({...settings, [path]: value});
  }

  return (
      <>
    <TreeRoot nodeId={agg.field}
        label={
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="body2" fontWeight="bold">{field.label()}</Typography>
            <Stack direction="row" alignItems="center">
              <AggTreeItemIcon count={numFilters}/>
              {hasSettings && <IconButton size="small" onClick={openSettings} sx={{
                color: 'white',
                '&:hover': {
                  bgcolor:'grey.500'
                }
              }}>
                <TuneIcon/>
              </IconButton>}
            </Stack>
          </Box>
        }
        sx={{backgroundColor: 'transparent', py:1 }} {...props}>
      {agg.buckets.map(b => {
        const nodeId = `${agg.field}__${b.key}`;
        const isFilter = aggIsFilter(b);
        return (<TreeItem key={nodeId} nodeId={nodeId} label={<Box display="flex">
            <Typography variant="body2" sx={{flexGrow:1}}>
              {b.label}
            </Typography>
            <Typography variant="caption" >
              {b.count}
            </Typography>
          </Box>} icon={isFilter ? <FiberManualRecordIcon color="secondary"/> : <FiberManualRecordOutlinedIcon fontSize="small" />}>
        </TreeItem>)
      })}
    </TreeRoot>
        {hasSettings && <Dialog open={showSettings} onClose={closeSettings} maxWidth="sm" fullWidth>
          <DialogTitle>Bucket Settings</DialogTitle>
          <DialogContent dividers sx={{py:4}}>
            <Stack gap={4}>
              {queryAgg.settings.map(s => <AggSettingField key={s.path} setting={s} value={settings[s.path]}
                                                           onChange={value => updateSetting(s.path, value)}/>)}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeSettings}>
              Cancel
            </Button>
            <Button onClick={updateSettings} color="primary" variant="outlined" startIcon={<DoneIcon/>}>
              Finish
            </Button>
          </DialogActions>
        </Dialog>}
        </>
  )
}
const AggPanel = () => {
  const dispatch = useDispatch();
  const open = useSelector(state => state.search.view.aggs.open);
  const filters = useSelector(state => state.search.query.filters || []);
  const queryAggs = useSelector(state => state.search.query.aggs || []);
  const resultAggs = useSelector(state => state.search.result.aggs?.length > 0 ? state.search.result.aggs : (state.search.aggs || []));
  const height = useSelector(state => state.search.view.map.height);
  const dirty = useSelector(state => state.search.query.dirty || []);
  const theme = useTheme();

  useEffect(() => {
    if (dirty.includes('aggs')) dispatch(runAggs());
  }, [filters, dirty, dispatch]);

  const aggs = queryAggs
      .filter(a => {
        if (a.requires) {
          for (const field of Object.keys(a.requires)) {
            const filter = filters.find(f => f.field === field);
            if (!filter || !a.requires[field].some(r => filter.values.includes(r))) {
              return false;
            }
          }
        }
        return true;
      })
      .map(a => resultAggs.find(it => it.field === a.field))
      .filter(it => it != null)

  const handleSelect = values => {
    values.forEach(v => {
      const split = v.split("__");
      if (split.length === 2) {
        const [field, key] = split;
        dispatch(toggleAggFilter({field, key}));
      }
    })
  }

  return (
    <Stack alignItems="flex-start" sx={{
      width: "256px",
      maxHeight: height,
      overflowY: "scroll",
      flexShrink: 0,
      // bgcolor: "grey.100",
      ...(open && {
        width: "256px",
        pr: 1,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }),
      ...(!open && {
        width: "0px",
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      })
    }} >
      <TreeView
        multiSelect
        onNodeSelect={(evt, v) => handleSelect(v)}
        defaultExpanded={['type']}
        defaultCollapseIcon={<ArrowDropDownCircleOutlinedIcon/>}
        defaultExpandIcon={<ArrowDropDownCircleOutlinedIcon sx={{rotate: '-90deg'}}/>}
        sx={{width: "100%"}}
      >
        {aggs.map(agg => <AggTreeItem agg={agg} key={agg.field}/>)}
      </TreeView>
    </Stack>
  )
}

export default AggPanel;
