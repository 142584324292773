import React from "react"
import {useDispatch, useSelector} from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import {popSnack} from './state';


const Snacker = () => {
    const snack = useSelector(state => (state.app.snacks||[])[0]);
    const dispatch = useDispatch();

    const handleClose = (evt, reason) => {
        if (reason === "clickaway" && snack.clickaway === false) return;
        dispatch(popSnack());
    };
    return snack ? (
        <Snackbar
            anchorOrigin={snack.anchor || {
                vertical: "bottom",
                horizontal: "left",
            }}
            open={true}
            autoHideDuration={snack.autoHide !== false ? (snack.duration || 6000) : null}
            onClose={handleClose}
            message={snack.message}
            action={
                <React.Fragment>
                    {(snack.actions || []).map((a, i) => {
                        const {name, ...props} = a;
                        return <Button key={name} color="primary" size="small" {...props}>{name}</Button>
                    })}
                    <IconButton size="small"  color="inherit" onClick={handleClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
            }
        />
    ) : null;
};

export default Snacker;
