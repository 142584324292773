import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import {Alert, Button, Stack} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CircularProgress from '@mui/material/CircularProgress';
import InfoIcon from "@mui/icons-material/Info";
import DashboardIcon from '@mui/icons-material/Dashboard';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import VisibilityIcon from '@mui/icons-material/Visibility';
import HomeIcon from '@mui/icons-material/Home';
import ErrorAlert from '../../util/ErrorAlert';
import {TabBar} from '../../util/Tab';
import Tip from '../../util/Tip';
import GeneralPanel from "./GeneralPanel";
import LayoutPanel from "./LayoutPanel";
import FieldPanel from "./FieldPanel";
import PreviewPanel from "./PreviewPanel";
import {isNotBlank} from '../../util/utils';
import {changeTab, createSheet, saveSheet} from "../state";
import {SHEET_TYPE_ADJUST} from '../constants';
import SubjectPanel from './SubjectPanel';

const SheetEditor = ({...props}) => {
    const dispatch = useDispatch();
    const sheet = useSelector(state => state.sheet.curr);
    const activeTab = useSelector(state => state.sheet.view.tabs.curr);
    const {error, url} = useSelector(state => state.sheet.create);
    const [isCreating, setIsCreating] = React.useState(false);
    const [isSaving, setIsSaving] = React.useState(false);
    const [isSaved, setIsSaved] = React.useState(false);
    const [saveError, setSaveError] = React.useState(null);

    const handleCreate = () => {
        setIsCreating(true);
        dispatch(createSheet(sheet))
            .unwrap()
            .finally(() => setIsCreating(false))
    }

    const handleSave = () => {
        setIsSaving(true);
        dispatch(saveSheet(sheet))
            .unwrap()
            .then(() => setIsSaved(true))
            .catch(setSaveError)
            .finally(() => setIsSaving(false));
    }

    const isWorking = isCreating || isSaving;
    const hasFields = sheet.fields.length > 0;
    const timeIsComplete = (sheet.type === SHEET_TYPE_ADJUST && sheet.time.enabled ?
        sheet.time.date && sheet.time.name && sheet.time.field && sheet.time.appreciation : true);
    const hasUnit = sheet.type === SHEET_TYPE_ADJUST ? !!sheet.unit : true;
    const canGenerate = hasFields && timeIsComplete && hasUnit;
    const canSave = isNotBlank(sheet.title) && canGenerate;

    const tabs = [];
    tabs.push([{label: "General", icon: <InfoIcon/>}, (p) => <GeneralPanel {...p} />]);
    tabs.push([{label: "Subject", icon: <HomeIcon/>}, (p) => <SubjectPanel {...p} />]);
    tabs.push([{label: "Fields", icon: <ViewColumnIcon/>}, (p) => <FieldPanel {...p} />]);
    tabs.push([{label: "Layout", icon: <DashboardIcon/>}, (p) => <LayoutPanel {...p} />]);
    tabs.push([{label: "Preview", icon: <VisibilityIcon/>}, (p) => <PreviewPanel {...p} />]);

    const err = error || saveError;
    return (
        <>
            <TabBar tabs={tabs} sheet={sheet} activeTab={activeTab} onTabChange={t => dispatch(changeTab(t))} {...props}/>
            <Tip id="sheetGenOrSave" title="Generate vs Save" sx={{mb:2}}>
                <b>Generate</b> will create a spreadsheet that you can download, but not persist it. This is useful for
                when you are making changes to the sheet and want to see the results. <b>Save</b> will create  the spreadsheet,
                and persist the result for future use.
            </Tip>
            <Stack direction="row" spacing={1}>
                <Stack flexGrow={1} spacing={1}>
                    {err && <ErrorAlert error={err}/>}
                    {url && <Alert variant="outlined" severity="success"
                                   action={<Button color="inherit" size="small" href={url}>Download</Button>} >
                        Spreadsheet has been successfully created.
                    </Alert>}
                    {isSaved && <Alert variant="outlined" severity="success">
                        Spreadsheet <em>{sheet.title}</em> has been successfully saved.
                    </Alert>}
                </Stack>
                <Stack gap={1} alignItems="flex-end">
                    <Stack direction="row" spacing={1} alignItems="flex-start">
                        <Button
                            disabled={!canGenerate || isWorking}
                            variant="outlined"
                            color="primary"
                            onClick={handleCreate}
                            className="CreateButton"
                            startIcon={isCreating && <CircularProgress size={24}/>}
                        >
                            Generate
                        </Button>
                        <Button
                            variant="contained"
                            disabled={!url || isSaved || !canSave || isWorking}
                            color="primary"
                            onClick={handleSave}
                            startIcon={isSaving ? <CircularProgress size={24}/> : <SaveIcon/>}
                            className="SaveButton"
                        >
                            Save
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
            <Stack direction="row" sx={{mt:1, mb:4}} justifyContent="flex-end">
                {!canGenerate && !hasFields && <Alert variant="outlined" severity="warning">
                    The sheet has no fields chosen.
                </Alert>}
                {!canGenerate && hasFields && !hasUnit && <Alert variant="outlined" severity="warning">
                    The sheet has not specified a <em>Unit of Comparison</em> field.
                </Alert> }
                {!canGenerate && !timeIsComplete && <Alert variant="outlined" severity="warning">
                    The spreadsheet has <em>Time Adjustment</em> enabled, but the configuration is incomplete.
                </Alert>}
                {canGenerate && !url && <Alert variant="outlined" severity="info">
                    The spreadsheet must be generated before it can be saved.
                </Alert>}
                {canGenerate && url && !canSave && <Alert variant="outlined" severity="info">
                    The spreadsheet requires a name before it can be saved.
                </Alert>}
            </Stack>
        </>
    )
};

export default SheetEditor;
