import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {get} from 'lodash';
import Alert from '@mui/material/Alert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SettingsIcon from '@mui/icons-material/Settings';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PaletteIcon from '@mui/icons-material/Palette';
import FontControl from '../../util/FontControl';
import ColorPicker from '../../util/ColorPicker';
import {TabPanel, TabSideBar} from "../../util/Tab";
import {FormSection} from '../../util/Form';
import Map from "../content/Map";
import PopupField from './Field';
import FieldDialog from './FieldDialog';
import Instruction from "./Instruction";
import {Field, Fields} from "../../model/fields";
import {
    addFieldsToMapPopup,
    moveFieldInMapPopup,
    removeFieldFromMapPopup,
    updateConfig,
    updateView
} from '../state';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Tip from '../../util/Tip';
import {MoveIcon} from '../../util/Map';
import Fonts from '../content/Fonts';
import {TYPE_SINGLE} from '../../model/reportTypes';


const PreviewPanel = ({...props}) => {
    return (
        <TabPanel {...props} className="PreviewPanel">
            <Fonts/>
            <Tip id="mapPopups" mb={1}>
                The popup bubbles on the map can be repositioned by dragging them around the map. The map itself can be
                panned by first clicking the arrow tool <Box sx={{
                    backgroundImage: `url(${MoveIcon})`,
                    backgroundColor: 'white',
                    backgroundPosition: 'center',
                    width: '24px',
                    height: '24px',
                    display: 'inline-block',
                    verticalAlign: 'bottom',
                    borderRadius: '2px',
                    border: '1px solid #c7c7c7',
                }}/>, and then dragging the map to the desired location.
            </Tip>
            <Box sx={{
                position: "relative",
                height: "728px"
            }}>
                <Map isPreview={true} showSubtitle={false}/>
            </Box>
        </TabPanel>
    )
}

const ContentPanel = ({...props}) => {
    const comps = useSelector(state => state.cart.comps || []);
    const popup = useSelector(state => state.report.curr.map.popup || {});
    const dispatch = useDispatch();
    const [showFields, setShowFields] = React.useState(false);

    const handleAddFields = fields => {
        if (fields) {
            dispatch(addFieldsToMapPopup({fields}));
        }
        setShowFields(false);
    }

    return (
        <TabPanel {...props} className="ContentPanel">
            <FormSection label="Popup Fields" help="Choose fields to display on comp popups."
                         action={<IconButton onClick={() => setShowFields(true)}>
                             <AddCircleOutlineIcon/>
                         </IconButton>}>
                <Stack gap={1}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item md={3}>
                            <FormControlLabel label="Include Comp Index"
                                control={<Checkbox checked={popup.index.enabled}
                                                   onChange={() => dispatch(updateConfig({path: 'map.popup.index.enabled', value: !popup.index.enabled}))} />}
                            />
                        </Grid>
                        <Grid item md={9}>
                            <FontControl font={popup.index.style.font} size="small" disabled={!popup.index.enabled} showAlign={false} showFamily={true}
                                 onChange={(style, value) => {
                                     dispatch(updateConfig({path: `map.popup.index.style.font.${style}`, value}))
                                 }}
                                 colorPickerOpts={{anchor: 'bottom'}}/>
                        </Grid>
                    </Grid>
                    {popup.fields.map((it,i) => <Grid key={it.path} container alignItems="center" spacing={2}>
                       <Grid item md={3}>
                           <PopupField key={it.path} index={i} field={Fields.lookup(it.path)}
                                   onMove={(from, to) => dispatch(moveFieldInMapPopup({from, to}))}
                                   onRemove={(index) => dispatch(removeFieldFromMapPopup({index}))}
                                   component={Paper} comp={comps[0]}/>
                       </Grid>
                        <Grid item md={9}>
                            <FontControl font={it.style.font} size="small" showAlign={false} showFamily={true}
                                         onChange={(style, value) => {
                                            dispatch(updateConfig({path: `map.popup.fields[${i}].style.font.${style}`, value}))
                                         }}
                                         colorPickerOpts={{anchor: 'bottom'}}/>
                        </Grid>
                    </Grid>)}
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item md={3}>
                            <FormControlLabel label="Include Distance to Subject"
                                              control={<Checkbox checked={popup.distance.enabled}
                                                                 onChange={() => dispatch(updateConfig({path: 'map.popup.distance.enabled', value: !popup.distance.enabled}))} />}
                            />
                        </Grid>
                        <Grid item md={9}>
                            <FontControl font={popup.distance.style.font} size="small" disabled={!popup.distance.enabled} showAlign={false} showFamily={true}
                                         onChange={(style, value) => {
                                             dispatch(updateConfig({path: `map.popup.distance.style.font.${style}`, value}))
                                         }}
                                         colorPickerOpts={{anchor: 'bottom'}}/>
                        </Grid>
                    </Grid>
                </Stack>
            </FormSection>
            <FieldDialog isOpen={showFields} onClose={handleAddFields}/>
        </TabPanel>
    )
}

const StylesPanel = ({...props}) => {
    const style = useSelector(state => state.report.curr.map.style);
    const dispatch = useDispatch();

    const colorPickerProps = (path) => ({
        value: get(style, path).color,
        onChange: (v) => dispatch(updateConfig({path:`map.style.${path}.color`, value:v})),
    });

    return (
        <TabPanel {...props} className="StylesPanel">
            <FormSection label="Popup Colors" help="Choose colors for popups.">
                <Grid container spacing={2}>
                    <Grid item md={4}>
                        <ColorPicker label="Subject" anchor="bottom" {...colorPickerProps('popup.subject')}/>
                    </Grid>
                    <Grid item md={4}>
                        <ColorPicker label="Active Listings" anchor="bottom" {...colorPickerProps('popup.listing')}/>
                    </Grid>
                    <Grid item md={4}>
                        <ColorPicker label="Other" anchor="bottom" {...colorPickerProps('popup.default')}/>
                    </Grid>
                </Grid>
            </FormSection>
            <FormSection label="Marker Dot Colors" help="Choose colors for dots.">
                <Grid container spacing={2}>
                    <Grid item md={4}>
                        <ColorPicker label="Subject" anchor="bottom" {...colorPickerProps('dot.subject')}/>
                    </Grid>
                    <Grid item md={4}>
                        <ColorPicker label="Active Listings" anchor="bottom" {...colorPickerProps('dot.listing')}/>
                    </Grid>
                    <Grid item md={4}>
                        <ColorPicker label="Other" anchor="bottom" {...colorPickerProps('dot.default')}/>
                    </Grid>
                </Grid>
            </FormSection>
        </TabPanel>
    )
}

const SettingsPanel = ({...props}) => {
    const config = useSelector(state => state.report.curr);
    const dispatch = useDispatch();

    const aspect = config.map.aspect || (config.type === TYPE_SINGLE ? "1.413542926" : "0.707442258");
    return (
        <TabPanel {...props}>
            <FormSection label="Comp Index Prefix" help="Specify the prefix for comp numbers in the report.">
                <TextField value={config.map.popup.index.noun} placeholder="Index"  size="small"
                           onChange={evt => dispatch(updateConfig({
                               path:"map.popup.index.noun",
                               value:(evt.target.value)
                           }))} variant="outlined" />
            </FormSection>
            <FormSection label="Map Aspect Ratio" help="Specify the height of the map relative to its width">
                <Grid container>
                    <Grid item md={9}>
                        <RadioGroup value={config.map.aspect} onChange={(evt) => dispatch(updateConfig({
                            path:"map.aspect", value: evt.target.value
                        }))}>
                            <FormControlLabel value={"0.707442258"} control={<Radio />} label="Portrait - Map is taller than it is wide" />
                            <FormControlLabel value={"1.413542926"} control={<Radio />} label="Landscape - Map is wider than it is tall" />
                            <FormControlLabel value={"1"} control={<Radio />} label="Square - Map has same height and width" />
                            <FormControlLabel value={""} control={<Radio />} label="Unset - Aspect ratio is determined by the report type" />
                        </RadioGroup>
                    </Grid>
                    <Grid item md={3}>
                        <Box width="100%" sx={{
                            bgcolor: 'grey.300',
                            aspectRatio: aspect,
                        }}>

                        </Box>
                    </Grid>
                </Grid>
            </FormSection>
        </TabPanel>
    )
}

const MapPanel = ({...props}) => {
    const activeTab = useSelector(state => state.report.view.map.tab || 0);
    const hasConfidential = useSelector(state => (state.cart.comps || []).some(it => Field.CONFIRMATION_IS_CONFIDENTIAL.get(it) === true));
    const dispatch = useDispatch();

    return (
        <TabPanel {...props}>
            <Instruction>
                The comp map shows all properties on the map, relative to the subject property if one was chosen. Pan the map and change the zoom level to the desired location. Position
                the popup bubbles by dragging them around the map.
            </Instruction>
            {hasConfidential && <Alert severity="warning" sx={{mb:2}}>This report contains comps marked as confidential, they will not be shown on the map.</Alert>}
            <TabSideBar tabs={[
                [{label: "Preview", icon: <VisibilityIcon/>}, (p) => <PreviewPanel {...p} />],
                [{label: "Content", icon: <SpeakerNotesIcon/>}, (p) => <ContentPanel {...p} />],
                // [{label: "Layout", icon: <ViewColumnIcon/>}, (p) => <LayoutPanel {...p} />],
                [{label: "Styles", icon: <PaletteIcon/>}, (p) => <StylesPanel {...p} />],
                [{label: "Settings", icon: <SettingsIcon/>}, (p) => <SettingsPanel {...p} />],
            ]} activeTab={activeTab} onTabChange={t => dispatch(updateView({path:"map.tab", value:t}))}/>
        </TabPanel>
    );
};

export default MapPanel;
