import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import {removeSearch} from './state';


const SearchDialog = ({open, onClose, firebase}) => {
    const searches = useSelector(state => state.find.searches || []);
    const auth = useSelector(state => state.auth);

    return (
        <Dialog open={open} maxWidth={"sm"} onClose={onClose} fullWidth sx={{ minHeight: "50vh" }}>
            <DialogTitle>
                Manage Searches
            </DialogTitle>
            <DialogContent dividers>
                <Typography sx={{mb: 2}}>
                    Remove searches from the list.
                </Typography>
                {searches.map((s) => <SearchItem key={s.id} search={s} auth={auth} firebase={firebase} />)}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()} color="primary" >
                    Finish
                </Button>
            </DialogActions>
        </Dialog>
    )
};

const SearchItem = ({search}) => {
    const [isWorking, setWorking] = useState(false);
    const dispatch = useDispatch();

    const handleRemove = () => {
        setWorking(true);
        dispatch(removeSearch(search)).then(() => {
            setWorking(false);
        });
    }

    return (
        <Paper variant="outlined" sx={{mb:1}}>
            <Grid container sx={{flexWrap: "nowrap", alignItems: "center"}}>
                <Grid item>
                    <Tooltip title={"Remove item"}>
                        <IconButton onClick={handleRemove} disabled={isWorking} size="large">
                            {isWorking ? <CircularProgress size={24}/> : <HighlightOffIcon/>}
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item sx={{flexGrow: 1}}>
                    <Typography variant="subtitle2">
                        {search.name}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default SearchDialog;
