import {useTheme} from '@mui/material';
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {pick, omitBy, isNil, set} from "lodash";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import GetAppIcon from '@mui/icons-material/GetApp';
import SaveIcon from "@mui/icons-material/Save";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import GridOnIcon from '@mui/icons-material/GridOn';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import ReplayIcon from '@mui/icons-material/Replay';
import HelpIcon from "@mui/icons-material/Help";
import {hasRole, ROLE_EDITOR} from '../auth/roles';
import {WizardInstruction} from "../util/Wizard";
import ClickPaper from "../util/ClickPaper";
import Spinner from "../util/Spinner";
import {PdfIcon, WordIcon, ZipIcon} from "../util/icons";
import Summary from "./content/Summary";
import Sheet from "./content/Sheet";
import SingleTable from "./content/SingleTable";
import {pushSnack} from "../app/state";
import Map from "./content/Map";
import Fonts from "./content/Fonts";
import ErrorAlert from "../util/ErrorAlert";
import HelpDialog from "../comp/editor/HelpDialog";
import MoodPanel from "../util/MoodPanel";
import {TYPE_SINGLE, TYPE_SUMMARY_DETAIL} from "../model/reportTypes";
import {addTemplate, generateImageReport, touchTemplate, exportReport, saveTemplate} from './state';


const Progress = ({progress}) => {
    const value= progress.total > 0 ? (progress.done/progress.total*100) : -1;
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant={value > -1 ? "determinate" : "indeterminate"} value={value} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                {value > -1 && <Typography variant="caption" component="div" color="textSecondary">
                    {`${Math.round(value)}%`}
                </Typography>}
            </Box>
        </Box>
    );
}

const createTemplate = (report, config) => {
    return {
        ...omitBy(pick(report.templates.curr, ["id", "title", "created", "updated"]), isNil),
        summary: config.summary,
        detail: config.detail,
        single: config.single,
        style: config.style,
        map: set(config.map, 'popup.positions', []),
        options: config.options
    };
};

const ExportOption = ({name, title, description, help, isDisabled, handleExport, Icon, BadgeIcon}) => {
    const [showHelp, setShowHelp] = React.useState(false);
    return (
        <>
            <Grid container spacing={2} sx={{ mb: 2, alignItems: "center", flexWrap: "nowrap"}}>
                <Grid item>
                    <ClickPaper disabled={isDisabled} onClick={handleExport} className={`Export${name}Button`} sx={{
                        height: "80px",
                        width: "80px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center"
                    }}>
                        <Box position="relative" width="min-content" margin="0 auto">
                            {BadgeIcon && <Box position="absolute" sx={{
                                width: 20,
                                height: 20,
                                bgcolor: 'common.white',
                                top: -5,
                                left: -5,
                                "& > svg": {
                                    width: "100%",
                                    height: "100%"
                                }
                            }}>
                                <BadgeIcon fontSize="small"/>
                            </Box>}
                            <Icon height={"3em"} disabled={isDisabled}/>
                        </Box>
                    </ClickPaper>
                </Grid>
                <Grid item sx={{flexGrow: 1}}>
                    <Box textAlign="left">
                        <Typography variant="h4" sx={{fontWeight: 'bold'}}>{title}</Typography>
                        <Typography>{description}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <Button color="primary" onClick={handleExport} disabled={isDisabled}>Choose</Button>
                </Grid>
                <Grid item>
                    {help && <IconButton size="small" onClick={() => setShowHelp(true)}>
                        <HelpIcon/>
                    </IconButton>}
                </Grid>
            </Grid>
            {help && <HelpDialog title={title} open={showHelp} onClose={() => setShowHelp(false)}>
                {help}
            </HelpDialog>}
        </>
    );
}
const ExportStep = ({reset}) => {
    const config = useSelector(state => state.report.curr);
    const comps = useSelector(state => state.cart.comps);
    const report = useSelector(state => state.report);
    const progress = useSelector(state => state.report.generate.progress);
    const isEditor = useSelector(state => hasRole(state.auth, ROLE_EDITOR));
    const dispatch = useDispatch();
    const theme = useTheme();
    const [isReady, setReady] = React.useState(true);
    const [exportError, setExportError] = React.useState(null);

    const [map, setMap] = React.useState();
    const handleMapMove = isMoving => {
        setReady(!isMoving);
    }
    const tables = React.useRef(null);

    const [isGenerating, setIsGenerating] = React.useState(false);
    const handleGenerate = () => {
        window.scrollTo(0,0);
        window.document.body.style.overflow = "hidden";
        setIsGenerating(true);

        if (!report.templates.curr.id && report.templates.curr.title) {
            dispatch(addTemplate(createTemplate(report, config)));
        }
        else {
            dispatch(touchTemplate(report.templates.curr));
        }

        const el = tables.current;
        const els = [];
        el.querySelectorAll("[data-section='single']").forEach(it => els.push(it));
        el.querySelectorAll("[data-section='summary']").forEach(it => els.push(it));
        el.querySelectorAll("[data-section='detail']").forEach((it) => els.push(it));

        const mapCanvas = map?.getCanvas();
        const popupCanvas = el.querySelector('#popups');

        dispatch(generateImageReport({
            root: `#${el.id}`, items: ["[data-section='single']", "[data-section='summary']", "[data-section='detail']"],
            map: mapCanvas ? {
                layers: [
                    mapCanvas,
                    ...(popupCanvas ? [popupCanvas] : null)
                ],
                width: mapCanvas.width,
                height: mapCanvas.height,
            } : null,
            config, comps
        }))
            .unwrap()
            .finally(() => {
                window.document.body.style.overflow = "";
                setIsGenerating(false);
            })
    };

    const [isExporting, setIsExporting] = React.useState(false);
    const handleExport = format => {
        setExportError(null);
        setIsExporting(true);
        dispatch(exportReport({report: report.generate.result, format})).unwrap()
            .catch(setExportError)
            .finally(() => setIsExporting(false));
    };

    const handleSaveTemplate = () => {
        const t = createTemplate(report, config);
        dispatch(saveTemplate(t)).unwrap().then(() => {
            dispatch(pushSnack({message: `Template ${t.title} saved.`}));
        })
    };

    const [showSaveTemplateAs, setShowSaveTemplateAs] = React.useState(false);
    const [saveTemplateAsName, setSaveTemplateAsName] = React.useState("");

    const handleSaveTemplateAs = proceed => {
      if (proceed) {
          const newTemplate = {...createTemplate(report, config), title: saveTemplateAsName};
          dispatch(addTemplate(newTemplate)).then(() => {
              dispatch(pushSnack({message: `Template ${newTemplate.title} created.`}))
          });
      }
      setShowSaveTemplateAs(false);
    };

    const isWordDisabled = isExporting && report.export.format !== "word";
    const isWordVectorDisabled = isExporting && report.export.format !== "word-vector";
    const isPdfDisabled = isExporting && report.export.format !== "pdf";
    const isZipDisabled = isExporting && report.export.format !== "zip";

    const padding = 0;
    const isDone = report.generate.result && report.generate.result.dirty === false;
    return (
        <Box sx={{textAlign: 'center'}}>
            {
                isDone ?
                    <Box>
                        <Box sx={{textAlign: 'right'}}>
                            <Button
                                onClick={reset}
                                variant="contained"
                                color="primary"
                                size="large"
                                startIcon={<ReplayIcon />}
                            >
                                New Report
                            </Button>
                        </Box>
                        <MoodPanel mood={report.error ? "sad" : "happy"} className="GenerateStatus"
                                   message={report.error ? "Oops, there was an error generating the report." : "Success! The report was successfully generated."}/>
                        {exportError && <ErrorAlert title="Export Error" error={exportError}/>}
                        {!report.error &&
                        <Box>
                            <Typography variant="h4" sx={{mt: 1}}>How would you like to export the report?</Typography>
                            <Box sx={{
                                flexDirection: "column",
                                mt: 4,
                                maxWidth: theme.breakpoints.values.md,
                                marginLeft: "auto",
                                marginRight: "auto",
                            }}>
                                <ExportOption name="WordImage" title="Word Document with Images" Icon={WordIcon} BadgeIcon={CropOriginalIcon}
                                      description="A Microsoft Word document containing non-editable images."
                                      isDisabled={isWordDisabled} handleExport={() => handleExport("word")}
                                      help={<Box>
                                          <Typography>
                                              This format is the default for QuickComp, and produces the best-looking output.
                                              The resulting word document contains a series of images, one for each component
                                              of the report, that are designed to be integrated into an existing Microsoft
                                              Word report template.
                                          </Typography>
                                          <Typography>
                                              The downside of using this option is that the resulting images are not editable.
                                              If you have a requirement to edit the contents outside of QuickComp in the Word
                                              environment, then the <em>Word Document with Tables</em> option is a better fit.
                                          </Typography>
                                      </Box>}
                                />
                                <ExportOption name="WordTable" title="Word Document with Tables" Icon={WordIcon} BadgeIcon={GridOnIcon}
                                      description="A Microsoft Word document containing editable tables."
                                      isDisabled={isWordVectorDisabled} handleExport={() => handleExport("word-vector")}
                                      help={<Box>
                                          <Typography>
                                              This format produces a Microsoft Word document containing a set of tables for each report component,
                                              that are fully editable in the Word Environment. While the output looks similar to the
                                              <em>Word Document with Images</em> format, it's not exactly the same. If you don't have a requirement
                                              to edit the contents of the report outside of QuickComp, then the images option is a quicker and
                                              easier to work with, and generally looks better.
                                          </Typography>
                                          <Typography>
                                              Like the <em>Word Document with Images</em> option, this option is suitable for copying and pasting
                                              into an existing Microsoft Word report template.
                                          </Typography>
                                      </Box>}
                                />
                                <ExportOption name="PDF" title="PDF Document" Icon={PdfIcon}
                                      description="A PDF documenting containing images."
                                      isDisabled={isPdfDisabled} handleExport={() => handleExport("pdf")}
                                      help={<Box>
                                          <Typography>
                                              This option produces the PDF equivalent of the <em>Word Document with Images</em> option. The
                                              resulting PDF is not editable and consists of a series of static images.
                                          </Typography>
                                      </Box>}
                                />
                                <ExportOption name="Zip" title="Zip Archive" Icon={ZipIcon}
                                      description="A Zip file containing images."
                                      isDisabled={isZipDisabled} handleExport={() => handleExport("zip")}
                                      help={<Box>
                                          <Typography>
                                              This option produces an archive of the images used in the <em>Word Document with Images</em>
                                              and <em>PDF Document</em> formats.
                                          </Typography>
                                      </Box>}
                                />

                            </Box>
                            <Box sx={{mt: 2}}>
                                {isExporting &&
                                <Box>
                                    <Spinner message="Exporting"/>
                                </Box>
                                }
                                {!isExporting && report.export.error &&
                                <ErrorAlert title="Export Error" error={report.export.error}/>
                                }
                                {!isExporting && report.export.result &&
                                <Box>
                                    <Typography component="div" gutterBottom>Export complete.</Typography>
                                    <Button
                                        href={report.export.result}
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        startIcon={<GetAppIcon />}
                                        className={"ExportDownloadButton"}
                                    >
                                        Download
                                    </Button>
                                </Box>
                                }
                            </Box>
                            {isEditor && report.templates.curr.new === false && report.templates.curr.dirty === true &&
                            <Grid container justifyContent="center" spacing={4} sx={{
                                mt: 5,
                                "& > button": {
                                    ml: 1,
                                },
                                "& > .MuiTypography-root": {
                                    lineHeight: "initial",
                                    alignSelf: "center"
                                }
                            }}>
                                <Typography variant="subtitle1" >
                                    Template {report.templates.curr.title} was modified.
                                </Typography>
                                <Tooltip title={"Save changes to " + report.templates.curr.title}>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="primary"
                                        endIcon={<SaveIcon/>}
                                        onClick={handleSaveTemplate}
                                    >
                                        Save
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Save changes as a new template">
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        color="primary"
                                        endIcon={<FileCopyIcon/>}
                                        onClick={() => setShowSaveTemplateAs(true)}
                                    >
                                        Save as
                                    </Button>
                                </Tooltip>
                            </Grid>
                            }
                        </Box>
                        }
                    </Box>
                    :
                    <Grid container>
                        <Grid item xs={2}>
                        </Grid>
                        <Grid item sx={{flexGrow: 1}}>
                            <WizardInstruction component="span">
                                The report is ready.
                            </WizardInstruction>
                        </Grid>
                        <Grid item xs={2} sx={{textAlign: 'right'}}>
                            <Button
                                onClick={handleGenerate}
                                className="GenerateButton"
                                component="span"
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={isGenerating || !isReady}
                                startIcon={isGenerating ? <Progress progress={progress}/> : <ExitToAppIcon />}
                            >
                                Generate
                            </Button>
                        </Grid>
                    </Grid>
            }

            {!isDone &&
            <Box id="qc-report-root" ref={tables} sx={{mt: 2}}>
                <Fonts/>
                {config.type === TYPE_SUMMARY_DETAIL && <>
                    <Box p={1}>
                        <Summary padding={padding}/>
                    </Box>
                    {comps.map((comp, i) => <Box key={i} p={1}><Sheet comp={comp} index={i} padding={padding}/></Box>)}
                </>}
                {config.type === TYPE_SINGLE && <>
                    <Box p={1}>
                        <SingleTable />
                    </Box>
                </>}
                {((config.map||{}).include !== false) && <>
                    <Box p={1} sx={{
                        position: "relative",
                        height: "728px"
                    }}>
                        <Map isPreview={false} setMap={setMap} mapProps={{onMove: handleMapMove}}/>
                    </Box>
                </>}
            </Box>}


            <Dialog
                open={showSaveTemplateAs}
                onClose={() => handleSaveTemplateAs(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Save Template Changes</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        Please specify a name for the new template.
                    </DialogContentText>
                    <TextField value={saveTemplateAsName} onChange={evt => setSaveTemplateAsName(evt.target.value)}
                               label={"Template name"} variant="outlined" fullWidth/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleSaveTemplateAs(true)} color="primary" disabled={!saveTemplateAsName}>
                        Proceed
                    </Button>
                    <Button onClick={() => handleSaveTemplateAs(false)} color="primary" autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>

    )
};

export default ExportStep;
