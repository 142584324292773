import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import {useTheme} from '@mui/material';
import AppBar from "@mui/material/AppBar";
import Box from '@mui/material/Box';
import Toolbar from "@mui/material/Toolbar";
import Cart from "../cart/Cart";
import UserMenu from "./UserMenu";
import Logo from "./Logo";


const Header = () => {
    const theme = useTheme();
    return (
        <Fragment>
            <AppBar
                position="fixed"
                sx={{
                    zIndex: theme.zIndex.drawer + 1,
                    transition: theme.transitions.create(['width', 'margin'], {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
                    backgroundColor: "#333"
                }}
            >
                <Toolbar>
                    <Link to="/" sx={{display: 'flex', alignItems: 'center'}} className="HomeLink">
                        <Logo size="sm" theme="dark"/>
                    </Link>
                    <Box sx={{flexGrow: 1}}></Box>
                    <Cart/>
                    <UserMenu/>
                </Toolbar>
            </AppBar>
        </Fragment>
    )
};

export default Header;

