import React from "react";
import Box from '@mui/material/Box';
import IconButton from "@mui/material/IconButton";
import HelpIcon from '@mui/icons-material/Help';
import HelpDialog from "./HelpDialog";

const FieldWithHelp = ({field, help, children}) => {
    const [open, setOpen] = React.useState(false);

    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    return (
        <Box sx={{
          display: "flex",
          "& > *:first-of-type": {
            flexGrow: 1
          }
        }}>
            {children}
            <IconButton sx={{alignSelf: "center"}} onClick={handleOpen} size="large">
                <HelpIcon/>
            </IconButton>
            <HelpDialog title={field.label()} open={open} onClose={handleClose}>
                {help}
            </HelpDialog>
        </Box>
    );
};

export default FieldWithHelp;
