import React, {Fragment} from "react";
import {useDispatch} from 'react-redux';
import {NumericFormat} from "react-number-format";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {FormRow} from "../../util/Form";
import {Field} from  "../../model/fields";
import {addRateJump, removeRateJump, updateRateJump} from '../state';


const LeaseRateRow = ({jump, onChange, onRemove}) => {
    const styles = {
        row: {
            alignItems: "center", flexWrap: "nowrap"
        }
    }
    return (
        <FormRow spacing={0} sx={styles.row}>
            <Grid item>
                <Tooltip title="Remove this item">
                    <IconButton onClick={() => onRemove(jump)} size="large">
                        <HighlightOffIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item sx={{flexGrow: 1, p: 0}}>
                <Grid container spacing={1} sx={styles.row}>
                    <Grid item>
                        At
                    </Grid>
                    <Grid item>
                        <NumericFormat value={jump.month} size="small" variant="outlined"
                                      InputProps={{
                                          endAdornment: <InputAdornment position="end">Months</InputAdornment>
                                      }}
                                      onValueChange={ onChange(jump, "month") }
                                      decimalScale={0}
                                      customInput={TextField}>
                        </NumericFormat>
                    </Grid>
                    <Grid item sx={{whiteSpace: "nowrap"}}>
                        rate jumps to
                    </Grid>
                    <Grid item>
                        <NumericFormat value={jump.value} size="small" variant="outlined"
                                      InputProps={{
                                          startAdornment: <InputAdornment position="start">$</InputAdornment>
                                      }}
                                      onValueChange={ onChange(jump, "value") }
                                      decimalScale={2}
                                      customInput={TextField} >
                        </NumericFormat>
                    </Grid>
                </Grid>
            </Grid>
        </FormRow>
    );
};
const LeaseRateCalculator = ({comp}) => {
    const dispatch = useDispatch();

    const handleChange = (j, name) => (evt) => {
        let value = name === "month" ? parseInt(evt.floatValue) : evt.floatValue;
        dispatch(updateRateJump({id:j.id, jump: {[name]: value}}));
    };

    const jumps = Field.LEASE_RATE_JUMPS.get(comp);
    return (
        <Fragment>
            {jumps.map((j, i) => (
                <LeaseRateRow key={j.id} jump={j} onChange={handleChange}
                      onRemove={() => dispatch(removeRateJump(j))}/>
            ))}
            <Grid container spacing={0}>
                <Grid item>
                    <Tooltip title="Add a rate jump">
                        <IconButton onClick={() => dispatch(addRateJump())} size="large">
                            <AddCircleOutlineIcon color="primary"/>
                        </IconButton>
                    </Tooltip>

                </Grid>
            </Grid>
        </Fragment>
    );
};

export default LeaseRateCalculator
