import React from "react";
import Grid from "@mui/material/Grid";
import CompEditorPanel from './CompEditorPanel';
import CompField from "./CompField";
import {FormRow} from "../../util/Form";
import {Field} from  "../../model/fields";


const CommentPanel = ({comp, highlight, ...props}) => {
    const fieldProps = {comp, highlight};
    return (
        <CompEditorPanel name="CommentsPanel" comp={comp} {...props}>
            <FormRow>
                <Grid item xs={12}>
                    <CompField field={Field.COMMENTS} multiline={true} {...fieldProps}/>
                </Grid>
            </FormRow>
            <FormRow>
                <Grid item xs={12}>
                    <CompField field={Field.BRIEF} {...fieldProps}    inputProps={{ maxLength: 150 }}
                        helperText="Shorter version of comments suitable for summary views. Limited to 150 characters."/>
                </Grid>
            </FormRow>
        </CompEditorPanel>
    )
};

export default CommentPanel;
