import {Stack} from '@mui/material';
import Chip from '@mui/material/Chip';
import React, {Fragment} from "react";
import {useDispatch, useSelector} from "react-redux";
import prettyBytes from "pretty-bytes";
import {getDownloadURL, getStorage, ref as storageRef, uploadBytesResumable} from 'firebase/storage';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ImageList from "@mui/material/ImageList";
import PublishIcon from "@mui/icons-material/Publish";
import ImageIcon from "@mui/icons-material/Image";
import LabelIcon from "@mui/icons-material/Label";
import StarIcon from "@mui/icons-material/Star";
import CategoryIcon from "@mui/icons-material/Category";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FlipIcon from '@mui/icons-material/Flip';
import ErrorAlert from '../../util/ErrorAlert';
import TagInput from "../../util/TagInput";
import ImageWithItemBar from "../../util/ImageWithItemBar";
import {isStorageLimitReachedSelector} from "../../app/selectors";
import PlanLimitAlert from "../../util/PlanLimitAlert";
import SelectWithLabel from "../../util/SelectWithLabel";
import {PhotoCategory, PhotoOrientation} from "../../model/fields";
import {splitFileName} from '../../util/utils';
import {
    addPhoto,
    promotePhoto,
    removePhoto,
    selectPhoto,
    setPhotoCategory,
    setPhotoOrientation,
    setPhotoTags
} from '../state';
import CompEditorPanel from './CompEditorPanel';


const [photoWidth] = [ 384 ];

const CurrentPhoto = ({photoIndex}) => {
    const photo = useSelector(state => state.comp.curr.photos[photoIndex]);
    const dispatch = useDispatch();

    const handleDelete = () => {
        //evt.stopPropagation();
        dispatch(removePhoto(photo));
    };

    const handlePromote = () => {
        // evt.stopPropagation();
        dispatch(promotePhoto(photo));
    };

    const handleMakeAerial = () => {
        // evt.stopPropagation();
        dispatch(setPhotoCategory({
            photo: photoIndex,
            category: photo.category === PhotoCategory.AERIAL ? null : PhotoCategory.AERIAL,
            moveToFront: true
        }));
    };

    return (
        <Grid container>
            <Grid item>
                <ImageWithItemBar src={photo.url} icon={<DeleteOutlineIcon/>} sx={{
                    display: "inline-block",
                    height: "100% !important",
                    width: photoWidth,
                }}/>
            </Grid>
            <Grid item sx={{flexGrow:1, maxWidth: 512}}>
                <List disablePadding={true}>
                    <ListItem component="a" href={photo.url} sx={{pt:0}}>
                        <ListItemAvatar>
                            <Avatar>
                                <ImageIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={photo.name} secondary={prettyBytes(photo.size)}/>
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar>
                                <CategoryIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <SelectWithLabel label="Photo Category" values={PhotoCategory.values()} value={photo.category||""} addNone={true}
                                         onChange={evt => dispatch(setPhotoCategory({photo:photoIndex, category:evt.target.value}))} />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar>
                                <FlipIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <SelectWithLabel label="Photo Orientation" values={PhotoOrientation.values(photo.category)} value={photo.orientation||""}
                                         disabled={photo.category === PhotoCategory.AERIAL} addNone={true}
                                         onChange={evt => dispatch(setPhotoOrientation({photo: photoIndex, orientation:evt.target.value}))} />
                    </ListItem>
                    <ListItem sx={{mb: 8}}>
                        <ListItemAvatar>
                            <Avatar>
                                <LabelIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <TagInput value={photo.tags || []} onChange={tags => dispatch(setPhotoTags({photo:photoIndex, tags}))}
                                  placeholder="Add photo tags"  sx={{width: '100%'}}/>
                    </ListItem>
                    <ListItem>
                        <Button
                            variant="outlined"
                            color="secondary"
                            sx={{width: 200, mr:1}}
                            onClick={handleDelete}
                            startIcon={<DeleteOutlineIcon />}
                        >
                            Delete
                        </Button>
                        <Button
                          disabled={photoIndex===0}
                          variant="outlined"
                          color="primary"
                          onClick={handlePromote}
                          sx={{width: 200, mr:1}}
                          startIcon={<StarIcon />}
                        >
                            Primary
                        </Button>
                        <Button
                          variant="outlined"
                          disabled={photo.category === PhotoCategory.AERIAL}
                          onClick={handleMakeAerial}
                          sx={{width: 200}}
                          startIcon={<ImageIcon />}
                        >
                            Aerial
                        </Button>
                    </ListItem>
                </List>
            </Grid>
        </Grid>
    );
};
const PhotoPanel = ({firebase, ...props}) => {
    const [progress, setProgress] = React.useState(0);
    const [running, setRunning] = React.useState(false);
    const [error, setError] = React.useState(null);

    const auth = useSelector(state => state.auth);
    const comp = useSelector(state => state.comp.curr);
    const photos = useSelector(state => state.comp.curr.photos || []);
    const selectedPhoto = useSelector(state => state.comp.photo || 0);
    const isLimitReached = useSelector(isStorageLimitReachedSelector);
    const dispatch = useDispatch();

    const handleUpload = async (evt) => {
        setError(null);
        const dir = storageRef(getStorage(), `${auth.org.id}/comps/${comp.id}/photos`);
        const files = Array.from(evt.target.files);
        const uploads = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            // find an unused filename
            let fileName = file.name;
            let x = 1;
            while (x < 25) {
                try {
                    await getDownloadURL(storageRef(dir, fileName));
                    let [baseName, ext] = splitFileName(fileName);

                    if (baseName.endsWith(`-${x-1}`)) {
                        baseName = baseName.replace(new RegExp(`-${x-1}$`), `-${x}`)
                    }
                    else {
                        baseName = `${baseName}-${x}`;
                    }
                    fileName = `${baseName}.${ext}`
                    x++;
                } catch (err) {
                    break;
                }
            }

            uploads.push(new Promise((resolve, reject) => {
                const ref = storageRef(dir, fileName);

                setProgress(0);
                const task = uploadBytesResumable(ref, file);
                task.on('state_changed',
                  (snapshot) => {
                      setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                  },
                  (error) => {
                      reject(error);
                  },
                  () => {
                      dispatch(addPhoto({url: `${ref}`, comp}))
                        .unwrap()
                        .then(resolve)
                        .catch(setError);
                  }
                );
            }))
        }

        setRunning(true);
        Promise.allSettled(uploads)
          .catch(setError)
          .finally(() => setRunning(false));
    }

    return (
        <CompEditorPanel name="PhotoPanel" comp={comp} {...props}>
            {isLimitReached && <PlanLimitAlert title="Storage Limit Reached"
                 message="You have reached the maximum amount of storage for photos that your plan allows for."
                 upgradeMessage="Upgrade your plan to get more storage." gutterBottom/>}
            {!comp.id &&
                <Typography align="center">This comp has not yet been saved. Before you can add photos you must save.</Typography>
            }
            {comp.id && <Fragment>
                {error && <ErrorAlert error={error} sx={{mb:1}}/>}
                <Grid container>
                    <Grid item sx={{flexGrow:1}}>
                        {photos.length > 0 && <CurrentPhoto photoIndex={selectedPhoto} auth={auth} firebase={firebase}/> }
                    </Grid>
                    <Grid item sx={{textAlign: "right"}}>
                        <input id="photo-upload" type="file" multiple hidden onChange={handleUpload}/>
                        <label htmlFor="photo-upload">
                            <Button
                              component="span"
                              variant="contained"
                              color="primary"
                              size="large"
                              startIcon={<PublishIcon/>}
                              disabled={isLimitReached}
                              sx={{mt:1}}
                            >
                                Upload
                            </Button>
                        </label>
                        {running &&
                        <Box sx={{p: 3}}>
                            <LinearProgress variant="determinate" value={progress} />
                        </Box>}
                    </Grid>
                </Grid>
                <ImageList cols={6}>
                    {photos.map((photo,i) => {
                        const isPrimary = i === 0;
                        const hasCategory = !!photo.category;
                        return <ImageWithItemBar key={photo.url} src={photo.url}
                             onClick={() => dispatch(selectPhoto(i))}
                             itemBarProps={(isPrimary || hasCategory) ? {
                                 title: <Stack direction="row" spacing={1}>
                                     {isPrimary && <Chip variant="filled" label="Primary" color="primary" size="small"/>}
                                     {hasCategory && <Chip variant="filled" label={photo.category} color="secondary" size="small"
                                                        onDelete={() => dispatch(setPhotoCategory({photo: i, category: null}))}/>}
                                 </Stack>
                             } : null}
                             sx={{
                                 border: "4px solid",
                                 borderColor: "transparent",
                                 ...(i === selectedPhoto && {
                                     borderColor: 'primary.main'
                                 })
                             }}
                        />
                    })}
                </ImageList>
            </Fragment>}
        </CompEditorPanel>
    )
};

export default PhotoPanel;

