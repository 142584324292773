import React, {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useDrag, useDrop} from "react-dnd";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MenuIcon from "@mui/icons-material/Menu";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {hoverY} from "../util/dnd";
import FieldSelect from "../util/FieldSelect";
import {FieldLabel, Fields} from "../model/fields";
import {SORT_ASC} from "./constants";
import {moveInCart, removeFromCart, setSortField, toggleSortOrder} from './state';


const CartDialog = ({isOpen, onClose}) => {
    const comps = useSelector(state => state.cart.comps || []);
    const sort = useSelector(state => state.cart.sort || {});
    const [isPicking, setPicking] = useState(false);
    const dispatch = useDispatch();

    const sortField = sort.field && Fields.lookup(sort.field);
    // const isSorting = !!sortField;

    const handleSortField = f => {
        dispatch(setSortField({field:f}));
        setPicking(false);
    }

    return (
        <Dialog open={isOpen} fullWidth maxWidth={"md"} onClose={onClose} sx={{minHeight: "50vh"}}>
            <DialogTitle>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        Manage Cart
                    </Grid>
                    <Grid item>
                        {isPicking && <FieldSelect inputSize="small" value={sortField} onChange={handleSortField}/>}
                        {!isPicking && !sortField &&
                        <Tooltip title="Sort items in the cart by field">
                            <IconButton size="small" onClick={() => setPicking(true)} sx={{color: 'secondary.contrastText'}}>
                                <SortByAlphaIcon/>
                            </IconButton>
                        </Tooltip>}
                        {!isPicking && sortField && <>
                            <Chip label={sortField.label(FieldLabel.SHORT)} variant="outlined" sx={{bgcolor: 'white'}}
                                  onDelete={() => dispatch(setSortField({field:null}))}/>
                            <IconButton size="small" onClick={() => dispatch(toggleSortOrder())} sx={{color: 'secondary.contrastText'}}>
                                {sort.order === SORT_ASC ? <ArrowUpwardIcon/> : <ArrowDownwardIcon/>}
                            </IconButton>
                        </>}
                    </Grid>
                </Grid>

            </DialogTitle>
            <DialogContent dividers>
                <Typography sx={{mb:2}}>
                    Reorder and remove comps in the cart.
                </Typography>
                {comps.map((c,i) => <CartItem key={c.id} comp={c} index={i} />)}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()} color="primary" >
                    Finish
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const DRAG_TYPE_ITEM = "item";

const CartItem = ({index, comp}) => {
    const sort = useSelector(state => state.cart.sort || {});
    const dispatch = useDispatch();
    const ref = useRef(null);

    const [,drop] = useDrop({
        accept: DRAG_TYPE_ITEM,
        hover(item, monitor) {
            hoverY(ref, index, item, monitor, (from, to) => dispatch(moveInCart({from, to})));
        }
    });
    const [{ isDragging }, drag] = useDrag({
        item: { type: DRAG_TYPE_ITEM, id: comp.id, index },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    const sortField = sort.field && Fields.lookup(sort.field);
    return (
        <Paper ref={ref} style={{opacity}} variant="outlined" sx={{mb:1}}>
            <Grid container sx={{
                flexWrap: "nowrap",
                alignItems: "center"
            }}>
                <Grid item>
                    <Typography sx={{
                        color: 'grey.600',
                        pl: 1
                    }}>
                        {index+1}
                    </Typography>
                </Grid>
                <Grid item>
                    <Tooltip title={"Remove item"}>
                        <IconButton onClick={() => dispatch(removeFromCart(comp))} size="large">
                            <HighlightOffIcon/>
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item sx={{flexGrow: 1}}>
                    <Typography variant="subtitle2">
                        {comp.title}
                    </Typography>
                    {sortField && <Typography variant="caption">{sortField.render(comp)}</Typography> }
                </Grid>
                <Grid item>
                    <Tooltip title={"Reorder item"}>
                        <IconButton size="large">
                            <MenuIcon/>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default CartDialog;
