import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
    collection, doc,
    getDocs,
    getFirestore,
    limit,
    orderBy,
    query,
    startAfter,
    startAt,
    updateDoc
} from 'firebase/firestore';
import {SheetTemplate, Template} from '../model/objects';
import {first, last} from 'lodash';
import {copyTemplate as copySheetTemplate} from '../sheet/state';
import {copyTemplate as copyReportTemplate} from '../report/state';

export const loadTemplates = createAsyncThunk(
    'template/load',
    async (arg, {getState, rejectWithValue}) => {
        const {auth} = getState();
        try {
            const reports = (
                await getDocs(collection(getFirestore(), `orgs/${auth.org.id}/templates`))
            ).docs.map(Template.create);
            const sheets = (
                await getDocs(collection(getFirestore(), `orgs/${auth.org.id}/sheetTemplates`))
            ).docs.map(Template.create);
            return {reports, sheets};
        }
        catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const pageReportTemplates = createAsyncThunk(
    'template/report/page',
    async (forward, {getState, rejectWithValue}) => {
        const org = getState().auth.org.id;
        const list = getState().template.reports.list;
        const newPage = list.page+(forward ? 1 : -1);

        try {
            const constraints = [
                orderBy(list.sort.field, list.sort.order),
                limit(list.size)
            ];
            if (list.page > -1) {
                if (forward) {
                    constraints.push(startAfter(list.last));
                }
                else {
                    constraints.push(startAt(last(list.stack)));
                }
            }
            const result = await getDocs(query(
                collection(getFirestore(), 'orgs', org, 'templates'),
                ...constraints
            ));
            return {docs: result.docs, page: newPage};
        }
        catch(err) {
            return rejectWithValue(err);
        }
    }
);

export const pageSheetTemplates = createAsyncThunk(
    'template/sheet/page',
    async (forward, {getState, rejectWithValue}) => {
        const org = getState().auth.org.id;
        const list = getState().template.sheets.list;
        const newPage = list.page+(forward ? 1 : -1);

        try {
            const constraints = [
                orderBy(list.sort.field, list.sort.order),
                limit(list.size)
            ];
            if (list.page > -1) {
                if (forward) {
                    constraints.push(startAfter(list.last));
                }
                else {
                    constraints.push(startAt(last(list.stack)));
                }
            }
            const result = await getDocs(query(
                collection(getFirestore(), 'orgs', org, 'sheetTemplates'),
                ...constraints
            ));
            return {docs: result.docs, page: newPage};
        }
        catch(err) {
            return rejectWithValue(err);
        }
    }
);

export const copyTemplate = createAsyncThunk(
    'template/copy',
    async ({template, title, type}, {dispatch, rejectWithValue}) => {
        try {
            if (type === 'sheet') {
                await dispatch(copySheetTemplate({template, title})).unwrap();
            }
            else {
                await dispatch(copyReportTemplate({template, title})).unwrap();
            }
        }
        catch(err) {
            return rejectWithValue(err);
        }
    }
);

const doUpdateTemplate = async (template, orgId, type, data) => {
    let d;
    if (type === 'sheet') {
        d = doc(getFirestore(), `orgs/${orgId}/sheetTemplates/${template.id}`);
    }
    else {
        d = doc(getFirestore(), `orgs/${orgId}/templates/${template.id}`);
    }

    await updateDoc(d, data);
}

export const renameTemplate = createAsyncThunk(
    'template/rename',
    async ({template, title, type}, {getState, rejectWithValue}) => {
        const orgId = getState().auth.org.id;
        try {
            await doUpdateTemplate(template, orgId, type, {title});
        }
        catch(err) {
            console.log(err);
            return rejectWithValue(err);
        }
    }
);

export const updateTemplateTypes = createAsyncThunk(
    'template/updateTypes',
    async ({template, types, type}, {getState, rejectWithValue}) => {
        const orgId = getState().auth.org.id;
        try {
            await doUpdateTemplate(template, orgId, type, {types});
        }
        catch(err) {
            console.log(err);
            return rejectWithValue(err);
        }
    }
);

export const archiveTemplate = createAsyncThunk(
    'template/archive',
    async ({template, type}, {getState, rejectWithValue}) => {
        const orgId = getState().auth.org.id;
        try {
            await doUpdateTemplate(template, orgId, type, {
                archived: template.archived !== true
            });
        }
        catch(err) {
            console.log(err);
            return rejectWithValue(err);
        }
    }
);

const patchTemplate = (template, type, state, mutator) => {
    const values = type === 'sheet' ? state.sheets.list.values : state.reports.list.values;
    if (values && values.length > 0) {
        const idx = values.findIndex(t => t.id === template.id);
        if (idx > -1) {
            mutator(values[idx]);
        }
    }
}
const template = createSlice({
    name: 'template',
    initialState: {
        reports: {
            all: [],
            list: {
                page: -1,       // page index
                size: 10,       // page size
                values: [],     // page contents
                last: null,
                stack: [],
                sort: {
                    field: "updated",
                    order: "desc"
                },
            },
        },
        sheets: {
            all: [],
            list: {
                page: -1,       // page index
                size: 10,       // page size
                values: [],     // page contents
                last: null,
                stack: [],
                sort: {
                    field: "updated",
                    order: "desc"
                },
            },
        }
    },
    reducers: {
        sortReportTemplates: (state, action) => {
            const {field, order} = action.payload;
            state.reports.list.page = -1;
            state.reports.list.sort = {field: field.path, order}
        },
        sortSheetTemplates: (state, action) => {
            const {field, order} = action.payload;
            state.sheets.list.page = -1;
            state.sheets.list.sort = {field: field.path, order}
        },
        clearTemplate: (state, action) => {
            const {id} = action.payload;
            state.reports.all = state.reports.all.filter(t => t.id !== id);
            state.reports.list.values = state.reports.list.values.filter(t => t.id !== id);
            state.sheets.all = state.sheets.all.filter(t => t.id !== id);
            state.sheets.list.values = state.sheets.list.values.filter(t => t.id !== id);
        }
    },
    extraReducers: builder =>
        builder
            .addCase(loadTemplates.fulfilled, (state, action) => {
                state.reports.all = action.payload.reports || [];
                state.sheets.all = action.payload.sheets || [];
            })
            .addCase(pageReportTemplates.fulfilled, (state, action) => {
                const {page, docs} = action.payload;
                const list = state.reports.list;
                const forward = page > list.page;
                const stack = list.stack.slice();
                if (forward) {
                    stack.push(list.first);
                }
                else {
                    stack.pop();
                }
                list.page = page;
                list.values = docs.map(doc => Template.create(doc));
                list.last = last(docs);
                list.first = first(docs);
                list.stack = stack;
            })
            .addCase(pageSheetTemplates.fulfilled, (state, action) => {
                const {page, docs} = action.payload;
                const list = state.sheets.list;
                const forward = page > list.page;
                const stack = list.stack.slice();
                if (forward) {
                    stack.push(list.first);
                }
                else {
                    stack.pop();
                }
                list.page = page;
                list.values = docs.map(doc => SheetTemplate.create(doc));
                list.last = last(docs);
                list.first = first(docs);
                list.stack = stack;
            })
            .addCase(copyTemplate.fulfilled, (state, action) => {
                state.sheets.list.page = -1;
                state.reports.list.page = -1;
            })
            .addCase(renameTemplate.fulfilled, (state, action) => {
                const {template, title, type} = action.meta.arg;
                patchTemplate(template, type, state, (t) => {
                   t.title = title;
                });
            })
            .addCase(updateTemplateTypes.fulfilled, (state, action) => {
                const {template, types, type} = action.meta.arg;
                patchTemplate(template, type, state, (t) => {
                    t.types = types;
                });
            })
            .addCase(archiveTemplate.fulfilled, (state, action) => {
                const {template, type} = action.meta.arg;
                patchTemplate(template, type, state, (t) => {
                    t.archived = !t.archived;
                });
            })
});


export const {
    sortReportTemplates, sortSheetTemplates, clearTemplate
} = template.actions;

export default template.reducer;
