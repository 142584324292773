import IconButton from '@mui/material/IconButton';
import React from "react";
import {useSelector, useDispatch} from "react-redux";
import nanoid from "nanoid";
import moment from "moment";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Fab from "@mui/material/Fab";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import Tip from '../../util/Tip';
import {UserAvatar} from "../../util/UserAvatar";
import {Comp} from "../../model/objects";
import {addNote, removeNote} from '../state';
import CompEditorPanel from './CompEditorPanel';


const NotesPanel = ({comp, ...props}) => {
    const user = useSelector(state => state.auth.user);
    const notes = useSelector(state => state.comp.curr.notes || []);
    const dispatch = useDispatch();
    const [note, setNote] = React.useState("");

    const handleChange = evt => setNote(evt.target.value);
    const handleAdd = evt => {
        dispatch(addNote({
            id: nanoid(),
            timestamp: new Date(),
            content: note,
            user: user.displayName
        }));
        setNote("");
    };

    const isReady = !!note.trim();

    return (
        <CompEditorPanel name="NotesPanel" comp={comp} {...props}>
            <Tip id="notes" mb={4}>
                Notes are for internal team use only, and not used in comp outputs. Use the <em>Comments</em> tab to
                provide information to be displayed in comp reports.
            </Tip>
            <Grid container spacing={2} alignItems="flex-end">
                <Grid item sx={{flexGrow: 1}}>
                    <TextField value={note} onChange={handleChange} placeholder="Type something..." multiline fullWidth/>
                </Grid>
                <Grid item>
                    <Fab color="primary" aria-label="add" disabled={!isReady}>
                        <SendIcon onClick={handleAdd}/>
                    </Fab>
                </Grid>
            </Grid>
            <Box sx={{mt: 2}}>
                <List>
                    {notes.map((note, i) =>
                        <ListItem key={i}
                                  secondaryAction={
                                    <IconButton edge="end" onClick={() => dispatch(removeNote(note))}>
                                        <DeleteIcon/>
                                    </IconButton>
                                  }>
                            <ListItemAvatar>
                                <UserAvatar user={note.user}/>
                            </ListItemAvatar>
                            <ListItemText primary={note.content}
                              secondary={moment(Comp.date(note.timestamp)).fromNow() + " by " + note.user} />
                        </ListItem>
                    )}
                </List>
            </Box>
        </CompEditorPanel>
    )
};

export default NotesPanel
