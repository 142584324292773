import {Timestamp} from 'firebase/firestore';
import {produce} from 'immer';
import {ICI} from "./propertyTypes";

import {Field, FieldLabel, Fields} from "./fields";
import {isEmpty} from 'lodash';

export class Org {
    static create(doc) {
        return Object.assign({}, doc.data(), { id: doc.id});
    }
}

export class Comp {

    static new() {
        return {
            type: ICI,
            address: {
                street: "",
                city: "",
                region: "",
                postal: "",
                country: "",
            },
            general: {},
            confirmation: {},
            improvement: {},
            physical: {},
            sales: {},
            utilities: {
                [Field.UTILITIES_HYDRO.name]: Field.UTILITIES_HYDRO.values()[0],
                [Field.UTILITIES_WATER.name]: Field.UTILITIES_WATER.values()[0],
                [Field.UTILITIES_SEWER.name]: Field.UTILITIES_SEWER.values()[0],
                [Field.UTILITIES_NATURAL_GAS.name]: Field.UTILITIES_NATURAL_GAS.values()[0],
            },
            financial: {
            },
            lease: {},
            notes: []
        };
    }

    static create(doc) {
        const org = doc.ref.parent.parent.id;
        return Object.assign({}, doc.data(), { id: doc.id, org });
    }

    static title(obj) {
        return obj.title ||
          (obj.address ?
            ((obj.address.unit ? `${obj.address.unit} - ` : "") + obj.address.street) :
            null);
    }

    static geo(doc) {
        let g = typeof doc.get === "function" ? doc.get("geo") : doc["geo"];
        if (g) {
            g = Comp.point(g);
        }
        return g;
    }

    static date(obj) {
        if (obj == null) return null;
        if (obj instanceof Date) {
            return obj;
        }
        if (obj instanceof Timestamp) {
            return obj.toDate();
        }
        if (typeof obj === "object" && "seconds" in obj) {
            return new Date(obj.seconds * 1000);
        }
        if (typeof obj === "string") {
            return new Date(Date.parse(obj));
        }
        if (typeof obj === 'number') {
            return new Date(obj);
        }
        if (typeof obj === "undefined") {
            return null;
        }
        return obj;
    }

    static point(obj) {
        if (!Array.isArray(obj)) {
            if ("longitude" in obj) {
                obj = [obj.longitude, obj.latitude];
            }
        }
        return obj;
    }

    static clone(comp, skipGroups) {
        const newComp = {...comp};

        Fields.list().filter(it => it.isCopyable).filter(it => !skipGroups.includes(it.group)).forEach(f => {
            const v1 = f.get(newComp);
            if (v1 === null || typeof v1 === "undefined") {
                const v2 = f.get(comp);
                if (v2 !== null && typeof v2 !== "undefined") {
                    f.set(newComp, v2);
                }
            }
        });

        return newComp;
    }
}

export class Report {
    static create(doc) {
        let org = doc.ref.parent.parent.id;
        return Object.assign({}, doc.data(), { id: doc.id, org});
    }
}

export class Template {
    static create(doc, base={}) {
        let org = doc.ref.parent.parent.id;
        return Object.assign({}, base, doc.data(), { id: doc.id, org});
    }

    static map(obj) {   // should be unmap?
        const mapSection = s => {
            if ("field" in s) {
                s = produce(s, d2 => {
                    d2.field = Fields.lookup(d2.field);
                });
            }
            if ("fields" in s) {
                s = produce(s, d2 => {
                    d2.fields = d2.fields.map(Fields.lookup)
                });
            }
            return s;
        }
        let m = produce(obj, d1 => {
            d1.summary.columns = d1.summary.columns.map(c => produce(c, d2 => {
                d2.fields = d2.fields.map(Fields.lookup)
            }))
            if (d1.detail.sections) {
                d1.detail.sections = d1.detail.sections.map(mapSection);
            }
            if (d1.detail.pages) {
                d1.detail.pages = d1.detail.pages.map(p => produce(p, d2 => {
                    d2.sections = d2.sections.map(mapSection);
                }))
            }
        });

        if (obj.single && obj.single.columns) {
            m = produce(m, d1 => {
                d1.single.columns = d1.single.columns.map(c => produce(c, d2 => {
                    d2.fields = d2.fields.map(Fields.lookup)
                }))
            })
        }

        return m;
    }

    static mapField(f) {
        return {
            path: f.path,
            type: f.type,
            options: {
              roundToDollar: f.roundToDollar,
              formatWithComma: f.formatWithComma,
              ...(f.numDecimals > 0 ? {numDecimals: f.numDecimals} : {})
            },
            labels: {
              [FieldLabel.DEFAULT]: f.label(),
              [FieldLabel.REPORT_HEADER]: f.label(FieldLabel.REPORT_HEADER),
              [FieldLabel.REPORT_BODY]: f.label(FieldLabel.REPORT_BODY),
            }
        };
    }

    static listFields(t) {
        const s = new Set([]);
        const addField = f => s.add(typeof f === "string" ? f : f.path);
        for (let s of ['single', 'summary']) {
            if (t[s] && t[s].columns) {
                t[s].columns.forEach(c => c.fields.forEach(addField));
            }
        }
        if (t.detail) {
            if (t.detail.sections) {
                t.detail.sections.forEach(s => {
                    if (s.field) addField(s.field);
                    if (s.fields) s.fields.forEach(addField);
                });
            }
            if (t.detail.pages) {
                t.detail.pages.forEach(p => p.sections.forEach(s => {
                    if (s.field) addField(s.field);
                    if (s.fields) s.fields.forEach(addField);
                }));
            }
        }
        return Array.from(s);
        // summary, single
        // detail

    }
}

export class Search {
    static create(doc) {
        let org = doc.ref.parent.parent.id;
        return Object.assign({}, doc.data(), { id: doc.id, org});
    }
}

export class Backup {
    static create(doc) {
        let org = doc.ref.parent.parent.id;
        return Object.assign({}, doc.data(), { id: doc.id, org});
    }
    static date(doc) {
        return doc.finished || doc.started || doc.created;
    }
}

const unmapField = f => Fields.lookup(f.path);
const mapField = f => {
    if (typeof f.label === "function") {
        return {name: f.label(FieldLabel.SHORT), type: f.type, path: f.path};
    }
    return f;
}

export class Sheet {
    static create(doc) {
        let org = doc.ref.parent.parent.id;
        let sheet = {...doc.data(), id: doc.id, org, ref: doc.ref };
        sheet.fields = (sheet.fields||[]).map(unmapField);
        if (sheet.unit) {
            sheet.unit = unmapField(sheet.unit);
        }
        if (sheet.time.field) {
            sheet.time.field = unmapField(sheet.time.field);
        }
        if (sheet.time.date) {
            sheet.time.date = Comp.date(sheet.time.date);
        }
        if (sheet.metrics && sheet.metrics.fields) {
            sheet.metrics.fields = sheet.metrics.fields.map(unmapField)
        }

        if (Array.isArray(sheet.adjusts)) {
            sheet.adjusts = {
                enabled: true,
                columns: sheet.adjusts
            };
        }
        return sheet;
    }

    static map(obj) {
        const s = {
            ...obj,
            fields: (obj.fields||[]).map(mapField),
            metrics: {
              ...obj.metrics,
              fields: (obj.metrics.fields||[]).map(mapField)
            },
            ref: null
        }
        if (obj.unit) {
            s.unit = mapField(obj.unit);
        }
        if ((obj.time||{}).field) {
            s.time = {
                ...obj.time,
                field: mapField(obj.time.field)
            }
            if (obj.time.date && !isEmpty(obj.time.date)) {
                s.time.date = Comp.date(obj.time.date).toISOString()
            }
        }
        return s;
    }

    static unmap(obj) {
        return produce(obj, d => {
            d.fields = (d.fields || []).map(unmapField);
            if (obj.unit) {
                d.unit = unmapField(obj.unit);
            }
            if ((obj.time||{}).field) {
                d.time.field = unmapField(d.time.field);
            }
            if ((obj.metrics||{}).fields) {
                d.metrics.fields = (d.metrics.fields||[]).map(unmapField);
            }
            d.ref = null;
        });
    }
}

export class SheetTemplate {
    static create(doc, base={}) {
        let org = doc.ref.parent.parent.id;
        const t = Object.assign({}, base, doc.data(), { id: doc.id, org});
        if (t.time && t.time.date) {
            t.time.date = Comp.date(t.time.date);
        }
        return t;
    }
}

export class List {

    static create(doc) {
        let org = doc.ref.parent.parent.id;
        return {...doc.data(), id: doc.id, org, ref: doc.ref };
    }
}

export class User {

    static create(doc) {
        let org = doc.ref.parent.parent.id;
        return {...doc.data(), id: doc.id, org, ref: doc.ref };
    }
}

