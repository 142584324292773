import CircularProgress from '@mui/material/CircularProgress';
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Checkbox from '@mui/material/Checkbox';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import DoneIcon from '@mui/icons-material/Done';
import {pushSnack} from '../app/state';
import SelectWithLabel from "../util/SelectWithLabel";
import {isSomething} from "../util/utils";
import {MODE_CREATE, MODE_UPDATE} from "./constants";
import ErrorAlert from "../util/ErrorAlert";
import {addList, chooseList, loadLists, setMode, setProp, updateList} from './state';


const ListDialog = ({isOpen, onClose}) => {
    const mode = useSelector(state => state.list.mode);
    const list = useSelector(state => state.list.curr || {});
    const lists = useSelector(state => state.list.all || []);
    const dispatch = useDispatch();
    const [error, setError] = useState();
    const [isWorking, setWorking] = useState(false);
    const [replace, setReplace] = useState(false);

    useEffect(() => {
        if (mode === MODE_UPDATE) {
            dispatch(loadLists());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode]);

    const finish = () => {
        setWorking(true);
        if (mode === MODE_CREATE) {
            dispatch(addList())
                .unwrap()
                .then(newList => {
                    dispatch(pushSnack({message: `List ${newList.title} created.`}));
                    onClose();
                })
                .catch(setError)
                .finally(() => setWorking(false))
        }
        else {
            dispatch(updateList(replace))
              .unwrap()
              .then(newList => {
                  dispatch(pushSnack({message: `List ${newList.title} updated.`}));
                  onClose();
              })
              .catch(setError)
              .finally(() => setWorking(false));
        }
    }
    const isReady = mode === MODE_CREATE ? isSomething(list.title) : isSomething(list.id)
    return (
        <Dialog open={isOpen} fullWidth maxWidth={"md"} sx={{minHeight: "50vh"}} onClose={onClose} className="ListDialog">
            <DialogTitle>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        Create List
                    </Grid>
                    <Grid item>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                {error && <ErrorAlert error={error}/>}
                <RadioGroup value={mode} onChange={evt => dispatch(setMode(evt.target.value))}>
                    <FormControlLabel value={MODE_CREATE} control={<Radio />} label="Create a New List" />
                    {mode === MODE_CREATE && <>
                        <Box mb={1} mt={1}>
                            <TextField value={list.title||""} label="Title" fullWidth={true} variant="outlined"
                                       onChange={evt => dispatch(setProp({key:"title", value:evt.target.value}))}
                            />
                        </Box>
                        <Box mb={2}>
                            <TextField value={list.description||""} label="Description" fullWidth={true} multiline={true} rows={5}
                                       onChange={evt => dispatch(setProp({key:"description", value:evt.target.value}))}
                                       variant="outlined" placeholder="An optional brief description of the list."
                            />
                        </Box>
                    </>}
                    <FormControlLabel value={MODE_UPDATE} control={<Radio />} label="Update an Existing List" />
                    {mode === MODE_UPDATE && <>
                        <Box mt={1}>
                            <SelectWithLabel label="Choose a List" value={list.id || ""} onChange={evt => dispatch(chooseList(evt.target.value))}
                                             values={lists.map(l => [l.id, l.title])}/>
                        </Box>
                        <Box mt={1}>
                            <FormControlLabel
                              control={<Checkbox checked={replace} onChange={() => setReplace(!replace)} name="replace" />}
                              label={`Replace the List with the comps in the cart, rather than append to it`}
                            />
                        </Box>
                    </>}
                </RadioGroup>

            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()} disabled={isWorking}>
                    Cancel
                </Button>
                <Button onClick={finish} color="primary" variant="outlined" disabled={!isReady || isWorking} className={"FinishButton"}
                        startIcon={isWorking ? <CircularProgress size={24}/> : <DoneIcon/>}>
                    Finish
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ListDialog;
