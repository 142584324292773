import React from "react";
import Box from '@mui/material/Box';
import Sidebar from "../app/Sidebar";


const Page = ({children, sx, className}) => {
    return (
        <Box display="flex" mt={8} pb={4} sx={sx} className={className}>
            <Sidebar/>
            <Box sx={{flexGrow:1, overflowX:'hidden', display: 'flex', flexDirection: 'column'}}>
                {children}
            </Box>
        </Box>
    )
};

export default Page;
