import React, {useRef} from "react";
import {useDrag, useDrop} from "react-dnd";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import EditableLabel from '../../util/EditableLabel';
import {hoverXY} from "../../util/dnd";
import {FieldLabel} from "../../model/fields";

const DRAG_TYPE_FIELD = "field";

const FieldChip = ({field, index, isSelected, onSelect, onMove, onRemove, label, onEditLabel, ...props}) => {
    const ref = useRef(null);
    const [, drop] = useDrop({
        accept: DRAG_TYPE_FIELD,
        hover(item, monitor) {
            hoverXY(ref, index, item, monitor, onMove)
        }
    });
    const [{ isDragging }, drag] = useDrag({
        item: { type: DRAG_TYPE_FIELD, id: field.path, index },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    const removable = onRemove != null;
    const selectable = onSelect != null;
    const editable = onEditLabel != null;
    const defaultLabel = field.label(FieldLabel.SHORT);
    return (
        <Paper ref={ref} style={{opacity}}>
            <Stack direction="row" sx={{cursor: 'pointer'}}>
                <Box pl={2} py={0.5} pr={editable?0:2} flex={1}>
                    {editable ?
                        <EditableLabel value={label || defaultLabel} defaultValue={defaultLabel} showDefaultValue={true} onChange={onEditLabel}/>
                        :<Typography>{label || defaultLabel}</Typography>}
                </Box>
                <Stack direction="row" alignItems="center" sx={{bgcolor: 'grey.100', px: 1}}>
                    {selectable && <IconButton onClick={onSelect} size="small" sx={{p:0.1}}>
                        {isSelected ? <RadioButtonCheckedIcon sx={{color: `secondary.main`}}/> : <RadioButtonUncheckedIcon/>}
                    </IconButton>}
                    {removable && <IconButton onClick={onRemove} size="small" sx={{p:0.1}}>
                        <HighlightOffIcon/>
                    </IconButton>}
                </Stack>
            </Stack>
        </Paper>
        // <Chip ref={ref} label={field.label(FieldLabel.SHORT)} style={{opacity}} {...props}
        //     icon={selectable === true ? isSelected === true ? <RadioButtonCheckedIcon sx={{color: `secondary.main`}}/> : <RadioButtonUncheckedIcon/> : null}
        //     onDelete={onRemove} onClick={selectable === true ? onSelect : null}/>
    );
};

export default FieldChip;

