import {useState} from "react";
import {useDispatch} from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import {addSearch} from "./state";

const SaveSearchDialog = ({open, onClose}) => {
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [isWorking, setWorking] = useState(false);

    const handleSave = () => {
        setWorking(true);
        dispatch(addSearch(name)).unwrap()
            .then(search => onClose(search))
            .finally(() => setWorking(false));
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth={true}>
            <DialogTitle>Save Search</DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    Please enter a name for the new saved search.
                </DialogContentText>
                <TextField
                    value={name} onChange={evt => setName(evt.target.value)}
                    autoFocus
                    margin="dense"
                    label="Search Name"
                    variant="outlined"
                    sx={{mt:2}}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleSave} startIcon={isWorking && <CircularProgress size={24}/>}
                        disabled={isWorking || !name.trim()}>
                    Save
                </Button>
                <Button onClick={() => onClose(false)} disabled={isWorking}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default SaveSearchDialog;