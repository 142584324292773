import React from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Stack from '@mui/material/Stack';
import CreateIcon from "@mui/icons-material/Create";

const EditableLabel = ({value, onChange, tooltip, withClick = false, withButton = true, withEditHelp = true, typeSx, defaultValue, showDefaultValue, size="medium"}) => {
    const [edit, setEdit] = React.useState(value);
    const [isEditing, setIsEditing] = React.useState(false);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleKey = evt => {
        if (evt.charCode === 13) {
            evt.preventDefault();
            setIsEditing(false);
            onChange(edit || defaultValue);
        }
        else if (evt.key === 'Escape') {
            evt.preventDefault();
            setIsEditing(false);
            setEdit(value);
        }
    };

    const button = withButton ? <IconButton onClick={handleEdit} size={size}>
        <CreateIcon fontSize="small"/>
    </IconButton> : null;
    return (
    <Stack direction="row" alignItems="center">
        {isEditing ?
            <TextField value={edit} onChange={evt => setEdit(evt.target.value)} onKeyPress={handleKey} onKeyUp={handleKey} autoFocus
                       helperText={withEditHelp ? "Press Enter when done editing, press Escape to cancel" : null} size={size}/>
            :
            <>
                <Stack>
                    <Typography component="span" onClick={withClick ? handleEdit : null} sx={{
                        ...(withClick && {
                            cursor: "pointer",
                            "&:hover": {
                                textDecoration: "underline"
                            }
                        }),
                        ...typeSx
                    }}>{value}</Typography>
                    {showDefaultValue && value !== defaultValue && <Typography variant="caption" sx={{fontStyle: "italic"}}>{defaultValue}</Typography>}
                </Stack>
                {tooltip ? <Tooltip title={tooltip}>{button}</Tooltip> : button }
            </>
        }
    </Stack>
    );
};

export default EditableLabel;
