import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {sumBy} from 'lodash';
import prettyBytes from "pretty-bytes";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor';
import SendIcon from '@mui/icons-material/Send';
import ClearIcon from '@mui/icons-material/Clear';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ErrorAlert from '../util/ErrorAlert';
import Page from '../util/Page';
import ToolBar from '../util/ToolBar';
import {submitTicket} from './state';

const ScreenShot = ({file, onRemove, ...props}) => {
  const [hover, setHover] = useState(false);
  return (
    <Stack direction="row" sx={{alignItems: "flex-start", pl:2}} {...props}>
      <IconButton size="small" onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)}
        onClick={onRemove}>
        {hover ? <ClearIcon/> : <AttachFileIcon/>}
      </IconButton>
      <Stack>
        <Typography>{file.name}</Typography>
        <Typography variant="subtitle2">{prettyBytes(file.size)}</Typography>
      </Stack>
    </Stack>
  )
}

const SupportPage = () => {
  const dispatch = useDispatch();
  const [info, setInfo] = useState({
    title: '',
    description: ''
  });
  const updateInfo = evt => {
    setInfo({
      ...info,
      [evt.target.name]: evt.target.value
    });
  }

  const [files, setFiles] = useState([]);
  const removeFile = i => {
    const newFiles = files.slice();
    newFiles.splice(i,1);
    setFiles(newFiles);
  }
  const addFile = (evt) => {
    const f = files.slice();
    for (let i = 0; i < evt.target.files.length; i++) {
      f.push(evt.target.files[i]);
    }
    setFiles(f);
  }

  const [size, setSize] = useState(0);
  useEffect(() => {
    setSize(sumBy(files, f => f.size));
  }, [files]);

  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const [isWorking, setWorking] = useState(false);
  const handleSubmit = () => {
    setWorking(true);
    setError(null);

    setTimeout(() => {
      Promise.all(files.map(f => new Promise(resolve => {
        const r = new FileReader();
        r.addEventListener("load", () => resolve({
          name: f.name,
          data: r.result
        }));
        r.readAsDataURL(f)
      })))
        .then(urls => {
          dispatch(submitTicket({
            ...info,
            files: urls
          }))
            .unwrap()
            .then(() => setSuccess(true))
            .catch(setError)
            .finally(() => setWorking(false))
        })
        .catch(setError);
    });
  }

  const uploadLimit = 10000000;
  const isReady = info.title.trim() && info.description.trim();

  return (
    <Page className="SupportPage">
      <ToolBar title="File a Support Ticket">
      </ToolBar>
      <Container fixed sx={{pt:4}}>
        <Alert severity="info" action={<Button color="inherit" size={"small"} href="mailto:support@quickcomp.io?subject=">
          Email
        </Button>}>
          <AlertTitle>Having an Issue?</AlertTitle>
          <Typography>
            No problem. Use the form below to send us some information and create a support ticket and one of our support
            agents will get on it right away. If you're not a fan of forms, feel free to email our support team directly instead.
          </Typography>
        </Alert>
        <Stack spacing={2} sx={{mt:4}}>
          <TextField value={info.title} onChange={updateInfo} name="title" label="Title"
                     variant="outlined" placeholder="A short description of your problem"
                     required={true} autoFocus={true} InputLabelProps={{ shrink: true }}/>
          <TextField value={info.description} onChange={updateInfo} name="description" label="Description"
                     variant="outlined"  multiline={true} required={true} rows={10} InputLabelProps={{ shrink: true }}
                     placeholder="More details about the problem, and the steps you went through to experience it. More detail the better." />
          <Grid container sx={{flexWrap: "nowrap"}}>
            <Grid item>
              <input id="screenshot-upload" type="file" multiple hidden accept="image/*" onChange={addFile}/>
              <label htmlFor="screenshot-upload">
                <Button
                  component="span"
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<ScreenshotMonitorIcon/>}
                >
                  Screenshots
                </Button>
              </label>
            </Grid>
            <Grid item sx={{flexGrow: 1, display: "flex", flexWrap: "wrap"}}>
              {files.map((f,i) => <ScreenShot file={f} key={i} onRemove={() => removeFile(i)}/>)}
            </Grid>
          </Grid>
          <FormHelperText sx={{mt:0.5}}>Screenshots help to show us exactly what you are seeing, and solve your problem faster.</FormHelperText>
        </Stack>
        {size > uploadLimit && <Alert severity="error" sx={{mt:2}}>
          <AlertTitle>Too Large to Upload</AlertTitle>
          <Typography>Unfortunately the screen shots you have attached total <em>{prettyBytes(size)}</em>, which is larger than the max size of <em>{prettyBytes(uploadLimit)}</em>. Please attach fewer files, or attach images that
            are smaller in size.</Typography>
        </Alert> }
        <Box sx={{mt:2}}>
            {error && <ErrorAlert error={error}/>}
            {success ? <Alert severity="success">
            <AlertTitle>Success!</AlertTitle>
            <Typography>Your issue has been submitted to our help desk. You'll be hearing from us shortly.</Typography>
          </Alert> : <Button variant="contained" size="large" onClick={handleSubmit} disabled={!isReady || isWorking}
                             startIcon={isWorking ? <CircularProgress size={24}/> : <SendIcon/>} >
            Submit
          </Button>}
        </Box>
      </Container>
    </Page>
  )
};

export default SupportPage;
