import React from 'react';
import {Helmet} from 'react-helmet';
import {FONTS, fontURL, lookupFont} from '../model/fonts';

const Fonts = ({families}) => {
    return (
        <Helmet>
            {Array.from(families).map(f => FONTS.find(it => it.id === f))
                .filter(it => it.type === "google" || it.previewId)
                .map(it => it.previewId ? lookupFont(it.previewId) : it)
                .map(it => <link key={it.id} rel="stylesheet" href={fontURL(it)}/>)}
        </Helmet>
    )
}

export default Fonts;