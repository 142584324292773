import React, {useEffect, useRef} from "react";
import Box from '@mui/material/Box';
import {borderAsObject, DEFAULT_IMAGE_HEIGHT} from "../config";
import ImagePlaceholder from "../../util/ImagePlaceholder";

const hackSrcURL = u => {
  return u.replaceAll(/'/g, "\\'").replaceAll(/"/g, '\\"')
    .replaceAll(/\(/g, "\\(").replaceAll(/\)/g, "\\)")
}

const Image = ({src, alt, placeholder, section, style, onRender, ...props}) => {
    const ref = useRef();

    useEffect(() => {
      if (onRender && ref.current) {
        onRender(ref.current);
      }
    }, [ref, onRender]);

    const height = section.height || DEFAULT_IMAGE_HEIGHT;
    const borderStyle = section.border === true ? borderAsObject(style.section.border) : {};
    return (
        src ? <Box ref={ref} component="div" src={src} {...props} alt={alt||""} style={borderStyle} sx={{
              backgroundImage: `url(${hackSrcURL(src)})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
              height: `${height}px`,
              // maxHeight: "512px",
          }}/> : placeholder ? <ImagePlaceholder height={height} message={placeholder}/> : null
    );
};

export default Image;
