import {useTheme} from '@mui/material';
import React from "react";
import Typography from "@mui/material/Typography";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineContent from "@mui/lab/TimelineContent";
import Paper from "@mui/material/Paper";
import {UserAvatar} from "../../util/UserAvatar";
import CompEditorPanel from './CompEditorPanel';
import {formatDateTime, fromNow} from "../../util/utils";
import {FieldLabel, Fields} from "../../model/fields";


const HistoryPanel = ({comp, ...props}) => {
    const theme = useTheme();
    const history = comp.history || [];

    const styles = {
        avatar: {
            width: theme.spacing(4),
            height: theme.spacing(4),
        },
        paper: {
            p: 1
        }
    }
    return (
        <CompEditorPanel name="HistoryPanel" comp={comp} {...props}>
            <Timeline align="right">
                {history.map((h,i) => <TimelineItem key={i}>
                    <TimelineOppositeContent>
                        <Typography>
                            {formatDateTime(h.updated)}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {fromNow(h.updated)}
                        </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot>
                            <UserAvatar user={comp.updated_by} sx={styles.avatar}/>
                        </TimelineDot>
                        <TimelineConnector/>
                    </TimelineSeparator>
                    <TimelineContent>
                        <Paper elevation={1} sx={styles.paper}>
                            <Typography>Updated by {comp.updated_by.name}.</Typography>
                            <Typography variant="body2" color="textSecondary">
                                Changed {(h.fields||[]).slice().reverse().map(f => {
                                    if (f === "docs") return "Documents";
                                    if (f === "photos") return "Photos";
                                    const field = Fields.lookup(f);
                                    if (field) return field.label(FieldLabel.SHORT);
                                    return "";
                                }).join(", ")}
                            </Typography>
                        </Paper>
                    </TimelineContent>
                </TimelineItem>)}
                {history.length === 0 && comp.updated > comp.created && comp.updated_by &&
                <TimelineItem>
                    <TimelineOppositeContent>
                        <Typography>
                            {formatDateTime(comp.updated)}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {fromNow(comp.updated)}
                        </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot>
                            <UserAvatar user={comp.updated_by} sx={styles.avatar}/>
                        </TimelineDot>
                    </TimelineSeparator>
                    <TimelineContent>
                        <Paper elevation={1} sx={styles.paper}>
                            <Typography>Updated by {comp.updated_by.name}.</Typography>
                        </Paper>
                    </TimelineContent>
                </TimelineItem>
                }
                {comp.created_by &&
                <TimelineItem>
                    <TimelineOppositeContent>
                        <Typography>
                            {formatDateTime(comp.created)}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {fromNow(comp.created)}
                        </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot>
                            <UserAvatar user={comp.created_by} sx={styles.avatar}/>
                        </TimelineDot>
                    </TimelineSeparator>
                    <TimelineContent>
                        <Paper elevation={1} sx={styles.paper}>
                            <Typography>Created by {comp.created_by.name}.</Typography>
                        </Paper>
                    </TimelineContent>
                </TimelineItem>
                }
            </Timeline>
        </CompEditorPanel>
    )
}

export default HistoryPanel;
