import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import {TabPanel} from "../../util/Tab";
import {FormRow} from "../../util/Form";
import PlanCard from "../../signup/PlanCard";
import Spinner from "../../util/Spinner";
import ErrorAlert from "../../util/ErrorAlert";
import {planSelector} from "../selectors";
import {TableToolbar} from "../../util/Table";
import {changePlan, choosePlan, loadPlans} from '../state';

const PlanPanel = (props) => {
    const dispatch = useDispatch();
    const plan = useSelector(planSelector);
    const plans = useSelector(state => state.pref.billing.plans);
    const planIndex = useSelector(state => state.pref.billing.currentPlan);
    const newPlanIndex = useSelector(state => state.pref.billing.newPlan);
    const isChanged = useSelector(state => state.pref.billing.planChanged);
    const error = useSelector(state => state.pref.error);
    const hasCard = !!plan.payment;

    const [isLoading, setIsLoading] = useState(true);
    const [isWorking, setIsWorking] = useState(false);

    useEffect(() => {
        dispatch && dispatch(loadPlans()).unwrap().then(() => setIsLoading(false));
    }, [dispatch]);

    const handleProceed = () => {
        setIsWorking(true);
        dispatch(changePlan({newPlan: plans[newPlanIndex], oldPlan: plan}))
          .unwrap()
          .then(() => {
            setIsWorking(false);
          })
    };

    const handleCancel = () => dispatch(choosePlan(-1));

    return (
        <TabPanel {...props} >
            {isLoading ? <Spinner message="Loading plan data" /> :
                <>
                    <Paper sx={{mb:2}}>
                        <TableToolbar title="Current Plan"/>
                        <div>
                            {isWorking ? <Spinner/> : <Typography sx={{
                                fontWeight: "bold",
                                textTransform: "uppercase",
                                p: 2
                            }}>
                                {plan.name}
                            </Typography>}
                        </div>
                    </Paper>

                    {plans &&
                        <Grid item xs={12}>
                            <Grid container disableGutters spacing={1}>
                                {plans.map((p,i) => <Grid key={p.id} item style={{flex: "1 0"}}>
                                    <PlanCard plan={p} disabled={p.name === plan.name || !hasCard}
                                              chosen={newPlanIndex === i}
                                              onChoose={() => !isWorking ? dispatch(choosePlan(i)) : null}
                                              buttonText={i < planIndex  ? "Downgrade" : i > planIndex ? "Upgrade" : "Current"}
                                    />
                                </Grid> )}
                            </Grid>
                        </Grid>
                    }

                    {error && <ErrorAlert error="There was an issue changing your plan. Please contact us and we'll sort out the issue."/> }
                    {newPlanIndex > -1 && <FormRow>
                        <Grid item sx={{flexGrow: 1}}>
                            Are you sure you want to change your plan to the {plans[newPlanIndex].name} plan?
                        </Grid>
                        <Grid item>
                            <Button color="primary" variant="contained" size="small" sx={{mr:1}} disabled={isWorking}
                                    onClick={handleProceed}>
                                Proceed
                            </Button>
                            <Button color="primary" variant="outlined" size="small" disabled={isWorking}
                                    onClick={handleCancel}>
                                Cancel
                            </Button>
                        </Grid>
                    </FormRow>}
                    {!hasCard && <FormRow>
                        <Grid item sx={{flexGrow: 1}}>
                            <Alert severity="warning">
                                <AlertTitle>No Card on File</AlertTitle>
                                You must add a credit card before you can change your plan.
                            </Alert>
                        </Grid>
                    </FormRow>}
                    {newPlanIndex === -1 && isChanged && <FormRow>
                        <Grid item sx={{flexGrow: 1}}>
                            <Alert severity="success">
                                <AlertTitle>Success</AlertTitle>
                                Your plan has been successfully changed.
                            </Alert>
                        </Grid>
                    </FormRow>}
                </>}
        </TabPanel>
    )
};

export default PlanPanel;
