import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import TableContainer from "@mui/material/TableContainer";
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ErrorAlert from '../../util/ErrorAlert';
import {TabPanel} from "../../util/Tab";
import Spinner from "../../util/Spinner";
import {HeadCell, TableToolbar} from "../../util/Table";
import {Field} from  "../../model/fields";
import {loadInvoices} from '../state';


const formatDate = seconds => new Date(seconds*1000).toLocaleDateString("en-US", {
    month: "short", day: "2-digit", year: "numeric"
});

const formatMoney = cents => Field.CURRENCY_FORMATTER.format(cents / 100.00);

const StatusChip = ({status}) => {
    const color = (status === 'paid') ? 'primary' :
                  (status === 'open') ? 'secondary' : 'primary';
    return (
      status ? <Chip label={status.toUpperCase()} color={color} size="small" sx={{borderRadius: "4px"}}/> : null
    )
}
const InvoicePanel = (props) => {
    const dispatch = useDispatch();
    const invoices = useSelector(state => state.pref.billing.invoices);
    const [error, setError] = useState(null);

    useEffect(() => {
        dispatch && dispatch(loadInvoices())
            .unwrap()
            .catch(setError)
    }, [dispatch]);

    return (
        <TabPanel {...props}>
            {!error && !invoices && <Spinner message="Loading Invoice Information"/>}
            {error && <ErrorAlert error={error}/> }
            {invoices && <>
                {invoices.next && <Paper sx={{mb:2}}>
                    <TableToolbar title="Next Invoice"/>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <HeadCell width="20%">Date</HeadCell>
                                    <HeadCell width="20%">Total</HeadCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        {formatDate(invoices.next.created)}
                                    </TableCell>
                                    <TableCell>
                                        {formatMoney(invoices.next.total)}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>}

                <Paper>
                    <TableToolbar title="Past Invoices"/>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <HeadCell width="20%">Date</HeadCell>
                                    <HeadCell width="20%">Total</HeadCell>
                                    <HeadCell width="20%">Reference #</HeadCell>
                                    <HeadCell width="20%"/>
                                    <HeadCell width="20%"/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {invoices.past.map(i =>
                                    <TableRow key={i.id}>
                                        <TableCell component="th" scope="row">
                                            {formatDate(i.created)}
                                            <Box>
                                                <StatusChip status={i.status}/>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            {formatMoney(i.total)}
                                        </TableCell>
                                        <TableCell>
                                            {i.id}
                                        </TableCell>
                                        <TableCell>
                                            <Button href={i.invoice_pdf} color="primary" startIcon={<PictureAsPdfIcon/>} size="small">
                                                Invoice
                                            </Button>
                                        </TableCell>
                                        <TableCell>
                                            {i.receipt_url &&
                                            <Button href={i.receipt_url} color="primary" startIcon={<ReceiptIcon/>} size="small">
                                                Receipt
                                            </Button>}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </>}
        </TabPanel>
    )
};

export default InvoicePanel;
