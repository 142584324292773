import React from "react";
import {sortBy} from "lodash";
import {styled} from '@mui/material';
import TextField from "@mui/material/TextField";
import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete';
import {Fields, FieldLabel} from "../model/fields";

const Chooser = styled(Autocomplete)({
  input: {
    width: "min-content !important"
  }
})

const FieldSelect = ({value, onChange, label, inputSize, ...props}) => {

  return (
      <Chooser
          value={value} onChange={(evt, value) => onChange(value)}
          options={sortBy(Fields.list(), f => f.label(FieldLabel.DEFAULT))}
          isOptionEqualToValue={(opt, val) => {
            if (!val) return false;
            return opt.path === val.path;
          }}
          getOptionLabel={option => option && option.label(FieldLabel.DEFAULT)}
          renderOption={(props, option, state) => {
            return (
              <li {...props} key={option.path}>
                {option.label(FieldLabel.DEFAULT)}
              </li>
            )
          }}
          renderInput={params => <TextField {...params} label={label} variant="outlined" size={inputSize} placeholder="Choose a Field" fullWidth/>}
          filterOptions={createFilterOptions({
              matchFrom: "start",
              stringify: option => option.label(FieldLabel.DEFAULT)
          })}
          {...props}
      />
  );
};

export default FieldSelect;
