import React from "react";
import {useSelector} from "react-redux";
import {chunk, maxBy, range} from "lodash";
import {Box} from "@mui/material";
import {THUMBNAIL_END, THUMBNAIL_START} from "../../model/reportTypes";
import {applyTextStyle, borderAsObject, fontAsObject, spacingAsObject} from "../config";
import PhotoPicker from "./PhotoPicker";
import Table from "./Table";
import FieldValue from "./FieldValue";
import FieldHeader from "./FieldHeader";
import {LIGHT_GRAY} from '../../model/colors';


export const SingleTableContent = ({padding, comps, config, options}) => {
    const columns = config.columns;
    const numCols = columns.length;
    const depth = numCols > 0 ? maxBy(columns, (it) => it.fields.length).fields.length : 0;

    const tableStyle = {
        ...borderAsObject(config.style.border)
    };
    const headStyle = {
        ...fontAsObject(config.style.header.font),
        backgroundColor: config.style.header.background
    };
    const bodyStyle = {
        verticalAlign: "top",
        ...fontAsObject(config.style.body.font),
        backgroundColor: config.style.body.background,
    };
    const bodyStyleOdd = config.style.body.alt?.enabled === true?  {
        ...bodyStyle,
        backgroundColor: config.style.body.alt.background || LIGHT_GRAY,
    } : bodyStyle;

    const indexStyle = {
        verticalAlign: "top",
        ...fontAsObject(config.style.index.font),
        backgroundColor: config.style.index.background,
    };
    const indexStyleOdd = config.style.index.alt?.enabled === true?  {
        ...indexStyle,
        backgroundColor: config.style.index.alt.background || LIGHT_GRAY,
    } : indexStyle;

    const cellStyle = {
        verticalAlign: "top",
        lineHeight: "1.25em",
        ...headStyle,
        // ...spacingAsObject(config.style.padding, 1, 1)
    };

    const imgStyle = {
        width: "100%",
        minWidth: 128,
        height: 128,
        backgroundSize: "cover",
        backgroundPosition: "center",
    }

    const chunks = chunk(Array(comps.length).fill(0).map((it, i) => i),
      config.split === true ? config.rowsPerPage : comps.length);

    return (
      <>
          {chunks.map((chunk, c) => <Box key={c} mb={1}>
              <Box p={padding} data-section="single">
                  <Table style={tableStyle}>
                      <thead>
                      {range(depth).map((i) => {
                          const headCellStyle = {
                              ...cellStyle, ...spacingAsObject(config.style.padding, i===0?1:2, 1, i===(depth-1)?1:2)
                          };
                          return (
                            <tr key={i}>
                                {config.thumbnail === THUMBNAIL_START && <td style={headCellStyle}>{i===0?"Photo":""}</td>}
                                <td style={headCellStyle}>{i === 0 ? config.options?.noun || "No." : ""}</td>
                                {range(numCols).map((j) => {
                                    const col = columns[j];
                                    const f = i < col.fields.length ? col.fields[i] : null;
                                    const fieldCellStyle = f ? applyTextStyle(headCellStyle, (col.styles||{})[f.path], "label") : headCellStyle;
                                    return (
                                      <td key={j} style={fieldCellStyle}>
                                          {i < col.fields.length && <FieldHeader column={col} field={f} options={options}/>}
                                      </td>
                                    )
                                })}
                                {config.thumbnail === THUMBNAIL_END && <td style={headCellStyle}>{i===0?"Photo":""}</td>}
                            </tr>
                          )
                      })}
                      </thead>
                      <tbody>
                      {chunk.map(i => [comps[i], i]).map((compAndIndex, i) => {
                          const comp = compAndIndex[0];
                          const photoPicker = new PhotoPicker(comp.photos);

                          return range(depth).map((x) => {
                              let bodyCellStyle = {
                                  ...(i % 2 === 0 ? bodyStyle : bodyStyleOdd),
                                  ...spacingAsObject(config.style.padding, x===0?1:2, 1, x===(depth-1)?1:2)
                              };
                              let indexCellStyle = {
                                  ...(i % 2 === 0 ? indexStyle : indexStyleOdd),
                                  ...spacingAsObject(config.style.padding, x===0?1:2, 1, x===(depth-1)?1:2)
                              }
                              let borderBottomStyle = borderAsObject(config.style.border, "bottom");
                              return (
                                <tr key={x}>
                                    {config.thumbnail === THUMBNAIL_START && x === 0 && <td style={{
                                        ...bodyCellStyle,
                                        ...(i < chunk.length-1 && borderBottomStyle)
                                    }} rowSpan={depth}>
                                        <div style={{...imgStyle, backgroundImage: `url(${photoPicker.pick()})`}} />
                                    </td>}
                                    {x === 0 && <td style={{
                                        ...indexCellStyle,
                                        ...(i < chunk.length-1 && borderBottomStyle)
                                    }} rowSpan={depth}>{compAndIndex[1]+(options.offset||1)}</td>}
                                    {range(numCols).map((y) => {
                                        const f = columns[y].fields[x];
                                        const styles = columns[y].styles || {};
                                        // let isBorder = x === depth-1 && (i < chunk.length-1);

                                        let thisCellStyle = f && f.path in styles ? {
                                            ...bodyCellStyle,
                                            ...applyTextStyle({}, styles[f.path], "value"),
                                        } : bodyCellStyle;

                                        const isBorder = (x === (columns[y].fields.length-1)) && (i < chunk.length-1);
                                        if (isBorder) {
                                            thisCellStyle = {
                                                ...thisCellStyle,
                                                ...borderBottomStyle
                                            }
                                        }
                                        const rowSpanOpts = x === (columns[y].fields.length-1) ? {rowSpan: depth-x} : {}
                                        return x < columns[y].fields.length ? <td key={y} style={thisCellStyle} {...rowSpanOpts}>
                                            <FieldValue field={f} comp={comp} options={options}/>
                                        </td> : null;
                                        // return (
                                        //     <td key={y} style={thisCellStyle} >
                                        //         {x < columns[y].fields.length && <FieldValue field={f} comp={comp} options={options}/>}
                                        //     </td>
                                        // );
                                    })}
                                    {config.thumbnail === THUMBNAIL_END && x === 0 && <td style={{
                                        ...bodyCellStyle,
                                        ...(i < chunk.length-1 && borderBottomStyle)
                                    }} rowSpan={depth}>
                                        <div style={{...imgStyle, backgroundImage: `url(${photoPicker.pick()})`}} />
                                    </td>}
                                </tr>
                              )
                          })
                      })}
                      </tbody>
                  </Table>
              </Box>
          </Box>)}
      </>
    )
}

const SingleTable = ({padding}) => {
    const config = useSelector(state => state.report.curr.single);
    const options = useSelector(state => state.report.curr.options || {});
    const comps = useSelector(state => state.cart.comps);

    return (<SingleTableContent comps={comps} config={config} options={options} padding={padding}/>)
};

export default SingleTable;
