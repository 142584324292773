import React from "react";


const Table = ({children, style, ...props}) => {
    const s = {
        width: "100%",
        borderSpacing: 0,
        borderStyle: "solid",
        textAlign: "left",
        backgroundColor: "white",
        ...style
    };

    return (
      <table style={s} {...props}>
          {children}
      </table>
    );
};

export default Table;
