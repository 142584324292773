import React, {Fragment} from "react";
import Grid from "@mui/material/Grid";
import {FormRow} from "../../util/Form";
import CompEditorPanel from './CompEditorPanel';
import CompField from "./CompField";
import AreaField from "./AreaField";
import ShowAllFieldsToggle from "./ShowAllFieldsToggle";
import {ICI, LEASE, MULTI_FAMILY, RESIDENTIAL_RENT, VACANT_LAND} from "../../model/propertyTypes";
import {
    AreaUnit,
    Field,
    PhysicalDevelopmentType,
    PhysicalPropertySubType,
    PhysicalPropertyType
} from "../../model/fields";


const PropertyTypeField = ({comp, ...props}) => {
    const isMultiFamily = Field.PHYSICAL_PROPERTY_TYPE.get(comp) === "Multi-Family";
    return (
        <CompField field={Field.PHYSICAL_PROPERTY_TYPE} comp={comp} {...props}
                   helperText={isMultiFamily?"'Multi-Family' has been discontinued. We recommend you use 'Residential Development Lands' instead.":null}
                   helperTextProps={{error:true}}
        />
    )
}

const PhysicalPanel = ({comp, highlight, ...props}) => {
    const fieldProps = {comp, highlight};
    const [showLandDetails, setShowLandDetails] = React.useState(comp.type !== LEASE);
    const toggleShowLandDetails = (evt) => {
        setShowLandDetails(evt.target.checked);
    };
    const [showAll, setShowAll] = React.useState(false);
    const toggleShowAll = (evt) => {
        setShowAll(evt.target.checked);
    };

    const showDepth = comp.type !== RESIDENTIAL_RENT;
    const hasNoLandArea = AreaUnit.get(Field.PHYSICAL_LAND_AREA_UNITS.get(comp)) === AreaUnit.None;
    const isDevelopmentLands = PhysicalPropertyType.isResidentialDevLands(comp);
    const isResidential = PhysicalPropertyType.isResidential(comp);
    const isSingleFamily = PhysicalDevelopmentType.isSingleFamily(comp);
    const isNotSingleFamily = !isSingleFamily && !!Field.PHYSICAL_DEVELOPMENT_TYPE.get(comp);
    const isFarmLand = PhysicalPropertyType.isAgriculturalFarmland(comp);

    return (
        <CompEditorPanel name="PhysicalPanel" comp={comp} {...props}
                         extra={<ShowAllFieldsToggle value={showAll} onChange={toggleShowAll}/>}>
                {comp.type !== RESIDENTIAL_RENT ?
                    <Fragment>
                        <FormRow>
                            <Grid item md={6}>
                                <PropertyTypeField {...fieldProps}/>
                            </Grid>
                            <Grid item md={6}>
                                {[ICI,LEASE].includes(comp.type) &&  <CompField field={Field.PHYSICAL_PROPERTY_SUBTYPE} {...fieldProps}/>}
                                {isDevelopmentLands && <CompField field={Field.PHYSICAL_DEVELOPMENT_TYPE} {...fieldProps}/>}
                                {MULTI_FAMILY.includes(comp.type) && isResidential && <CompField field={Field.PHYSICAL_PROPERTY_SUBTYPE} {...fieldProps}/>}
                            </Grid>
                        </FormRow>
                        <FormRow>
                            <Grid item md={6}>
                                <CompField field={Field.PHYSICAL_EXISTING_USE} {...fieldProps}/>
                            </Grid>
                            <Grid item md={6}>
                                <CompField field={Field.PHYSICAL_PROPOSED_USE} {...fieldProps}/>
                            </Grid>
                        </FormRow>
                        {comp.type === LEASE &&
                            <ShowAllFieldsToggle value={showLandDetails} onChange={toggleShowLandDetails}
                                                 label="Show Land Detail Fields"/>
                        }
                        {showLandDetails &&
                        <Fragment>
                            <FormRow>
                                <Grid item md={6}>
                                    <CompField field={Field.PHYSICAL_LAND_AREA_UNITS} {...fieldProps}/>
                                </Grid>
                            </FormRow>
                            <FormRow>
                                <Grid item md={6}>
                                    <AreaField field={Field.PHYSICAL_TOTAL_LAND_AREA} {...fieldProps} disabled={hasNoLandArea}/>
                                </Grid>
                                <Grid item md={6}>
                                    <AreaField field={Field.PHYSICAL_EFFECTIVE_LAND_AREA} {...fieldProps} disabled={hasNoLandArea}/>
                                </Grid>
                            </FormRow>
                            {PhysicalPropertyType.isAgriculturalFarmland(comp) && <FormRow>
                                <Grid item md={6}>
                                    <CompField field={Field.PHYSICAL_ARABLE_AREA_PERCENT} {...fieldProps} />
                                </Grid>
                                <Grid item md={6}>
                                    <CompField field={Field.PHYSICAL_ARABLE_AREA} {...fieldProps} />
                                </Grid>
                            </FormRow>}
                            {PhysicalPropertySubType.isMarineFacilities(comp) &&
                            <FormRow>
                                <Grid item md={6}>
                                    <AreaField field={Field.PHYSICAL_WATER_LOT_LEASE_AREA} {...fieldProps} />
                                </Grid>
                                <Grid item md={6}>
                                    <AreaField field={Field.PHYSICAL_FORESHORE_LEASE_AREA} {...fieldProps} />
                                </Grid>
                            </FormRow>}
                            {showAll && comp.type === VACANT_LAND &&
                            <>
                              <FormRow>
                                {!isFarmLand &&
                                <Grid item md={6}>
                                    <AreaField field={Field.PHYSICAL_TOTAL_BUILDABLE_AREA} {...fieldProps} showConversion={false}/>
                                </Grid>}
                                {isFarmLand && <>
                                    <Grid item md={6}>
                                        <AreaField field={Field.PHYSICAL_BUILDING_SITE_AREA} {...fieldProps} showConversion={false}/>
                                    </Grid>
                                    <Grid item md={6}>
                                        <AreaField field={Field.PHYSICAL_OTHER_SITE_AREA} {...fieldProps} showConversion={false}/>
                                    </Grid>
                                </>}
                              </FormRow>
                              <FormRow>
                                <Grid item md={6}>
                                    <CompField field={Field.PHYSICAL_SITE_DIMENSIONS} {...fieldProps} />
                                </Grid>
                              </FormRow>
                            </>
                            }
                            {isDevelopmentLands && (isSingleFamily || isNotSingleFamily) &&
                            <FormRow>
                                <Grid item md={6}>
                                    {isSingleFamily && <CompField field={Field.PHYSICAL_NUM_DEVELOPABLE_LOTS} {...fieldProps}/>}
                                    {isNotSingleFamily && <CompField field={Field.PHYSICAL_NUM_DEVELOPABLE_UNITS} {...fieldProps}/>}
                                </Grid>
                            </FormRow>
                            }
                            <FormRow>
                                <Grid item md={6}>
                                    <CompField field={Field.PHYSICAL_CORNER_LOT} {...fieldProps}/>
                                </Grid>
                            </FormRow>
                            <FormRow>
                                <Grid item md={6}>
                                    <CompField field={Field.PHYSICAL_FRONTAGE} {...fieldProps}/>
                                </Grid>
                                <Grid item md={6}>
                                    <CompField field={Field.PHYSICAL_FRONTAGE_STREET} {...fieldProps}/>
                                </Grid>
                            </FormRow>
                            {PhysicalPropertySubType.isMarineFacilities(comp) &&
                            <FormRow>
                                <Grid item md={6}>
                                    <CompField field={Field.PHYSICAL_MOORAGE} {...fieldProps}/>
                                </Grid>
                            </FormRow>}
                            {showDepth &&
                            <FormRow>
                                <Grid item md={6}>
                                    <CompField field={Field.PHYSICAL_DEPTH} {...fieldProps}/>
                                </Grid>
                            </FormRow>
                            }
                            <FormRow>
                                <Grid item md={6}>
                                    <CompField field={Field.PHYSICAL_TOPOGRAPHY} {...fieldProps}/>
                                </Grid>
                                <Grid item md={6}>
                                    <CompField field={Field.PHYSICAL_SHAPE} {...fieldProps}/>
                                </Grid>
                            </FormRow>
                            {comp.type !== RESIDENTIAL_RENT && <FormRow>
                                <Grid item md={6}>
                                    <CompField field={Field.PHYSICAL_ACCESS} {...fieldProps}/>
                                </Grid>
                                <Grid item md={6}>
                                    <CompField field={Field.PHYSICAL_EXPOSURE} {...fieldProps}/>
                                </Grid>
                            </FormRow>}
                            {comp.type === VACANT_LAND && <FormRow>
                                <Grid item md={6}>
                                    <CompField field={Field.PHYSICAL_WATERFRONT} {...fieldProps}/>
                                </Grid>
                                {showAll && <Grid item md={6}>
                                    <CompField field={Field.IMPROVEMENT_FLOOR_SPACE_INDEX} {...fieldProps}/>
                                </Grid>}
                            </FormRow>}
                            {comp.type === VACANT_LAND && isFarmLand && <>
                                <FormRow>
                                    <Grid item md={6}>
                                        <CompField field={Field.PHYSICAL_SOIL_TYPE} {...fieldProps}/>
                                    </Grid>
                                    <Grid item md={6}>
                                        <CompField field={Field.PHYSICAL_CLI_CLASS} {...fieldProps}/>
                                    </Grid>
                                </FormRow>
                                <FormRow>
                                    <Grid item md={6}>
                                        <CompField field={Field.PHYSICAL_DRAINAGE} {...fieldProps}/>
                                    </Grid>
                                </FormRow>
                            </>}
                            <FormRow>
                                <Grid item md={3}>
                                    <CompField field={Field.PHYSICAL_ZONING_CODE} {...fieldProps}/>
                                </Grid>
                                <Grid item md={9}>
                                    <CompField field={Field.PHYSICAL_ZONING_DESC} {...fieldProps}/>
                                </Grid>
                            </FormRow>
                            <FormRow>
                                <Grid item md={12}>
                                    <CompField field={Field.PHYSICAL_OCP_DESIGNATION} {...fieldProps}/>
                                </Grid>
                            </FormRow>
                            {showAll &&
                            <>
                                <FormRow>
                                    <Grid item md={3}>
                                        <CompField field={Field.PHYSICAL_IN_FLOOD_PLAIN} {...fieldProps}/>
                                    </Grid>
                                    <Grid item md={3}>
                                        <CompField field={Field.PHYSICAL_IN_ALR} {...fieldProps}/>
                                    </Grid>
                                </FormRow>
                            </>
                            }
                            {comp.type === VACANT_LAND && showAll && <>
                                <FormRow>
                                    <Grid item md={6}>
                                        <CompField field={Field.PHYSICAL_GREENBELT_CONSERVATION_AUTHORITY} {...fieldProps}/>
                                    </Grid>
                                    <Grid item md={6}>
                                        <CompField field={Field.PHYSICAL_INTERIM_USE} {...fieldProps}/>
                                    </Grid>
                                </FormRow>
                                <FormRow>
                                    <Grid item md={6}>
                                        <CompField field={Field.PHYSICAL_NUM_PROPOSED_APPROVED_UNITS} {...fieldProps}/>
                                    </Grid>
                                    <Grid item md={6}>
                                        <CompField field={Field.PHYSICAL_APPLICATION_NUM} {...fieldProps}/>
                                    </Grid>
                                </FormRow>
                                <FormRow>
                                    <Grid item md={6}>
                                        <CompField field={Field.PHYSICAL_PLANNING_STATUS} {...fieldProps}/>
                                    </Grid>
                                    <Grid item md={6}>
                                        <CompField field={Field.PHYSICAL_DEVELOPMENT_TIMEFRAME} {...fieldProps}/>
                                    </Grid>
                                </FormRow>
                                <FormRow>
                                    <Grid item md={6}>
                                        <CompField field={Field.PHYSICAL_OPEN_SPACE_ESA} {...fieldProps}/>
                                    </Grid>
                                    <Grid item md={6}>
                                        <CompField field={Field.PHYSICAL_DRAFT_PLAN_STATUS} {...fieldProps}/>
                                    </Grid>
                                </FormRow>
                            </>}
                        </Fragment>
                        }
                    </Fragment>
                    :
                    <Fragment>
                        <FormRow>
                            <Grid item md={6}>
                                <CompField field={Field.PHYSICAL_PROPERTY_TYPE} {...fieldProps}/>
                            </Grid>
                        </FormRow>
                        <FormRow>
                            <Grid item md={6}>
                                <CompField field={Field.PHYSICAL_LAND_AREA_UNITS} {...fieldProps}/>
                            </Grid>
                        </FormRow>
                        <FormRow>
                            <Grid item md={6}>
                                <AreaField field={Field.PHYSICAL_TOTAL_LAND_AREA} {...fieldProps} disabled={hasNoLandArea}/>
                            </Grid>
                        </FormRow>
                        <FormRow>
                            <Grid item md={6}>
                                <CompField field={Field.PHYSICAL_ZONING_CODE} {...fieldProps}/>
                            </Grid>
                        </FormRow>
                    </Fragment>
                }
        </CompEditorPanel>
    )
};

export default PhysicalPanel;
