import React from "react";
import Grid from "@mui/material/Grid";
import CompCard from "../comp/CompCard";


const CompGrid = ({comps, width=275, CompComponent=CompCard}) => {
    return (
        <Grid container spacing={1}>
            {comps.map((comp, i) =>
                <Grid item key={i} sx={{width}}>
                    <CompComponent comp={comp}/>
                </Grid>
            )}
        </Grid>
    );
};

export default CompGrid;
