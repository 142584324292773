import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getFunctions, httpsCallable} from 'firebase/functions';

export const submitTicket = createAsyncThunk(
  'support/submit',
  async (ticket, {getState, rejectWithValue}) => {
     const {app, auth} = getState();
     try {
       const t = {
         org: {
           id: app.org.id,
           name: app.org.name,
         },
         user: {
           name: auth.user.displayName,
           email: auth.user.email
         },
         browser: window.navigator.userAgent,
         info: ticket
       };

       const result = await httpsCallable(getFunctions(), 'createTicket')(t);
       if (result.data.status === 'success') {
          return result.data;
       }
       else return rejectWithValue(result.data);
     }
     catch(err) {
       return rejectWithValue(err);
     }
  }
)
const support = createSlice({
  name: 'support',
  initialState: {
  },
  reducers: {
  }
});

export default support.reducer;
