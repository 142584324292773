import React, {Fragment, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link as RouterLink, useLocation, useHistory} from "react-router-dom";
import Divider from "@mui/material/Divider";
import Badge from "@mui/material/Badge";
import Menu from "@mui/material/Menu";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ClearIcon from '@mui/icons-material/Clear';
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import GridOnIcon from "@mui/icons-material/GridOn";
import BlockIcon from '@mui/icons-material/Block';
import DeleteIcon from "@mui/icons-material/Delete";
import TocIcon from "@mui/icons-material/Toc";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ListDialog from '../list/ListDialog';
import CartDialog from "./CartDialog";
// import ListDialog from "../list/ListDialog";
import {clearCart, initCart, loadCart, removeFromCart} from './state';


const CartLink = ({children, openInNewTab, ...props}) => {
    const openProps = openInNewTab === true ? {target: "_blank", rel: "noopener"} : {};
    return (
        <Link component={RouterLink} color="inherit" underline="none"  {...openProps} {...props}>{children}</Link>

    )
};

const Cart = () => {
    const location = useLocation();
    const history = useHistory();
    const [showCartDialog, setShowCartDialog] = useState(false);
    const [showListDialog, setShowListDialog] = useState(false);
    const comps = useSelector(state => state.cart.comps || []);
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(initCart());
        dispatch(loadCart(localStorage))
          .unwrap()
          .catch(console.error);
    }, [dispatch]);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = evt => {
        setAnchorEl(null);
        if (evt) evt.stopPropagation();
    };

    const handleClear = () => {
        dispatch(clearCart());
        if (location.pathname === "/report") {
            history.replace("/");
        }
    };

    const handleRemove = comp => () => {
        dispatch(removeFromCart(comp));
    };

    const handleCreateList = () => {
        setShowListDialog(true);
        handleClose();
    };

    const open = Boolean(anchorEl);
    const id = open ? 'popover' : undefined;

    return (
        <Fragment>
            <IconButton onClick={handleClick} className="CartMenu" size="large">
                <Badge badgeContent={comps.length} color="secondary">
                    <ShoppingCartIcon sx={{color: 'primary.contrastText'}}/>
                </Badge>
            </IconButton>
            <Menu
                id={id}
                className="Cart"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {comps.filter(comp => !!comp.title).map((comp, i) =>
                    <MenuItem key={i}>
                        <ListItemIcon onClick={handleRemove(comp)}>
                            <ClearIcon />
                        </ListItemIcon>
                        <CartLink to={"/comp/" + comp.id} openInNewTab={true} onClick={handleClose}>
                            <span>{comp.title}</span>
                        </CartLink>
                        <ListItemText sx={{
                            ml: 3,
                            textAlign: "right",
                            color: 'grey.600'
                        }}>{i+1}</ListItemText>
                    </MenuItem>
                )}
                {comps.length > 0 &&
                    <Divider />
                }

                {comps.length > 0 &&
                    <CartLink to={"/report"} onClick={handleClose} className="ExportLink">
                        <MenuItem>
                            <ListItemIcon>
                                <ExitToAppIcon />
                            </ListItemIcon>
                            <Typography variant="inherit">Create Report</Typography>
                        </MenuItem>
                    </CartLink>}

                {comps.length > 0 &&
                    <CartLink to={"/sheet"} onClick={handleClose} className="SheetLink">
                        <MenuItem>
                            <ListItemIcon>
                                <GridOnIcon />
                            </ListItemIcon>
                            <Typography variant="inherit">Create Spreadsheet</Typography>
                        </MenuItem>
                    </CartLink>}

                {comps.length > 0 &&
                    <MenuItem onClick={handleCreateList} className="ListLink">
                        <ListItemIcon>
                            <ListAltIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">Create/Modify List</Typography>
                    </MenuItem>}

                {comps.length > 0 && <Divider />}

                {comps.length > 0 &&
                    <MenuItem onClick={handleClear}>
                        <ListItemIcon >
                            <DeleteIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">Clear Cart</Typography>
                    </MenuItem>
                }
                {comps.length > 0 &&
                    <MenuItem onClick={() => setShowCartDialog(true)}>
                        <ListItemIcon >
                            <TocIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">Manage Items</Typography>
                    </MenuItem>
                }

                {comps.length === 0 ?
                    <MenuItem onClick={handleClear}>
                        <ListItemIcon>
                            <BlockIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">Cart Empty</Typography>
                    </MenuItem>
                    : null}
            </Menu>
            <CartDialog isOpen={showCartDialog} onClose={() => setShowCartDialog(false)}/>
            <ListDialog isOpen={showListDialog} onClose={() => setShowListDialog(false)}/>
        </Fragment>
    );
};

export default Cart;
