import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';
import FilterPanel from './FilterPanel';
import LayoutPanel from './LayoutPanel';
import ResultLabel from './ResultLabel';

import {runSearch, setText} from './state';
import IconButton from '@mui/material/IconButton';

const QueryPanel = () => {
  const q = useSelector(state => state.search.query);
  const isSearching = useSelector(state => state.search.searching);
  const dispatch = useDispatch();

  const handleSearch = () => {
    dispatch(runSearch())
  }

  return (
    <Box p={1}>
      <Stack direction="row" gap={1} alignItems="flex-start">
          <TextField value={q.text} onChange={evt => dispatch(setText(evt.target.value))}
            onKeyDown={e => e.key === 'Enter' && handleSearch()}
            sx={{maxWidth: "384px"}}
            variant="outlined" placeholder="Search by address, comp id, or other text" fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">
                  <IconButton onClick={handleSearch}>
                      <SearchIcon/>
                  </IconButton>
              </InputAdornment>,
          }}/>
          <FilterPanel sx={{flexGrow:1}}/>
          <Stack>
            <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center" >
              <Button
                component="span"
                variant="contained"
                color="primary"
                size="large"
                className="SearchButton"
                startIcon={ isSearching ? <CircularProgress size={24} sx={{color: 'primary.contrastText'}}/> : <SearchIcon/> }
                onClick={handleSearch} disabled={isSearching}
              >
                Search
              </Button>
              <LayoutPanel/>
            </Stack>
            <ResultLabel/>
          </Stack>
      </Stack>
    </Box>
  )
};

export default QueryPanel;
