import React from "react";
import Section from "./Section";
import {spacingAsObject} from "../config";

const Row = ({row, index, rows, style, ...props}) => {
    const rowStyle = {
      height: '100%'
    };
    const cellStyle = {
        verticalAlign: "top",
        height: 'inherit'
    };
    const cellSpacingStyle = i =>
        spacingAsObject(style.margin, (index===0)?1:2, (i<row.length-1)?2:1, (index<rows.length-1)?2:1, (i===0)?1:2);

    return (
      <tr key={index} style={rowStyle}>
      {row.map((section,i) =>
          <td key={i} colSpan={2/row.length} width={`${100/row.length}%`} style={{...cellStyle, ...cellSpacingStyle(i)}}>
              <Section {...props} section={section} style={style}/>
          </td>
      )}
      </tr>
  );
};

export default Row;
