import {max} from 'lodash';
export const ROLE_OWNER = "owner";
export const ROLE_ADMIN = "administrator";
export const ROLE_EDITOR = "editor";
export const ROLE_CREATOR = "creator";
export const ROLE_MASTER = "master";

export const roleTitle = role => {
    switch(role) {
        case ROLE_MASTER:
            return "Master";
        case ROLE_OWNER:
            return "Owner";
        case ROLE_ADMIN:
            return "Administrator";
        case ROLE_EDITOR:
            return "Editor";
        case ROLE_CREATOR:
            return "Creator";
        default:
            return null;
    }
};

export const getRole = (auth) => {
    if (auth && auth.roles) {
        return numToRole(max(auth.roles.map(roleToNum)))
    }
    return ROLE_CREATOR;
}

export const getHigherRoles = (role) => {
    return [ROLE_CREATOR, ROLE_EDITOR, ROLE_ADMIN, ROLE_OWNER]
        .map(roleToNum)
        .filter(it => it > roleToNum(role))
        .map(numToRole);
}

export const getLowerRoles = (role) => {
    return [ROLE_CREATOR, ROLE_EDITOR, ROLE_ADMIN, ROLE_OWNER]
      .map(roleToNum)
      .filter(it => it < roleToNum(role))
      .map(numToRole);
}

export const hasRole = (auth, role) => {
    if (!auth || !auth.roles) return false;
    const roleNum = max(auth.roles.map(roleToNum));
    return roleNum >= roleToNum(role);
}

export const roleToNum = role => {
    switch(role) {
        case ROLE_MASTER:
            return 100;
        case ROLE_OWNER:
            return 10;
        case ROLE_ADMIN:
            return 5;
        case ROLE_EDITOR:
            return 4;
        case ROLE_CREATOR:
            return 3;
        default:
            return -1;
    }
}

export const numToRole = num => {
    switch(num) {
        case 100:
            return ROLE_MASTER;
        case 10:
            return ROLE_OWNER;
        case 5:
            return ROLE_ADMIN;
        case 4:
            return ROLE_EDITOR;
        case 3:
            return ROLE_CREATOR;
        default:
            return -1;
    }
}
