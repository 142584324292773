import {Checkbox, FormControlLabel, Grid} from '@mui/material';
import {FormRow, FormSection} from '../../util/Form';
import SheetEditorPanel from './SheetEditorPanel';
import {setValue} from '../state';
import {useDispatch} from 'react-redux';

const SubjectPanel = ({sheet, ...props}) => {
  const dispatch = useDispatch();
  return (
      <SheetEditorPanel {...props} sheet={sheet} name="SubjectPanel">
        <FormSection label="Subject" help="Configure a subject row/column for the spreadsheet.">
          <FormRow spacing={1} gutterBottom={true}>
            <Grid item>
              <FormControlLabel
                  control={<Checkbox checked={sheet.subject?.enabled===true} onChange={() => dispatch(setValue({path: 'subject.enabled', value: !(sheet.subject?.enabled===true)}))}/>}
                  label="Include a Subject Row/Column"
              />
            </Grid>
          </FormRow>
        </FormSection>
      </SheetEditorPanel>

  )
};

export default SubjectPanel;
