import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { first } from 'lodash';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Stack,
    DialogActions,
    Button,
    IconButton,
    Tooltip,
    TextField, Box
} from '@mui/material';
import DialogContentText from '@mui/material/DialogContentText';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DoneIcon from '@mui/icons-material/Done';
import FieldSelect from '../util/FieldSelect';
import SelectWithLabel from '../util/SelectWithLabel';
import { Field, FieldType, Fields } from '../model/fields';
import DateRangeChooser from '../find/DateRangeChooser';
import NumberRangeChooser from '../find/NumberRangeChooser';
import {DATE_CUSTOM, NUMBER_BETWEEN, NUMBER_GREATER, NUMBER_LESSER} from '../find/constants';
import {Comp} from "../model/objects";


const TextFilter = ({filter, onChange}) => {
    const exact = filter.exact || false;
    return (
        <Stack direction="row" gap={2}>
            <TextField value={filter.values[0]||""} onChange={evt => onChange({values: [evt.target.value]})} />
            <FormControlLabel
                control={<Checkbox checked={exact} onChange={() => onChange({exact: !exact})} name="exact" />}
                label="Perform an Exact Match"
            />
        </Stack>
    )
}

const SelectFilter = ({filter, onChange}) => {
    const filters = useSelector(state => state.search.query.filters);
    const field = Fields.lookup(filter.field);

    const comp = filters.reduce((obj, f) => {
        if (f.field === Field.TYPE.path) Field.TYPE.set(obj, first(f.values||[]));
        if (f.field === Field.PHYSICAL_PROPERTY_TYPE) Field.TYPE.set(obj, first(f.values||[]));
        return obj;
    }, {});

    return (
        <SelectWithLabel value={filter.values} values={field.values(comp)} label="Values"
                         onChange={evt => onChange({values: evt.target.value})} size="large" className={`${field.path}-Filter`} multiple={true}/>
    )
}

const BooleanFilter = ({filter, onChange}) => {
    return (
        <SelectWithLabel value={filter.values[0]} values={[[true, "Yes"], [false, "No"]]}
                         onChange={evt => onChange({values: [evt.target.value]})} size="small" />
    )
}

const RelativeDateFilter = ({filter, onChange}) => {
    return <TextField value={filter.label} variant="outlined" disabled={true} />
}

const DateFilter = ({filter, onChange}) => {
    const handleChange = v => {
        onChange({
            values: [[Comp.date(v.range[0]).getTime(), Comp.date(v.range[1]).getTime()]]
        });
    }
    return (
        <DateRangeChooser value={{
            mode: DATE_CUSTOM,
            range: filter.values[0] || [new Date(Date.now()),new Date(Date.now())]
        }} onChange={handleChange} size="large" showMode={false}/>
    )
}

const NumericFilter = ({filter, onChange}) => {
    const field = Fields.lookup(filter.field);
    const hasValue = !!filter.values[0];
    const hasLower = hasValue && filter.values[0][0] != null;
    const hasUpper = hasValue && filter.values[0][1] != null;

    let value = null;
    if (!hasValue) {
        value = {type: NUMBER_BETWEEN, range: ['', '']}
    }
    else if (hasLower && hasUpper) {
        value = {type: NUMBER_BETWEEN, range: filter.values[0]}
    }
    else if (hasLower) {
        value = {type: NUMBER_GREATER, range: [filter.values[0][0], ''] }
    }
    else {
        value = {type: NUMBER_LESSER, range: [filter.values[0][1], ''] }
    }

    const handleChange = v => {
        const r = v.range;
        switch(v.type) {
            case NUMBER_BETWEEN:
                onChange({
                    values: [[r[0], r[1]]],
                })
                break;
            case NUMBER_GREATER:
                onChange({
                    values: [[r[0], null]]
                });
                break;
            case NUMBER_LESSER:
                onChange({
                    values: [[null, r[0]]]
                });
                break;
            default:
                break;
        }
        
    }
    
    return (
        <NumberRangeChooser onChange={handleChange} value={value} type={field.type} size="large"/>
    );
}


const Filter = ({filter, onChange, onRemove}) => {
    const field = filter.field ? Fields.lookup(filter.field) : null;

    const filterProps = {filter, onChange};
    return (
        <Stack direction="row" alignItems="center" gap={2} sx={{flexGrow:1}}>
            <FieldSelect value={field} onChange={f => onChange({field: f})} label="Field" disabled={filter.editable===false}/>
            <Box sx={{flexGrow: 1}}>
            {field && <>
                {field.hasValues ? <SelectFilter {...filterProps}/> : 
                 field.type === FieldType.DATE ? filter.date_range===true? <RelativeDateFilter {...filterProps}/> : <DateFilter {...filterProps}/> :
                 field.type === FieldType.BOOLEAN ? <BooleanFilter {...filterProps}/> : 
                 FieldType.isNumeric(field.type) ? <NumericFilter {...filterProps}/> : 
                 <TextFilter {...filterProps}/>}
            </>}
            </Box>
            <Tooltip title="Remove filter">
                <IconButton onClick={onRemove}>
                    <HighlightOffIcon/>
                </IconButton>
            </Tooltip>
        </Stack>
    )
}

const FilterDialog = ({open, onClose, ...props}) => {
    const queryFilters = useSelector(state => state.search.query.filters || []);
    const [dirty, setDirty] = useState(false);
    const [filters, setFilters] = useState([]);

    useEffect(() => {
        setFilters(queryFilters);
    }, [queryFilters]);

    // console.log(filters);
    const addFilter = () => {
        setDirty(true);
        setFilters([...filters, {
            field: null,
            values: []
        }]);
    }

    const handleChange = (i) => (change) => {
        setDirty(true);
        setFilters(filters.map((f,j) => i === j ? {...f, ...change} : f));
    }

    const handleRemove = (i) => (change) => {
        setDirty(true);
        setFilters(filters.filter((f,j) => i !== j));
    }

    return (
        <Dialog open={open} fullWidth={true} onClose={onClose} {...props}>
            <DialogTitle>Filter Search</DialogTitle>
            <DialogContent dividers>
                <DialogContentText>Add filters to refine your search for comps. </DialogContentText>
                <Stack py={2} gap={2} alignItems="flex-start" sx={{width:"100%"}}>
                    <Stack gap={2} sx={{width: "100%"}}>
                        {filters.map((f,i) =>
                            <Filter key={f.field || i} filter={f} onChange={handleChange(i)} onRemove={handleRemove(i)}/>)}
                    </Stack>
                    <Button variant="contained" onClick={addFilter}>
                        Add Filter
                    </Button>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()}>
                    Cancel
                </Button>
                <Button onClick={() => onClose(filters)} color="primary" variant="outlined" disabled={!dirty}
                        startIcon={<DoneIcon/>}>
                    Finish
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default FilterDialog;