import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Alert from '@mui/material/Alert';
import { blue, yellow } from '@mui/material/colors';
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "./MapboxPopup.css";
import {addToCart, removeFromCart} from '../cart/state';
import {Map, mapOptsForOrg} from "../util/Map";
import marker from "../util/marker.png";
import {Field} from "../model/fields";
import {CONFIRMED, CURRENT_LISTING, UNCONFIRMED} from "../model/colors";
import {pickResult, updateGeoFilter} from './state';

const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

const SearchMap = () => {
    const [map, setMap] = useState();
    const [place, setPlace] = useState();
    const [draw, setDraw] = useState();
    const dispatch = useDispatch();
    const org = useSelector(state => state.app.org);
    const results = useSelector(state => state.find.results || {found:0});
    const filters = useSelector(state => state.find.search.filters || []);
    const compsInCart = useSelector(state => state.cart.comps.map(c => c.id));
    const prevFilters = usePrevious(filters);

    useEffect(() => {
        window.map = map;
        return () => {
            window.map = undefined;
        }
    }, [map]);

    useEffect(() => {
        window.isCompInCart = (compId) => (compsInCart||[]).includes(compId);
        window.toggleCompInCart = (compId) => {
            const comp = results.comps.find(c => c.id === compId);
            if (comp) {
                const inCart = window.isCompInCart(compId);
                if (inCart) {
                    dispatch(removeFromCart(comp));
                }
                else {
                    dispatch(addToCart([comp]));
                }

                const data = window.map.getSource("results")._data;
                const feature = data.features.findIndex(f => f.properties.id === compId);
                if (feature > -1) {
                    window.map.setFeatureState({
                        id: feature,
                        source: 'results'
                    }, {in_cart: !inCart})
                }
            }
        }
        window.addCompsToCart = (compIds) => {
            compIds.forEach(compId => {
                if (!window.isCompInCart(compId)) window.toggleCompInCart(compId);
            });
            document.querySelectorAll(".CompInCartCheckbox").forEach(checkbox => checkbox.checked = true);
        };
        return () => {
            window.isCompInCart = undefined;
            window.toggleCompInCart = undefined;
            window.addCompsToCart = undefined;
        }
    }, [compsInCart, dispatch, results])

    useEffect(() => {
        if (map && results) {
            map.removeFeatureState({source: 'results'});

            if (results.features) {
                map.getSource("results").setData(results.features);
                results.features.features.forEach((f,i) => {
                    if (window.isCompInCart(f.properties.id)) {
                        map.setFeatureState({
                            id: i,
                            source: 'results'
                        }, {in_cart: true});
                    }
                })
            }
            else {
                map.getSource("results").setData({type: "FeatureCollection", features: []})
            }
            if (results.bounds) {
                map.fitBounds(results.bounds, {padding: 50, maxZoom: 18});
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [results]);

    useEffect(() => {
        if (map && place) {
            if (place.bbox) {
                map.fitBounds(place.bbox, {padding: 50, maxZoom: 18});
            }
            else if (place.center) {
                map.flyTo({center: place.center, zoom: 18})
            }
        }
    }, [map, place]);

    const hasGeo = f => f.field === Field.GEO
    const hasGeoFilter = filters.find(hasGeo);

    useEffect(() => {
        const prev = prevFilters || [];
        const geoFilter = filters.find(hasGeo);
        if (prev.find(hasGeo) && !geoFilter) {
            draw.deleteAll();
            map.removeControl(draw);
        }
        if (!prev.find(hasGeo) && geoFilter) {
            map.addControl(draw);
            if (geoFilter.value) {
                draw.add(geoFilter.value);
            }
            else draw.changeMode("draw_polygon");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    const initMapData = (map) => {
        map.loadImage(
            marker,
            function(error, image) {
                if (error) throw error;
                // map.addImage('custom-marker', image);
                map.addSource('results', {
                    type: 'geojson', data: {type: "FeatureCollection", features: []},
                    maxzoom: 22,
                    cluster: true,
                    clusterMaxZoom: 22,
                    generateId: true
                });
                map.addLayer({
                    id: 'clusters',
                    type: 'circle',
                    source: 'results',
                    filter: ['has', 'point_count'],
                    paint: {
                        'circle-color': [
                            'step',
                            ['get', 'point_count'],
                            '#51bbd6',
                            100,
                            '#f1f075',
                            750,
                            '#f28cb1'
                        ],
                        'circle-radius': [
                            'step',
                            ['get', 'point_count'],
                            20,
                            100,
                            30,
                            750,
                            40
                        ]
                    }
                });
                map.addLayer({
                    id: 'counts',
                    type: 'symbol',
                    source: 'results',
                    filter: ['has', 'point_count'],
                    layout: {
                        'text-field': '{point_count_abbreviated}',
                        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                        'text-size': 12
                    },
                    paint: {
                        'text-color': '#fff',
                    }
                });
                map.addLayer({
                    id: 'point-labels',
                    type: 'symbol',
                    source: 'results',
                    filter: ['!', ['has', 'point_count']],
                    paint: {
                        'text-color': '#202',
                        'text-halo-color': '#fff',
                        'text-halo-width': 2
                    },
                    layout: {
                        // "icon-image": ['get', 'icon'],
                        'text-field': [
                            "format",
                            ['get', 'name'], {"font-scale": 0.8}
                        ],
                        'text-variable-anchor': ['bottom', 'top', 'left', 'right'],
                        'text-radial-offset': 1,
                        'text-justify': 'left',
                    }
                });
                map.addLayer({
                    id: 'points',
                    type: 'circle',
                    source: 'results',
                    filter: ['!', ['has', 'point_count']],
                    paint: {
                        'circle-color': [
                            'case',
                                ['==', ['get', 'is_unconfirmed'], 'true'], UNCONFIRMED,
                                ['==', ['get', 'is_listing'], 'true'], CURRENT_LISTING,
                                CONFIRMED
                        ],
                        'circle-radius': 8,
                        'circle-stroke-width': 3,
                        'circle-stroke-color': [
                            'case', ['boolean', ['feature-state', 'in_cart'], false], yellow["500"], "#fff"
                        ]
                    }
                });

            }
        );
    };

    const initMap = (map) => {
        const draw = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
                polygon: false,
                trash: false
            },
            styles: [
                // ACTIVE (being drawn)
                // line stroke
                {
                    "id": "gl-draw-line",
                    "type": "line",
                    "filter": ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
                    "layout": {
                        "line-cap": "round",
                        "line-join": "round"
                    },
                    "paint": {
                        "line-color": blue[500],
                        "line-dasharray": [0.2, 2],
                        "line-width": 2
                    }
                },
                // polygon fill
                {
                    "id": "gl-draw-polygon-fill",
                    "type": "fill",
                    "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
                    "paint": {
                        "fill-color": blue[500],
                        "fill-outline-color": blue[500],
                        "fill-opacity": 0
                    }
                },
                // polygon outline stroke
                // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
                {
                    "id": "gl-draw-polygon-stroke-active",
                    "type": "line",
                    "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
                    "layout": {
                        "line-cap": "round",
                        "line-join": "round"
                    },
                    "paint": {
                        "line-color": blue[500],
                        // "line-dasharray": [0.2, 2],
                        "line-width": 2
                    }
                },
                // vertex point halos
                {
                    "id": "gl-draw-polygon-and-line-vertex-halo-active",
                    "type": "circle",
                    "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
                    "paint": {
                        "circle-radius": 5,
                        "circle-color": "#FFF"
                    }
                },
                // vertex points
                {
                    "id": "gl-draw-polygon-and-line-vertex-active",
                    "type": "circle",
                    "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
                    "paint": {
                        "circle-radius": 3,
                        "circle-color": blue[500],
                    }
                },

                // INACTIVE (static, already drawn)
                // line stroke
                {
                    "id": "gl-draw-line-static",
                    "type": "line",
                    "filter": ["all", ["==", "$type", "LineString"], ["==", "mode", "static"]],
                    "layout": {
                        "line-cap": "round",
                        "line-join": "round"
                    },
                    "paint": {
                        "line-color": "#000",
                        "line-width": 3
                    }
                },
                // polygon fill
                {
                    "id": "gl-draw-polygon-fill-static",
                    "type": "fill",
                    "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
                    "paint": {
                        "fill-color": "#000",
                        "fill-outline-color": "#000",
                        "fill-opacity": 0
                    }
                },
                // polygon outline
                {
                    "id": "gl-draw-polygon-stroke-static",
                    "type": "line",
                    "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
                    "layout": {
                        "line-cap": "round",
                        "line-join": "round"
                    },
                    "paint": {
                        "line-color": "#000",
                        "line-width": 3
                    }
                }
            ]
        });
        // map.addControl(draw);
        map.addControl(new mapboxgl.FullscreenControl());

        const handleMapDraw = () => {
            let data = draw.getAll();
            if (data.features.length > 0) {
                let f = data.features[0];
                dispatch(updateGeoFilter({key:"value", value:f}));
            }
            else {
                dispatch(updateGeoFilter({key:"value", value:""}));
            }
        };

        map.on('draw.create', handleMapDraw);
        map.on('draw.delete', handleMapDraw);
        map.on('draw.update', handleMapDraw);
        map.on('draw.modechange', (e) => {
            const data = draw.getAll();
            if (draw.getMode() === 'draw_polygon') {
                draw.delete(data.features.slice(0,-1).map(f => f.id));
            }
        });

        if (org && org.extent) {
            map.fitBounds(org.extent, {
                maxZoom: 16, animate: false
            });
        }
        // setMap(map);
        map.on("load", () => {
            initMapData(map);
            setMap(map);
            setDraw(draw);
        });

        const popup = new mapboxgl.Popup({
            closeButton: true,
            closeOnClick: false
        });
        map.on("mouseenter", "points", () => {
            map.getCanvas().style.cursor = 'pointer';
        });
        map.on("mouseleave", "points", () => {
            map.getCanvas().style.cursor = '';
        });
        map.on("mouseenter", "clusters", () => {
            map.getCanvas().style.cursor = 'pointer';
        });
        map.on("mouseleave", "clusters", () => {
            map.getCanvas().style.cursor = '';
        });

        let clicker;
        map.on("dblclick", "points", (e) => {
            e.preventDefault();
        });
        map.on("click", "points", (e) => {
            const f = e.features[0];
            if (e.originalEvent.detail === 1) {
                clicker = setTimeout(() => {
                    let coordinates = f.geometry.coordinates.slice();
                    let confirmStatus = (f.properties.is_unconfirmed==="true")?"Unconfirmed":((f.properties.is_listing==="true")?"Current Listing":"");
                    let content = `
                     <div class="point-popup">
                        <div class="header">
                            <div>
                                <h4>${f.properties.name}</h4>
                                <div>${f.properties.comp_id || ""}</div>
                                <div class="caption">${confirmStatus}</div>
                            </div>
                             <div>
                                <div>${f.properties.value || ""}</div>
                                <div>${f.properties.date || ""}</div>
                             </div>
                         </div>`;

                            if (f.properties.photo) {
                                content += `<img src="${f.properties.photo}"/>`;
                            }
                            content +=
                                `<div class="footer">
                                    <div>
                                        <a class="TextButton primary" href="/comp/${f.properties.id}?preview=true" target="_blank" rel="noopener">Preview</a>
                                    </div>
                                    <input type="checkbox" ${window.isCompInCart(f.properties.id) ? "checked" : ""}
                                        onclick="toggleCompInCart('${f.properties.id}', ${f.id})"/>
                                </div>
                        </div>
                    `;

                    // Ensure that if the map is zoomed out such that multiple
                    // copies of the feature are visible, the popup appears
                    // over the copy being pointed to.
                    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                    }

                    popup
                        .setLngLat(coordinates)
                        // .setMaxWidth("384px")
                        .setHTML(content)
                        .addTo(map);
                }, 250);
            }
            else if (e.originalEvent.detail === 2) {
                clearTimeout(clicker);
                window.toggleCompInCart(f.properties.id);
            }

        });

        map.on("click", "clusters", (e) => {
            const f = e.features[0];
            const limit = 25;
            map.getSource(f.source).getClusterLeaves(f.properties.cluster_id, limit, 0, (error, results) => {
                let coordinates = f.geometry.coordinates.slice();
                let content = `
                    <div class="cluster-popup">
                        <div class="header">
                            <div>
                                <h4>${f.properties.point_count} Comps</h4>
                    `;
                if (f.properties.point_count > limit) {
                    content += `<p class="caption">Showing the first ${limit} comps</p>`
                }
                content += `
                            </div>
                            <div>
                                <button onclick="window.addCompsToCart([${results.map(it => `'${it.properties.id}'`).join(',')}])" class="TextButton primary"/>Add All</button>
                            </div>
                       </div>`;

                content += '<div class="content">';
                // content += '<table>';
                results.forEach(r => {
                    content += '<div>';
                    if (r.properties.photo) {
                        content += `<img src="${r.properties.photo}"/>`;
                    }
                    content += `<div>
                      <h5>${r.properties.name}</h5>
                      <div>${r.properties.comp_id || ""}</div>
                      <div>${r.properties.value || ""}</div>
                      <div>${r.properties.date || ""}</div>`
                    content += '</div>';

                    content += `<div class="sidebar">
                        <input class="CompInCartCheckbox" type="checkbox" ${window.isCompInCart(r.properties.id)?"checked":""} 
                            onclick="window.toggleCompInCart('${r.properties.id}')"/>
                            <div>
                                <a class="TextButton primary" href="/comp/${r.properties.id}?preview=true" target="_blank" rel="noopener">Preview</a>
                            </div>
                    </div>`;

                    content += '</div>';
                })

                content += '</div>' // content
                content += '</div>'

                popup
                    .setLngLat(coordinates)
                    .setHTML(content)
                    .addTo(map);
            });
        });
        map.on("click", "results", (e) => {
            dispatch(pickResult(e.features[0].properties.index));
        });

        map.on("stylechange", () => {
            initMapData(map);
        })
    };

    const mapOpts = mapOptsForOrg(org);
    return (
        <>
            {hasGeoFilter && <Alert severity="info" variant="outlined" sx={{mb: 1}}>
                To define a search area click at multiple locations on the map. When done defining the area
                double-click to finalize the search polygon.
            </Alert>}
            <Map initMap={initMap} withGeocoder={true} onGeocodeResult={e => setPlace(e.result)} {...mapOpts}/>
        </>
    )
};

export default SearchMap;
