import React, {Fragment} from "react";
import Grid from "@mui/material/Grid";
import {FormRow} from "../../util/Form";
import CompEditorPanel from './CompEditorPanel';
import CompField from "./CompField";
import ShowAllFieldsToggle from "./ShowAllFieldsToggle";
import {ICI, LEASE, MULTI_FAMILY, RESIDENTIAL_RENT, VACANT_LAND} from "../../model/propertyTypes";
import {Field} from  "../../model/fields";

const GeneralPanel = ({comp, highlight, ...props}) => {
    const [showAll, setShowAll] = React.useState(false);
    const fieldProps = {comp, highlight};

    const toggleShowAll = (evt) => {
        setShowAll(evt.target.checked);
    };

    return (
        <CompEditorPanel name="GeneralPanel" comp={comp} {...props}
            extra={comp.type !== RESIDENTIAL_RENT && comp.type !== VACANT_LAND ? <ShowAllFieldsToggle value={showAll} onChange={toggleShowAll}/> : null}>
                <FormRow>
                    <Grid item md={10}>
                        <CompField field={Field.COMP_ID} {...fieldProps} withCopy={true} InputProps={{readOnly: true}}/>
                    </Grid>
                </FormRow>
                <FormRow>
                    <Grid item md={10}>
                        <CompField field={Field.ADDRESS_STREET} {...fieldProps}/>
                    </Grid>
                    <Grid item md={2}>
                        <CompField field={Field.ADDRESS_UNIT} {...fieldProps}/>
                    </Grid>
                </FormRow>
                <FormRow>
                    <Grid item md={6}>
                        <CompField field={Field.ADDRESS_CITY} {...fieldProps}/>
                    </Grid>
                    <Grid item md={6}>
                        <CompField field={Field.ADDRESS_REGION} {...fieldProps}/>
                    </Grid>
                </FormRow>
                <FormRow>
                    <Grid item md={6}>
                        <CompField field={Field.ADDRESS_MUNICIPALITY} {...fieldProps}/>
                    </Grid>
                </FormRow>
                <FormRow>
                    <Grid item md={6}>
                        <CompField field={Field.ADDRESS_POSTAL} {...fieldProps}/>
                    </Grid>
                    <Grid item md={6}>
                        <CompField field={Field.ADDRESS_COUNTRY} {...fieldProps}/>
                    </Grid>
                </FormRow>
                <FormRow>
                  <Grid item xs={12}>
                    <CompField field={Field.GENERAL_LOCATION} {...fieldProps}/>
                  </Grid>
                </FormRow>
                {comp.type !== RESIDENTIAL_RENT ?
                <FormRow>
                    <Grid item xs={12}>
                        <CompField field={Field.GENERAL_LEGAL_DESCRIPTION} {...fieldProps}/>
                    </Grid>
                </FormRow>
                : null}
                <FormRow>
                    <Grid item xs={6}>
                        <CompField field={Field.GENERAL_PID} {...fieldProps}/>
                    </Grid>
                </FormRow>
                {showAll ?
                    <Fragment>
                        {[ICI, LEASE, MULTI_FAMILY].includes(comp.type) ?
                        <FormRow>
                            <Grid item xs={12}>
                                <CompField field={Field.GENERAL_DEVELOPMENT_NAME} {...fieldProps}/>
                            </Grid>
                        </FormRow>
                        : null}
                    </Fragment>
                    : null}

        </CompEditorPanel>
    )
};


export default GeneralPanel;
