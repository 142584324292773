import React from "react";
import {fontAsObject, spacingAsObject} from "../config";

const Banner = ({comp, index, noun, offset, page, style}) => {
    const rowStyle = {
        backgroundColor: style.banner.background,
        ...fontAsObject(style.banner.font)
    };

    const cellStyle = spacingAsObject(style.section.padding, 2, 1, 2);
    return (
        <thead>
            <tr style={rowStyle}>
                <td style={cellStyle}>{comp.address.street}, {comp.address.city}</td>
                <td style={{
                    ...cellStyle,
                    textAlign: "right"
                }}>{noun||"Index"} {index+offset} {page > 0 ? " (Continued)" : ""}</td>
            </tr>
        </thead>
    )
};

export default Banner;
