import React, {Fragment} from "react";
import {useDispatch} from 'react-redux';
import {setSectionAspect} from '../state';
import Photo from "./Photo";
import Map from "./MapThumb";
import FieldList from "./FieldList";
import Table from "./Table";
import SingleField from "./SingleField";
import Divider from "./Divider";
import {borderAsObject, fontAsObject, spacingAsObject, underlineAsObject} from "../config";
import {SECTION_DIVIDER, SECTION_FIELD_LIST, SECTION_MAP, SECTION_PHOTO, SECTION_SINGLE_FIELD} from "../sectionTypes";

const FieldSection = ({section, comp, style, ...props}) => {
    const tableStyle = {
        ...borderAsObject(style.section.border),
        ...(section.border===false?{borderWidth: 0}: {})
    };
    const spacingStyle = spacingAsObject(style.section.padding, 2,2,2,2);

    const titleStyle = {
        ...fontAsObject(style.section.heading.font),
        ...underlineAsObject(style.section.heading.underline),
        ...spacingStyle,
        paddingLeft: 0,
        paddginRight: 0,
        marginLeft: spacingStyle.paddingLeft,
        marginRight: spacingStyle.paddingRight,
        backgroundColor: style.section.heading.background
    };
    const bodyStyle = {
        ...fontAsObject(style.section.body.font),
        ...spacingStyle,
        backgroundColor: style.section.body.background
    };
    const fieldProps = {
        ...props, section, comp, bodyStyle
    };
    return (
        <Table style={tableStyle}>
            <tbody>
            {section.title &&
                <tr>
                    <td colSpan={section.span * 2}>
                        <div style={titleStyle}>{section.title}</div>
                    </td>
                </tr>}
            {section.type === SECTION_FIELD_LIST &&
                <FieldList {...fieldProps} />}
            {section.type === SECTION_SINGLE_FIELD &&
                <SingleField {...fieldProps} />}
            </tbody>
        </Table>
    );
};

const Section = ({section, sheetIndex, ...props}) => {
   const dispatch = useDispatch();
   const handleRenderImage = (props.isPreview || sheetIndex > 0) ? null : el => {
       const rect = el.getBoundingClientRect();
       const aspect = rect.width ? (rect.height / rect.width) : 0;
       dispatch(setSectionAspect({id: section.id, aspect}))
   }
   return (
       <Fragment>
           {section.type === SECTION_PHOTO && <Photo section={section} {...props} onRender={handleRenderImage}/>}
           {section.type === SECTION_MAP && <Map section={section} {...props} onRender={handleRenderImage}/>}
           {(section.type === SECTION_FIELD_LIST || section.type === SECTION_SINGLE_FIELD) && <FieldSection section={section} {...props}/>}
           {section.type === SECTION_DIVIDER && <Divider {...props}/>}
       </Fragment>
   )
};

export default Section;
