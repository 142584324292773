import {Select} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {FieldType, Fields} from '../../model/fields';
import EditableLabel from '../../util/EditableLabel';
import FieldChip from './FieldChip';
import {addMetricField, removeMetricField, setMetricStatLabel, toggleMetricStat} from '../state';

const MetricGrid = () => {
  const sheet = useSelector(state => state.sheet.curr);
  const dispatch = useDispatch();
  const [field, setField] = useState("")
  const metricFields = sheet.metrics.fields||[];

  const addField = (f) => {
    dispatch(addMetricField(f));
    setField("");
  }
  return (
    <Grid container>
      <Grid item md={8}>
        <Box display="flex" flexWrap="wrap" gap={1}>
          {metricFields.map((f,i) => <FieldChip field={f} index={i} key={f.path} sx={{mr:1}}
                     onRemove={() => dispatch(removeMetricField(f))}/>)}
        </Box>
        <Box mt={1}>
          <Select value={field} variant="outlined" className="MetricFieldSelect"
                  onChange={evt => addField(Fields.lookup(evt.target.value))} sx={{minWidth:"50%"}} displayEmpty>
            <MenuItem value={""} disabled>
              Add a Field
            </MenuItem>
            {(sheet.fields||[]).filter(f => FieldType.isNumeric(f.type)).filter(f => !metricFields.includes(f))
              .map(f => <MenuItem key={f.path} value={f.path}>{f.label()}</MenuItem>)}
          </Select>
        </Box>
      </Grid>
      <Grid item md={4}>
        {sheet.metrics.stats.map(s => <Box key={s.type} display="flex" alignItems="flex-start">
          <Checkbox color="secondary" checked={s.enabled} onChange={() => dispatch(toggleMetricStat(s.type))}/>
          <Box ml={2}>
            <EditableLabel value={s.label} onChange={v => dispatch(setMetricStatLabel({stat:s.type, label:v}))}/>
          </Box>
        </Box>)}
      </Grid>
    </Grid>
  )
}

export default MetricGrid;
