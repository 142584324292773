import TextField from '@mui/material/TextField';
import {DatePicker} from '@mui/x-date-pickers';
import {produce} from 'immer';
import React, {useEffect, useState} from "react";
import moment from "moment";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Chip from "@mui/material/Chip";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import CheckIcon from '@mui/icons-material/Check';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import DateRangeIcon from '@mui/icons-material/DateRange';
import HistoryIcon from '@mui/icons-material/History';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {DATE_CUSTOM, DATE_WITHIN} from "./constants";

const WITHIN_OPTIONS = [{
    key: "7d",
    title: "7 Days",
    diff: [7, "days"]
}, {
    key: "1m",
    title: "1 Month",
    diff: [1, "months"]
}, {
    key: "3m",
    title: "3 Months",
    diff: [3, "months"]
}, {
    key: "6m",
    title: "6 Months",
    diff: [6, "months"]
}, {
    key: "9m",
    title: "9 Months",
    diff: [9, "months"]
}, {
    key: "1y",
    title: "1 Year",
    diff: [1, "years"]
}, {
    key: "2y",
    title: "2 Years",
    diff: [2, "years"]
}, {
    key: "3y",
    title: "3 Years",
    diff: [3, "years"]
}];

const DateRangeChooser = ({value, onChange, size, showMode=true, disabled, inputProps}) => {

    const [anchor, setAnchor] = useState(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (!value) {
            const now = new Date();
            onChange({
                mode: DATE_CUSTOM,
                range: [now ,now]
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!value) {
        return (null);
    }

    // const props = {
    //     disabled: disabled === true || false
    // };

    const now = new Date();
    const mode = value.mode;
    const [start, end] = value.range;

    const handleChange = (newMode, newValue) => {
        onChange(produce(value, draft => {
            draft.mode = newMode;

            let then = new Date(now.getTime());
            if (newMode === DATE_WITHIN) {
                draft.last = newValue.key;
                then = moment(then).subtract(newValue.diff[0], newValue.diff[1]).toDate();
            }
            else {
                then = null;
            }

            if (then) {
                draft.range = [then, now];
            }
        }))
        setOpen(false);
    }

    const sz = size || "small";

    return (
        <Grid container alignItems="center">
            <Grid item>
                {showMode && <ToggleButtonGroup size={sz} value={mode} exclusive>
                    <ToggleButton value={DATE_CUSTOM} onClick={() => handleChange(DATE_CUSTOM)}>
                        <DateRangeIcon/>
                    </ToggleButton>
                    <ToggleButton value={DATE_WITHIN} onClick={(evt) => {
                        setAnchor(evt.target);
                        setOpen(true);
                    }}>
                        <HistoryIcon/>
                        <ArrowDropDownIcon/>
                    </ToggleButton>
                </ToggleButtonGroup>}
                <Menu
                    anchorEl={anchor}
                    keepMounted
                    open={open && Boolean(anchor)}
                    onClose={() => {
                        setOpen(false);
                        setAnchor(null)
                    }}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    {WITHIN_OPTIONS.map(o => {
                        const isChosen = mode === DATE_WITHIN && value.last === o.key;
                        return <MenuItem key={o.key} onClick={() => handleChange(DATE_WITHIN, o)}>
                            <ListItemIcon>
                                <CheckIcon sx={{
                                    ...(!isChosen && {visibility: "hidden"})
                                }}/>
                            </ListItemIcon>
                            <ListItemText>{o.title}</ListItemText>
                        </MenuItem>
                    })}
                </Menu>
            </Grid>
            {mode === DATE_CUSTOM &&
            <Grid item sx={{ ml: 1, display: "flex", alignItems: "center"}}>
                <DatePicker
                  timezone="UTC"
                  renderInput={(params) => <TextField {...params} size={sz} sx={{width: "196px"}}/>}
                  value={start}
                  onChange={v => onChange(produce(value, draft => {
                      draft.range.splice(0,1,v.toDate());
                  }))}
                  invalidDateMessage=""
                  emptyLabel="Choose a date"
                  clearable
                  inputFormat="MMM Do, YYYY"
                  inputVariant="outlined"
                  InputLabelProps={{shrink: true}}
                  className="DateRangeChooser-from"
                />
                <SyncAltIcon sx={{mx:1}}/>
                <DatePicker
                  renderInput={(params) => <TextField {...params} size={sz} sx={{width: "196px"}}/>}
                  value={end}
                  onChange={v => onChange(produce(value, draft => {
                      draft.range.splice(1,1,v.toDate());
                  }))}
                  invalidDateMessage=""
                  emptyLabel="Choose a date"
                  clearable
                  inputFormat="MMM Do, YYYY"
                  inputVariant="outlined"
                  InputLabelProps={{shrink: true}}
                  className="DateRangeChooser-to"
                />
            </Grid>
            }
            {mode === DATE_WITHIN &&
            <Grid item sx={{pl: 1}}>
                {value.last && <Chip label={`Within ${WITHIN_OPTIONS.find(it => it.key === value.last).title}`} />}
            </Grid>
            }
        </Grid>
  )
};

export default DateRangeChooser;
