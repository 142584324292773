import React, {Fragment} from "react";
import {ALIGN_JUSTIFY} from '../../model/fonts';
import FieldValue from "./FieldValue";

const SingleField = ({section, comp, bodyStyle, options}) => {
    const field = (section.fields && section.fields[0]) || section.field;
    const style = {
      textAlign: field.path in (section.styles||{}) ? section.styles[field.path].align || ALIGN_JUSTIFY : ALIGN_JUSTIFY,
      display: "inline-block"
    };
    return (
        <Fragment>
            <tr><td style={bodyStyle}>
                <FieldValue field={field} comp={comp} options={options} style={style}/>
            </td></tr>
        </Fragment>
    );
};

export default SingleField;
