import React, {forwardRef, Fragment, useImperativeHandle} from "react";
import {styled} from '@mui/material';
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";


const StyledTab = styled(Tab)(({theme}) => ({
    '&&': {
        minWidth: '160px',
        color: theme.palette.grey["500"]
    },
    '&&.Mui-selected': {
        color: "initial"
    }
}));

const StyledVerticalTab = styled(Tab)(({theme}) => ({
    root: {
        color: theme.palette.grey["500"],
        wordSpacing: "9999px"
    },
    selected: {
        color: "initial"
    }
}));

const CenteredTabs = styled(Tabs)({
    '&&': {
        '.MuiTabs-flexContainer': {
            justifyContent: "center"
        }
    }
});

export const TabBar = forwardRef(({tabs, activeTab, onTabChange, centered, className, ...props}, ref) => {
    const [active, setActive] = React.useState(activeTab||0);

    useImperativeHandle(ref, () => ({
        next: () => {
            if (active < tabs.length-1) setActive(active+1);
        }
    }));

    const handleChange = (event, newValue) => {
        setActive(newValue);
        if (onTabChange) onTabChange(newValue);
    };

    const TabsComponent = centered === true ? CenteredTabs : Tabs;
    return (
        <Fragment>
            <AppBar position="static" color="default" ref={ref} className={className}>
                <TabsComponent value={active} onChange={handleChange} variant="scrollable" scrollButtons="auto" indicatorColor="primary">
                    {tabs.map((tab,i) => <StyledTab key={"tab-" + i} {...tab[0]} className={tab[0].label.replace(" ","") + "Tab"}/>)}
                </TabsComponent>
            </AppBar>
            {tabs.map((tab, i) => tab[1]({active, key: "panel-"+i, index: i, ...props}))}
        </Fragment>
    );
});

export const TabSideBar = ({tabs, activeTab, onTabChange, ...props}) => {
    const [active, setActive] = React.useState(activeTab || 0);
    const handleChange = (event, newValue) => {
        setActive(newValue);
        if (onTabChange) onTabChange(newValue);
    };

    return (
        <Box display="flex" flexGrow={1}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={active}
                onChange={handleChange}
                indicatorColor="secondary"
                sx={{
                    borderRight: `1px solid`,
                    borderColor: 'divider',
                    width: 150,
                    minWidth: 150
                }}
            >
                {tabs.map((tab, i) => <StyledVerticalTab key={"tab-"+i} {...tab[0]} />)}
            </Tabs>
            {tabs.map((tab, i) => tab[1]({active, key: "panel-"+i, index: i, sx: {width: '100%'}, ...props}))}
        </Box>
    )
};

export const TabPanel = ({children, active, index, ...other}) => {
    return (
        <Typography
            component="div"
            hidden={active !== index}
            {...other}
        >
            {active === index && <Box sx={{py:2, px:4}}>{children}</Box>}
        </Typography>
    );
};
