import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SelectWithLabel from "../util/SelectWithLabel";
import SearchDialog from "./SearchDialog";
import {MODE_QUERY} from "./constants";
import {openSearch, setMode} from './state';


const SearchPanel = () => {
    const search = useSelector(state => state.find.search.name || "");
    const searches = useSelector(state => state.find.searches || []);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    const handleChoose = evt => {
        const s = searches.find(it => it.name === evt.target.value);
        if (s) {
            dispatch(openSearch(s));
            dispatch(setMode(MODE_QUERY));
        }
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <SelectWithLabel label="Saved Search" value={search} size="small" fullWidth
                        values={searches.map(s => s.name)} onChange={handleChoose}/>
                </Grid>
                {searches.length > 0 && <Grid item>
                    <Button color="primary" onClick={() => setOpen(true)}>Manage</Button>
                </Grid>}
            </Grid>
            <SearchDialog open={open} onClose={() => setOpen(false)}/>
        </>
    )
}
export default SearchPanel;
