import React from "react";
import {useSelector} from "react-redux";
import {chunk, maxBy, range} from "lodash";
import {Box} from "@mui/material";
import Table from "./Table";
import {applyTextStyle, borderAsObject, fontAsObject, spacingAsObject} from "../config";
import FieldValue from "./FieldValue";
import FieldHeader from "./FieldHeader";
import {LIGHT_GRAY} from '../../model/colors';

export const SummaryContent = ({padding, comps, config, options}) => {
    const columns = config.columns||[];
    const numCols = columns.length;
    const depth = numCols > 0 ? maxBy(columns, (it) => it.fields.length).fields.length : 0;

    const tableStyle = {
        ...borderAsObject(config.style.border)
    };
    const headStyle = {
        verticalAlign: "top",
        ...fontAsObject(config.style.header.font),
        backgroundColor: config.style.header.background
    };
    const bodyStyle = {
        verticalAlign: "top",
        ...fontAsObject(config.style.body.font),
        backgroundColor: config.style.body.background,
    };
    const bodyStyleOdd = config.style.body.alt?.enabled === true?  {
        ...bodyStyle,
        backgroundColor: config.style.body.alt.background || LIGHT_GRAY,
    } : bodyStyle;

    const indexStyle = {
        verticalAlign: "top",
        ...fontAsObject(config.style.index.font),
        backgroundColor: config.style.index.background,
    };
    const indexStyleOdd = config.style.index.alt?.enabled === true?  {
        ...indexStyle,
        backgroundColor: config.style.index.alt.background || LIGHT_GRAY,
    } : indexStyle;

    const chunks = chunk(Array(comps.length).fill(0).map((it, i) => i),
      config.split === true ? config.rowsPerPage : comps.length);

    return (
      <>
          {chunks.map((chunk, c) =>
            <Box key={c} mb={1}>
                <Box p={padding} data-section="summary">
                    <Table style={tableStyle}>
                        <thead>
                        {range(depth).map((i) => {
                            const cellStyle = {
                                ...headStyle, ...spacingAsObject(config.style.padding, i===0?1:2, 1, i===(depth-1)?1:2)
                            };
                            return (
                              <tr key={i}>
                                  <td style={cellStyle}>{i === 0 ? config.options?.noun || "No." : ""}</td>
                                  {range(numCols).map((j) => {
                                      const col = columns[j];
                                      const f = i < col.fields.length ? col.fields[i] : null;
                                      const fieldCellStyle = f ? {
                                          ...cellStyle,
                                          ...applyTextStyle({}, (col.styles||{})[f.path], "label"),
                                      } : cellStyle;
                                      return (
                                        <td key={j} style={fieldCellStyle}>
                                            {i < col.fields.length && <FieldHeader column={col} field={col.fields[i]} options={options}/>}
                                        </td>
                                      )
                                  })}
                              </tr>
                            )
                        })}
                        </thead>
                        <tbody>
                        {chunk.map(i => [comps[i], i]).map((compAndIndex, i) => {
                            const comp = compAndIndex[0];
                            return range(depth).map((x) => {
                                let isBorder = x === depth-1 && (i < chunk.length-1);

                                let cellStyle = {
                                    ...(i % 2 === 0 ? bodyStyle : bodyStyleOdd),
                                    ...(isBorder ? borderAsObject(config.style.border, "bottom") : {}),
                                    ...spacingAsObject(config.style.padding, x===0?1:2, 1, x===(depth-1)?1:2)
                                };
                                let indexCellStyle = {
                                    ...(i % 2 === 0 ? indexStyle : indexStyleOdd),
                                    ...(isBorder ? borderAsObject(config.style.border, "bottom") : {}),
                                    ...spacingAsObject(config.style.padding, x===0?1:2, 1, x===(depth-1)?1:2)
                                }
                                return (
                                  <tr key={x}>
                                      <td style={indexCellStyle}>{x === 0 ? (compAndIndex[1]+(options.offset||1)) : ""}</td>
                                      {range(numCols).map((y) => {
                                          const field = columns[y].fields[x];
                                          const styles = columns[y].styles || {};
                                          const thisCellStyle = field && field.path in styles ? {
                                              ...cellStyle,
                                              ...applyTextStyle({}, styles[field.path], "value"),
                                          } : cellStyle;
                                          return (
                                            <td key={y} style={thisCellStyle}>
                                                {x < columns[y].fields.length && <FieldValue field={field} comp={comp} options={options}/>}
                                            </td>
                                          );
                                      })}
                                  </tr>
                                )
                            })
                        })}
                        </tbody>
                    </Table>
                </Box>
            </Box>
          )}
      </>
    )
}
const Summary = ({padding}) => {
    const config = useSelector(state => state.report.curr.summary);
    const options = useSelector(state => state.report.curr.options || {});
    const comps = useSelector(state => state.cart.comps);

    return (<SummaryContent padding={padding} comps={comps} config={config} options={options}/>)
};

export default Summary;
