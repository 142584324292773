import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getAuth, reauthenticateWithCredential, updateProfile, updatePassword, EmailAuthProvider} from 'firebase/auth';

export const updateDisplayName = createAsyncThunk(
  'profile/updateDisplayName',
  async (name, {rejectWithValue}) => {
    try {
      await updateProfile(getAuth().currentUser, {
        displayName: name
      });
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const changePassword = createAsyncThunk(
  'profile/changePassword',
  async ({currPass, newPass}, {getState, rejectWithValue}) => {
    const {auth} = getState();
    try {
      await reauthenticateWithCredential(getAuth().currentUser, EmailAuthProvider.credential(auth.user.email, currPass));
      await updatePassword(getAuth().currentUser, newPass);
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

const profile = createSlice({
  name: 'profile',
  initialState: {}
});

export default profile.reducer;
