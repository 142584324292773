import React, {Fragment} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {FormRow} from "../../util/Form";
import CompEditorPanel from './CompEditorPanel';
import CompField from "./CompField";
import LeaseRateCalculator from "./LeaseRateCalculator";
import ShowAllFieldsToggle from "./ShowAllFieldsToggle";
import FieldWithHelp from "./FieldWithHelp";
import {Field} from  "../../model/fields";

const LeaseRecoveryHelp = () => {
    return (
        <Fragment>
            <Typography variant="h5">
                Triple Net Lease
            </Typography>
            <Typography>
                Tenant pays rent, utilities, taxes, insurance, and maintenance expenses. Property owner pays for structural repairs only.
            </Typography>

            <Typography variant="h5">
                Gross Lease
            </Typography>
            <Typography>
                Tenant pays rent. Property owner pays other expenses.
            </Typography>

            <Typography variant="h5">
                Single Net Lease
            </Typography>
            <Typography>
                Tenant pays rent, utilities, and taxes or insurance. Property owner pays for structural repairs, property maintenance, and property taxes or insurance.
            </Typography>

            <Typography variant="h5">
                Double Net Lease
            </Typography>
            <Typography>
                Tenant pays rent, utilities, taxes, and insurance. Property owner pays structural repairs and property maintenance expenses.
            </Typography>

            <Typography variant="h5">
                Modified Gross (Semi-Gross) Lease
            </Typography>
            <Typography>
                Tenant and property owner share expenses.
            </Typography>

            <Typography variant="h5">
                Absolute Net Lease
            </Typography>
            <Typography>
                Tenant pays rent and all expenses.
            </Typography>
        </Fragment>
    );
};

const LeasePanel = ({comp, highlight, ...props}) => {
    const fieldProps = {comp, highlight};

    const [showAll, setShowAll] = React.useState(false);
    const toggleShowAll = (evt) => {
        setShowAll(evt.target.checked);
    };

    return (
      <CompEditorPanel name="LeasePanel" comp={comp} {...props}
        extra={<ShowAllFieldsToggle value={showAll} onChange={toggleShowAll}/>}>
                <FormRow>
                    <Grid item md={6}>
                        <CompField field={Field.LEASE_TYPE} {...fieldProps}/>
                    </Grid>
                    {Field.LEASE_TYPE.get(comp) === "Other" &&
                    <Grid item md={6}>
                        <CompField field={Field.LEASE_TYPE_OTHER} {...fieldProps}/>
                    </Grid>
                    }
                </FormRow>
                <FormRow>
                    <Grid item md={6}>
                        <FieldWithHelp field={Field.LEASE_RECOVERY} help={<LeaseRecoveryHelp/>}>
                            <CompField field={Field.LEASE_RECOVERY} {...fieldProps}/>
                        </FieldWithHelp>
                    </Grid>
                </FormRow>
                <FormRow>
                    <Grid item xs={6}>
                        <CompField field={Field.LEASE_START_DATE} {...fieldProps}/>
                    </Grid>
                    <Grid item xs={6}>
                        <CompField field={Field.LEASE_TERM} {...fieldProps} disabled={Field.LEASE_MONTH_TO_MONTH.get(comp) === true}/>
                        <CompField field={Field.LEASE_MONTH_TO_MONTH} {...fieldProps}/>
                    </Grid>
                </FormRow>
                <FormRow>
                    <Grid item xs={6}>
                        <CompField field={Field.LEASE_YEAR1_RATE} {...fieldProps}/>
                    </Grid>
                </FormRow>
                <FormRow>
                    <Grid item md={6}>
                        <CompField field={Field.LEASE_AVERAGE_RATE_OVER_TERM} {...fieldProps}/>
                    </Grid>
                    <Grid item md={6}>
                        <CompField field={Field.LEASE_USE_RATE_CALCULATOR} {...fieldProps}/>
                    </Grid>
                </FormRow>
                {Field.LEASE_USE_RATE_CALCULATOR.get(comp) === true &&
                <FormRow>
                    <Grid item md={6}/>
                    <Grid item md={6}>
                        <LeaseRateCalculator comp={comp}/>
                    </Grid>
                </FormRow>
                }
                {showAll && <Fragment>
                    <FormRow>
                        <Grid item md={6}>
                            <CompField field={Field.LEASE_TENANT_IMPROVEMENT_ALLOWANCE} {...fieldProps}/>
                        </Grid>
                        <Grid item md={6}>
                            <CompField field={Field.LEASE_TENANT_IMPROVEMENT_ALLOWANCE_DESC} {...fieldProps}/>
                        </Grid>
                    </FormRow>
                    <FormRow>
                        <Grid item md={6}>
                            <CompField field={Field.LEASE_FREE_RENT} {...fieldProps}/>
                        </Grid>
                        <Grid item md={6}>
                            <CompField field={Field.LEASE_FREE_RENT_DESC} {...fieldProps}/>
                        </Grid>
                    </FormRow>
                </Fragment>}
                <FormRow>
                    <Grid item xs={6}>
                        <CompField field={Field.LEASE_BASE_LEASE_RATE} {...fieldProps}/>
                    </Grid>
                    <Grid item xs={6}>
                        <CompField field={Field.LEASE_ADDITIONAL_RENT} {...fieldProps}/>
                    </Grid>
                </FormRow>
                {showAll && <FormRow>
                    <Grid item xs={6}>
                        <CompField field={Field.LEASE_LANDLORD_NAME} {...fieldProps}/>
                    </Grid>
                    <Grid item xs={6}>
                        <CompField field={Field.LEASE_TENANT_NAME} {...fieldProps}/>
                    </Grid>
                </FormRow>}
                <FormRow>
                    <Grid item xs={6}>
                        <CompField field={Field.LEASE_HAS_RENEWAL_OPTION} {...fieldProps}/>
                    </Grid>
                </FormRow>
                <FormRow>
                    {Field.LEASE_HAS_RENEWAL_OPTION.get(comp) === true &&
                    <Grid item xs={12}>
                        <CompField field={Field.LEASE_RENEWAL_OPTIONS_DESC} {...fieldProps}/>
                    </Grid>
                    }
                </FormRow>
      </CompEditorPanel>
    )
};

export default LeasePanel;
