import React from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {useTheme, Drawer as MuiDrawer, styled} from '@mui/material';
import Box from '@mui/material/Box';
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import MUIListItemText from "@mui/material/ListItemText";
import MUIListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from '@mui/icons-material/Settings';
import BuildIcon from '@mui/icons-material/Build';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { blue, green, orange} from '@mui/material/colors';
import {DRAWER_WIDTH} from "./constants"
import {hasRole, ROLE_ADMIN, ROLE_EDITOR, ROLE_MASTER} from "../auth/roles";
import {useLocalStorage} from "../util/useLocalStorage";
import Avatar from '@mui/material/Avatar';

const openedMixin = (theme) => ({
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(6)} + 5px)`,
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
      width: DRAWER_WIDTH,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
      }),
  }),
);

const ListItemText = styled(MUIListItemText)({
    '&&': {
        marginTop: '4px',
        marginBottom: '4px'
    }
});

const ListItemIcon = styled(MUIListItemIcon)({
    '&&': {
        minWidth: '56px'
    }
});

const ListItemAvatar = ({color, children}) => {
    return <Avatar sx={{bgcolor: color, width: '1.75em', height: '1.75em', fontSize: '1rem'}} >{children}</Avatar>
}

const Sidebar = () => {
    const theme = useTheme();
    const isAdmin = useSelector(state => hasRole(state.auth, ROLE_ADMIN));
    const isMaster = useSelector(state => hasRole(state.auth, ROLE_MASTER));
    const isEditor = useSelector(state => hasRole(state.auth, ROLE_EDITOR));
    const [isOpen, setOpen] = useLocalStorage("quickcomp.drawer", true);

    const styles = {
        root: {
            width: DRAWER_WIDTH,
            ...(isOpen && {
                width: DRAWER_WIDTH,
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            }),
            ...(!isOpen && {
                width: theme.spacing(7),
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                overflowX: 'hidden',
            })
        },
        divider: {
            my: 1
        }
    };
    return (
        <Drawer
            variant="permanent"
            className="Sidebar"
            // classes={{
            //     paper: styles.root
            // }}
            // sx={styles.root}
            open={isOpen}
        >
            <Box sx={{
                width: DRAWER_WIDTH,
                height: theme.spacing(8)
            }}>
                {/*<MenuList className={classes.logoMenu}>*/}
                {/*    <MenuItem component={Link} to="/" className={classes.homeLink}>*/}
                {/*        <Logo size={"sm"} className={classes.logo}/>*/}
                {/*    </MenuItem>*/}
                {/*</MenuList>*/}
            </Box>
            <MenuList>
                <MenuItem className="ToggleLink" onClick={() => setOpen(!isOpen)}>
                    <ListItemIcon>
                        {isOpen ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                    </ListItemIcon>
                    {/*<ListItemText primary="Add a Comp" />*/}
                </MenuItem>
                <Divider sx={styles.divider}/>
                <MenuItem component={Link} to="/add" className="AddLink">
                    <ListItemIcon><AddIcon/></ListItemIcon>
                    <ListItemText primary="Add a Comp" />
                </MenuItem>
                <MenuItem component={Link} to="/find" className="FindLink">
                    <ListItemIcon><SearchIcon/></ListItemIcon>
                    <ListItemText primary="Find a Comp" />
                </MenuItem>
                <Divider sx={styles.divider}/>
                <MenuItem component={Link} to="/comps" className="CompsLink">
                    <ListItemIcon>
                        <ListItemAvatar color="primary.main">C</ListItemAvatar>
                    </ListItemIcon>
                    <ListItemText primary="Comps" />
                </MenuItem>
                <MenuItem component={Link} to="/reports" className="ReportsLink">
                    <ListItemIcon>
                        <ListItemAvatar color="secondary.main">R</ListItemAvatar>
                    </ListItemIcon>
                    <ListItemText primary="Reports" />
                </MenuItem>
                <MenuItem component={Link} to="/sheets" className="SheetsLink">
                    <ListItemIcon>
                        <ListItemAvatar color={blue[500]}>S</ListItemAvatar>
                    </ListItemIcon>
                    <ListItemText primary="Sheets" />
                </MenuItem>
                <MenuItem component={Link} to="/lists" className="ListsLink">
                    <ListItemIcon>
                        <ListItemAvatar color={green[500]}>L</ListItemAvatar>
                    </ListItemIcon>
                    <ListItemText primary="Lists" />
                </MenuItem>
                {isEditor && <MenuItem component={Link} to="/templates" className="TemplatesLink">
                    <ListItemIcon>
                        <ListItemAvatar color={orange[500]}>T</ListItemAvatar>
                    </ListItemIcon>
                    <ListItemText primary="Templates" />
                </MenuItem>}
                <Divider sx={styles.divider}/>
                {isAdmin && <MenuItem component={Link} to="/settings" className="SettingsLink">
                    <ListItemIcon><SettingsIcon/></ListItemIcon>
                    <ListItemText primary="Settings" />
                </MenuItem>}
                {/*{isAdmin && <MenuItem component={Link} to="/backups" className="BackupLink">*/}
                {/*    <ListItemIcon><BackupIcon/></ListItemIcon>*/}
                {/*    <ListItemText primary="Backups" />*/}
                {/*</MenuItem>}*/}
                {isMaster && <MenuItem component={Link} to="/master">
                    <ListItemIcon><BuildIcon/></ListItemIcon>
                    <ListItemText primary="Master" />
                </MenuItem>}
            </MenuList>
        </Drawer>
    )
};

export default Sidebar;
