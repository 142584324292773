import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import {lighten, useTheme} from "@mui/material";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Grid from "@mui/material/Grid";
import TablePagination from "@mui/material/TablePagination";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import DeleteIcon from "@mui/icons-material/Delete";
import Spinner from "./Spinner";
import {pluralize} from "./utils";
import {Field} from  "../model/fields";


const TableToolbar = ({numSelected, page, numItems, noun}) => {
    const theme = useTheme();
    const styles = {
        title: {
            flex: '1 1 100%',
        }
    }
    return (
        <Toolbar variant="dense" sx={{
            pl: 2,
            pr: 1,
            bgcolor: 'grey.100',
            ...(numSelected > 0 && {
                color: 'secondary.main',
                bgcolor: lighten(theme.palette.secondary.light, 0.85),
            })
        }}>
            {page > -1 &&
            <Fragment>
                {numSelected > 0 ? (
                    <Typography sx={styles.title} variant="subtitle1">
                        {numSelected} selected
                    </Typography>
                ) : (
                    <Typography sx={styles.title} variant="subtitle1">
                        {pluralize(numItems, noun)}
                    </Typography>
                )}

                {numSelected > 0 &&
                <Tooltip title="Delete">
                    <IconButton aria-label="delete" size="large">
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
                }
            </Fragment>
            }
            {page < 0 && <Spinner message={`Loading ${pluralize(2, noun, {withCount: false})}`}/> }

        </Toolbar>
    );
};

const TableHeader = ({ fields, onSelectAllClick, numSelected, sort, onSort, createActions, styleFieldCell}) => {

    const createSortHandler = field => () => {
        const isAsc = sort.field === field.path && sort.order === "asc";
        onSort(field, isAsc ? "desc" : "asc");
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    {/*<Checkbox*/}
                    {/*    indeterminate={numSelected > 0 && numSelected < rowCount}*/}
                    {/*    checked={rowCount > 0 && numSelected === rowCount}*/}
                    {/*    onChange={onSelectAllClick}*/}
                    {/*/>*/}
                </TableCell>
                {fields.map((f,i) =>
                    <TableCell key={f.path} style={styleFieldCell && styleFieldCell(f, i)}>
                        <TableSortLabel active={sort && sort.field === f.path}
                                        direction={(sort && sort.field === f.path) ? sort.order : 'asc'}
                                        onClick={sort && createSortHandler(f)}>
                            <Typography variant="subtitle2" sx={{
                                textTransform: "uppercase",
                                fontSize: "0.75rem"
                            }}>{f.label()}</Typography>
                        </TableSortLabel>
                    </TableCell>)}
                {createActions && <TableCell key="actions"/>}
            </TableRow>
        </TableHead>
    );
};

const FieldCell = ({field, item, createItemLink, createSubtitle, renderCell, styleCell, ...props}) => {
    let value = field.render(item);
    let inner;
    switch(field) {
        case Field.TITLE:
            inner = createItemLink ? <Link to={createItemLink(item)}>{value}</Link> : <Typography>{value}</Typography>;
            inner = createSubtitle ? <Fragment>
                {inner}
                {createSubtitle(item)}
            </Fragment> : inner;
            break;
        case Field.UPDATED:
        case Field.CREATED:
            const fromNow = field.render(item, {fromNow: true});
            inner = <Fragment>
                <Typography component="div">{value}</Typography>
                <Typography component="div" variant="subtitle2">{fromNow}</Typography>
            </Fragment>;
            break;
        default:
            inner = (renderCell && renderCell(item, field)) || <Typography>{value}</Typography>;
    }
    return (
        <TableCell {...props} style={styleCell && styleCell(item, field)}>
            {inner}
        </TableCell>
    );
};

const DataTable = ({list, numItems, noun, fields, usePaging=true, onPage, onSort, createAddButton, createItemLink, createSubtitle, createActions, styleFieldCell, renderCell, styleCell}) => {
    const [isPaging, setIsPaging] = React.useState(false);

    React.useEffect(() => {
        if (usePaging && list.page === -1) {
            onPage(true);
        }
    }, [usePaging, list, onPage]);

    const handleChangePage = usePaging ? (evt, newPage) => {
        if (isPaging) return;
        setIsPaging(true);
        onPage(newPage > list.page).then(() => setIsPaging(false));
    } : null;

    const emptyRows = list.size - Math.min(list.values.length, numItems - list.page * list.size);
    return (
        <Box sx={{width: '100%'}}>
            <Paper sx={{width: '100%', mb: 2}}>
                <TableToolbar numSelected={/*selected.length*/0} numItems={numItems} page={list.page} noun={noun}/>
                <TableContainer>
                    <Table className={`DataTable`} sx={{minWidth: 750,}}>
                        <TableHeader {...{fields, sort: list.sort, onSort, createActions, styleFieldCell, numSelected: 0}} />
                        {list.page > -1 &&
                        <TableBody>
                            {list.values.map(item => {
                                const isItemSelected = false /*isSelected(comp.id)*/;

                                return (
                                    <TableRow
                                        hover
                                        // onClick={event => handleClick(event, comp.id)}
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={item.id}
                                        selected={isItemSelected}
                                        sx={{verticalAlign: "baseline"}}
                                    >
                                        <TableCell padding="checkbox">
                                            {/*<Checkbox checked={isItemSelected} />*/}
                                        </TableCell>
                                        {fields.map(f => <FieldCell field={f} item={item} key={`${item.id}-${f.path}`} createItemLink={createItemLink} createSubtitle={createSubtitle} renderCell={renderCell} styleCell={styleCell}/>) }
                                        {createActions && <TableCell sx={{
                                            padding: 0,
                                            verticalAlign: "middle",
                                            textAlign: "center"}}
                                        >{createActions(item)}</TableCell>}
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53*emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                        }
                    </Table>
                </TableContainer>
                {usePaging && <Grid container sx={{
                    bgcolor: 'grey.100'
                }}>
                    {isPaging && <Spinner/>}
                    {list.page > -1 && list.values.length > 0 &&
                    <TablePagination
                        rowsPerPageOptions={[]}
                        component="div"
                        count={numItems}
                        rowsPerPage={list.size}
                        page={list.page}
                        sx={{flexGrow: 1}}
                        onPageChange={handleChangePage}
                    />
                    }
                </Grid>}
            </Paper>
            {list.page > -1 && list.values.length === 0 &&
            <Box sx={{
                textAlign: "center",
                "& > a ": {
                    mt: 2
                }
            }}>
                <Typography>No {pluralize(2, noun, {withCount: false})} have been created yet.</Typography>
                {createAddButton && createAddButton()}
            </Box>
            }
        </Box>
    );
};

export default DataTable;
