import React, {useState} from "react";
import {useDispatch} from "react-redux";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import FieldDialog from "../../report/builder/FieldDialog";
import FieldChip from "./FieldChip";
import {addFields, moveField, removeField, renameField, selectField} from '../state';


const FieldGrid =  ({fields, labels, selected, selectable}) => {
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState(false);

    const open = () => {
        setOpen(true);
    };

    const handleClose = (fields) => {
        if (fields) {
            dispatch(addFields(fields));
        }
        setOpen(false);
    };

    return (
        <>
            <Grid item xs={12}>
                <Box sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexWrap: "wrap",
                    gap: 1,
                    mb: 1,
                    "& > .MuiChip-root": {
                        mr: 1,
                        mb: 1
                    }
                }}>
                    {fields.map((f,i) => <FieldChip field={f} index={i} key={f.path}
                        isSelected={selected && selected.path === f.path}
                        onSelect={selectable ? () => dispatch(selectField(i)) : null}
                        onMove={(from, to) => dispatch(moveField({from,to}))}
                        onRemove={() => dispatch(removeField(f))}
                        label={labels[f.path]}
                        onEditLabel={v => dispatch(renameField({field: f, label: v}))}
                    />)}
                </Box>
            </Grid>
            <Fab color="primary" onClick={open} size="small">
                <AddIcon />
            </Fab>
            <FieldDialog isOpen={isOpen} onClose={handleClose}/>
        </>
    );
};

export default FieldGrid;
