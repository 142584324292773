import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Box from '@mui/material/Box';
import RestoreIcon from '@mui/icons-material/Restore';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Page from '../util/Page';
import ToolBar from '../util/ToolBar';
import AggPanel from './AggPanel';
import QueryPanel from './QueryPanel';
import ResultPanel from './ResultPanel';
import SearchMap from './SearchMap';
import {initSearch, runAggs, runSearch} from './state';
import SearchesDialog from "./SearchesDialog";
import SavedSearchIcon from "@mui/icons-material/SavedSearch";
import {throttle} from 'lodash';


const GeoFilter = (factory, deps) => {
  const dispatch = useDispatch();
  const geoFilter = useSelector(state => state.search.query.geo);

  const search = useMemo(() => throttle(() => {
      dispatch(runSearch());
  }, 1000), [dispatch]);

  useEffect(() => {
    if (geoFilter.enabled) search();
  }, [geoFilter, search]);
  return null;
}

const SearchPage = () => {
  const dispatch = useDispatch();
  const [showSavedSearchDialog, setShowSavedSearchDialog] = useState(false);

  useEffect(() => {
    dispatch(initSearch())
        .unwrap()
        .then(() => dispatch(runAggs()));
  }, [dispatch]);

  const handleReset = () => {
  };

  return (
    <Page className="SearchPage" sx={{flexGrow: 1}}>
      <ToolBar title="Find a Comp" label={"Beta"}>
        <Tooltip title="Saved Searches">
          <IconButton onClick={() => setShowSavedSearchDialog(true)} size="large" className="SavedSearchButton">
            <SavedSearchIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Reset Search">
          <IconButton onClick={handleReset} size="large" className="ResetButton">
            <RestoreIcon />
          </IconButton>
        </Tooltip>
      </ToolBar>
      <Container maxWidth={false} sx={{display: "flex", flexDirection: "column", flexGrow: 1}}>
        <QueryPanel/>
        <Box display="flex" p={1} sx={{height:"100%"}}>
          <AggPanel/>
          <SearchMap/>
          <ResultPanel/>
          <GeoFilter/>
        </Box>
      </Container>
      {showSavedSearchDialog && <SearchesDialog open={showSavedSearchDialog} maxWidth="md"
                                                onClose={() => setShowSavedSearchDialog(false)}/>}
    </Page>
  );
}

export default SearchPage;
