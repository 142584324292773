import React from "react";
import {useTheme} from '@mui/material';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";


export const FormLabel = ({children, sx, ...props}) => {
  return <Typography variant="body2" sx={{
      fontStyle: "italic",
      fontSize: '0.9rem',
      ...sx
    }} {...props}>{children}</Typography>
}

export const FormRow = ({spacing, gutterBottom, alignment, verticalAlignment, children, sx, ...props}) => {
    const s = typeof spacing !== "undefined" ? spacing : 4;
    return (
      <Box p={s/2}>
        <Grid container spacing={s} sx={{
            /*m: 0,*/ width: '100%',
            // ...(gutterBottom === true && {mb: 2}),
            ...(alignment === 'center' && {justifyContent: 'center'}),
            ...(verticalAlignment === 'center' && {alignItems: 'center'}),
            ...sx
        }} {...props}>
            {children}
        </Grid>
      </Box>
    )
};

export const FormSection = ({label, help, action, children, ...props}) => {
    return (
        <Box sx={{mb:4}} {...props}>
            {label && <Grid container justifyContent="space-between" sx={{mb: 2}}>
                <Grid item sx={{flex:1}}>
                    <Typography>{label}</Typography>
                    {help && <Typography variant="caption" component="p">{help}</Typography> }
                </Grid>
                {action &&
                <Grid item>
                    {action}
                </Grid>}
            </Grid>}
            {children}
        </Box>
    );
};

export const Form = ({children, size, usePaper, sx, ...props}) => {
    const theme = useTheme();
    return (
        <Box sx={{
            margin: 'auto',
            maxWidth: {
              xs: 'initial',
              md: theme.breakpoints.values.md
            }
        }}>
            <form {...props}>
                {usePaper ?
                    <Paper sx={{p: 2}}>
                        {children}
                    </Paper> :
                    <>
                        {children}
                    </>
                }
            </form>
        </Box>
    );
}
