import React from "react";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {useDispatch} from 'react-redux';
import {addExpense, moveExpense, removeExpense, updateExpense} from '../state';
import ExpenseRow from "./ExpenseRow";
import {Field} from  "../../model/fields";


const ExpenseCalculator = ({comp}) => {
    const dispatch = useDispatch();
    const expenses = Field.FINANCIAL_EXPENSES.get(comp);

    const handleChange = (index, name) => (evt) => {
        let value = name !== "title" ? evt.floatValue : evt.target.value;
        dispatch(updateExpense({index, expense:{[name]: value}}));
    };
    
    return (
        <>
            {expenses.map((e, i) => (
                <ExpenseRow key={e.id} expense={e} index={i}
                            onChange={name => handleChange(i, name)}
                            onMove={(from,to) => dispatch(moveExpense({from,to}))}
                            onRemove={(e) => dispatch(removeExpense(e))}/>
            ))}
            <Grid container spacing={0}>
                <Grid item>
                    <Tooltip title="Add a new expense">
                        <IconButton onClick={() => dispatch(addExpense())} size="large">
                            <AddCircleOutlineIcon color="primary"/>
                        </IconButton>
                    </Tooltip>

                </Grid>
            </Grid>
        </>
    );
};

export default ExpenseCalculator;


