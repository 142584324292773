import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import ForwardIcon from '@mui/icons-material/Forward';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import ErrorAlert from '../util/ErrorAlert';
import SelectWithLabel from '../util/SelectWithLabel';
import Spinner from '../util/Spinner';
import {assignList, loadUsers,} from './state';


const AssignDialog = ({list, isOpen, onClose}) => {
  const me = useSelector(state => state.auth?.user?.email);
  const dispatch = useDispatch();
  const [isWorking, setWorking] = useState(true);
  const [error, setError] = useState();
  const [users, setUsers] = useState([]);
  const [target, setTarget] = useState("me");
  const [user, setUser] = useState("")

  useEffect(() => {
    dispatch(loadUsers())
        .unwrap()
        .then(setUsers)
        .finally(() => setWorking(false));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (target === 'me' ) setUser(me);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target]);

  const handleAssign = () => {
    setWorking(true);
    dispatch(assignList({list, email: user})).unwrap()
        .then(() => onClose(true))
        .catch(setError)
        .finally(() => {
          setWorking(false);
        });
  }

  return (
      <Dialog open={isOpen} fullWidth maxWidth={"sm"} onClose={onClose}>
        <DialogTitle>
          Assign List
        </DialogTitle>
        <DialogContent dividers>
          {error && <ErrorAlert error={error}/>}
          <Stack gap={2}>
            <Typography>
              Please choose a team member below to assign the list <em>{list.title}</em> to.
            </Typography>
            {users.length === 0 && <Spinner/>}
            {users.length > 0 && <>
              <FormControl>
                <RadioGroup defaultValue="me" value={target} onChange={(evt) => setTarget(evt.target.value)}>
                  <FormControlLabel value="me" control={<Radio />} label="Assign to Me" />
                  <FormControlLabel value="team" control={<Radio />} label="Assign to Team Member" />
                </RadioGroup>
              </FormControl>
              {target === "team" && <SelectWithLabel label="Team Member"
                 value={user} values={users.map(u => [u.email, `${u.first_name} ${u.last_name}`])}
                 onChange={e => setUser(e.target.value)} />}
            </>}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false)} disabled={isWorking}>
            Cancel
          </Button>
          <Button onClick={handleAssign} color="primary" variant="outlined" disabled={isWorking || !user}
                  startIcon={isWorking ? <CircularProgress size={24}/> : <ForwardIcon/>}>
            Assign
          </Button>
        </DialogActions>
      </Dialog>
  )
};

export default AssignDialog;
