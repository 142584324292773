import React from "react";
import {Helmet} from "react-helmet";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {fontAsObject} from "../config";
import {lookupFont, fontURL} from "../../model/fonts";


const TextPreview = ({font, background, withFamily}) => {
    const f = lookupFont(font.family);
    const style = {
        ...(background ? {backgroundColor: background} : null),
        ...fontAsObject(font)
    }
    if (withFamily) {
        style.fontFamily = withFamily;
    }
    return (
        <>
            <Helmet>
                <link rel="stylesheet" href={fontURL(f)}/>
            </Helmet>
            <Box style={style} sx={{py: 0.5, px: 1}}>
                This is a preview of the text.
            </Box>
            {f.type === "excel" && <Typography variant="subtitle2" sx={{
                fontSize: "0.65rem",
                marginTop: 0.5
            }}>
                You have chosen a Microsoft font, this preview is an approximation of it.
            </Typography>}
        </>

    )
};

export default TextPreview;
