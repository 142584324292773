import React from "react";
import ReportBuilder from "./builder/ReportBuilder";


const ConfigStep = () => {
    return (
        <ReportBuilder />
    );
};

export default ConfigStep;


