import React from "react";
import {useDispatch} from 'react-redux';
import {NumericFormat} from "react-number-format";
import parse from 'html-react-parser';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import {FieldLabel} from "../../model/fields";
import {setField} from '../state';

const AreaField = ({field, comp, showConversion, ...props}) => {
    const dispatch = useDispatch();

    const handleValueChange = !props.disabled ? values => {
        dispatch(setField({field, value: values.floatValue}));
    } : () => {};

    const value = field.get(comp);
    let convertText = null;
    if (showConversion !== false && value) {
        const [convertedValue, convertedUnit] = field.convert(comp);
        convertText = convertedValue + " " + convertedUnit.symbol;
    }

    return (
        <NumericFormat label={field.label(FieldLabel.FORM)} value={value} onValueChange={handleValueChange} variant="outlined"
              name={field.path}
              helperText={convertText ? parse(convertText) : null}
              // helperText={convertText}
              thousandSeparator={true}
              fullWidth
              InputProps={{
                  endAdornment: <InputAdornment position="end">{parse(field.unit(comp).symbol)}</InputAdornment>
              }}
              customInput={TextField}
              {...props}
        />
    );
}

export default AreaField;
