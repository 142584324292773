import React, {Fragment, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Prompt} from "react-router";
import {useParams, useHistory, useLocation} from "react-router-dom"
import {styled} from '@mui/material';
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import MUIDivider from '@mui/material/Divider';
import SaveIcon from "@mui/icons-material/Save";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorAlert from '../util/ErrorAlert';
import Spinner from "../util/Spinner";
import Page from "../util/Page";
import ToolBar from "../util/ToolBar";
import CompEditor from "./editor/CompEditor";
import CartButton from "../cart/CartButton";
import {cloneComp, deleteComp, loadComp, saveComp, setField, setPreview} from './state';
import {TypeAvatarWithStatus} from "./TypeAvatar";
import {Field} from  "../model/fields";
import {propertyNoun} from "../model/propertyTypes";
import TemplateChooser from './TemplateChooser';


const Divider = styled(MUIDivider)(({theme}) => ({
    margin: theme.spacing(0, 1),
    height: theme.spacing(4),
    // alignSelf: "stretch"

}));

const CompPage = () => {
    let {id} = useParams();
    let history = useHistory();
    let location = useLocation();
    const comp = useSelector(state => state.comp.curr);
    const isDirty = useSelector(state => state.comp.dirty.length > 0);
    const dispatch = useDispatch();
    const [error, setError] = useState(null);

    React.useEffect(() => {
        dispatch(loadComp(id));
    }, [id, dispatch]);

    React.useEffect(() => {
        const params = new URLSearchParams(location.search);
        dispatch(setPreview(params.get('preview') === 'true'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const [showConfirmDelete, setShowConfirmDelete] = React.useState(false);
    const handleDelete = () => {
        setShowConfirmDelete(true);
    };
    const finishDelete = (confirmed) => {
        setShowConfirmDelete(false);
        if (confirmed) {
            dispatch(deleteComp({compId:id, comp})).unwrap().then(() => {
                // history.replace("/");
                history.goBack();
            })
        }
    };

    const [showClone, setShowClone] = React.useState(false);
    const [cloneAsResale, setCloneAsResale] = React.useState(true);

    const handleCopy = () => {
        setShowClone(true);
    };
    const finishCopy = (confirmed) => {
        setShowClone(false);
        if (confirmed) {
            dispatch(cloneComp({comp, cloneAsResale})).unwrap().then(({ref}) => {
                history.replace(`/comp/${ref.id}`);
            })
        }
    };

    const handleSave = () => {
        setError(null);
        dispatch(saveComp({id, comp}))
          .unwrap()
          .catch(setError);
    };

    const handleTitleChange = t => {
        dispatch(setField({field: Field.TITLE, value: t}));
    };

    return (
        <Page fluid={true} className="CompPage">
            {
                comp ?
                    <Fragment>
                        <Prompt when={isDirty} message="This comp has unsaved changes, are you sure you want to leave the page?"/>
                        <ToolBar title={comp.title} titleEditable={true} onTitleChange={handleTitleChange} titleEditTooltip="Edit Comp Title"
                                 defaultTitle={`${Field.ADDRESS_STREET.get(comp)}`}
                                 avatar={<TypeAvatarWithStatus comp={comp}/>}>
                            <TemplateChooser comp={comp}/>
                            <Divider orientation="vertical"/>
                            <Tooltip title="Save Changes">
                                <IconButton onClick={handleSave} size="large">
                                    <SaveIcon />
                                </IconButton>
                            </Tooltip>
                            <CartButton comp={comp}/>
                            <Tooltip title="Clone this Comp">
                                <IconButton onClick={handleCopy} size="large">
                                    <FileCopyIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete this Comp">
                                <IconButton onClick={handleDelete} size="large" className="DeleteButton">
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </ToolBar>
                        <Container maxWidth={false}>
                            {error && <ErrorAlert error={error} sx={{mb:1}}/>}
                            <CompEditor comp={comp}/>
                        </Container>
                        <Dialog open={showConfirmDelete} className="DeleteDialog" onClose={finishDelete}
                        >
                            <DialogTitle>{"Delete " + comp.title + "?"}</DialogTitle>
                            <DialogContent dividers>
                                <DialogContentText>
                                    Are you sure you want to delete this comparable? This action is permanent and can't be undone.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => finishDelete(true)} color="primary" className="ProceedButton">
                                    Proceed
                                </Button>
                                <Button onClick={() => finishDelete(false)} color="primary" autoFocus>
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={showClone}
                            onClose={finishCopy}
                        >
                            <DialogTitle>{"Clone " + comp.title + "?"}</DialogTitle>
                            <DialogContent dividers>
                                <DialogContentText>
                                    {`Cloning a comp will create a copy of it in the database. You can clone it as a Re-${propertyNoun(comp.type)} of the original, which will allow you to track ${propertyNoun(comp.type)}s of the property over time.`}
                                </DialogContentText>
                                <FormControlLabel
                                    control={<Checkbox checked={cloneAsResale} onChange={() => setCloneAsResale(!cloneAsResale)} name="cloneAsResale" />}
                                    label={`Clone as Re-${propertyNoun(comp.type)}`}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => finishCopy(true)} color="primary" className="ProceedButton">
                                    Proceed
                                </Button>
                                <Button onClick={() => finishCopy(false)} color="primary" autoFocus>
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Fragment>
                    : <Spinner/>
            }
        </Page>
    );
};

export default CompPage;
