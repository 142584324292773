export default class PhotoPicker {
    constructor(photos) {
        this.photos = (photos||[]).slice();
    }

    pick(tags, category, orientation) {
        tags = tags || [];
        if (this.photos.length > 0) {
            const filters = [];
            if (category) {
                filters.push(p => category === p.category);
            }
            if (orientation) {
                filters.push(p => orientation === p.orientation);
            }
            if (tags.length > 0) {
                filters.push(p => tags.every(t => (p.tags||[]).includes(t)));
            }

            let i = -1;
            if (filters.length > 0) {
                i = this.photos.findIndex(p => filters.every(f => f(p)));
            }
            else {
                i = this.photos.findIndex(p => (!p.tags || p.tags.length === 0) && !p.category && !p.orientation);
                if (i < 0) {
                    i = 0;
                }
            }

            if (i > -1) {
                return this.photos.splice(i, 1)[0].url;
            }
        }
        return null;
    }
}
