import React, {useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ReportCard from "../report/ReportCard";
import {loadRecentReports} from './state';


const ReportGrid = () => {
    const reports = useSelector(state => state.dash.reports||[]);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadRecentReports());
    }, [dispatch]);

    return (
        <Grid container spacing={1}>
            {reports.length === 0 &&
                <Grid item>
                    <Typography variant="subtitle2">No reports have been created. Start by adding a comp to the cart.</Typography>
                </Grid>
            }
            {reports.map((rep, i) =>
                <Grid item key={i} xs={12} md={8}>
                    <ReportCard report={rep}/>
                </Grid>
            )}
        </Grid>
    );
};

export default ReportGrid;

