import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Box from '@mui/material/Box';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import ListIcon from '@mui/icons-material/List';
import ReorderIcon from '@mui/icons-material/Reorder';
import {SheetContent} from '../../report/content/Sheet';
import {SingleTableContent} from '../../report/content/SingleTable';
import {SummaryContent} from '../../report/content/Summary';
import {TabPanel, TabSideBar} from '../../util/Tab';
import {VIEW_COMP_TABLE, VIEW_DETAIL_SHEET, VIEW_SUMMARY_TABLE, viewTitle} from '../../model/reportTypes';
import {setPreviewView} from '../state';


const SummaryTablePanel = ({comp, template, ...props}) => {
  return (<TabPanel {...props}>
    <SummaryContent comps={[comp]} config={template.summary} options={{}}/>
  </TabPanel>)
}

const DetailSheetPanel = ({comp, template, ...props}) => {
  return (<TabPanel {...props}>
    <SheetContent comp={comp} config={template.detail} options={{}}/>
  </TabPanel>)
}

const CompTablePanel = ({comp, template, ...props}) => {
  return (<TabPanel {...props}>
    <SingleTableContent comps={[comp]} config={template.single} options={{}}/>
  </TabPanel>)
}


const PreviewPanel = ({comp, ...props}) => {
  const dispatch = useDispatch();
  const templates = useSelector(state => state.report.templates.all);
  const template = useSelector(state => state.report.templates.curr);
  const config = useSelector(state => state.report.curr);
  const view = useSelector(state => state.comp.preview.view);

  return (
    <TabPanel {...props} className="PreviewPanel">
      {templates && templates.length && template && <>
        <Box>
          <TabSideBar activeTab={view} onTabChange={v => dispatch(setPreviewView(v))} tabs={[
            [{label: viewTitle(VIEW_DETAIL_SHEET), icon: <ArtTrackIcon/>},
              (p) => <DetailSheetPanel comp={comp} template={config} {...p} />],
            [{label: viewTitle(VIEW_SUMMARY_TABLE), icon: <ReorderIcon/>},
              (p) => <SummaryTablePanel comp={comp} template={config} {...p} />],
            [{label: viewTitle(VIEW_COMP_TABLE), icon: <ListIcon/>},
              (p) => <CompTablePanel comp={comp} template={config} {...p} />],
          ]}/>
        </Box>
        </>}

    </TabPanel>
  );
}

export default PreviewPanel;
