import React from "react";
import {useDispatch} from "react-redux";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import ListAltIcon from "@mui/icons-material/ListAlt";
import CodeIcon from "@mui/icons-material/Code";
import Grid from "@mui/material/Grid";
import Page from "../util/Page";
import ToolBar from "../util/ToolBar";
import {FormRow, FormSection} from "../util/Form";
import {exportFieldsAsCSV, exportFieldsAsJSON} from './state';

const MasterPage = () => {
    const dispatch = useDispatch();
    const handleExportCSV = () => {
        dispatch(exportFieldsAsCSV()).then(url => {
            const link = document.createElement("a");
            link.setAttribute("href", url);
            link.setAttribute("download", "fields.csv.b64");
            document.body.appendChild(link);
            link.click();
        });
    };
    const handleExportJSON = () => {
        dispatch(exportFieldsAsJSON()).then(url => {
            const link = document.createElement("a");
            link.setAttribute("href", url);
            link.setAttribute("download", "fields.json.b64");
            document.body.appendChild(link);
            link.click();
        });
    };

    return (
      <Page>
          <ToolBar title="Master">
          </ToolBar>
          <Container fixed>
              <FormSection label="Export Fields" help="Exports fields in CSV and JSON format.">
                  <FormRow>
                      <Grid item>
                          <Button
                              variant="contained"
                              color="primary"
                              size="large"
                              onClick={handleExportCSV}
                              startIcon={<ListAltIcon />}
                          >
                              Export CSV
                          </Button>
                      </Grid>
                      <Grid item>
                          <Button
                              variant="contained"
                              color="primary"
                              size="large"
                              onClick={handleExportJSON}
                              startIcon={<CodeIcon />}
                          >
                              Export JSON
                          </Button>
                      </Grid>
                  </FormRow>
              </FormSection>

          </Container>
      </Page>
    )
};

export default MasterPage;
