import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {TabPanel} from '../../util/Tab';
import {togglePreview} from '../state';
import PreviewPanel from './PreviewPanel';


const CompEditorPanel = ({comp, name, extra, children, ...props}) => {
  const showPreview = useSelector(state => state.comp.preview.show);
  const dispatch = useDispatch()

  return (
    <TabPanel {...props} className={name}>
      <form>
        <Box mb={2}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Fab color={showPreview?"primary":"default"} onClick={() => dispatch(togglePreview())} size="small">
                <VisibilityIcon/>
              </Fab>
            </Grid>
            <Grid item>
              {!showPreview && extra}
            </Grid>
          </Grid>
        </Box>
        {showPreview && <PreviewPanel comp={comp}/>}
        {!showPreview && children}
      </form>
    </TabPanel>
  );
};

export default CompEditorPanel;
