import React from "react";
import {useDispatch} from 'react-redux';
import {useHistory} from "react-router";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import InputIcon from '@mui/icons-material/Input';
import {FormRow} from "../util/Form";
import ErrorAlert from "../util/ErrorAlert";
import {login} from './state';


const LoginForm = () => {
    const [email, setEmail] = React.useState("");
    const [pass, setPass] = React.useState("");
    const [error, setError] = React.useState(null);
    const [isLoggingIn, setIsLoggingIn] = React.useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const handleLogin = () => {
        setError(null);
        setIsLoggingIn(true);
        dispatch(login({email, pass}))
          .unwrap()
          .then(() => {
            history.replace("/");
          })
          .catch((err) => {
            setError(err);
          })
          .finally(() => setIsLoggingIn(false))
    };

    return (
      <form>
          <FormRow>
              <Grid item xs={12}>
                  <Typography variant="h5" sx={{my:4, textAlign:'center'}}>
                      Please log in to your QuickComp account.
                  </Typography>
              </Grid>
          </FormRow>
          <Paper sx={{py: 8, px: 4}}>
              <Grid container spacing={4}>
                  <Grid item xs={12}>
                      <TextField name="email" label="Email Address" variant="outlined" value={email}
                                 onChange={(e) => setEmail(e.target.value.trim())}
                                 fullWidth autoFocus
                                 InputProps={{
                                     endAdornment: (
                                         <InputAdornment position="end">
                                             <EmailIcon/>
                                         </InputAdornment>
                                     )
                                 }}
                      />
                  </Grid>
                <Grid item xs={12}>
                  <TextField name="passwd" label="Password" type="password" variant="outlined" value={pass}
                             onChange={(e) => setPass(e.target.value.trim())}
                             fullWidth
                             InputProps={{
                               endAdornment: (
                                 <InputAdornment position="end">
                                   <LockIcon/>
                                 </InputAdornment>
                               )
                             }}
                  />
                </Grid>
                <Grid item xs={12} sx={{textAlign: 'center'}}>
                  <Button
                    id="login"
                    disabled={isLoggingIn || !(email && pass)}
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={isLoggingIn ? <CircularProgress/> : <InputIcon />}
                    onClick={handleLogin}
                  >
                    Login
                  </Button>
                </Grid>
              </Grid>
              {error && <ErrorAlert title="Login Error" error={error} sx={{my:2}}/>}
              <Typography variant="h6" sx={{mt:2}}>
                  <Link href="/password">Forgot Password?</Link>
              </Typography>
          </Paper>
      </form>
    );
};

export default LoginForm;

