import React, {useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useDrag, useDrop} from "react-dnd";
import Grid from "@mui/material/Grid";
import {TextField} from "@mui/material";
import Fab from "@mui/material/Fab";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import MenuIcon from '@mui/icons-material/Menu';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {hoverXY} from "../../util/dnd";
import {addAdjust, moveAdjust, removeAdjust, updateAdjust} from '../state';

const DRAG_TYPE_ADJUST = "adjust";

const Adjust = ({adjust, index, ...props}) => {
    const dispatch = useDispatch();
    const ref = useRef(null);

    const [, drop] = useDrop({
        accept: DRAG_TYPE_ADJUST,
        hover(item, monitor) {
            hoverXY(ref, index, item, monitor, (from, to) => dispatch(moveAdjust({from,to})));
        }
    });
    const [{ isDragging }, drag] = useDrag({
        item: { type: DRAG_TYPE_ADJUST, id: adjust.id, index },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    return (
        <Grid item sx={{display: "flex", alignItems: "center"}} {...props} style={{opacity}} ref={ref}>
            <TextField value={adjust.name} variant="outlined" size="small"
                       onChange={evt => dispatch(updateAdjust({id: adjust.id, key: "name", value:evt.target.value}))}/>
            <Tooltip title="Remove Column">
                <IconButton onClick={() => dispatch(removeAdjust(index))} size="small">
                    <HighlightOffIcon color="secondary" fontSize="small"/>
                </IconButton>
            </Tooltip>
            <Tooltip title="Reorder Column">
                <IconButton size="small">
                    <MenuIcon fontSize="small"/>
                </IconButton>
            </Tooltip>
        </Grid>
    )
}

const AdjustGrid = () => {
    const sheet = useSelector(state => state.sheet.curr);
    const dispatch = useDispatch();
    return (
        <>
            <Grid container spacing={1} sx={{mb: 1}}>
                {sheet.adjusts.columns.map((a,i) => <Adjust adjust={a} index={i} key={a.id}/> )}
            </Grid>
            <Fab color="primary" size="small" onClick={() => dispatch(addAdjust())}>
                <AddIcon />
            </Fab>
        </>
    )
};

export default AdjustGrid;
