import React from "react";
import {useDispatch, useSelector} from "react-redux";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {updateConfig} from './state'

const TitleStep = () => {
    const title = useSelector(state => state.report.curr.title);
    const dispatch = useDispatch();
    return (
        <Box>
            <TextField value={title} name="report_title" variant="outlined" fullWidth
                       onChange={e => dispatch(updateConfig({path:"title", value:e.target.value}))} />
        </Box>
    );
};

export default TitleStep;

