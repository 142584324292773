import Checkbox from '@mui/material/Checkbox';
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Stack, useTheme} from '@mui/material';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Container from "@mui/material/Container";
import TextField from '@mui/material/TextField';
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Box from "@mui/material/Box";
import ListAltIcon from '@mui/icons-material/ListAlt';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import {TabPanel} from "../../util/Tab";
import RadioGroup from "../../util/HorizontalRadioGroup";
import {FormSection} from "../../util/Form";
import ClickPaper from "../../util/ClickPaper";
import Instruction from "./Instruction";
import {updateAllFonts, updateConfig} from '../state';
import { TYPE_SINGLE, TYPE_SUMMARY_DETAIL } from "../../model/reportTypes";
import HelpDialog from '../../comp/editor/HelpDialog';
import IconButton from '@mui/material/IconButton';
import HelpIcon from '@mui/icons-material/Help';
import {FontFamilySelect} from '../../util/FontControl';
import Button from '@mui/material/Button';
import {pushSnack} from '../../app/state';
import {lookupFont} from '../../model/fonts';

const GeneralPanel = ({...props}) => {
    const config = useSelector(state => state.report.curr);
    const dispatch = useDispatch();
    const theme = useTheme();
    const [showForceWebFontsHelp, setShowForceWebFontsHelp] = useState(false);
    const [showUpdateAllFontsHelp, setShowUpdateAllFontsHelp] = useState(false);
    const styles = {
        paper: {
            height: theme.spacing(8),
            width: theme.spacing(8),
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mr: 2
        },
        label: {
            mr: 0
        },
        icon: {
            fontSize: theme.spacing(6)
        }
    }

    const updateType = t => () => dispatch(updateConfig({path:"type", value:t}));

    const doUpdateAllFonts = () => {
        const f = config.style.defaultFont.family;
        dispatch(updateAllFonts({key: 'family', value: f}));
        dispatch(pushSnack({message: `All fonts updated to ${lookupFont(f).name}`}));
    }
    
    return (
        <TabPanel {...props}>
            <Instruction>General report settings.</Instruction>
            <Container maxWidth="md">
                <FormSection label="Report Layout" help="The type of the report to generate.">
                    <FormControl component="fieldset">
                        <RadioGroup name="type" value={config.type} sx={{display: "block"}}
                                    onChange={evt => updateType(evt.target.value)()}>
                            <Box display="flex" alignItems="center" mb={1}>
                                <FormControlLabel value={TYPE_SUMMARY_DETAIL} control={<Radio />} sx={styles.label}/>
                                <ClickPaper onClick={updateType(TYPE_SUMMARY_DETAIL)} sx={styles.paper}>
                                    <ArtTrackIcon sx={styles.icon}/>
                                </ClickPaper>
                                <Box>
                                    <Typography>Summary/Detail</Typography>
                                    <FormHelperText>A multi page in portrait layout containing a single summary table, followed by a detail sheet for each comp.</FormHelperText>
                                </Box>
                            </Box>
                            <Box display="flex" alignItems="center" mb={1}>
                                <FormControlLabel value={TYPE_SINGLE} control={<Radio />} sx={styles.label}/>
                                <ClickPaper onClick={updateType(TYPE_SINGLE)} sx={styles.paper}>
                                    <ListAltIcon sx={styles.icon}/>
                                </ClickPaper>
                                <Box>
                                    <Typography>Single Page</Typography>
                                    <FormHelperText>A single page in landscape layout containing a table with comp details.</FormHelperText>
                                </Box>
                            </Box>
                        </RadioGroup>
                    </FormControl>
                </FormSection>
                <FormSection label="Map" help="Include a comp map in the report.">
                    <FormControlLabel
                        control={<Checkbox name="includeMap" checked={config.map.include}
                                           onChange={() => dispatch(updateConfig({path: "map.include", value: !config.map.include}))} />}
                        label={`Include a comp map in the report`}
                    />
                </FormSection>
                <FormSection label="Unit Display" help="Switch between Imperial and Metric units in the report.">
                    <FormControl component="fieldset">
                        <RadioGroup name="metric" value={config.options.metric}
                                    onChange={evt => dispatch(updateConfig({path:"options.metric", value:evt.target.value === "true"}))}>
                            <FormControlLabel value={false} control={<Radio />} label="Imperial" />
                            <FormControlLabel value={true} control={<Radio />} label="Metric" />
                        </RadioGroup>
                    </FormControl>
                </FormSection>
                <FormSection label="Comp Numbering" help="Specify the starting number for comps in the report.">
                    <TextField value={config.options.offset} placeholder="1" type="number" size="small"
                               onChange={evt => dispatch(updateConfig({
                                   path:"options.offset",
                                   value:(evt.target.value ? parseInt(evt.target.value) : null),
                                   dirty:false
                               }))} variant="outlined" />
                </FormSection>
                <FormSection label="Empty Fields" help="Control how empty fields are presented in the report.">
                  <TextField value={config.options.emptyField} onChange={evt => dispatch(updateConfig({
                    path: "options.emptyField", value:evt.target.value
                  }))} size="small" helperText="Specifying no value will show the field as blank"/>
                  <Box mt={1}>
                    <FormControlLabel
                      control={<Checkbox name="zeroAsEmpty"  checked={config.options.zeroAsEmpty}
                                         onChange={() => dispatch(updateConfig({path: "options.zeroAsEmpty", value: !config.options.zeroAsEmpty}))} />}
                      label={`Treat zero values as empty`}
                    />
                  </Box>
                </FormSection>
                <FormSection label="Fonts" help="Control how fonts are managed in the report.">
                    <Box py={1}>
                        <Grid container spacing={1} alignItems="flex-start">
                            <Grid item md={4}>
                                <FontFamilySelect size="small" name="Fallback Font" helperText="Fallback font used for reports." value={config.style.defaultFont.family}
                                    onChange={evt => dispatch(updateConfig({path: "style.defaultFont.family", value: evt.target.value}))}/>
                            </Grid>
                            <Grid item >
                                <Stack direction="row">
                                    <Button onClick={doUpdateAllFonts}>Update All Fonts</Button>
                                    <IconButton onClick={() => setShowUpdateAllFontsHelp(true)}>
                                        <HelpIcon />
                                    </IconButton>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item md={8}>
                                <Stack direction="row" mt={1}>
                                    <FormControlLabel
                                        control={<Checkbox name="forceWebFonts" checked={config.options.forceWebFonts === true}
                                                           onChange={() => dispatch(updateConfig({path: "options.forceWebFonts", value: !config.options.forceWebFonts}))} />}
                                        label={`Force the use of Google fonts in Microsoft Word`}
                                    />
                                    <IconButton onClick={() => setShowForceWebFontsHelp(true)}>
                                        <HelpIcon />
                                    </IconButton>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                </FormSection>
                <FormSection label="Page Setup" help="Configure page settings for Microsoft Word documents.">
                    <Typography variant="body2" sx={{fontStyle: 'italic'}}>Margins</Typography>
                    <Box py={1}>
                      <Grid container spacing={1}>
                        <Grid item md={3}>
                          <TextField value={config.options.margin.top} placeholder="1" type="number" label="Top"
                                     size="small" variant="outlined" onChange={evt => dispatch(updateConfig({
                            path:"options.margin.top",
                            value:(parseFloat(evt.target.value||0))
                          }))} inputProps={{
                            step: 0.1,
                          }} InputProps={{
                            endAdornment: <InputAdornment position="end">inches</InputAdornment>,
                          }}/>
                        </Grid>
                        <Grid item md={3}>
                          <TextField value={config.options.margin.bottom} placeholder="1" type="number" label="Bottom"
                                     size="small" variant="outlined" onChange={evt => dispatch(updateConfig({
                            path:"options.margin.bottom",
                            value:(parseFloat(evt.target.value||0))
                          }))} inputProps={{
                            step: 0.1,
                          }} InputProps={{
                            endAdornment: <InputAdornment position="end">inches</InputAdornment>,
                          }}/>
                        </Grid>
                        <Grid item md={3}>
                          <TextField value={config.options.margin.left} placeholder="1" type="number" label="Left"
                                     size="small" variant="outlined" onChange={evt => dispatch(updateConfig({
                            path:"options.margin.left",
                            value:(parseFloat(evt.target.value||0))
                          }))} inputProps={{
                            step: 0.1,
                          }} InputProps={{
                            endAdornment: <InputAdornment position="end">inches</InputAdornment>,
                          }}/>
                        </Grid>
                        <Grid item md={3}>
                          <TextField value={config.options.margin.right} placeholder="1" type="number" label="Right"
                                     size="small" variant="outlined" onChange={evt => dispatch(updateConfig({
                            path:"options.margin.right",
                            value:(parseFloat(evt.target.value||0))
                          }))} inputProps={{
                            step: 0.1,
                          }} InputProps={{
                            endAdornment: <InputAdornment position="end">inches</InputAdornment>,
                          }}/>
                        </Grid>
                      </Grid>
                    </Box>
                </FormSection>
                <HelpDialog title="Force Google Fonts" open={showForceWebFontsHelp} onClose={() => setShowForceWebFontsHelp(false)}>
                    <Typography>
                        QuickComps reports offer the use of two types of fonts: Google Fonts and Microsoft Fonts. Google Fonts are designed
                        to be use for creating reports exported as <em>Word Document with Images</em>. Microsoft Fonts are designed
                        to be used for creating reports exported as <em>Word Document with Tables</em>.
                    </Typography>
                    <Typography>
                        Because Google Fonts are not installed on most computers, QuickComp converts a Google Font to the most similar Microsoft
                        font when exporting to Word with Tables. Similarly, because Microsoft Fonts are proprietary, QuickComp converts a Microsoft
                        Font to a Google Font when exporting to Word with Images.
                    </Typography>
                    <Typography>
                        This option forces QuickComp to forgo the conversion to Microsoft Fonts when exporting to Word with Tables. This only
                        works if you have manually installed the Google Font on the computer running Microsoft Word.
                    </Typography>

                </HelpDialog>
                <HelpDialog title="Update All Fonts" open={showUpdateAllFontsHelp} onClose={() => setShowUpdateAllFontsHelp(false)}>
                    <Typography>
                        This option is used to update all fonts in the report to the selected fallback font. This is useful to batch update fonts for the
                        summary table, detail sheet, etc... in one action.
                    </Typography>
                </HelpDialog>
            </Container>
        </TabPanel>
    );
};

export default GeneralPanel;
