import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {sendPasswordResetEmail, sendSignInLinkToEmail, signInWithEmailAndPassword, getAuth, signOut} from 'firebase/auth';
import {collection, getDocs, getFirestore, query, where} from 'firebase/firestore';
import {getFunctions, httpsCallable} from 'firebase/functions';
import {APP_URL} from '../app/constants';
import {ROLE_ADMIN, ROLE_EDITOR, ROLE_OWNER} from './roles';

export const login = createAsyncThunk(
  'auth/login',
  async ({email, pass}, {rejectWithValue}) => {
    try {
      return await signInWithEmailAndPassword(getAuth(), email, pass);
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const logout = createAsyncThunk(
  'auth/logout',
  async (args, {rejectWithValue}) => {
    try {
      await signOut(getAuth());

    }
    catch(error) {
      return rejectWithValue(error);
    }
  }
)

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (email, {rejectWithValue}) => {
    try {
      await sendPasswordResetEmail(getAuth(), email, {
        url: `${APP_URL}/login`
      });
    }
    catch(error) {
      return rejectWithValue(error);
    }
  }
);

export const inviteUser = createAsyncThunk(
  'auth/inviteUser',
  async (user, {getState, rejectWithValue}) => {
    const org = getState().auth.org.id;
    try {
      const result = await getDocs(query(
        collection(getFirestore(), 'orgs', org, 'users'),
        where('email', '==', user.email)
      ));

      if (result.docs.length > 0) {
        return rejectWithValue({
          "title": "User already exists",
          "message": `An account with the email address ${user.email} already exists in your organization.`
        })
      }

      const r = await httpsCallable(getFunctions(), 'createInvite')({org, ...user});
      if (r.data.status === 'success') {
        await sendSignInLinkToEmail(getAuth(), user.email, {
          url: `${APP_URL}/join?invite=${r.data.token}`,
          handleCodeInApp: true
        })
      }
      else {
        return rejectWithValue(r);
      }
    }
    catch(error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchInvite = createAsyncThunk(
  'auth/fetchInvite',
  async (token, {rejectWithValue}) => {
    try {
      const r = await httpsCallable(getFunctions(), 'fetchInvite')({token});
      if (r.data.status === "success") {
        return r.data.invite;
      }
      else {
        return rejectWithValue(r);
      }
    }
    catch(error) {
      return rejectWithValue(error);
    }
  }
);

export const cancelInvite = createAsyncThunk(
  'auth/cancelInvite',
  async (email, {getState, rejectWithValue}) => {
    const org = getState().auth.org.id;
    try {
      const r = await httpsCallable(getFunctions(), 'cancelInvite')({email, org});
      if (r.data.status !== 'success') return rejectWithValue(r);
      return r.data;
    }
    catch(error) {
      return rejectWithValue(error);
    }
  }
);

export const acceptInvite = createAsyncThunk(
  'auth/acceptInvite',
  async (invite, {rejectWithValue}) => {
    try {
       const r = await httpsCallable(getFunctions(), 'acceptInvite')({...invite});
       if (r.data.status !== 'success') return rejectWithValue(r);
       return r.data;
    }
    catch(error) {
      return rejectWithValue(error);
    }
  }
);

export const grantRole = createAsyncThunk(
  'auth/grantRole',
  async ({email, role}, {getState, rejectWithValue}) => {
    const org = getState().auth.org.id;
    try {
      const r = await httpsCallable(getFunctions(), 'grantRole')({email, role, org});
      if (r.data.status !== 'success') return rejectWithValue(r);
      return r.data;
    }
    catch(error) {
      return rejectWithValue(error);
    }
  }
);

export const suspendUser = createAsyncThunk(
  'auth/suspendUser',
  async (email, {getState, rejectWithValue}) => {
    const org = getState().auth.org.id;
    try {
      const r = await httpsCallable(getFunctions(), 'suspendUser')({email, org});
      if (r.data.status !== 'success') return rejectWithValue(r);
      return r.data;
    }
    catch(error) {
      return rejectWithValue(error);
    }
  }
);

export const enableUser = createAsyncThunk(
  'auth/enableUser',
  async (email, {getState, rejectWithValue}) => {
    const org = getState().auth.org.id;
    try {
      const r = await httpsCallable(getFunctions(), 'enableUser')({email, org});
      if (r.data.status !== 'success') return rejectWithValue(r);
      return r.data;
    }
    catch(error) {
      return rejectWithValue(error);
    }
  }
);

const auth = createSlice({
  name: 'auth',
  initialState: {
    invite: null,
    roles: []
  },
  reducers: {
    authChange: (state, action) => {
      const claims = (action.payload.token ? action.payload.token.claims : null) || {};
      state.roles = claims.role ? [claims.role] :
        (claims.owner === true) ? [ROLE_OWNER] :
          (claims.admin === true) ? [ROLE_ADMIN] :
            [ROLE_EDITOR];
      state.user = action.payload.user;
      state.org = action.payload.org;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(login.fulfilled, (state, action) => {
      })
      .addCase(fetchInvite.fulfilled, (state, action) => {
        state.invite = action.payload;
      })
  }
});

export const {authChange} = auth.actions;

export default auth.reducer;
