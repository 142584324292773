import React from "react";
import {useHistory} from 'react-router';
import {Link} from "react-router-dom";
import AvatarGroup from '@mui/material/AvatarGroup';
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SettingsIcon from '@mui/icons-material/Settings';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {TypeAvatarWithStatus} from "./TypeAvatar";
import {UserAvatar, UserGroupAvatar} from "../util/UserAvatar";
import {fromNow} from "../util/utils";
import ImagePlaceholder from "../util/ImagePlaceholder";
import CartButton from "../cart/CartButton";
import {Field} from  "../model/fields";

const CompCard = ({comp, content}) => {
    const showContent = content !== false;
    const thumb = comp.photos && comp.photos.length > 0 ? comp.photos[0].url : comp.map;

    const styles = {
        media: {flexGrow: 1, minHeight: "150px"},
        icon: {minWidth: "32px"}
    }
    return (
        <Card elevation={1} className={`CompCard`} sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column"
        }}>
            <CardHeader title={comp.title} subheader={Field.COMP_ID.get(comp)}
                avatar={
                    <TypeAvatarWithStatus comp={comp}/>
                }
            />
            {thumb ? <CardMedia image={thumb} sx={styles.media}/>
                   : <ImagePlaceholder message={""} sx={styles.media}/>}
            { showContent &&
            <CardContent>
                <List dense={true}>
                    <ListItem disableGutters>
                        <ListItemIcon sx={styles.icon}>
                            <LocationOnIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={comp.address.city} secondary={comp.address.region}
                        />
                    </ListItem>
                    <ListItem disableGutters>
                        <ListItemIcon sx={styles.icon}>
                            <AccessTimeIcon />
                        </ListItemIcon>
                        <ListItemText secondary={`Updated ${fromNow(comp.updated)}`}
                        />
                    </ListItem>
                </List>
            </CardContent>
            }
            <CardActions sx={{flexDirection: "row-reverse", bgcolor: 'grey.100'}}>
                <IconButton
                    component={Link}
                    to={"/comp/" + comp.id}
                    className="SettingsLink"
                    size="large">
                    <SettingsIcon />
                </IconButton>
                <CartButton comp={comp}/>
                <UserGroupAvatar users={[comp.created_by, comp.updated_by]} sx={{
                  justifyContent: 'flex-end',
                  flexGrow: 1
                }}/>
            </CardActions>
        </Card>
    );
};

export default CompCard;

export const SimpleCompCard = ({comp}) => {
  const history = useHistory();

  const open = () => {
    history.push("/comp/" + comp.id);
  }

  return (
    <Card elevation={1} className={`CompCard`} sx={{
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "row"
    }}>
      <CardActionArea onClick={open}>
        <CardHeader title={comp.title} subheader={Field.ADDRESS_CITY.get(comp)}
              avatar={
                <AvatarGroup>
                  <TypeAvatarWithStatus comp={comp}/>
                  <UserAvatar user={comp.updated_by || comp.created_by}/>
                </AvatarGroup>

              }
              sx={{flexGrow:1}}
        />
      </CardActionArea>
      <CardActions sx={{flexDirection: "row-reverse", bgcolor: 'grey.100'}}>
        <CartButton comp={comp} />
      </CardActions>
    </Card>
  )
};
