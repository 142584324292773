import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SaveIcon from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import SaveSearchDialog from './SaveSearchDialog';
import {addToCart} from '../cart/state';
import {pushSnack} from '../app/state';

const ResultLabel = ({sx, ...props}) => {
  const dispatch = useDispatch();
  const result = useSelector( state => state.search.result);
  const isDirty = useSelector(state => state.search.query.dirty?.length > 0);
  const compsInCart = useSelector(state => state.cart.comps.map(c => c.id));
  const [menu, setMenu] = useState();
  const [showSaveSearchDialog, setShowSaveSearchDialog] = useState(false);

  const handleOpen = evt => {
    setMenu(evt.currentTarget);
  }

  const handleClose = evt => {
    setMenu(null);
  }

  const isCompInCart = (compId) => (compsInCart||[]).includes(compId);
  const handleAddAllToCart = () => {
    setMenu(null);
    dispatch(addToCart(result.hits.filter(comp => !isCompInCart(comp.id))));
  }

  const handleSaveSearch = (search) => {
    if (search) {
      dispatch(pushSnack({message: `Saved search ${search.name} has been created.`}));
    }
    setShowSaveSearchDialog(false);
  }

  const enableAddAllToCart = result.hits.length < 26;
  return (
    <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{...sx}} {...props}>
      {result.count === 0 && <Typography>Search found no results.</Typography>}
      {result.count > 0 && <Typography>Showing <em>{result.hits.length}</em> of <em>{result.count}</em> results.</Typography>}
      {result.count > 0 && (enableAddAllToCart || isDirty) && <>
        <IconButton size="small" onClick={handleOpen}>
          <ArrowDropDownIcon/>
        </IconButton>
        <Menu
          anchorEl={menu}
          // keepMounted
          open={Boolean(menu)}
          onClose={handleClose}
        >
          {enableAddAllToCart &&
            <MenuItem onClick={handleAddAllToCart}>
              <ListItemIcon>
                <AddShoppingCartIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit" noWrap>
                Add all to Cart
              </Typography>
            </MenuItem>
          }
          {isDirty &&
            <MenuItem onClick={() => setShowSaveSearchDialog(true)}>
              <ListItemIcon>
                <SaveIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit" noWrap>
                Save this Search
              </Typography>
            </MenuItem>
          }
        </Menu>
        </>}
        <SaveSearchDialog open={showSaveSearchDialog} onClose={handleSaveSearch}/>
    </Stack>
  )
}

export default ResultLabel;
