import React from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import store from "./state";
import { Firebase, FirebaseContext} from "./firebase";
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";

const root = createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <FirebaseContext.Provider value={new Firebase(store)}>
      <App/>
    </FirebaseContext.Provider>
  </Provider>
);
serviceWorker.unregister();
