import React from "react";
import {useTheme} from '@mui/material';
import Paper from "@mui/material/Paper";

const ClickPaper = ({children, disabled, sx, onClick, ...props}) => {
    const theme = useTheme();
    const handleClick = evt => {
        if (!disabled) onClick(evt)
    };

    return (
        <Paper variant="outlined" square sx={{
            position: "relative",
            cursor: "pointer",
            "&:hover": {
                boxShadow: theme.shadows[1]
            },
            ...(disabled === true && {color: 'text.disabled', cursor: 'default'}),
            ...sx
        }} {...props} onClick={handleClick}>
            {children}
        </Paper>
    )
};

export default ClickPaper;
