import React from "react";
import LinearProgress from "@mui/material/LinearProgress";

const UsageBar = ({value}) => {
    return (
        <LinearProgress value={Math.max(0, Math.min(100, value))} variant="determinate" sx={{
            ...((value > 75 && value < 90) && {
                bgcolor: 'yellow.200',
                "& > .MuiLinearProgress-bar": {
                    bgcolor: 'yellow.500'
                }
            }),
            ...(value > 90 && {
                bgcolor: 'red.200',
                "& > .MuiLinearProgress-bar": {
                    bgcolor: 'red.500'
                }
            })
        }} />
    );
};

export default UsageBar;
