const hover = (ref, index, item, monitor, onMove, check) => {
    if (!ref.current) return;

    const dragIndex = item.index;
    const hoverIndex = index;
    if (dragIndex === hoverIndex) return;

    const hoverBoundingRect = ref.current.getBoundingClientRect();
    const clientOffset = monitor.getClientOffset();
    const clientOffsetDiff = monitor.getDifferenceFromInitialOffset();
    if (!check(dragIndex, hoverIndex, clientOffset, hoverBoundingRect, clientOffsetDiff)) return;

    onMove(dragIndex, hoverIndex);

    item.index = hoverIndex
};

export const hoverX = (ref, index, item, monitor, onMove) => {
    hover(ref, index, item, monitor, onMove, (dragIndex, hoverIndex, clientOffset, hoverBoundingRect) => {
        const hoverMiddleX = (hoverBoundingRect.left - hoverBoundingRect.right) / 2;
        const hoverClientX = clientOffset.x - hoverBoundingRect.right;

        // Only perform the move when the mouse has crossed half of the items width
        // When dragging forwards, only move when the cursor is past 50%
        // When dragging backwards, only move when the cursor is before 50%
        // Dragging forwards
        if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
            return false;
        }
        // Dragging backwards
        if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
            return false;
        }
        return true;
    });
};

export const hoverY = (ref, index, item, monitor, onMove) => {
    hover(ref, index, item, monitor, onMove, (dragIndex, hoverIndex, clientOffset, hoverBoundingRect) => {
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return false;
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return false;
        }
        return true;
    });
};

export const hoverXY = (ref, index, item, monitor, onMove) => {
    return hover(ref, index, item, monitor, onMove, (dragIndex, hoverIndex, clientOffset, hoverBoundingRect, clientOffsetDiff) => {
        if (Math.abs(clientOffsetDiff.x) > Math.abs(clientOffsetDiff.y)) {
            const hoverMiddleX = (hoverBoundingRect.left - hoverBoundingRect.right) / 2;
            const hoverClientX = clientOffset.x - hoverBoundingRect.right;

            if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
                return false;
            }
            if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
                return false;
            }
        }
        else {
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return false;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return false;
            }
        }

        return true;
    });
};

export const pointInRect = (point, rect) => {
    return point.x >= rect.left && point.x <= rect.right && point.y >= rect.top && point.y <= rect.bottom;
}

export const overlapPositionY = (monitor, ref) => {
    const rect = ref.current.getBoundingClientRect();
    const point = monitor.getClientOffset();
    return (point.y < (rect.top + ((rect.bottom-rect.top)/2))) ? 'top' : 'bottom';
}
