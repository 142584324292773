import React from "react";
import Box from '@mui/material/Box';
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import EditableLabel from "./EditableLabel";
import {Stack} from '@mui/material';
import Chip from '@mui/material/Chip';


const ToolBar = ({title, label, avatar, breadcrumbs, alert, titleEditable, onTitleChange, titleEditTooltip, defaultTitle, children}) => {
    return (
        <Paper square elevation={0} sx={{
            display: "flex",
            py: 1,
            px: 2,
            mb: 2,
            "& > div": {
                alignSelf: "center"
            }
        }}>
            <Stack direction="row" alignItems="center" gap={1} sx={{width: '100%'}}>
                {avatar}
            {(title || breadcrumbs) &&
            <Box sx={{flexGrow:1, flexShrink:0}}>
                {title && titleEditable === true ?
                    <EditableLabel value={title} onChange={onTitleChange} tooltip={titleEditTooltip} defaultValue={defaultTitle}/> :
                    <Typography variant="h4">{title}</Typography>
                }
                {label && <Chip label={label} color="secondary" size="small" sx={{
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    borderRadius: 0.5
                }}/>}
                {breadcrumbs}
            </Box>}
            {alert && <Box sx={{flexGrow:1}}>
                {alert}
            </Box>}
                <Box display="flex" sx={{alignItems:'center'}}>
                    {children}
                </Box>
            </Stack>

        </Paper>
    )
};

export default ToolBar;
