import React, {useEffect, useState} from "react";
import {NumericFormat} from "react-number-format";
import {produce} from 'immer';
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import SvgIcon from "@mui/material/SvgIcon";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ToggleButton from "../util/ToggleButton";
import { ReactComponent as GreaterIcon } from '../util/greater.svg';
import { ReactComponent as LesserIcon } from '../util/lesser.svg';
import { ReactComponent as BetweenIcon } from '../util/between.svg';
import {NUMBER_BETWEEN, NUMBER_GREATER, NUMBER_LESSER} from "./constants";
import {FieldType} from "../model/fields";

const NumberRangeChooser = ({value, type, onChange, size, disabled, inputProps}) => {
    const [anchor, setAnchor] = useState(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (!value) {
            onChange({
                type: NUMBER_GREATER,
                range: ["", ""]
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = t => {
        onChange(produce(value, draft => {
            draft.type = t;
        }));
        setOpen(false);
    }

    const numberProps = type === FieldType.CURRENCY ?
        { thousandSeparator: true } :
        type === FieldType.PERCENT ? { decimalScale: 2, fixedDecimalScale: true } :
        {}

    if (!value) {
        return (null)
    }

    const isBetween = value.type === NUMBER_BETWEEN;
    const range = value.range || ["", ""];
    const sz = size || "small";
    return (
        <Grid container spacing={1} className="NumberRangeChooser">
            <Grid item>
                <ToggleButton
                    size={sz}
                    value="check"
                    selected={true}
                    onClick={(evt) => {
                        setAnchor(evt.target);
                        setOpen(!open)
                    }}
                >
                    {value.type === NUMBER_BETWEEN ? <SvgIcon component={BetweenIcon} /> :
                        value.type === NUMBER_GREATER ? <SvgIcon component={GreaterIcon}/> : <SvgIcon component={LesserIcon}/>}
                    <ArrowDropDownIcon/>
                </ToggleButton>
            </Grid>
            <Grid item className="NumberRangeChooserInput1">
                <NumericFormat value={range[0]}
                              onValueChange={v => onChange(
                                produce(value, draft => {
                                    draft.range.splice(0,1,v.floatValue);
                                })
                              )}
                              variant="outlined"
                              size={sz}
                              name="value1"
                              fullWidth
                              customInput={TextField}
                              disabled={disabled}
                              {...inputProps}
                              {...numberProps}
                />
            </Grid>
            {isBetween &&
            <>
                <Grid item sx={{
                    alignSelf: "center",
                    ...(disabled && {color: 'text.disabled'})
                }}>
                    AND
                </Grid>
                <Grid item className="NumberRangeChooserInput2">
                    <NumericFormat value={range[1]}
                                  onValueChange={v => onChange(
                                    produce(value, draft => {
                                        draft.range.splice(1,1,v.floatValue);
                                    })
                                  )}
                                  size={sz}
                                  variant="outlined"
                                  name="value2"
                                  fullWidth
                                  disabled={disabled}
                                  customInput={TextField}
                                  {...inputProps}
                                  {...numberProps}
                    />
                </Grid>
            </>
            }
            <Menu
                anchorEl={anchor}
                keepMounted
                open={open && Boolean(anchor)}
                onClose={() => {
                    setOpen(false);
                    setAnchor(null)
                }}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <MenuItem onClick={() => handleChange(NUMBER_LESSER)} className="NumberRangeChooserLesser">
                    <ListItemIcon>
                        <SvgIcon component={LesserIcon} />
                    </ListItemIcon>
                    <ListItemText>Less Than</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleChange(NUMBER_BETWEEN)} className="NumberRangeChooserBetween">
                    <ListItemIcon>
                        <SvgIcon component={BetweenIcon} />
                    </ListItemIcon>
                    <ListItemText>Between</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleChange(NUMBER_GREATER)} className="NumberRangeChooserGreater">
                    <ListItemIcon>
                        <SvgIcon component={GreaterIcon} />
                    </ListItemIcon>
                    <ListItemText>Greater Than</ListItemText>
                </MenuItem>
            </Menu>

        </Grid>
    );
};

export default NumberRangeChooser;
