import React, {Fragment} from "react";
import {useDispatch} from "react-redux";
import {styled} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import MToggleButton from '@mui/material/ToggleButton';
import {FormRow} from "../../util/Form";
import {setField} from '../state';
import CompEditorPanel from './CompEditorPanel';
import CompField from "./CompField";
import ExpenseCalculator from "./ExpenseCalculator";
import RevenueCalculator from "./RevenueCalculator";
import {ICI, MULTI_FAMILY, RESIDENTIAL_RENT} from "../../model/propertyTypes";
import {Field, VacationCollectionLossMode} from "../../model/fields";

const ToggleButton = styled(MToggleButton)({
    '&&': {
        width: "48px"
    }
});

const FinancialPanel = ({comp, highlight, ...props}) => {
    const fieldProps = {comp, highlight};

    const showExpenseCalc = Field.FINANCIAL_USE_EXPENSE_CALCULATOR.get(comp) === true;
    const hasOtherRevenue = Field.FINANCIAL_HAS_OTHER_REVENUE.get(comp) === true;

    return (
        <CompEditorPanel name="FinancialPanel" comp={comp} {...props}>
            {[ICI, MULTI_FAMILY].includes(comp.type) ?
                <Fragment>
                    <FormRow>
                        <Grid item md={6}>
                            <CompField field={Field.FINANCIAL_USE_EFFECTIVE_SALE_PRICE} {...fieldProps}/>
                        </Grid>
                    </FormRow>
                    <FormRow>
                        <Grid item md={6}>
                            <CompField field={Field.FINANCIAL_GROSS_BUILDABLE_AREA_PRICE} {...fieldProps}/>
                        </Grid>
                        <Grid item md={6}>
                            <CompField field={Field.FINANCIAL_RENTABLE_AREA_PRICE} {...fieldProps}/>
                        </Grid>
                    </FormRow>
                    {comp.type === MULTI_FAMILY &&
                    <FormRow>
                        <Grid item md={6}>
                            <CompField field={Field.FINANCIAL_BASE_RENT} {...fieldProps}/>
                        </Grid>
                        <Grid item md={6}>
                            <CompField field={Field.FINANCIAL_HAS_OTHER_REVENUE} {...fieldProps}/>
                        </Grid>
                    </FormRow>
                    }
                    {comp.type === MULTI_FAMILY && hasOtherRevenue &&
                    <FormRow>
                        <Grid item md={6}>
                        </Grid>
                        <Grid item md={6}>
                            <RevenueCalculator comp={comp}/>
                        </Grid>
                    </FormRow>
                    }
                    <FormRow>
                        <Grid item md={6}>
                            <CompField field={Field.FINANCIAL_POTENTIAL_GROSS_INCOME} {...fieldProps}/>
                        </Grid>
                        <Grid item md={6}>
                            <VacancyLossField comp={comp} setField={setField} fieldProps={fieldProps}/>
                        </Grid>
                    </FormRow>
                    <FormRow>
                        <Grid item md={6}>
                            <CompField field={Field.FINANCIAL_EFFECTIVE_GROSS_INCOME} {...fieldProps}/>
                        </Grid>
                        <Grid item md={6}>
                            <CompField field={Field.FINANCIAL_EFFECTIVE_GROSS_INCOME_PER_UNIT} {...fieldProps}/>
                        </Grid>
                    </FormRow>
                    <FormRow>
                        <Grid item md={6}>
                        </Grid>
                        <Grid item md={6}>
                            <CompField field={Field.FINANCIAL_EFFECTIVE_GROSS_INCOME_PER_UNIT_PER_MONTH} {...fieldProps}/>
                        </Grid>
                    </FormRow>
                    <FormRow>
                        <Grid item md={6}>
                            <CompField field={Field.FINANCIAL_TOTAL_EXPENSES} {...fieldProps} />
                        </Grid>
                        <Grid item md={6}>
                            <CompField field={Field.FINANCIAL_USE_EXPENSE_CALCULATOR} {...fieldProps}/>
                        </Grid>
                    </FormRow>
                    {  showExpenseCalc ?
                        <FormRow>
                            <Grid item md={6}/>
                            <Grid item md={6}>
                                <ExpenseCalculator comp={comp}/>
                            </Grid>
                        </FormRow>
                        : null }
                    <FormRow>
                        <Grid item md={6}>
                            <CompField field={Field.FINANCIAL_NET_OPERATING_INCOME} {...fieldProps}/>
                        </Grid>
                        <Grid item md={6}>
                            {comp.type === MULTI_FAMILY && <CompField field={Field.FINANCIAL_NET_OPERATING_INCOME_PER_UNIT} {...fieldProps}/>}
                            {comp.type === ICI && <CompField field={Field.FINANCIAL_NET_OPERATING_INCOME_PER_SQFT} {...fieldProps}/>}
                        </Grid>
                    </FormRow>
                    <FormRow>
                        <Grid item md={6}>
                        </Grid>
                        <Grid item md={6}>
                            <CompField field={Field.FINANCIAL_NET_OPERATING_INCOME_PER_UNIT_PER_MONTH} {...fieldProps}/>
                        </Grid>
                    </FormRow>
                    <FormRow>
                        <Grid item md={6}>
                            <CompField field={Field.FINANCIAL_POTENTIAL_GROSS_INCOME_MULTIPLIER} {...fieldProps}/>
                        </Grid>
                        <Grid item md={6}>
                            <CompField field={Field.FINANCIAL_EFFECTIVE_GROSS_INCOME_MULTIPLIER} {...fieldProps}/>
                        </Grid>
                    </FormRow>
                    <FormRow>
                        <Grid item md={6}>
                            <CompField field={Field.FINANCIAL_CAP_RATE} {...fieldProps}/>
                        </Grid>
                        <Grid item md={6}>
                            <CompField field={Field.FINANCIAL_EXPENSE_RATIO} {...fieldProps}/>
                        </Grid>
                    </FormRow>
                    <FormRow>
                      <Grid item md={6}>
                        <CompField field={Field.FINANCIAL_OCCUPANCY_PERCENT} {...fieldProps}/>
                      </Grid>
                    </FormRow>
                    <FormRow>
                        <Grid item>
                            <CompField field={Field.FINANCIAL_SEE_ADDITIONAL} {...fieldProps} useSwitch={true}/>
                        </Grid>
                    </FormRow>
                    {Field.FINANCIAL_SEE_ADDITIONAL.get(comp) === true ?
                      <>
                        <FormRow>
                          <Grid item md={3}>
                            <CompField field={Field.FINANCIAL_YEAR_ONE_CAP_RATE} {...fieldProps}/>
                          </Grid>
                            <Grid item md={3}>
                                <CompField field={Field.FINANCIAL_ANNUAL_EXPENSE_INCREASE} {...fieldProps}/>
                            </Grid>
                            <Grid item md={3}>
                                <CompField field={Field.FINANCIAL_DISCOUNT_RATE} {...fieldProps}/>
                            </Grid>
                            <Grid item md={3}>
                                <CompField field={Field.FINANCIAL_TERMINAL_CAP_RATE} {...fieldProps}/>
                            </Grid>
                        </FormRow>
                      </> :
                        null
                    }

                </Fragment> :
                null}

            {comp.type === RESIDENTIAL_RENT ?
                <Fragment>
                    <FormRow>
                        <Grid item md={6}>
                            <CompField field={Field.FINANCIAL_MONTHLY_RENT} {...fieldProps}/>
                        </Grid>
                        <Grid item md={6}>
                            <CompField field={Field.FINANCIAL_RENTAL_TERM} {...fieldProps}/>
                        </Grid>
                    </FormRow>
                    <FormRow>
                        <Grid item md={6}>
                            <CompField field={Field.FINANCIAL_UTILITIES_INCLUDED} {...fieldProps}/>
                        </Grid>
                      <Grid item md={6}>
                        <CompField field={Field.FINANCIAL_RENTAL_START_DATE} {...fieldProps}/>
                      </Grid>
                    </FormRow>
                </Fragment> :
                null}
        </CompEditorPanel>
    );
};

const VacancyLossField = ({comp, fieldProps}) => {
    const dispatch = useDispatch();
    return (
        <Box display="flex">
            <Box sx={{flexGrow: 1, pr: 1}}>
                {Field.FINANCIAL_VACANCY_COLLECTION_LOSS_MODE.get(comp) === VacationCollectionLossMode.AMOUNT ?
                    <CompField field={Field.FINANCIAL_VACANCY_COLLECTION_LOSS_AMOUNT} {...fieldProps} /> :
                    <CompField field={Field.FINANCIAL_VACANCY_COLLECTION_LOSS} {...fieldProps}
                               helperText={Field.FINANCIAL_VACANCY_COLLECTION_LOSS_VALUE.render(comp)}/>}
            </Box>
            <ToggleButtonGroup size="large" exclusive sx={{alignItems: 'flex-start'}}
                               onChange={(evt,value) => dispatch(setField({field: Field.FINANCIAL_VACANCY_COLLECTION_LOSS_MODE, value}))}
                               value={Field.FINANCIAL_VACANCY_COLLECTION_LOSS_MODE.get(comp) || VacationCollectionLossMode.PERCENT}>
                <ToggleButton value={VacationCollectionLossMode.PERCENT} >%</ToggleButton>
                <ToggleButton value={VacationCollectionLossMode.AMOUNT}>$</ToggleButton>
            </ToggleButtonGroup>
        </Box>
    )
}
export default FinancialPanel;
