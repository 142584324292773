import React from "react";
import {styled} from '@mui/material';
import TableCell from "@mui/material/TableCell";
import MUIToolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";


const Toolbar = styled(MUIToolbar)(({theme}) => ({
    '&&': {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        backgroundColor: theme.palette.grey["100"]
    }
}));

export const TableToolbar = ({title, children, ...props}) => {
    return (
        <Toolbar variant="dense" sx={{display: 'flex', justifyContent: 'space-between'}} {...props}>
            <Typography variant="subtitle1">{title}</Typography>
            {children}
        </Toolbar>
    )
}

export const HeadCell = styled(TableCell)({
    '&&': {
        textTransform: "uppercase",
        fontSize: "0.75rem"
    }
});

