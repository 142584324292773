import {styled} from '@mui/material';
import Typography from "@mui/material/Typography";

export default styled(Typography)(({theme})=> ({
    '&&': {
        textAlign: "center",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
        fontWeight: theme.typography.subtitle1.fontWeight,
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    }
}));
