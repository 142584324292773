import Badge from '@mui/material/Badge';
import React from "react";
import {useTheme} from '@mui/material';
import Avatar from "@mui/material/Avatar";
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';


const icon = type => {
    return <PhotoLibraryIcon/>;
};

const ReportAvatar = ({type, size, numComps, disabled}) => {
    const theme = useTheme();
    const avatar = <Avatar sx={{
      ...(size === 'large' && {width: theme.spacing(8), height: theme.spacing(8)}),
      ...(disabled === true && {bgcolor: 'grey.400'}),
    }}>
      {icon(type)}
    </Avatar>;

    return numComps ? <Badge overlap="circular" badgeContent={numComps} color="primary" anchorOrigin={{
      vertical: 'bottom', horizontal: 'left'
    }}>
      {avatar}
    </Badge> : avatar;
};

export default ReportAvatar;
