import React, {useState} from "react";
import {useTheme} from '@mui/material';
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import {useSelector} from 'react-redux';
import Page from "../util/Page";
import ToolBar from "../util/ToolBar";
import MoodPanel from "../util/MoodPanel";


const ErrorHandler = ({error, resetErrorBoundary}) => {
    const stack = useSelector(state => state.app.error ? state.app.error.componentStack : null);
    const [copied, setCopied] = useState(false);
    const theme = useTheme();

    const styles = {
        label: {
            mt: 0.5,
            mb: 0.5
        },
        frame: {
            m: 0,
            ml: 1
        }
    };

    const handleCopy = () => {
        let payload = "";
        if (stack) payload += stack;
        if (error) payload += "\n\n" + error.stack;

        navigator.clipboard.writeText(payload);
        setCopied(true);
    };

    return (
        <Page fluid={true}>
            <ToolBar>
            </ToolBar>
            <Container maxWidth="md">
                <MoodPanel mood="sad" message="Oops, an unexpected error occurred."/>
                <Alert severity="info" variant="outlined"
                       action={
                           <Button color="inherit" size="small" onClick={() => window.location.reload()}>
                               Refresh Now
                           </Button>}
                       sx={{
                           marginTop: 4,
                           maxWidth: theme.breakpoints.values.md,
                           margin: "auto"
                       }}>
                    <AlertTitle>Refresh Page</AlertTitle>
                    <Typography>Often, these types of errors can be resolved by refreshing the page.</Typography>
                </Alert>
                <Box mt={2}>
                    <Card variant="outlined">
                        <CardHeader title="Error Details" subheader={error.message}/>
                        <CardContent sx={{
                            bgcolor: 'grey.100',
                            borderTop: "1px solid",
                            borderBottom: "1px solid",
                            borderTopColor: 'grey.200',
                            borderBottomColor: 'grey.200',
                            maxHeight: 384,
                            overflow: "scroll"
                        }}>
                            <Typography component="div">
                                {stack &&<>
                                    <Box component="p" sx={styles.label}>Component Stack:</Box>
                                    {stack.split('\n').map((v,i) => <Box component="p" key={i} sx={styles.frame}>{v}</Box>)}
                                </>}
                                {error && <>
                                    <Box component="p" sx={{...styles.label, mt: 2}}>Error Trace:</Box>
                                    {error.stack.split('\n').map((v,i) => <Box component="p" key={i} sx={styles.frame}>{v}</Box>)}
                                </>}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Tooltip title="Copy to Clipboard">
                                <IconButton onClick={handleCopy} size="large">
                                    <FileCopyIcon/>
                                </IconButton>
                            </Tooltip>
                            {copied === true && <Typography>Copied.</Typography>}
                        </CardActions>
                    </Card>
                </Box>
            </Container>
        </Page>
    )
}

export default ErrorHandler;

