import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Prompt} from "react-router";
import {Stack} from '@mui/material';
import CompEditor from "./editor/CompEditor";
import {Field} from "../model/fields";
import Spinner from "../util/Spinner";
import TemplateChooser from './TemplateChooser';
import {copyData} from './state';


const EditStep = () => {
    const dispatch = useDispatch();
    const comp = useSelector(state => state.comp.curr);
    const isDirty = useSelector(state => state.comp.dirty.length > 0);
    const isCopyData = useSelector(state => state.comp.copyData);
    const isResale = useSelector(state => Field.SALES_IS_RESALE.get(state.comp.curr) === true);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        if (isResale && isCopyData) {
            dispatch(copyData(["sales", "confirmation"]))
                .then(() => {
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                })
        }
        else setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Prompt when={isDirty} message="This comp has not been saved yet, leaving the page will cause changes to be lost."/>
            {isLoading && <Spinner message={(isResale && isCopyData) ? "Copying data from previous sale" :""}/>}
            {!isLoading && <Stack gap={1}>
                <TemplateChooser comp={comp}/>
                <CompEditor/>
            </Stack>}
        </>

    );
};

export default EditStep;

