import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {bbox, envelope, featureCollection, point} from '@turf/turf';
import {getFunctions, httpsCallable} from 'firebase/functions';
import {isEqual, set, union} from 'lodash';
import {Field, FieldType, Fields} from '../model/fields';
import {
  addDoc,
  collection,
  deleteDoc,
  doc, getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  setDoc,
  where
} from "firebase/firestore";
import {Search} from "../model/objects";
import {RESIDENTIAL_RENT, LEASE, ICI, MULTI_FAMILY, VACANT_LAND} from '../model/propertyTypes';
import {pluralize} from '../util/utils';

export const FILTER_TERMS = "terms";
export const FILTER_RANGE = "range";
export const FILTER_MATCH = "match";

export const FILTER_GEO_POLYGON = "geo_polygon";

export const FILTER_GEO_BOUNDING_BOX = "geo_bounding_box";

export const AGG_TERMS = "terms";
export const AGG_HISTOGRAM = "histogram";

export const AGG_VARIABLE_WIDTH_HISTOGRAM = "variable_width_histogram";

export const AGG_AUTO_DATE_HISTOGRAM = "auto_date_histogram";

export const AGG_DATE_RANGE = "date_range";

const chooseFilterType = (field) => {
  if (FieldType.isNumeric(field.type) || field.type === FieldType.DATE) return FILTER_RANGE;
  if (field === Field.PHYSICAL_PROPERTY_TYPE || field === Field.PHYSICAL_PROPERTY_SUBTYPE) return FILTER_TERMS;
  if (field.type === FieldType.STRING) return FILTER_MATCH;
  return FILTER_TERMS;
}

// const aggTypeToFilterType = (aggType) => {
//   switch(aggType) {
//     case AGG_TERMS:
//       return FILTER_TERMS;
//     case AGG_HISTOGRAM:
//     case AGG_AUTO_DATE_HISTOGRAM:
//       return FILTER_RANGE;
//     default:
//       return null;
//   }
// }

// const buildFilter = (filter, bucket) => {
//   switch(bucket.type) {
//     case AGG_TERMS: {
//       const i = filter.values.indexOf(bucket.key);
//       if (i > -1) {
//         filter.values.splice(i, 1);
//       }
//       else filter.values.push(bucket.key);
//       break;
//     }

//     case AGG_HISTOGRAM: {
//       const i = filter.values.findIndex(it => isEqual(it, bucket.values));
//       if (i > -1) {
//         filter.values.splice(i, 1);
//       }
//       if (filter.values.length === 0) {
//         filter.values = [...bucket.values];
//       }
//       else filter.values.length = 0;
//       break;
//     }

//     case AGG_AUTO_DATE_HISTOGRAM:
//       break;

    
//     default:
//       break;
//   }
// }

const composeGeoFilter = (feature) => {
  return {
    field: Field.GEO.path,
    type: FILTER_GEO_POLYGON,
    values: feature.geometry.coordinates[0]
  }
}

const prepAggs = (search) => {
  return search.query.aggs
      .map(a => {
        if (a.type === AGG_DATE_RANGE) {
          const {time_zone, buckets, resolution, interval} = a.agg[AGG_DATE_RANGE];
          const resolutionName = a.settings.find(it => it.path === 'resolution')?.values?.find(it => it[0] === resolution)?.[1];
          const r = (x) => {
            return `now-${(x)*interval}${resolution}/${resolution}`;
          }
          const ranges = Array.from(Array(buckets).keys()).map(i => ({
            key: `Last ${pluralize((i+1)*interval, resolutionName)};+:${r(i+1)}`,
            from: r(i+1),
          }));
          ranges.push({
            to: r(buckets),
            key: `+${pluralize(buckets*interval, resolutionName)};-:${r(buckets)}`
          })
          return {
            field: a.field,
            agg: {
              [AGG_DATE_RANGE]: {
                field: a.field,
                time_zone,
                ranges
              }
            }
          }
        }
        else return a;
      })
      .filter(a => {
        // if (a.requires) {
        //   for (const field of Object.keys(a.requires)) {
        //     const filter = search.query.filters.find(f => f.field === field);
        //     if (!filter || !a.requires[field].some(r => filter.values.includes(r))) {
        //       return false;
        //     }
        //   }
        // }
        return true;
      }).reduce((obj, a) => {
        obj[a.field] = {
          ...a.agg,
          global: a.global === true
        };
        return obj;
      }, {});
};

const processAggs = (result, search) => {
  const resultAggs = Object.keys(result.aggregations).reduce((obj, k) => {
    if (k === 'all') {
      Object.keys(result.aggregations[k]).reduce((obj, j) => {
        if (j !== 'doc_count') {
          obj[j] = result.aggregations[k][j];
        }
        return obj;
      }, obj);
    }
    else {
      obj[k] = result.aggregations[k];
    }
    return obj;
  }, {});

  const aggs = [];
  for (const field of Object.keys(resultAggs)) {
    const fld = Fields.lookup(field);
    const agg = resultAggs[field];
    if (agg.buckets.length > 0) {
      const src = search.query.aggs.find(it => it.field === field);
      const type = Object.keys(src.agg)[0];

      let buckets;
      switch (type) {
        case AGG_TERMS: {
          buckets = agg.buckets.map(b => ({
            type,
            key: b.key,
            value: b.key,
            label: fld.render(b.key),
            count: b.doc_count
          }));
          break;
        }
        case AGG_HISTOGRAM:
        case AGG_VARIABLE_WIDTH_HISTOGRAM: {
          buckets = [];
          for (let i = 0; i < agg.buckets.length - 1; i++) {
            const curr = agg.buckets[i];
            const next = agg.buckets[i + 1];
            buckets.push({
              type,
              key: curr.key.toString(),
              value: [curr.key, next.key],
              label: `${fld.render(curr.key)} - ${fld.render(next.key)}`,
              count: curr.doc_count
            });
          }
          const last = agg.buckets.slice(-1)[0];
          buckets.push({
            type,
            key: last.key.toString(),
            value: [last.key, null],
            label: `${fld.render(last.key)}+`,
            count: last.doc_count
          });
          break;

        }
        case AGG_AUTO_DATE_HISTOGRAM: {
          buckets = [];
          for (let i = 0; i < agg.buckets.length - 1; i++) {
            const curr = agg.buckets[i];
            const next = agg.buckets[i + 1];
            buckets.push({
              type,
              key: curr.key.toString(),
              value: [curr.key, next.key],
              label: `${curr.key_as_string} - ${next.key_as_string}`,
              count: curr.doc_count
            });
          }
          const last = agg.buckets.slice(-1)[0];
          buckets.push({
            type,
            key: last.key.toString(),
            value: [last.key, null],
            label: `${last.key_as_string}+`,
            count: last.doc_count
          });
          break;
        }
        case AGG_DATE_RANGE: {
          buckets = agg.buckets.toReversed().map(b => {
            const [label, filter] = b.key.split(';');
            const [op, value] = filter.split(':');
            return {
              ...b,
              type,
              label,
              value: [op === '+' ? value : null, op === '-' ? value : null],
              key: b.key,
              count: b.doc_count,
            }
          });
          break;
        }
        default: {
          buckets = agg.buckets;
        }
      }

      aggs.push({field, type, buckets});
    }
  }

  return aggs;
}

const doSearch = async (search, auth) => {
  const rsp = await httpsCallable(getFunctions(), 'search')({
    org: auth.org.id,
    query: {
      ...search.query,
      aggs: prepAggs(search)
    }
  });

  // unmap search results
  const hits = rsp.data.result.hits.hits.map(hit => {
    const doc = {};
    for (const k of Object.keys(hit._source)) {
      const path = k.replaceAll('__', '.');
      set(doc, path, hit._source[k]);
    }
    doc.id = hit._id;
    return doc;
  })

  const map = {}
  if (hits.length > 0) {
    const features = featureCollection(
        hits.filter((d) => d.geo !== undefined).map((d,i) => {
          return point(d.geo.coordinates, {
            ...d,
            value: Field.VALUE.render(d),
            date: Field.DATE.render(d),
            photo: (d.photos?.length || 0) > 0 ? d.photos[0].url : undefined,
          });
        })
    );
    map.bounds = bbox(envelope(features));
    map.data = features;
  }

  return {count: rsp.data.result.hits.total.value, hits, map, aggs: processAggs(rsp.data.result, search)}
}

export const runSearch = createAsyncThunk(
  'search/run',
  async (arg, {getState, rejectWithValue}) => {
    const {search, auth} = getState();
    try {
      return await doSearch(search, auth)
    }
    catch(err) {
      console.error(err);
      return rejectWithValue(err);
    }
  }
)

export const initSearch = createAsyncThunk(
    'search/init',
    async (arg, {getState, rejectWithValue}) => {
      const {auth} = getState();
      try {
        const org = await getDoc(doc(getFirestore(), `orgs/${auth.org.id}`));
        return JSON.parse(org.data().settings?.search||'{}');
      }
      catch(err) {
        console.error(err);
        return rejectWithValue(err);
      }
    }
)

export const extendSearch = createAsyncThunk(
  'search/extend',
  async (arg, {getState, rejectWithValue}) => {
    const {search, auth} = getState();
    try {
      return await doSearch(search, auth)
    }
    catch(err) {
      console.error(err);
      return rejectWithValue(err);
    }
  }
)
export const runAggs = createAsyncThunk(
  'search/aggs',
  async (arg, {getState, rejectWithValue}) => {
    const {search, auth} = getState();
    try {
      const rsp = await httpsCallable(getFunctions(), 'search')({
        org: auth.org.id,
        query: {
          size: 0,
          filters: [],
          aggs: prepAggs(search)
        }
      });

      return processAggs(rsp.data.result, search);
    }
    catch(err) {
      console.error(err);
      return rejectWithValue(err);
    }
  }
)

export const addSearch = createAsyncThunk(
    'search/save',
    async (name, {getState, rejectWithValue}) => {
      const {search, auth} = getState();
      try {
        const now = new Date();
        const data = {
          name,
          module: "search",
          version: "1",
          created: now,
          text: search.query.text,
          filters: search.query.filters.map(f => ({...f, values: JSON.stringify(f.values||[])})),
          updated: now
        }
        const d = await addDoc(collection(getFirestore(), 'orgs', auth.org.id, 'searches'), data);
        return {
          ...data,
          id: d.id
        };
      }
      catch(err) {
        return rejectWithValue(err);
      }
    }
);

export const loadSearches = createAsyncThunk(
    'search/load',
    async (arg, {getState, rejectWithValue}) => {
      const org = getState().auth.org.id;
      try {
        const result = await getDocs(query(
            collection(getFirestore(), 'orgs', org, 'searches'),
            where('module', '==', 'search'),
            orderBy('created', 'desc')
        ));
        return result.docs
            .map(Search.create)
            .map(s => {
                s.filters = s.filters.map(f => ({...f, values: JSON.parse(f.values)}));
                return s;
            })
      }
      catch(err) {
        return rejectWithValue(err);
      }
    }
);

export const updateSearch = createAsyncThunk(
    'search/update',
    async (id, {getState, rejectWithValue}) => {
      const {search, auth} = getState();
      try {
        const data = {
          text: search.query.text,
          filters: search.query.filters,
          updated: new Date()
        };
        await setDoc(doc(getFirestore(), 'orgs', auth.org.id, 'searches', id), data, {merge: true});
        return {
          ...search.saved.all.find(it => it.id === id),
          ...data
        }
      }
      catch(err) {
        return rejectWithValue(err);
      }
    }
);

export const removeSearch = createAsyncThunk(
    'search/remove',
    async (search, {getState, rejectWithValue}) => {
      const org = getState().auth.org.id;
      try {
        await deleteDoc(doc(getFirestore(), 'orgs', org, 'searches', search.id))
        return search;
      }
      catch(err) {
        return rejectWithValue(err);
      }
    }
);

export const applySearch = createAsyncThunk(
    'search/apply',
    async (searchId, {dispatch, getState, rejectWithValue}) => {
      const {search} = getState()
      try {
        const s = search.saved.all.find(it => it.id === searchId);
        if (s) {
            dispatch(setText(s.text));
            dispatch(setFilters(s.filters));
            return s;
        }
      }
      catch(err) {
        return rejectWithValue(err);
      }
    }
)

const dateRangeSettings = () => {
    return [{
        name: 'Interval',
        path: 'interval',
        type: FieldType.INTEGER,
        note: 'Sets the date interval, in units of resolution, for each bucket',
    }, {
        name: 'Resolution',
        path: 'resolution',
        type: FieldType.STRING,
        note: 'Sets the unit of the date interval',
        values: [['d', 'Day'], ['w', 'Week'], ['M', 'Month'], ['y', 'Year']]
    }, {
        name: 'Number of Buckets',
        path: 'buckets',
        type: FieldType.INTEGER,
        note: 'Sets the number of buckets to categorize items into',
    }]
};

const search = createSlice({
  name: 'search',
  initialState: {
    query: {
      size: 100,
      from: 0,
      text: '',
      filters: [],
      aggs: [{
        type: AGG_TERMS,
        field: Field.TYPE.path,
        agg: {
          [AGG_TERMS]: {
            field: Field.TYPE.path
          }
        },
        global: true,
        settings: [],
      }, {
        type: AGG_TERMS,
        field: Field.PHYSICAL_PROPERTY_TYPE.path,
        agg: {
          [AGG_TERMS]: {
            field: Field.PHYSICAL_PROPERTY_TYPE.path
          }
        },
        settings: [],
      },{
          type: AGG_TERMS,
          field: Field.PHYSICAL_PROPERTY_SUBTYPE.path,
          agg: {
            [AGG_TERMS]: {
              field: Field.PHYSICAL_PROPERTY_SUBTYPE.path
            }
          },
          settings: [],
      },{
        type: AGG_VARIABLE_WIDTH_HISTOGRAM,
        field: Field.SALES_PRICE.path,
        requires: {
          [Field.TYPE.path]: [ICI, MULTI_FAMILY, VACANT_LAND ],
        },
        agg: {
          [AGG_VARIABLE_WIDTH_HISTOGRAM]: {
            field: Field.SALES_PRICE.path,
            buckets: 5,
            // interval: 500000,
            // min_doc_count: 25
          }
        },
        settings: [{
          name: 'Number of  Buckets',
          path: 'buckets',
          type: FieldType.INTEGER,
          note: 'The number of categories to group results into',
        }, /*{
          name: 'Price Interval',
          path: 'interval',
          type: FieldType.CURRENCY,
          note: 'Sets the price interval amount, in dollars, used to group results into buckets',
        }, {
          name: 'Bucket Size',
          path: 'min_doc_count',
          type: FieldType.INTEGER,
          note: 'Sets the minimum number of items for each bucket (ie. category). A higher value will result in fewer buckets.',
        }*/]
      }, {
        type: AGG_DATE_RANGE,
        field: Field.SALES_DATE.path,
        requires: {
          [Field.TYPE.path]: [ICI, MULTI_FAMILY, VACANT_LAND],
        },
        agg: {
          [AGG_DATE_RANGE]: {
            field: Field.SALES_DATE.path,
            buckets: 3,
            resolution: "d",
            interval: 30,
            time_zone: `-0${new Date().getTimezoneOffset() / 60}:00`
          }
        },
        settings: dateRangeSettings()
      }, {
        type: AGG_DATE_RANGE,
        field: Field.CREATED.path,
        agg: {
          [AGG_DATE_RANGE]: {
            field: Field.CREATED.path,
            buckets: 3,
            resolution: "d",
            interval: 30,
            // format: 'MMM dd, yyyy',
            // minimum_interval: "month",
            time_zone: `-0${new Date().getTimezoneOffset() / 60}:00`
          }
        },
        settings: dateRangeSettings()
      }, {
        type: AGG_HISTOGRAM,
        field: Field.FINANCIAL_MONTHLY_RENT.path,
        requires: {
          [Field.TYPE.path]: [RESIDENTIAL_RENT],
        },
        agg: {
          [AGG_HISTOGRAM]: {
            field: Field.FINANCIAL_MONTHLY_RENT.path,
            interval: 1000,
            min_doc_count: 1
          }
        },
        settings: [{
          name: 'Price Interval',
          path: 'interval',
          type: FieldType.CURRENCY,
          note: 'Sets the rent interval amount, in dollars, used to group results into buckets',
        }, {
          name: 'Bucket Size',
          path: 'min_doc_count',
          type: FieldType.INTEGER,
          note: 'Sets the minimum number of items for each bucket (ie. category). A higher value will result in fewer buckets.',

        }]
      }, {
        type: AGG_DATE_RANGE,
        field: Field.LEASE_START_DATE.path,
        requires: {
          [Field.TYPE.path]: [LEASE],
        },
        agg: {
          [AGG_DATE_RANGE]: {
            field: Field.LEASE_START_DATE.path,
            buckets: 3,
            resolution: "d",
            interval: 30,
            time_zone: `-0${new Date().getTimezoneOffset() / 60}:00`
          }
        },
        settings: dateRangeSettings()
      }, {
        type: AGG_HISTOGRAM,
        field: Field.LEASE_AVERAGE_RATE_OVER_TERM.path,
        requires: {
          [Field.TYPE.path]: [LEASE],
        },
        agg: {
          [AGG_HISTOGRAM]: {
            field: Field.LEASE_AVERAGE_RATE_OVER_TERM.path,
            interval: 10, // TODO: what is the interval here?
            min_doc_count: 1
          }
        },
        settings: [{
          name: 'Rate Interval',
          path: 'interval',
          type: FieldType.NUMBER,
          note: 'Sets the rate interval amount, used to group results into buckets',

        }, {
          name: 'Bucket Size',
          path: 'min_doc_count',
          type: FieldType.INTEGER,
          note: 'Sets the minimum number of items for each bucket (ie. category). A higher value will result in fewer buckets.',
        }]
      }],
      geo: {
        enabled: true,
        filter: {
          [FILTER_GEO_BOUNDING_BOX]: {
            [Field.GEO.path]: {
              top_left: null,
              bottom_right: null
            }
          }
        }
      },
      dirty: []
    },
    result: {
      count: -1,
      hits: [],
      map: {},
      aggs: []
    },
    aggs: [],
    saved: {
      all: [],
      curr: null
    },
    view: {
      aggs: {
        open: false
      },
      result: {
        open: true
      },
      map: {
        height: 0,
        center: null,
        resize: false
      }
    },
    searching: false
  },
  reducers: {
    setText: (state, action) => {
      state.query.text = action.payload;
      state.query.dirty = union(state.query.dirty, ['text']);
    },
    setFilters: (state, action) => {
      state.query.filters = action.payload.map(f => {
        const path = typeof f.field === 'string' ? f.field : f.field.path;
        return {
          ...f,
          field: path,
          type: chooseFilterType(Fields.lookup(path)),
          values: f.values || []
        }
      });
      state.query.dirty = union(state.query.dirty, ['filters']);
    },
    updateView: (state, action) => {
      const {path, value, resizeMap = false} = action.payload;
      set(state.view, path, value);

      if (resizeMap) {
        state.view.map.resize = true;
      }
    },
    zoomTo: (state, action) => {
      const comp = action.payload;
      state.view.map.center = comp.geo;
    },

    toggleAggFilter: (state, action) => {
      const {field, key} = action.payload;

      let agg = state.result.aggs.find(a => a.field === field);
      if (!agg) {
        agg = state.aggs.find(a => a.field === field);
      }
      if (agg) {
        const bucket = agg.buckets.find(b => b.key === key);
        if (bucket) {
          let filter = state.query.filters.find(f => f.field === field);
          if (!filter) {
            filter = {
              type: chooseFilterType(Fields.lookup(field)),
              field,
              values: [],
              editable: true
            };
            state.query.filters.push(filter);
          }

          if (agg.type === AGG_DATE_RANGE) {
            // relative date ranges aren't multi select
            filter.values.length = 0;
            filter.values.push(bucket.value);
            filter[AGG_DATE_RANGE] = true;
            filter.label = key.split(';')[0];
            filter.editable = false;
          }
          else {
            const i = filter.values.findIndex(v => isEqual(v, bucket.value));
            if (i > -1) {
              filter.values.splice(i, 1);
            }
            else {
              filter.values.push(bucket.value);
            }
          }

          if (filter.values.length === 0) {
            state.query.filters = state.query.filters.filter(f =>  f.field !== field);
          }

          state.query.dirty = union(state.query.dirty, ['filters']);
        }
      }
    },

    toggleGeoFilter: (state, action) => {
      state.query.geo.enabled = !state.query.geo.enabled;
      // state.query.dirty = ['filters'];
    },
    updateGeoFilter: (state, action) => {
      const [[left, bottom], [right, top]] = action.payload;
      state.query.geo.filter[FILTER_GEO_BOUNDING_BOX][Field.GEO.path].top_left = {lon: left, lat: top}
      state.query.geo.filter[FILTER_GEO_BOUNDING_BOX][Field.GEO.path].bottom_right = {lon: right, lat: bottom}
    },

    setAggSetting: (state, action) => {
      const {field, values} = action.payload;
      const agg = state.query.aggs.find(a => a.field === field);
      if (agg) {
        agg.agg[agg.type] = {...agg.agg[agg.type], ...values};
        state.query.dirty = union(state.query.dirty, ['aggs']);
      }
    },

    setGeoFilter: (state, action) => {
      const feature = action.payload;
      const i = state.query.filters.findIndex(f => f.field === Field.GEO.path);
      if (i > -1) {
        state.query.filters.splice(i, 1, ...(feature?[composeGeoFilter(feature)]:[]));
        state.query.dirty = union(state.query.dirty, ['filters']);
      }
      else {
        if (feature) {
          state.query.filters.push(composeGeoFilter(feature));
          state.query.dirty = union(state.query.dirty, ['filters']);
        }
      }
    },

    // addFilter: (state, action) => {
    //   state.query.filters.push({});
    // },

    removeFilter: (state, action) => {
      const {filter, markDirty=true} = action.payload;
      const i = state.query.filters.findIndex(f => f.field === filter.field);
      if (i > -1) {
        state.query.filters.splice(i, 1);
        // state.view.geo.enabled = false;
        if (markDirty) state.query.dirty = union(state.query.dirty, ['filters']);
      }
    },

    toggleFilter: (state, action) => {
    },

    // setFilters: (state, action) => {
    //   state.query.filters = action.payload.map(f => ({
    //     field: f.field.path,
    //     type: chooseFilterType(f.field),
    //     values: f.values
    //   }))
    // },
    // setFilterField: (state, action) => {
    //   const {index, field} = action.payload;
    //   const filter = state.query.filters[index];
    //   if (filter) {
    //     filter.field = field.path;
    //     filter.type = chooseFilterType(field);
    //     filter.values = [];
    //   }
    // },

    // setFilterValue: (state, action) => {
    //   const {index, value} = action.payload;
    //   const filter = state.query.filters[index];
    //   if (filter) {
    //     filter.values = value;
    //   }
    // },

    dirtyFilters: (state, action) => {
      state.query.dirty = union(state.query.dirty, ['filters']);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(initSearch.fulfilled, (state, action) => {
        state.query = {...state.query, ...action.payload};
      })
      .addCase(runAggs.pending, (state, action) => {
        state.searching = true;
      })
      .addCase(runAggs.fulfilled, (state, action) => {
        state.aggs = action.payload;
        state.view.aggs.open = true;
        state.searching = false;
        state.query.dirty = state.query.dirty.filter(it => it !== 'aggs');
      })
      .addCase(runAggs.rejected, (state, action) => {
        state.searching = false;
      })
      .addCase(runSearch.pending, (state, action) => {
        state.searching = true;
        state.query.from = 0;
      })
      .addCase(runSearch.fulfilled, (state, action) => {
        state.result = {...action.payload};
        state.searching = false;
      })
      .addCase(runSearch.rejected, (state, action) => {
        state.searching = false;
      })
      .addCase(extendSearch.pending, (state, action) => {
        state.searching = true;
        state.query.from += state.query.size;
      })
      .addCase(extendSearch.fulfilled, (state, action) => {
        state.result.hits = [...state.result.hits, ...action.payload.hits];
        state.result.map.data.features = [...state.result.map.data.features, ...action.payload.map.data.features];
        state.searching = false;
      })
      .addCase(extendSearch.rejected, (state, action) => {
        state.searching = false;
      })
      .addCase(loadSearches.fulfilled, (state, action) => {
        state.saved.all = action.payload;
      })
      .addCase(addSearch.fulfilled, (state, action) => {
        state.saved.all.splice(0, 0, action.payload);
      })
      .addCase(removeSearch.fulfilled, (state, action) => {
        state.saved.all = state.saved.all.filter(it => it.id !== action.payload.id);
      })
      .addCase(applySearch.fulfilled, (state, action) => {
        const search = action.payload;
        state.saved.curr = search.id;
        // state.view.geo.enabled = search.filters.find(f => f.field === Field.GEO.path) !== undefined;
        state.query.dirty = []
      })
  }
});

export const {
  setText, setFilters, updateView, zoomTo,
  toggleAggFilter, setAggSetting, setGeoFilter, addFilter, removeFilter, setFilterField, setFilterValue,
  toggleGeoFilter, updateGeoFilter, dirtyFilters
} = search.actions;

export default search.reducer;

