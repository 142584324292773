import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import ForwardIcon from '@mui/icons-material/Forward';
import Stack from '@mui/material/Stack';
import ErrorAlert from '../util/ErrorAlert';
import SelectWithLabel from '../util/SelectWithLabel';
import Spinner from '../util/Spinner';
import {loadUsers, shareList} from './state';


const ShareDialog = ({list, isOpen, onClose}) => {
  const dispatch = useDispatch();
  const [isWorking, setWorking] = useState(true);
  const [error, setError] = useState();
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState("")

  useEffect(() => {
    dispatch(loadUsers())
        .unwrap()
        .then(setUsers)
        .finally(() => setWorking(false));
    // eslint-disable-next-line
  }, []);

  const handleShare = () => {
    dispatch(shareList({list, email: user}))
        .then(() => onClose(true))
        .catch(setError)
        .finally(() => {
          setWorking(true);
        });
  }

  return (
      <Dialog open={isOpen} fullWidth maxWidth={"sm"} onClose={onClose}>
        <DialogTitle>
          Share List
        </DialogTitle>
        <DialogContent dividers>
          {error && <ErrorAlert error={error}/>}
          <Stack gap={2}>
            <Typography>
              Please choose a team member below to share the list <em>{list.title}</em> with.
            </Typography>
            {users.length === 0 && <Spinner/>}
            {users.length > 0 && <SelectWithLabel label="Team Member"
              value={user} values={users.map(u => [u.email, `${u.first_name} ${u.last_name}`])}
              onChange={e => setUser(e.target.value)}
            />}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false)} disabled={isWorking}>
            Cancel
          </Button>
          <Button onClick={handleShare} color="primary" variant="outlined" disabled={isWorking || !user}
                  startIcon={isWorking ? <CircularProgress size={24}/> : <ForwardIcon/>}>
            Share
          </Button>
        </DialogActions>
      </Dialog>
  )
};

export default ShareDialog;
