import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {getAuth, isSignInWithEmailLink, signOut, signInWithEmailLink} from 'firebase/auth';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import BusinessIcon from "@mui/icons-material/Business";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PersonIcon from "@mui/icons-material/Person";
import PublicPage from "../util/PublicPage";
import Spinner from "../util/Spinner";
import {Form, FormRow} from "../util/Form";
import ErrorAlert from "../util/ErrorAlert";
import EmailField from "../util/EmailField";
import PasswordInput from "../util/PasswordInput";
import CardControl from "../util/CardControl";
import PlanGrid from "./PlanGrid";
import {setInfo, setup} from './state';


const SetupPage = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isInvalid, setIsInvalid] = useState(false);
    const [isWorking, setIsWorking] = useState(false);
    const [error, setError] = useState(null);
    const [paymentError, setPaymentError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [card, setCard] = useState(null);
    const [stripe, setStripe] = useState(null);

    const auth = useSelector(state => state.auth);
    const info = useSelector(state => state.signup.info);
    const dispatch = useDispatch();

    useEffect(() => {
        if (dispatch && auth) {
            if (auth.user) {
                dispatch(setInfo({key:"email", value:auth.user.email}));
            }
            else {
                if (isSignInWithEmailLink(getAuth(), window.location.href)) {
                    const e = window.localStorage.getItem("quickcomp.signupEmail");
                    if (e) {
                        dispatch(setInfo({key:"email", value:e}));
                    }
                } else {
                    setIsInvalid(true);
                }
            }
            setIsLoading(false);
        }
    }, [dispatch, auth]);

    const handleUpdate = e => dispatch(setInfo({key:e.target.name, value:e.target.value}));

    const runSetup = () => {
        setIsWorking(true);
        setError(null);
        setPaymentError(null);

        dispatch(setup({info, card, stripe}))
            .unwrap()
            .then(() => {
                signOut(getAuth()).then(() => setSuccess(true));
            })
            .catch(error => {
                if (error.type === "StripeCardError") {
                    setPaymentError(error.raw);
                }
                else setError(error);
            })
            .finally(() => setIsWorking(true))
    }

    const handleFinish = (evt) => {
        evt.stopPropagation();
        evt.preventDefault();

        if (!auth.user) {
            signInWithEmailLink(getAuth(), info.email, window.location.href)
              .then(() => runSetup())
              .catch(setError);
        }
        else {
            // already logged in, verify
            if (auth.user.email !== info.email) {
                setError("Email address not match email corresponding to the setup link.");
            }
            else {
                runSetup();
            }
        }
    };
    const isSet = v => {
        return (v || "").trim().length > 0;
    }

    const isComplete = () => isSet(info.firstName) && isSet(info.lastName) && isSet(info.email) && isSet(info.password)
        && isSet(info.orgName) && isSet(info.plan) && info.agreeToTerms === true &&
        (info.plan !== "Free" ? (card && stripe) : true);

    return (
        <PublicPage>
            {isLoading && <Spinner/>}
            {/*{isWorking && <Spinner message="Hold tight while we set up your new account." />}*/}
            {!isLoading && !isInvalid &&
                <>
                    <Typography variant="h3" gutterBottom>Thank you for choosing QuickComp. Let's finalize your account.</Typography>
                    <Form>
                        <FormRow>
                            <Grid item md={6}>
                                <TextField label="First Name" name="firstName" value={info.firstName}
                                           onChange={handleUpdate} variant="outlined" fullWidth
                                           InputProps={{
                                               startAdornment: <InputAdornment position="start">
                                                   <PersonIcon/>
                                               </InputAdornment>
                                           }} />
                            </Grid>
                            <Grid item md={6}>
                                <TextField label="Last Name" name="lastName" value={info.lastName}
                                           onChange={handleUpdate}
                                           variant="outlined" fullWidth/>
                            </Grid>
                        </FormRow>
                        <FormRow>
                            <Grid item md={6}>
                                <EmailField label="Email" name="email" value={info.email}
                                            onChange={e => dispatch(setInfo({key:"email", value:e}))} fullWidth/>
                            </Grid>
                        </FormRow>
                        <FormRow>
                            <PasswordInput onChange={p => dispatch(setInfo({key:"password", value:p}))}/>
                        </FormRow>
                        <FormRow>
                            <Grid item md={6}>
                                <TextField label="Organization" name="orgName" value={info.orgName}
                                           onChange={handleUpdate} variant="outlined" fullWidth
                                           InputProps={{
                                               startAdornment: <InputAdornment position="start">
                                                   <BusinessIcon/>
                                               </InputAdornment>
                                           }} />
                            </Grid>
                        </FormRow>
                        <FormRow>
                            <Grid item xs={12}>
                                <PlanGrid plan={info.plan} onChange={p => dispatch(setInfo({key:"plan", value:p.name}))}/>
                            </Grid>
                        </FormRow>
                        {info.plan && info.plan !== "Free" &&
                        <FormRow>
                            <Grid item xs={12}>
                                <CardControl onChange={(card, stripe) => {
                                    setCard(card);
                                    setStripe(stripe);
                                }}/>
                            </Grid>
                        </FormRow>}
                        {paymentError && <FormRow>
                            <Grid item xs={12}>
                                <ErrorAlert title="Payment Error" error={paymentError}/>
                            </Grid>
                        </FormRow>}
                        {error && <FormRow>
                            <Grid item xs={12}>
                                <ErrorAlert title="Setup Error" error={error}/>
                            </Grid>
                        </FormRow>}
                        <FormRow>
                            <Grid item xs={12} sx={{textAlign: 'left'}}>
                                <FormControlLabel
                                    control={<Checkbox checked={info.agreeToTerms}
                                    onChange={evt => dispatch(setInfo({key:"agreeToTerms", value:evt.target.checked === true}))} />}
                                    label={
                                        <>
                                            I agree to the QuickComp <a href="https://quickcomp.io/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a> and <a href="https://quickcomp.io/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
                                        </>
                                    }
                                />
                            </Grid>
                        </FormRow>
                        <FormRow>
                            <Grid item xs={12} >
                                {success && <Alert severity="success" sx={{textAlign: 'left'}}>
                                    <AlertTitle>Success</AlertTitle>
                                    <p>
                                        Congratulations! You are now ready to start using QuickComp. You can start by <Link to="/login">logging in</Link>.
                                    </p>
                                </Alert>}
                                {!success &&
                                <Button
                                    disabled={!isComplete() || isWorking}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={handleFinish}
                                    startIcon={isWorking ? <CircularProgress size={24}/> : <ExitToAppIcon/>}
                                >
                                    Finish
                                </Button>
                                }
                            </Grid>
                        </FormRow>
                    </Form>
                </>
            }

        </PublicPage>
    );
};

export default SetupPage;
