import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import AddIcon from '@mui/icons-material/Add';
import {SimpleCompCard} from '../comp/CompCard';
import {clearComp, loadRecentComps} from '../comp/state';
import { Section } from "../util/Section";
import Page from "../util/Page";
import CompGrid from "./CompGrid";
import ReportGrid from "./ReportGrid";
import AccountCard from "./AccountCard";
import Spinner from "../util/Spinner";


const DashPage = () => {
    const myRecentComps = useSelector(state => (state.comp.recent.mine || []).slice(0,10));
    const teamRecentComps = useSelector(state => (state.comp.recent.team || []).slice(0,10));
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        dispatch(clearComp());
    }, [dispatch]);

    React.useEffect(() => {
        if (myRecentComps.length < 3) {
            dispatch(loadRecentComps()).then(() => {
                setIsLoading(false);
            })
        }
        else {
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Page className="DashPage">
            <Container maxWidth="xl" sx={{pt: 2}}>
                {isLoading ?
                    <Spinner absolute={true}/> :
                    myRecentComps.length > 0 ?
                        <Box>
                            <Grid container>
                                <Grid item md={12}>
                                   <Section icon={<PersonOutlineOutlinedIcon/>} heading="Recently Updated By Me">
                                       <CompGrid comps={myRecentComps}/>
                                   </Section>
                                </Grid>
                            </Grid>
                            <Grid container>
                                {teamRecentComps.length > 0 && <Grid item md={6}>
                                    <Section icon={<PeopleAltOutlinedIcon/>} heading="Recently Updated By Team"
                                             sx={{mt: 2}}>
                                        <CompGrid comps={teamRecentComps} width={320} CompComponent={SimpleCompCard}/>
                                        {/*<ReportGrid />*/}
                                    </Section>
                                </Grid>}
                                <Grid item md={6}>
                                    <Section icon={<DescriptionOutlinedIcon/>} heading="Recently Exported Reports"
                                             sx={{mt: 2}}>
                                        <ReportGrid width={320}/>
                                    </Section>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item md={4}>
                                    <Section icon={<AccountBoxOutlinedIcon/>} heading="Account & Usage">
                                        <AccountCard/>
                                    </Section>
                                </Grid>
                            </Grid>
                        </Box>
                        :
                        <Box sx={{pt: 8, textAlign: 'center'}}>
                            <Typography variant="h3">Welcome to QuickComp. Let's get started by adding your first comp.</Typography>
                            <Button
                                component={Link} to="/add"
                                variant="contained"
                                color="primary"
                                size="large"
                                startIcon={<AddIcon />}
                                sx={{mt: 4}}
                            >
                                Add a Comp
                            </Button>
                        </Box>
                }
            </Container>
        </Page>
    )
};

export default DashPage;
