import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextPreview from '../report/builder/TextPreview';
import ColorPicker from './ColorPicker';
import FontControl from './FontControl';
import {FormRow} from './Form';


const TextBuilder = ({config, size, onFontChange, onBackgroundChange, showBackground, showAlign, showFamily, withFamily, colorPickerOpts}) => {

  return (
    config.font ? <>
      <FormRow spacing={0} gutterBottom>
        <FontControl font={config.font} onChange={onFontChange} size={size} showAlign={showAlign} showFamily={showFamily} colorPickerOpts={colorPickerOpts}/>
      </FormRow>
      <Box py={2}>
        <FormRow spacing={0}>
          <Grid container spacing={2} alignItems="center">
            {showBackground && <Grid item xs={3}>
              <ColorPicker label="Background" value={config.background || ""} size={size}
                          onChange={onBackgroundChange} {...colorPickerOpts}/>
            </Grid>}
            <Grid item>
              <TextPreview font={config.font} background={showBackground && config.background} withFamily={withFamily}/>
            </Grid>
          </Grid>
        </FormRow>
      </Box>
    </> : null
  )
}

export default TextBuilder;
