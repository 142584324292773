import React, {useImperativeHandle, useRef} from "react";
import { DragSource, DropTarget } from 'react-dnd'
import {NumericFormat} from "react-number-format";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "@mui/material/Tooltip";
import MenuIcon from "@mui/icons-material/Menu";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from "@mui/material/IconButton";
import {FormRow} from "../../util/Form";

const ExpenseRow = React.forwardRef(({expense, onChange, onRemove, isDragging, connectDragSource, connectDropTarget}, ref) => {
    const elRef = useRef(null);
    connectDragSource(elRef);
    connectDropTarget(elRef);
    const opacity = isDragging ? 0 : 1;

    useImperativeHandle(ref, () => ({
        getNode: () => elRef.current,
    }));
    return (
        <div ref={elRef} style={{opacity}}>
            <FormRow spacing={0} sx={{alignItems: 'center', flexWrap: 'nowrap'}}>
                <Grid item>
                    <Tooltip title="Remove this expense">
                        <IconButton onClick={() => onRemove(expense)} size="large">
                            <HighlightOffIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item sx={{flexGrow:1, p: 0}}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextField value={expense.title} size="small" variant="outlined"
                                       onChange={onChange("title")} fullWidth/>
                        </Grid>
                        <Grid item xs={3}>
                            <NumericFormat value={expense.percent} size="small" variant="outlined"
                                          InputProps={{
                                              endAdornment: <InputAdornment position="end">%</InputAdornment>
                                          }}
                                          onValueChange={ onChange("percent") }
                                          customInput={TextField}>
                            </NumericFormat>
                        </Grid>
                        <Grid item xs={3}>
                            <NumericFormat value={expense.value} size="small" variant="outlined"
                                          InputProps={{
                                              startAdornment: <InputAdornment position="start">$</InputAdornment>
                                          }}
                                          onValueChange={ onChange("value") }
                                          disabled={expense.percent != null}
                                          customInput={TextField} >
                            </NumericFormat>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Tooltip title="Drag to reorder">
                        <IconButton style={{cursor: "grab"}} size="large">
                            <MenuIcon color="secondary" />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </FormRow>
        </div>
    );
});

export default DropTarget(
    "expense",
    {
        hover(props, monitor, component) {
            if (!component) {
                return null
            }
            // node = HTML Div element from imperative API
            const node = component.getNode();
            if (!node) {
                return null;
            }
            const dragIndex = monitor.getItem().index;
            const hoverIndex = props.index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = node.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            // Time to actually perform the action
            props.onMove(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            monitor.getItem().index = hoverIndex
        },
    },
    connect => ({
        connectDropTarget: connect.dropTarget(),
    }),
)(
    DragSource(
        "expense",
        {
            beginDrag: props => ({
                id: props.expense.id,
                index: props.index,
            }),
        },
        (connect, monitor) => ({
            connectDragSource: connect.dragSource(),
            isDragging: monitor.isDragging(),
        }),
    )(ExpenseRow),
)
