import React from 'react';
import {TabPanel} from '../../util/Tab';


const SheetEditorPanel = ({name, sheet, children, ...props}) => {
    return (
        <TabPanel {...props} sheet={sheet} className={name}>
        <form>
            {children}
        </form>
        </TabPanel>
    );
}

export default SheetEditorPanel;