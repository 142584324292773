import React from "react";
import {useDispatch, useSelector} from "react-redux";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import {addToCart, removeFromCart} from './state';


const CartButton = ({comp, size="large"}) => {
    const dispatch = useDispatch();
    const cartComps = useSelector(state => state.cart.comps || []);

    const handleAdd = () => {
        dispatch(addToCart([comp]));
    };
    const handleRemove = () => {
        dispatch(removeFromCart(comp));
    };

    const compInCart = cartComps.find(it => it.id === comp.id) || false;
    const tip = compInCart ? "Remove from Cart" : "Add to Cart";

    return (
        <IconButton
            onClick={compInCart ? handleRemove : handleAdd}
            className="CartButton"
            size={size}>
            <Tooltip title={tip}>
                {compInCart ? <RemoveShoppingCartIcon />: <AddShoppingCartIcon />}
            </Tooltip>
        </IconButton>
    );
};

export default CartButton;
