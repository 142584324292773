import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";


export const Section = ({heading, trailing, icon, children, ...props}) => {
    return (
        <Box {...props}>
            <Paper square sx={{
                p: 2,
                pl: 0,
                boxShadow: "none",
                backgroundColor: "transparent"
            }}>
                <Box display="flex" sx={{alignItems: "center"}}>
                    {React.cloneElement(icon, {sx: {
                        fontSize: 'h4.fontSize',
                        verticalAlign: "middle",
                        mr: 1
                    }})}
                    <Typography variant="h6" sx={{display:'inline-block', flexGrow:1}}>
                        {heading}
                    </Typography>
                    {trailing}
                </Box>
            </Paper>
            <div>
                {children}
            </div>
        </Box>
    )
};
