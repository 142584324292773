import React from "react";
import {useDispatch, useSelector} from "react-redux";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import PaletteIcon from "@mui/icons-material/Palette";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SettingsIcon from '@mui/icons-material/Settings';
import {TabPanel, TabSideBar} from "../../util/Tab";
import {FormSection} from "../../util/Form";
import Instruction from "./Instruction";
import Summary from "../content/Summary";
import Fonts from "../content/Fonts";
import RadioGroup from "../../util/HorizontalRadioGroup";
import ColumnBuilder from "./ColumnBuilder";
import RowSplitter from "./RowSplitter";
import TableStylePanel from "./TableStylePanel";
import TableSettingsPanel from './TableSettingsPanel';
import {updateConfig, updateView} from '../state';


const LayoutPanel = ({...props}) => {
    const config = useSelector(state => state.report.curr.summary);
    const options = useSelector(state => state.report.curr.options);
    const dispatch = useDispatch();
    return (
        <TabPanel {...props}>
            <ColumnBuilder config={config} configName="summary" {...props}/>
            <FormSection label="Unit Display" help="Switch between Imperial and Metric units in the report.">
                <FormControl component="fieldset">
                    <RadioGroup name="metric" value={options.metric}
                                onChange={evt => dispatch(updateConfig({path: "options.metric", value:evt.target.value === "true"}))}>
                        <FormControlLabel value={false} control={<Radio />} label="Imperial" />
                        <FormControlLabel value={true} control={<Radio />} label="Metric" />
                    </RadioGroup>
                </FormControl>
            </FormSection>
            <RowSplitter config={config} configName="summary"/>
        </TabPanel>
    );
};


const PreviewPanel = ({...props}) => {
    return (
        <TabPanel {...props}>
            <Fonts/>
            <Summary/>
        </TabPanel>
    );
};

const SummaryPanel = ({...props}) => {
    const activeTab = useSelector(state => state.report.view.summary.tab);
    const dispatch = useDispatch();
    return (
        <TabPanel {...props}>
            <Instruction>The summary table lists all of the comps in the report.</Instruction>
            <TabSideBar tabs={[
                [{label: "Preview", icon: <VisibilityIcon/>}, (p) => <PreviewPanel {...p} />],
                [{label: "Layout", icon: <ViewColumnIcon/>}, (p) => <LayoutPanel {...p} />],
                [{label: "Styles", icon: <PaletteIcon/>}, (p) => <TableStylePanel configName="summary" {...p} />],
                [{label: "Settings", icon: <SettingsIcon/>}, (p) => <TableSettingsPanel configName="summary" {...p} />],
            ]} activeTab={activeTab} onTabChange={t => dispatch(updateView("summary.tab", t))}/>
        </TabPanel>
    );
};

export default SummaryPanel;


