import React from "react";
import {useDispatch, useSelector} from "react-redux";
import Container from "@mui/material/Container";
import Page from "../util/Page";
import ToolBar from "../util/ToolBar";
import DataTable from "../util/DataTable";
import {ReportCardActions} from "./ReportCard";
import {Field} from  "../model/fields";
import {pageReports, sortReports} from './state';

const FIELDS =  [Field.TITLE, Field.CREATED];

const ReportsPage = () => {
    const list = useSelector(state => state.report.list);
    const numReports = useSelector(state => state.app.org.num_reports || 0);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (list.page === -1) {
            dispatch(pageReports(true));
        }
    }, [list, dispatch]);

    const handlePage = forward => {
        return dispatch(pageReports(forward));
    };

    const handleSort = (field, order) => {
        return dispatch(sortReports({field, order}));
    };

    return (
        <Page className="ReportsPage">
            <ToolBar title="Reports">
            </ToolBar>
            <Container fixed>
                <DataTable fields={FIELDS} list={list} numItems={numReports} onPage={handlePage} onSort={handleSort} noun="report"
                    createActions={report => <ReportCardActions report={report}/> }
                    styleFieldCell={(field, i) => i === 0 ? {width: "50%"}: {}}
                />
            </Container>
        </Page>
    )
};

export default ReportsPage;
