import React, {useState} from "react";
import {useDispatch} from "react-redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import EmailIcon from "@mui/icons-material/Email";
import InputIcon from "@mui/icons-material/Input";
import {FormRow} from "../util/Form";
import {GoodIcon} from "../util/icons";
import {resetPassword} from './state';

const ResetForm = () => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [isResetting, setIsResetting] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleReset = () => {
        setIsResetting(true);
        dispatch(resetPassword(email))
          .unwrap()
          .then(() => {
            setIsResetting(false);
            setSuccess(true);
        });
    };

    return (
        <form>
            <FormRow>
                <Grid item xs={12}>
                    <Grid container sx={{justifyContent: "center", my: 4}}>
                        {success === true && <GoodIcon/>}
                        <Typography component="span" variant="h5">
                            {success === true ? "Password reset sent, check your inbox." : "Forgot your password? No problem."}
                        </Typography>
                    </Grid>
                </Grid>
            </FormRow>
            <Paper sx={{py:8, mx:4}}>
                <FormRow>
                    <Grid item xs={12}>
                        <TextField label="Email Address" variant="outlined" value={email}
                                   onChange={(e) => setEmail(e.target.value)}
                                   fullWidth
                                   InputProps={{
                                       endAdornment: (
                                           <InputAdornment position="end">
                                               <EmailIcon/>
                                           </InputAdornment>
                                       )
                                   }}
                        />
                    </Grid>
                </FormRow>
                <FormRow>
                    <Grid item xs={12} sx={{textAlign: "center"}}>
                        <Button
                            disabled={isResetting || !email}
                            variant="contained"
                            color="primary"
                            size="large"
                            startIcon={isResetting ? <CircularProgress/> : <InputIcon />}
                            onClick={handleReset}
                        >
                            Reset Password
                        </Button>
                    </Grid>
                </FormRow>
            </Paper>
        </form>
    );
};

export default ResetForm;
