import React, {useEffect, useState} from "react";
import {CardElement, Elements, useElements, useStripe} from "@stripe/react-stripe-js";
import { useTheme } from '@mui/material/styles';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import loadStripe from "../stripe";
import { red } from '@mui/material/colors';
import './CardControl.css';

const stripe = loadStripe();

const cardOpts = theme => ({
    iconStyle: 'solid',
    style: {
        base: {
            // iconColor: '#c4f0ff',
            color: theme.palette.text.primary,
            fontWeight: 400,
            fontFamily: 'Roboto, Helvetica, sans-serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            // ':-webkit-autofill': {color: '#fce883'},
            // '::placeholder': {color: '#87BBFD'},
        },
        invalid: {
            iconColor: red[500],
            color: red[500],
        },
    }
});

const CardControl = ({onChange}) => {
    const stripe = useStripe();
    const elements = useElements();
    const theme = useTheme();

    const [value, setValue] = useState();
    useEffect(() => {
        onChange && stripe && onChange(value, stripe);
    }, [value, onChange, stripe])

    const handleChange = evt => {
        setValue(evt.complete ? elements.getElement(CardElement) : null);
    }

    return (
        <FormControl variant="outlined" fullWidth sx={{backgroundColor: "white"}}>
            <InputLabel shrink={true} sx={{
                bgcolor: 'background.default',
                padding: "0 5px",
                whiteSpace: "nowrap"
            }}>Credit Card</InputLabel>
            <CardElement options={cardOpts(theme)} onChange={handleChange} className="CardControl"/>
            {/*{cardError && <FormHelperText>{cardError.message}</FormHelperText>}*/}
        </FormControl>
    )
};

const CardControlElement = ({...props}) => {
    return (
        <Elements stripe={stripe}>
            <CardControl {...props}/>
        </Elements>
    )
};

export default CardControlElement;
