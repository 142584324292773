import {useDispatch, useSelector} from 'react-redux';
import {TabPanel} from '../../util/Tab';
import {FormSection} from '../../util/Form';
import TextField from '@mui/material/TextField';
import {updateConfig} from '../state';
import React from 'react';


const TableSettingsPanel = ({configName, ...props}) => {
    const options = useSelector(state => state.report.curr[configName].options || {});
    const dispatch = useDispatch();

    return (
        <TabPanel {...props}>
            <FormSection label="Comp Index Prefix" help="Specify the prefix for comp numbers in the report.">
                <TextField value={options.noun} placeholder="No."  size="small"
                           onChange={evt => dispatch(updateConfig({
                               path:`${configName}.options.noun`,
                               value:(evt.target.value)
                           }))} variant="outlined" />
            </FormSection>
        </TabPanel>
    );
};

export default TableSettingsPanel;