import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import React, {Fragment} from "react";
import {useDispatch, useSelector} from "react-redux";
import Moment from "react-moment";
import prettyBytes from 'pretty-bytes';
import {getDownloadURL, getStorage, ref as storageRef, uploadBytesResumable} from 'firebase/storage';
import { lighten } from '@mui/material/styles';
import {useTheme} from '@mui/material';
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Toolbar from '@mui/material/Toolbar';
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import PublishIcon from "@mui/icons-material/Publish";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import ErrorAlert from '../../util/ErrorAlert';
import Pluralize from "../../util/Pluralize";
import PlanLimitAlert from "../../util/PlanLimitAlert";
import {splitFileName} from '../../util/utils';
import CompEditorPanel from './CompEditorPanel';
import {isStorageLimitReachedSelector} from "../../app/selectors";
import {Comp} from "../../model/objects";
import {addDocument, removeDocuments} from '../state';

const DocPanel = ({...props}) => {
    const theme = useTheme();
    const auth = useSelector(state => state.auth);
    const comp = useSelector(state => state.comp.curr);
    const docs = useSelector(state => state.comp.curr.docs || []);
    const dispatch = useDispatch();

    const [running, setRunning] = React.useState(false);
    const [progress, setProgress] = React.useState(0);
    const [selected, setSelected] = React.useState([]);
    const [error, setError] = React.useState(null);

    const isLimitReached = useSelector(isStorageLimitReachedSelector);

    const handleUpload = async (evt) => {
        setError(null);
        const dir = storageRef(getStorage(), `${auth.org.id}/comps/${comp.id}/docs`);
        const files = Array.from(evt.target.files);
        const uploads = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            // find an unused filename
            let fileName = file.name;
            let x = 1;
            while (x < 25) {
                try {
                    await getDownloadURL(storageRef(dir, fileName));
                    let [baseName, ext] = splitFileName(fileName);

                    if (baseName.endsWith(`-${x-1}`)) {
                        baseName = baseName.replace(new RegExp(`-${x-1}$`), `-${x}`)
                    }
                    else {
                        baseName = `${baseName}-${x}`;
                    }
                    fileName = `${baseName}.${ext}`
                    x++;
                } catch (err) {
                    break;
                }
            }

            uploads.push(new Promise((resolve, reject) => {
                const ref = storageRef(dir, fileName);
                setProgress(0);
                const task = uploadBytesResumable(ref, file);
                task.on('state_changed',
                  (snapshot) => {
                      setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                  },
                  (error) => {
                      reject(error);
                  },
                  () => {
                      dispatch(addDocument(`${ref}`))
                        .unwrap()
                        .then(resolve)
                        .catch(setError);
                  }
                );
            }));
        }

        setRunning(true);
        Promise.allSettled(uploads)
          .catch(setError)
          .finally(() => setRunning(false));
    }

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            const newIds = docs.map(d => d.id);
            setSelected(newIds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleDelete = () => {
        const selectedDocs = selected.map(it => docs.find(d => d.id === it));
        dispatch(removeDocuments(selectedDocs));
        setSelected([]);
    };

    const isSelected = id => selected.indexOf(id) !== -1;
    const numSelected = selected.length;
    const rowCount = docs.length;

    const styles = {
        table: {
            flexGrow: 1,
            pl: 2,
            pr: 2
        },
        title: {
            flex: '1 1 100%',
        }
    }
    return (
        <CompEditorPanel name="DocPanel" comp={comp} {...props}>
              {isLimitReached && <PlanLimitAlert title="Storage Limit Reached"
                     message="You have reached the maximum amount of storage for documents that your plan allows for."
                     upgradeMessage="Upgrade your plan to get more storage." gutterBottom/>}
              {!comp.id &&
              <Typography align="center">This comp has not yet been saved. Before you can add documents you must save.</Typography>
              }
              {comp.id && <Fragment>
                  {error && <ErrorAlert error={error} sx={{mb:1}}/>}
                  <Grid container>
                      <Grid item sx={styles.table}>
                          <Paper>
                              <Toolbar sx={{
                                  px: 2,
                                  bgcolor: 'grey.100',
                                  ...(numSelected > 0 && {
                                      color: 'secondary.main',
                                      bgcolor: lighten(theme.palette.secondary.light, 0.85)
                                  })
                              }}
                              >
                                  {numSelected > 0 ? (
                                      <Typography sx={styles.title} color="inherit" variant="subtitle1">
                                          {numSelected} selected
                                      </Typography>
                                  ) : (
                                      <Typography sx={styles.title} variant="subtitle1">
                                          <Pluralize count={rowCount} singular="document"/>
                                      </Typography>
                                  )}

                                  {numSelected > 0 && (
                                      <Tooltip title="Delete">
                                          <IconButton onClick={handleDelete} size="large">
                                              <DeleteIcon />
                                          </IconButton>
                                      </Tooltip>
                                  )}
                              </Toolbar>
                              <TableContainer>
                                  <Table sx={styles.table} >
                                      <TableHead>
                                          <TableRow>
                                              <TableCell padding="checkbox">
                                                  <Checkbox
                                                      indeterminate={numSelected > 0 && numSelected < rowCount}
                                                      checked={rowCount > 0 && numSelected === rowCount}
                                                      onChange={handleSelectAllClick}
                                                  />
                                              </TableCell>
                                              <TableCell>File</TableCell>
                                              <TableCell align="right">Size</TableCell>
                                              <TableCell align="right">Last Updated</TableCell>
                                          </TableRow>
                                      </TableHead>
                                      <TableBody>
                                          {docs.map(doc => (
                                              <TableRow key={doc.id}>
                                                  <TableCell padding="checkbox">
                                                      <Checkbox
                                                          checked={isSelected(doc.id)}
                                                          onClick={evt => handleClick(evt, doc.id)}
                                                      />
                                                  </TableCell>
                                                  <TableCell component="th" scope="row">
                                                      <Link href={doc.url} target="_blank">
                                                          {doc.name}
                                                      </Link>
                                                  </TableCell>
                                                  <TableCell align="right">{prettyBytes(doc.size)}</TableCell>
                                                  <TableCell align="right">
                                                      <Typography component="div">
                                                          <Moment format="MMM DD, YYYY">
                                                              {Comp.date(doc.updated)}
                                                          </Moment>
                                                      </Typography>
                                                      <Typography component="div" sx={{
                                                          fontSize: 'caption.fontSize',
                                                          color: 'grey.500'
                                                      }}>
                                                          <Moment fromNow>
                                                              {Comp.date(doc.updated)}
                                                          </Moment>
                                                      </Typography>
                                                  </TableCell>
                                              </TableRow>
                                          ))}
                                      </TableBody>
                                  </Table>
                              </TableContainer>
                          </Paper>

                      </Grid>
                      <Grid item>
                          <input id="doc-upload" type="file" hidden multiple onChange={handleUpload} />
                          <label htmlFor="doc-upload">
                              <Button
                                  disabled={running}
                                  component="span"
                                  variant="contained"
                                  color="primary"
                                  size="large"
                                  startIcon={<PublishIcon/>}
                              >
                                  Upload
                              </Button>
                          </label>
                          {running &&
                            <Box sx={{p:3}}>
                                <LinearProgress variant="determinate" value={progress} />
                            </Box>}
                      </Grid>
                  </Grid>
              </Fragment>}
          </CompEditorPanel>
    );
};

export default DocPanel;
