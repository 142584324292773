import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Paper from '@mui/material/Paper';
import {useTheme} from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import SettingsIcon from '@mui/icons-material/Settings';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import CartButton from '../cart/CartButton';
import {TypeAvatarWithStatus} from '../comp/TypeAvatar';
import {Field, FieldLabel} from '../model/fields';
import {extendSearch, zoomTo} from './state';
import Button from '@mui/material/Button';


const Cell = ({children, sx, ...props}) => {
  return (
    <TableCell sx={{
      borderBottom:0,
      paddingTop: 0,
      ...sx
    }} {...props}>
      {children}
    </TableCell>
  )
}

const ResultCard = ({comp, ...props}) => {
  const dispatch = useDispatch();
  const thumb = comp.photos && comp.photos.length > 0 ? comp.photos[0].url : comp.map;
  return (
    <Paper elevation={1} {...props} sx={{width: '100%'}}>
      <Card sx={{display: 'flex'}}>
        <CardActionArea sx={{display: 'flex', flexGrow: 1, alignItems: 'flex-start'}}>
          <CardMedia image={thumb} sx={{
            width: 128,
            height: 128,
            objectFit: 'cover',
            objectPosition: 'center',
            flexShrink: 0
          }}/>
          <CardContent sx={{flexGrow: 1, p: 0}}>
            <CardHeader title={comp.title} subheader={comp.address?.city} avatar={
              <TypeAvatarWithStatus comp={comp}/>
            } sx={{alignItems: 'flex-start'}}/>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <Cell>
                    <Typography variant="caption">{Field.VALUE.label(FieldLabel.DEFAULT, {comp})}</Typography>
                  </Cell>
                  <Cell align="right">
                    <Typography variant="body2">
                      {Field.VALUE.render(comp)}
                    </Typography>
                  </Cell>
                </TableRow>
                <TableRow>
                  <Cell>
                    <Typography variant="caption">{Field.DATE.label(FieldLabel.DEFAULT, {comp})}</Typography>
                  </Cell>
                  <Cell align="right">
                    <Typography variant="body2">
                      {Field.DATE.render(comp)}
                    </Typography>
                  </Cell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </CardActionArea>
        <CardActions sx={{flexDirection: "column", bgcolor: 'grey.100'}}>
          <CartButton comp={comp} size="small"/>
          <IconButton
            component={Link}
            to={"/comp/" + comp.id}
            className="SettingsLink"
            size="small"
            target="_blank">
            <SettingsIcon />
          </IconButton>
          <IconButton
            size="small"
            className="ZoomToLink"
            onClick={() => dispatch(zoomTo(comp))}
            sx={{
              ml: "0 !important"
            }}
          >
            <MyLocationIcon/>
          </IconButton>
        </CardActions>
      </Card>
    </Paper>
  )
}

const ResultPanel = () => {
  const dispatch = useDispatch();
  const result = useSelector(state => state.search.result);
  const open = useSelector(state => state.search.view.result.open);
  const height = useSelector(state => state.search.view.map.height);
  const isSearching = useSelector(state => state.search.searching === true);
  const theme = useTheme();

  return (
    <Stack alignItems="flex-end" spacing={1} sx={{
      width: "448px",
      flexShrink: 0,
      maxHeight: height,
      overflowY: 'scroll',
      // bgcolor: "grey.100",
      ...(open && {
        px: 1,
        width: "448px",
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }),
      ...(!open && {
        px: 0,
        width: "0px",
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      })
    }} >
      {result.hits?.map(comp => <ResultCard comp={comp} key={comp.id}/>)}
      {((result.hits?.length??0) < result.count) && <Button variant="outlined" sx={{
        width: '100%', textAlign: 'center', py: 2
      }} disabled={isSearching} onClick={() => dispatch(extendSearch())}>
        Load More Results
      </Button>}
    </Stack>
  )
}

export default ResultPanel;
