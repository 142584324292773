import React, {useRef, useState} from "react";
import {useDrag, useDrop} from "react-dnd";
import {useDispatch} from 'react-redux';
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Typography from "@mui/material/Typography";
import TextFieldsIcon from '@mui/icons-material/TextFields';
import CheckIcon from '@mui/icons-material/Check';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import LooksOneOutlinedIcon from '@mui/icons-material/LooksOneOutlined';
import LooksTwoOutlinedIcon from '@mui/icons-material/LooksTwoOutlined';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import {overlapPositionY, pointInRect} from "../../util/dnd";
import {FieldLabel, FieldOptions} from "../../model/fields";
import EditableLabel from "../../util/EditableLabel";
import {setFieldSpanInSection} from '../state';
import {ALIGN_CENTER, ALIGN_JUSTIFY, ALIGN_LEFT, ALIGN_RIGHT, nextAlign} from '../../model/fonts';
import {Stack} from '@mui/material';
import Divider from '@mui/material/Divider';

const DRAG_TYPE_FIELD = "field";

const Field = ({
       page, section, field, index, style, label, span, sx, withSpan = false, withAlign = false,
       onMove, onRemove, onStyle, onEdit, sectionRef, defaultAlign = ALIGN_LEFT, isLabelStyleSet = () => false,
       comp, ...props
    }) => {
    const dispatch = useDispatch();
    const [anchor, setAnchor] = useState(null);
    const [position, setPosition] = useState(null);

    const ref = useRef(null);
    const [{isTarget},drop] = useDrop({
        accept: DRAG_TYPE_FIELD,
        drop(item, monitor) {
            // check that we are not dropping on another section
            if (sectionRef && !pointInRect(monitor.getInitialClientOffset(), sectionRef.current.getBoundingClientRect())) {
                return;
            }
            onMove(item.index, index, (position === 'bottom' ? 'after' : 'before'));
        },

        hover(item, monitor) {
            setPosition(overlapPositionY(monitor, ref));
        },

        collect: monitor => ({
            isTarget: monitor.canDrop() && monitor.isOver()
        })
    });
    const [{ isDragging }, drag] = useDrag({
        item: { type: DRAG_TYPE_FIELD, id: field.path, index },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    const closeMenu = evt => {
        setAnchor(null);
        evt.stopPropagation();
    }

    const isStyleSet = (key, path) => {
        if (style) {
            if (path) {
                let v = style[path]?.[key];
                if (typeof v !== 'undefined') return v === true;
            }

            let v = style[key];
            if (typeof v !== 'undefined') return v === true;
        }
        return path === 'label' && isLabelStyleSet(key);
    }

    const open = Boolean(anchor);
    const id = open ? 'popover' : undefined;

    const isLabelBold = isStyleSet('bold', 'label');
    const isLabelItalic = isStyleSet('italic', 'label');
    const isLabelUnderline = isStyleSet('underline', 'label');
    const isValueBold = isStyleSet('bold', 'value');
    const isValueItalic = isStyleSet('italic', 'value');
    const isValueUnderline = isStyleSet('underline', 'value');

    const typeStyles = {
        ...(isLabelBold && {fontWeight: "bold"}),
        ...(isLabelItalic && {fontStyle: "italic"}),
        ...(isLabelUnderline && {textDecoration: "underline"}),
    };
    const align = style?.align || defaultAlign || ALIGN_LEFT;

    const defaultLabel = field.label(FieldLabel.REPORT_HEADER, {
        ...(comp && {[FieldOptions.COMP.key]: comp}),
    });
    const l = label || defaultLabel;

    const p = isTarget && position ? position : null;
    return (
        <Grid ref={ref} container style={{opacity}} sx={{
            flexWrap: "nowrap",
            alignItems: "center",
            borderTop: `1px solid ${p==='top'?'#c7c7c7':'transparent'}`,
            borderBottom: `1px solid ${p==='bottom'?'#c7c7c7':'transparent'}`,
            ...sx
        }} {...props}>
            <Grid item>
                <Tooltip title={"Remove field"}>
                    <IconButton onClick={() => onRemove(index)} size="large">
                        <HighlightOffIcon/>
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item sx={{
                flexGrow: "1",
                display: "inline-flex",
                flexDirection: "column"
            }}>
                {onEdit ?
                    <EditableLabel value={l} onChange={onEdit} withButton={false} withClick={true} withEditHelp={false} typeSx={typeStyles}>
                    </EditableLabel> : <Typography variant="subtitle2" sx={typeStyles}>
                        {l}
                    </Typography>
                }
                {l !== defaultLabel && <Typography variant="caption" sx={{fontStyle: "italic"}}>{defaultLabel}</Typography>}
            </Grid>
            <Grid item>
                {withSpan && <ToggleButtonGroup size="small" value={span.toString()} exclusive
                        onChange={(evt,v) => dispatch(setFieldSpanInSection({
                            field, section, page, value: parseInt(v)}))
                        }>
                    <ToggleButton value="1">
                        <LooksOneOutlinedIcon/>
                    </ToggleButton>
                    <ToggleButton value="2">
                        <LooksTwoOutlinedIcon/>
                    </ToggleButton>
                </ToggleButtonGroup>}
                {onStyle && <>
                    <Stack direction="row" flexWrap="nowrap">
                        <IconButton onClick={evt => setAnchor(evt.currentTarget)} size="small" sx={{verticalAlign: 'baseline'}}>
                            <TextFieldsIcon/>
                        </IconButton>
                        {withAlign && <IconButton onClick={() => onStyle("align", nextAlign(style.align))} size="small" sx={{verticalAlign: 'baseline'}}>
                            {align === ALIGN_LEFT && <FormatAlignLeftIcon/>}
                            {align === ALIGN_RIGHT && <FormatAlignRightIcon/>}
                            {align === ALIGN_CENTER && <FormatAlignCenterIcon/>}
                            {align === ALIGN_JUSTIFY && <FormatAlignJustifyIcon/>}
                        </IconButton>}
                    </Stack>
                    <Menu
                        id={id}
                        anchorEl={anchor}
                        keepMounted
                        open={Boolean(anchor)}
                        onClose={closeMenu}
                    >
                        <Divider textAlign="left" sx={{
                            color: "grey.600",
                            fontSize: "0.8em",
                        }}>Field Label</Divider>
                        <MenuItem onClick={() => onStyle("label.bold", !isLabelBold)}>
                            <ListItemIcon>
                                {isLabelBold ? <CheckIcon fontSize="small" /> : <span/>}
                            </ListItemIcon>
                            <Typography variant="inherit">Bold</Typography>
                        </MenuItem>
                        <MenuItem onClick={() => onStyle("label.italic", !isLabelItalic)}>
                            <ListItemIcon>
                                {isLabelItalic ? <CheckIcon fontSize="small" /> : <span/>}
                            </ListItemIcon>
                            <Typography variant="inherit">Italic</Typography>
                        </MenuItem>
                        <MenuItem onClick={() => onStyle("label.underline", !isLabelUnderline)}>
                            <ListItemIcon>
                                {isLabelUnderline ? <CheckIcon fontSize="small" /> : <span/>}
                            </ListItemIcon>
                            <Typography variant="inherit">Underline</Typography>
                        </MenuItem>
                        <Divider textAlign="left" sx={{
                            color: "grey.600",
                            fontSize: "0.8em",
                        }}>Field Value</Divider>
                        <MenuItem onClick={() => onStyle("value.bold", !isValueBold)}>
                            <ListItemIcon>
                                {isValueBold ? <CheckIcon fontSize="small" /> : <span/>}
                            </ListItemIcon>
                            <Typography variant="inherit">Bold</Typography>
                        </MenuItem>
                        <MenuItem onClick={() => onStyle("value.italic", !isValueItalic)}>
                            <ListItemIcon>
                                {isValueItalic ? <CheckIcon fontSize="small" /> : <span/>}
                            </ListItemIcon>
                            <Typography variant="inherit">Italic</Typography>
                        </MenuItem>
                        <MenuItem onClick={() => onStyle("value.underline", !isValueUnderline)}>
                            <ListItemIcon>
                                {isValueUnderline ? <CheckIcon fontSize="small" /> : <span/>}
                            </ListItemIcon>
                            <Typography variant="inherit">Underline</Typography>
                        </MenuItem>
                    </Menu>
                </>}
            </Grid>
        </Grid>
    );
};

export default Field;
