import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from '@mui/material/IconButton';
import HelpIcon from '@mui/icons-material/Help';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import {DialogActions} from '@mui/material';


const HelpDialog = ({title, open, onClose, children}) => {
    return (
        <Dialog open={open} onClose={onClose} scroll="paper" maxWidth="md">
            <DialogTitle>
              {title}
            </DialogTitle>
            <DialogContent dividers sx={{
              "& > p": {
                marginBottom: "1rem"
              },
            }}>
                {children}
            </DialogContent>
          <DialogActions>
            <Button onClick={() => onClose(false)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
    );
};

export default HelpDialog;

export const WithHelp = ({title, help, children}) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  return (
      <Stack direction="row">
        {children}
        <IconButton sx={{alignSelf: "center"}} onClick={handleOpen} size="large">
          <HelpIcon/>
        </IconButton>
        <HelpDialog title={title} open={open} onClose={handleClose}>
          {help}
        </HelpDialog>
      </Stack>
  );
};
