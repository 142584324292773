import React from "react";
import {useDispatch, useSelector} from "react-redux";
import ct from "countries-and-timezones";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {TabPanel} from "../util/Tab";
import {FormRow} from "../util/Form";
import SelectWithLabel from "../util/SelectWithLabel";
import LogoField from "./LogoField";
import {updateOrg} from './state';


const TimeZoneField = ({value, country, onChange}) => {
    const values = country ? ct.getTimezonesForCountry(country.toUpperCase()).map(it => it.name) : [];
    return (
        <SelectWithLabel label="Time Zone" value={value||""} values={values} onChange={onChange}
                         variant="outlined" fullWidth disabled={!country} />
    );
};

const OrgPanel = (props) => {
    const org = useSelector(state => state.pref.org);
    const dispatch = useDispatch();

    const handleChange = field => evt => {
        dispatch(updateOrg({[field]: evt.target.value}));
    };

    const handleLogoChange = data => {
        dispatch(updateOrg({logo: data}));
    };
    return (
        <TabPanel {...props} className="OrgPanel">
            {org &&
            <form>
                <Grid container spacing={4}>
                    <Grid item md={6}>
                        <FormRow>
                            <Grid item xs={12}>
                                <TextField label="Organization Name" value={org.name||''} onChange={handleChange("name")}
                                           variant="outlined" fullWidth/>
                            </Grid>
                        </FormRow>
                        <FormRow>
                            <Grid item xs={12}>
                                <TextField label="Website URL" value={org.url||''} onChange={handleChange("url")}
                                           variant="outlined" fullWidth/>
                            </Grid>
                        </FormRow>
                        <FormRow>
                            <Grid item xs={12}>
                                <SelectWithLabel label="Country" values={[["ca", "Canada"], ["us", "United States"]]}
                                        value={org.country||''} onChange={handleChange("country")}/>
                            </Grid>
                        </FormRow>
                        <FormRow>
                            <Grid item xs={12}>
                                <TimeZoneField value={org.timezone} country={org.country} onChange={handleChange("timezone")}/>
                            </Grid>
                        </FormRow>
                        <FormRow>
                            <Grid item xs={6}>
                                <TextField label="Monitor Density" value={window.devicePixelRatio} disabled={true}
                                           variant="outlined" fullWidth/>
                            </Grid>
                        </FormRow>
                    </Grid>
                    <Grid item md={6}>
                        <LogoField value={org.logo} onChange={handleLogoChange}/>
                    </Grid>
                </Grid>
            </form>
            }
        </TabPanel>
    );
};

export default OrgPanel;
