export const STYLE_ITALIC = "italic";
export const STYLE_NORMAL = "normal";
export const WEIGHT_BOLD = 600;
export const WEIGHT_NORMAL = 400;
export const TRANSFORM_NONE = "none";
export const TRANSFORM_UPPERCASE = "uppercase";
export const DECORATION_NONE = "none";
export const DECORATION_UNDERLINE = "underline";
export const ALIGN_LEFT = "left";
export const ALIGN_CENTER = "center";
export const ALIGN_RIGHT = "right";
export const ALIGN_JUSTIFY = "justify";

export const nextAlign = (align) => {
    if (!align || (align === ALIGN_LEFT)) return ALIGN_CENTER;
    if (align === ALIGN_CENTER) return ALIGN_RIGHT;
    if (align === ALIGN_RIGHT) return ALIGN_JUSTIFY;
    return ALIGN_LEFT;
}

/**
 * NOTE: When we update this, we need to also change report/word.js because it has the same font mappings.
 */
export const SYSTEM_FONTS = [
    {
        id: "sans-serif",
        name: "Sans Serif",
        family: "sans-serif",
        type: "native",
        href: "sans-serif"
    },
    {
        id: "serif",
        name: "Serif",
        family: "serif",
        type: "native",
        href: "serif"
    }
];

export const GOOGLE_FONTS = [
    {
        id: "arimo",
        name: "Arimo",
        type: "google",
        href: "Arimo:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700"
    },
    {
        id: "eb-garamond",
        name: "EB Garamond",
        type: "google",
        href: "EB+Garamond:ital,wght@0,400;0,600;0,700;1,400;1,500;1,700"
    },
    {
        id: "fira-sans",
        name: "Fira Sans",
        type: "google",
        href: "Fira+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700"
    },
    {
        id: "gfs-didot",
        name: "GFS Didot",
        type: "google",
        href: "GFS+Didot:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700"
    },
    {
        id: "jost",
        name: "Jost",
        type: "google",
        href: "Jost:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&"
    },
    {
        id: "lato",
        name: "Lato",
        type: "google",
        href: "Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700"
    },
    {
        id: "libre-baskerville",
        name: "Libre Baskerville",
        type: "google",
        href: "Libre+Baskerville:ital,wght@0,400;0,700;1,400"
    },
    {
        id: "michroma",
        name: "Michroma",
        type: "google",
        href: "Michroma"
    },
    {
        id: "montserrat",
        name: "Montserrat",
        type: "google",
        href: "Montserrat:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700"
    },
    {
        id: "noto-sans",
        name: "Noto Sans",
        type: "google",
        href: "Noto+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700"
    },
    {
        id: "open-sans",
        name: "Open Sans",
        type: "google",
        href: "Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700"
    },
    {
        id: "roboto",
        name: "Roboto",
        type: "google",
        href: "Roboto:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700"
    },
    {
        id: "tinos",
        name: "Tinos",
        type: "google",
        href: "Tinos:ital,wght@0,400;0,700;1,400;1,700"
    }
]

export const MICROSOFT_FONTS = [
    {
        id: "arial",
        name: "Arial",
        type: "excel",
        previewId: "arimo"
    },
    {
        id: "calibri",
        name: "Calibri",
        type: "excel",
        previewId: "open-sans"
    },
    {
        id: "century-gothic",
        name: "Century Gothic",
        type: "excel",
        previewId: "montserrat"
    },
    {
        id: "garamond",
        name: "Garamond",
        type: "excel",
        previewId: "eb-garamond"
    },
    {
        id: "palatino",
        name: "Palatino Linotype",
        type: "excel",
        previewId: "gfs-didot"
    },
    {
        id: "segoe-ui",
        name: "Segoe UI",
        type: "excel",
        previewId: "noto-sans"
    },
    {
        id: "times",
        name: "Times New Roman",
        type: "excel",
        previewId: "tinos"
    },
    {
        id: "trebuchet",
        name: "Trebuchet MS",
        type: "excel",
        previewId: "fira-sans"
    },
    {
        id: "verdana",
        name: "Verdana",
        type: "excel",
        previewId: "jost"
    },
]

export const FONTS = [...SYSTEM_FONTS, {type: 'divider', label: 'Microsoft Fonts'}, ...MICROSOFT_FONTS, {type: 'divider', label: 'Google Fonts'}, ...GOOGLE_FONTS];

export const fontURL = f => {
    if (f.previewId) {
        return fontURL(lookupFont(f.previewId));
    }

    return `https://fonts.googleapis.com/css2?family=${f.href}&display=swap`;
}

export const lookupFont = (id,fonts=FONTS) => fonts.find(it => it.id === id) || fonts[0];

