import React, {useEffect, useMemo, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {get, shuffle} from 'lodash';
import {bbox, envelope, point, distance} from "@turf/turf";
import Box from "@mui/material/Box";
import {pushSnack} from '../../app/state';
import dot from "../../util/dot.svg";
import star from "../../util/star.svg";
import {Map, MoveIcon} from "../../util/Map";
import {ConfirmationSource, Field, Fields} from "../../model/fields";
import {Comp} from "../../model/objects";
import {updateConfig} from '../state';
import {TYPE_SINGLE} from '../../model/reportTypes';
import {lookupFont} from '../../model/fonts';


const ReportMap = ({isPreview, setMap, mapProps}) => {
    const org = useSelector(state =>  state.app.org);
    const comps = useSelector(state => (state.cart.comps || [])
        .filter(it => Field.CONFIRMATION_IS_CONFIDENTIAL.get(it) !== true));
    const options = useSelector(state => state.report.curr.options || {});
    const dispatch = useDispatch();
    const reportType = useSelector(state => state.report.curr.type);
    const subject = useSelector(state => state.report.curr.hasSubject === true ? state.report.curr.subject : null);
    const config = useSelector(state => state.report.curr.map || {});
    const canvas = useRef();
    const renderer = useRef();

    const renderConfig = useMemo(() => ({
        ...options,
        ...config,
    }), [options, config]);

    useEffect(() => {
        renderer.current?.update(renderConfig);
    }, [renderConfig]);

    const mapOpts = {
        withNavigation: true,
        preserveDrawingBuffer: isPreview === false,
        ...(org.settings||{}).map,
        ...(config.view?.center ? config.view : {}),
        tools: [{
            control: new TogglePanControl(enabled => {
                if (canvas.current) {
                    canvas.current.style.pointerEvents = enabled ? "none" : "auto";
                }
            }),
            position: 'top-right'
        }],
        ...mapProps
    };

    const mapData = () => {
        const data = {
            type: "FeatureCollection",
            features: []
        };

        if (subject) {
            const s = subject;
            data.features.push({
                type: "Feature",
                id: s.id,
                geometry: {type: "Point", coordinates: Comp.point(s.geo)},
                properties: {
                    ...(config.popup.fields.reduce((obj, f) => {
                        obj[f.path] = Fields.lookup(f.path)?.render(s) || ""
                        return obj;
                    }, {})),
                    index: 0,
                    distance: 0,
                    distance_units: "km",
                    subject: true,
                    listing: false
                }
            });
        }
        comps.forEach((c,i) => {
            const g = Comp.point(c.geo);
            const dist = subject ? Number(distance(point(g), point(Comp.point(subject.geo))).toFixed(2)) : 0;
            data.features.push({
                type: "Feature",
                id: c.id,
                geometry: {type: "Point", coordinates: g},
                properties: {
                    ...(config.popup.fields.reduce((obj, f) => {
                        obj[f.path] = Fields.lookup(f.path)?.render(c) || "";
                        return obj;
                    }, {})),
                    distance: dist,
                    distance_units: "km",
                    index: i+1,
                    subject: false,
                    listing: ConfirmationSource.isCurrentListing(c)
                }
            });
        });

        return data;
    }

    const initMap = m => {

        m.on("load", () => {
            const dotImg = new Image(32,32);
            dotImg.onload = () => {
                m.addImage("dot", dotImg);
            };
            dotImg.src = dot;

            const starImg = new Image(32,32);
            starImg.onload = () => {
                m.addImage("star", starImg);
            };
            starImg.src = star;

            const data = mapData();
            m.addSource("comp", { type: "geojson", data});
            m.addLayer({
                id: 'comps',
                type: 'circle',
                source: 'comp',
                paint: {
                    'circle-radius': 5,
                    'circle-color': ['case',
                        ['==', ['get', 'subject'], true],
                        config.style.dot.subject.color,
                        ['==', ['get', 'listing'], true],
                        config.style.dot.listing.color,
                        config.style.dot.default.color
                    ],
                    'circle-stroke-width': 2,
                    'circle-stroke-color': 'white'
                }
            })

            if (!config?.view?.center) {
                m.fitBounds(bbox(envelope(data)), {padding: 100, maxZoom: 14, animate: false});
            }

            m.on("moveend", () => {
                dispatch(updateConfig({path:"map.view", value:{zoom: m.getZoom(), center: m.getCenter().toArray()}, dirty: false}));
            });
            if (isPreview === false) {
                m.scrollZoom.disable();
            }

            renderer.current?.destroy();
            renderer.current = new CompRenderer(mapData(), canvas.current, m, renderConfig)
                .listen((event, popup) => {
                   dispatch(updateConfig({
                       path: `map.popup.positions.${popup.id}`,
                       value: [popup.rect.x1 - popup.anchor.x, popup.rect.y1 - popup.anchor.y],
                       dirty: false
                   }));
                });
            window.renderer = renderer.current;
        });
        m.on('zoomend', () => { renderer.current?.init()?.render(); });
        m.on('moveend', () => { renderer.current?.init()?.render(); });
        m.on('resize', () => { renderer.current?.init()?.render(); });

        if (setMap) setMap(m);
    };

    return (
        <Box sx={{
            width: '100%',
            aspectRatio: config.aspect ? config.aspect : (reportType === TYPE_SINGLE ? "1.413542926" : "0.707442258"),
            position: 'relative',
            pb: isPreview ? 4 : 0
        }}>
            <Map initMap={initMap} withRefresh={true} onRefresh={m => {
                dispatch(pushSnack({message: `Comp map data reloaded.`, duration: 2000}));
                m.getSource("comp").setData(mapData());
                renderer.current?.render();
            }} {...mapOpts}/>
            <canvas ref={canvas} id="popups" style={{
                position: 'absolute', top: 0, bottom: 0, left: 0, zIndex: 1
            }}/>
        </Box>
    )
};

export class TogglePanControl {

    constructor(onClick) {
        this.enabled = false;
        this.onClick = onClick;
    }

    onAdd(map){
        this.map = map;

        const button = document.createElement("button");
        button.className = "mapbox-gl-draw_ctrl-draw-btn refresh-ctrl";

        button.style.setProperty("background-image", 'url(' + MoveIcon + ')');
        button.style.setProperty('background-color', 'white');
        button.title = "Toggle Map Panning";
        button.addEventListener("click", evt => {
            evt.stopPropagation();
            evt.preventDefault();

            this.enabled = !this.enabled;
            button.style.setProperty('background-color', this.enabled?'#c7c7c7':'white');
            this.onClick(this.enabled);
        });

        this.container = document.createElement("div");
        this.container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
        this.container.appendChild(button);

        return this.container;
    }

    onRemove(){
        this.container.parentNode.removeChild(this.container);
        this.map = undefined;
        this.marker = undefined;
    }
}

class CompRenderer {

    constructor(data, canvas, map, config) {
        this.data = data;
        this.popups = data.features.map((comp) => Popup.create(comp));
        this.canvas = canvas;
        this.map = map;
        this.config = config;
        this.boxes = [];
        this.dragging = false;
        this.mouse = null;
        this.listeners = [];

        canvas.addEventListener('mousemove', this.onMouseMove.bind(this));
        canvas.addEventListener('mousedown', this.onMouseDown.bind(this));
        canvas.addEventListener('mouseup', this.onMouseUp.bind(this));

        this.init();
        this.render();
    }

    listen(handler) {
        this.listeners.push(handler);
        return this;
    }

    init() {
        const space = new Space();
        // const box = bbox(this.data);
        // space.insert(Rect.fromPoints(this.map.project([box[0], box[1]]), this.map.project([box[2], box[3]])));

        this.popups.forEach(p => p.place(this.map, this.canvas.getContext('2d'), space, this.config));
        return this;
    }

    update(config) {
        this.config = config;
    }

    render() {
        // set up some styling
        this.canvas.style.width = `${Math.round(this.map.getCanvas().width / window.devicePixelRatio)}px`;
        this.canvas.style.height = `${Math.round(this.map.getCanvas().height / window.devicePixelRatio)}px`;

        const g = this.canvas.getContext('2d');
        g.canvas.width = this.map.getCanvas().width;
        g.canvas.height = this.map.getCanvas().height;
        g.clearRect(0, 0, g.canvas.width, g.canvas.height);
        g.lineWidth = 2;
        g.scale(window.devicePixelRatio, window.devicePixelRatio);

        // render all of the boxes
        this.popups.forEach((popup) => {
            // comp.place(this.map);
            const r = popup.rect;
            const p = popup.pivot;
            const a = popup.anchor;
            const d = 10;

            if (popup.data.subject === true) {
                g.fillStyle = this.config.style.popup.subject.color;
            }
            else if (popup.data.listing === true) {
                g.fillStyle = this.config.style.popup.listing.color;
            }
            else {
                g.fillStyle = this.config.style.popup.default.color;
            }

            g.beginPath();
            g.lineJoin = "round";

            switch(popup.orientation) {
                case "n":
                    g.moveTo(r.x1, r.y1);
                    g.lineTo(r.x2, r.y1);
                    g.lineTo(r.x2, r.y2);
                    g.lineTo(p.x+d, r.y2);
                    g.lineTo(a.x, a.y);
                    g.lineTo(p.x-d, r.y2);
                    g.lineTo(r.x1, r.y2);
                    break;
                case "ne":
                    g.moveTo(r.x1, r.y1);
                    g.lineTo(r.x2, r.y1);
                    g.lineTo(r.x2, r.y2);
                    g.lineTo(p.x+d, p.y);
                    g.lineTo(a.x, a.y);
                    g.lineTo(p.x, p.y-d);
                    break;
                case "e":
                    g.moveTo(r.x1, r.y1);
                    g.lineTo(p.x, p.y-d);
                    g.lineTo(a.x, a.y);
                    g.lineTo(p.x, p.y+d);
                    g.lineTo(r.x1, r.y2);
                    g.lineTo(r.x2, r.y2);
                    g.lineTo(r.x2, r.y1);
                    break;
                case "se":
                    g.moveTo(a.x, a.y);
                    g.lineTo(p.x+d, p.y);
                    g.lineTo(r.x2, r.y1);
                    g.lineTo(r.x2, r.y2);
                    g.lineTo(r.x1, r.y2);
                    g.lineTo(p.x, p.y+d);
                    break;
                case "s":
                    g.moveTo(r.x1, r.y1);
                    g.lineTo(p.x-d, p.y);
                    g.lineTo(a.x, a.y);
                    g.lineTo(p.x+d, p.y);
                    g.lineTo(r.x2, r.y1);
                    g.lineTo(r.x2, r.y2);
                    g.lineTo(r.x1, r.y2);
                    break;
                case "sw":
                    g.moveTo(r.x1, r.y1);
                    g.lineTo(p.x-d, p.y);
                    g.lineTo(a.x, a.y);
                    g.lineTo(p.x, p.y+d);
                    g.lineTo(r.x2, r.y2);
                    g.lineTo(r.x1, r.y2);
                    break;
                case "w":
                    g.moveTo(r.x1, r.y1);
                    g.lineTo(r.x2, r.y1);
                    g.lineTo(p.x, p.y-d);
                    g.lineTo(a.x, a.y);
                    g.lineTo(p.x, p.y+d);
                    g.lineTo(r.x2, r.y2);
                    g.lineTo(r.x1, r.y2);
                    break;
                case "nw":
                default:
                    g.moveTo(r.x1, r.y1);
                    g.lineTo(r.x2, r.y1);
                    g.lineTo(p.x, p.y-d);
                    g.lineTo(a.x, a.y);
                    g.lineTo(p.x-d, p.y)
                    g.lineTo(r.x1, r.y2);
                    break;
            }
            g.closePath();

            g.shadowColor = "rgba(0,0,0,0.35)";
            g.shadowBlur = 5;
            g.shadowOffsetX = 2;
            g.shadowOffsetY = 2;
            g.fill();

            g.shadowColor = 0;
            g.shadowBlur = 0;
            g.shadowOffsetX = 0;
            g.shadowOffsetY = 0;

            let y = r.y1 + Popup.PADDING;
            for (const line of popup.text(this.config, g)) {
                g.font = line.font;
                g.fillStyle = line.fill;

                const s = g.measureText(line.text);
                y += s.actualBoundingBoxAscent + s.actualBoundingBoxDescent;

                g.fillText(line.text, r.x1+Popup.PADDING, y);
                y+= Popup.LINE_SPACING;
            }

            for (let t = 0; t < popup.tries.length; t++) {
                const r = popup.tries[t];
                g.strokeStyle = "red";
                g.strokeRect(r.x, r.y, r.width, r.height);
            }
        });
        return this;
    }

    onMouseMove(e) {
        const x = e.x - this.canvas.getBoundingClientRect().left;
        const y = e.y - this.canvas.getBoundingClientRect().top;
        let target = this.first(x, y);
        e.target.style.cursor = target ? 'move' : 'default';

        if (this.dragging) {
            const dx = x - this.mouse[0];
            const dy = y - this.mouse[1];
            this.mouse = [x, y];

            this.popups.filter(p => p.dragging).forEach(p => {
                p.rect.shift(dx, dy);
                this.listeners.forEach(l => l('moved', p));
            });
            this.render();
        }
    }

    onMouseDown(e) {
        const x = e.x - this.canvas.getBoundingClientRect().left;
        const y = e.y - this.canvas.getBoundingClientRect().top;
        this.dragging = true;
        this.mouse = [x, y];

        this.query(x, y).forEach(p => p.dragging = true);
    }

    onMouseUp(e) {
        this.dragging = false;
        this.mouse = null;
        this.popups.forEach(p => p.dragging = false);
    }

    first(x, y) {
        for (const p of this.popups) {
            if (p.rect.contains(x, y)) {
                return p;
            }
        }
        return null;
    }
    query(x, y) {
        return this.popups.filter(p => p.rect.contains(x, y));
    }

    destroy() {
        this.canvas.removeEventListener('mousemove', this.onMouseMove);
        this.canvas.removeEventListener('mousedown', this.onMouseDown);
        this.canvas.removeEventListener('mouseup', this.onMouseUp);
        this.listeners.length = 0;
    }

    static toFontString(f) {
        let font = lookupFont(f.family);
        if (font.previewId) font = lookupFont(font.previewId);

        return `${f.style} normal ${f.weight} ${f.size}${f.unit} "${font?.name}"`;
    }
}

class Popup {
    static PADDING = 16;
    static LINE_SPACING = 5;
    static PLACEMENTS = ['n', 'ne', 'e', 'se', 's', 'sw', 'w', 'nw'];

    constructor(id, geo, data) {
        this.id = id;
        this.geo = geo;
        this.data = data;
        this.dragging = false;
        this.anchor = null;
        this.rect = null;
        this.lineHeight = 0
        this.tries = [];
    }

    text(config, g) {
        const isSubject = this.data.subject === true;
        const lines = [];
        if (config.popup.index.enabled) {
            lines.push({
                text: isSubject ? 'Subject' : `${config.popup.index.noun||'Index'} ${(this.data.index + (config.offset||1) - 1)}`,
                font: CompRenderer.toFontString(config.popup.index.style.font),
                fill: config.popup.index.style.font.color
            });
        }
        for (const field of config.popup.fields) {
            if (!(field.path in this.data)) continue;
            lines.push({
                text: get(this.data, field.path),
                font: CompRenderer.toFontString(field.style.font),
                fill: field.style.font.color
            })
        }
        if (!isSubject && config.popup.distance.enabled) {
            lines.push({
                text: `${this.data.distance} ${this.data.distance_units}`,
                font: CompRenderer.toFontString(config.popup.distance.style.font),
                fill: config.popup.distance.style.font.color
            });
        }
        return lines;
    }

    place(map, g, space, config) {
        let h = 0;
        let w = 0;
        const lines = this.text(config, g);
        for (const line of lines) {
            g.font = line.font;

            const size = g.measureText(line.text);
            w = Math.max(w, size.width);

            const lh = size.actualBoundingBoxAscent + size.actualBoundingBoxDescent;
            this.lineHeight = Math.max(this.lineHeight, lh);

            h += lh;
        }
        h += Popup.LINE_SPACING * (lines.length - 1) + Popup.PADDING*2
        w += Popup.PADDING*2;

        const anchor = map.project(this.geo);
        this.anchor = anchor;

        let rect = new Rect(anchor.x - w/2, anchor.y - h/2, w, h);
        if (this.id in config.popup.positions) {
            const pos = config.popup.positions[this.id];
            rect = new Rect(pos[0] + anchor.x, pos[1] + anchor.y, w, h);
        }
        else {
            const placements = shuffle(Popup.PLACEMENTS);
            O: for (let z = 1; z < 5; z++) {
                for (let p of placements) {
                    const r = rect.displace(anchor, z*50, p);
                    if (!space.query(r))  {
                        rect = r;
                        break O;
                    }
                }
            }
        }

        space.insert(rect.expand(anchor.x, anchor.y));
        this.rect = rect;
    }

    get orientation() {
        // calculate the position of the box relative to the anchor
        let pos = "n";
        if (this.anchor.x >= this.rect.x2 && this.anchor.y >= this.rect.y2) {
            pos = "nw";
        }
        else if (this.anchor.x >= this.rect.x1 && this.anchor.x <= this.rect.x2 && this.anchor.y >= this.rect.y2) {
            pos = "n";
        }
        else if (this.anchor.x <= this.rect.x1 && this.anchor.y >= this.rect.y2) {
            pos = "ne";
        }
        else if (this.anchor.x <= this.rect.x1 && this.anchor.y >= this.rect.y1 && this.anchor.y <= this.rect.y2) {
            pos = "e";
        }
        else if (this.anchor.x <= this.rect.x1 && this.anchor.y <= this.rect.y1) {
            pos = "se";
        }
        else if (this.anchor.x >= this.rect.x1 && this.anchor.x <= this.rect.x2 && this.anchor.y <= this.rect.y1) {
            pos = "s";
        }
        else if (this.anchor.x >= this.rect.x2 && this.anchor.y <= this.rect.y1) {
            pos = "sw";
        }
        else if (this.anchor.x >= this.rect.x2 && this.anchor.y >= this.rect.y1 && this.anchor.y <= this.rect.y2) {
            pos = "w";
        }

        return pos;
    }

    get pivot() {
        switch(this.orientation) {
            case "n": return {x: this.rect.x1 + this.rect.width/2, y: this.rect.y2};
            case "ne": return {x: this.rect.x1, y: this.rect.y2};
            case "e": return {x: this.rect.x1 , y: this.rect.y1 + this.rect.height/2};
            case "se": return {x: this.rect.x1, y: this.rect.y1};
            case "s": return {x: this.rect.x1 + this.rect.width/2, y: this.rect.y1};
            case "sw": return {x: this.rect.x2, y: this.rect.y1};
            case "w": return {x: this.rect.x2, y: this.rect.y1 + this.rect.height/2};
            case "nw":
            default:
                return {x: this.rect.x2, y: this.rect.y2};
        }
    }

    static create(comp) {
        return new Popup(comp.id, comp.geometry.coordinates, comp.properties);
    }
}

class Rect {
    constructor(x, y, width, height) {
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
    }

    get x1() {
        return this.x;
    }
    get x2() {
        return this.x + this.width;
    }

    get y1() {
        return this.y;
    }

    get y2() {
        return this.y + this.height;
    }

    get xm() {
        return this.x1 + this.width/2;
    }

    get ym() {
        return this.y1 + this.height/2;
    }

    contains(x, y) {
        return x >= this.x && x <= this.x + this.width &&
            y >= this.y && y <= this.y + this.height;
    }

    intersects(rect) {
        return this.contains(rect.x1, rect.y1) ||
            this.contains(rect.x1, rect.y2) ||
            this.contains(rect.x2, rect.y1) ||
            this.contains(rect.x2, rect.y2);
    }

    expand(x, y) {
        const rect = new Rect(this.x, this.y, this.width, this.height);
        if (x < this.x1) {
            rect.x = x;
            rect.width = this.x2 - x;
        }
        else if (x > this.x2) {
            rect.width = x - this.x1;
        }

        if (y < this.y1) {
            rect.y = y;
            rect.height = this.y2 - y;
        }
        else if (y > this.y2) {
            rect.height = y - this.y1;
        }

        return rect;
    }

    shift(dx, dy) {
        this.x += dx;
        this.y += dy;
    }

    displace(point, dist, quadrant) {
        switch(quadrant) {
            case "n":
                return Rect.shift(this, 0, -(dist+this.height));
            case "ne": {
                const delta = dist / Math.sqrt(2);
                return Rect.shift(this, delta+this.width, -(delta+this.height));
            }
            case "e":
                return Rect.shift(this, dist+this.width, 0);
            case "se": {
                const delta = dist / Math.sqrt(2);
                return Rect.shift(this, delta+this.width, delta+this.height);
            }
            case "s":
                return Rect.shift(this, 0, dist+this.height);
            case "sw": {
                const delta = dist / Math.sqrt(2);
                return Rect.shift(this, -(delta + this.width), delta + this.height);
            }
            case "w":
                return Rect.shift(this, -(dist+this.width), 0);
            case "nw":
            default: {
                return Rect.shift(this, -(dist + this.width), -(dist + this.height));
            }

        }
    }

    static shift(rect, dx, dy) {
        return new Rect(rect.x + dx, rect.y + dy, rect.width, rect.height);
    }

    static fromPoints(p1, p2) {
        const x = Math.min(p1.x, p2.x);
        const y = Math.min(p1.y, p2.y);
        const w = Math.abs(p2.x - p1.x);
        const h = Math.abs(p2.y - p1.y);
        return new Rect(x, y, w, h);
    }
}

class Space {
    constructor() {
        this.rects = [];
    }

    query(rect) {
        for (const r of this.rects) {
            if (r.intersects(rect)) {
                return true;
            }
        }
        return false;
    }

    insert(rect) {
        this.rects.push(rect);
    }
}


export default ReportMap;
