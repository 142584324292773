import {getAuth, signInWithEmailAndPassword} from 'firebase/auth';
import React from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {FormRow} from "../util/Form";
import ErrorAlert from "../util/ErrorAlert";
import {BadIcon, GoodIcon} from "../util/icons";
import {acceptInvite} from './state';

const JoinForm = ({invite}) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [data, setData] = React.useState(invite);
    const handleUpdate = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value.trim()
        });
    };

    const testPass = pass => {
        const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{10,}$/;
        return re.test(pass);
    };
    const [pass, setPass] = React.useState();
    const [passOk, setPassOk] = React.useState(false);
    const handlePass = e => {
        const p = e.target.value.trim();
        setPass(p);
        setPassOk(testPass(p));
    };

    const [passMatch, setPassMatch] = React.useState(false);
    const [passConfirm, setPassConfirm] = React.useState();
    const handlePassConfirm = e => {
        const p = e.target.value.trim();
        setPassConfirm(p);
        setPassMatch(p === pass);
    };

    const [isJoining, setIsJoining] = React.useState(false);
    const [error, setError] = React.useState();
    const handleJoin = () => {
        setIsJoining(true);
        setError(null);

        dispatch(acceptInvite({...invite, ...data, pass}))
          .unwrap()
          .then(result => {
            if (result.status === "success") {
                signInWithEmailAndPassword(getAuth(), data.email, pass)
                    .then(() => {
                        history.replace("/");
                    })
                    .catch(setError);
            }
            else {
                setError(result.error);
            }
            setIsJoining(false);
        })
    };

    return (
        <form>
            <FormRow>
                <Grid item xs={12}>
                    <Typography variant="h5" sx={{ textAlign: "center", my: 4}}>
                        To finalize your account we need you to confirm your details and set a password.
                    </Typography>
                </Grid>
            </FormRow>
            <Paper sx={{py: 8, px: 4}}>
                <FormRow>
                    <Grid item md={6}>
                        <TextField label="First Name" name="first_name" value={data.first_name}
                                   onChange={handleUpdate}
                                   variant="outlined" fullWidth/>
                    </Grid>
                    <Grid item md={6}>
                        <TextField label="Last Name" name="last_name" value={data.last_name}
                                   onChange={handleUpdate}
                                   variant="outlined" fullWidth/>
                    </Grid>
                </FormRow>
                <FormRow>
                    <Grid item md={6}>
                        <TextField label="Password" name="pass" type="password" value={pass}
                                   onChange={handlePass}
                                   helperText="Must be at least 10 characters and contain a mix of numbers, symbols, lower, and upper case."
                                   variant="outlined" fullWidth
                                   InputProps={{
                                       endAdornment: pass && (
                                           <InputAdornment position="end">
                                               {passOk ? <GoodIcon/> : <BadIcon/> }
                                           </InputAdornment>
                                       )
                                   }}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField disabled={!passOk} label="Confirm Password" name="passConfirm" type="password"
                                   onChange={handlePassConfirm}
                                   variant="outlined" fullWidth
                                   InputProps={{
                                       endAdornment: passConfirm && (
                                           <InputAdornment position="end">
                                               {passMatch ? <GoodIcon/> : <BadIcon/> }
                                           </InputAdornment>
                                       )
                                   }}
                               />
                    </Grid>
                </FormRow>
                <FormRow>
                    <Grid item xs={12} sx={{textAlign: "center"}}>
                        <Button
                            disabled={!(passOk && passMatch) || isJoining}
                            variant="contained"
                            color="primary"
                            size="large"
                            startIcon={isJoining ? <CircularProgress size={24}/> : <CheckCircleOutlineIcon />}
                            onClick={handleJoin}
                        >
                            Join
                        </Button>
                    </Grid>
                </FormRow>
                {error && <ErrorAlert error={error} sx={{mt: 2}}/>}
            </Paper>
        </form>
    );
};

export default JoinForm;

