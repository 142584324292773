import React from "react";
import Box from '@mui/material/Box';
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ImageListItem from "@mui/material/ImageListItem";

const ImageWithItemBar = ({src, alt, onClick, itemBarProps, ...props}) => {
    return (
        <ImageListItem onClick={onClick} {...props} component="span">
            <Box component="img" alt={alt||""} src={src} sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                "&:hover": {
                    cursor: "pointer",
                    filter: "brightness(85%)"
                }
            }}/>
            {itemBarProps && <ImageListItemBar {...itemBarProps}/>}
        </ImageListItem>
    );
};

export default ImageWithItemBar;
