import React, {Fragment} from "react";
import {useDispatch, useSelector} from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PublicPage from "../util/PublicPage";
import Spinner from "../util/Spinner";
import JoinForm from "./JoinForm";
import {fetchInvite} from './state';

const JoinPage = () => {
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    const invite = auth.invite;

    const [isFetching, setIsFetching] = React.useState(true);
    React.useEffect(() => {
        // if (!firebase.auth().isSignInWithEmailLink(window.location.href)) {
        //     history.push("/");
        // }
        const params = new URLSearchParams(decodeURIComponent(window.location.search));
        dispatch(fetchInvite(params.get("invite"))).unwrap().then(() => setIsFetching(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchInvite]);

    return (
        <PublicPage>
            {isFetching ? <Spinner/>
                :
                invite ?
                <Fragment>
                    <Typography variant="h3">{invite.invited_by.name} has invited you to join {invite.org_name} on QuickComp!</Typography>
                    <Box sx={{maxWidth: 'breakpoints.values.md'}} margin="auto">
                        <JoinForm invite={invite} />
                    </Box>
                </Fragment>
                :
                <Fragment>
                    <Typography variant="h3">Sorry, this invite has expired or is no longer valid.</Typography>
                </Fragment>
            }
        </PublicPage>
    );
};

export default JoinPage;

