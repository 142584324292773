import React from "react";
import logo_sm from "./logos/logo_100@2x.png";
import logo_md from "./logos/logo_250@2x.png";
import logo_lg from "./logos/logo_350@2x.png";
import logo_dark_sm from "./logos/logo-dark_100@2x.png";
import logo_dark_md from "./logos/logo-dark_250@2x.png";
import logo_dark_lg from "./logos/logo-dark_350@2x.png";

const Logo = ({size, theme, className, ...props}) => {
    let logo;
    switch(size) {
        case "sm":
            switch(theme) {
                case "dark":  logo = logo_dark_sm; break;
                default: logo = logo_sm;
            }
            break;
        case "md":
            switch(theme) {
                case "dark":  logo = logo_dark_md; break;
                default: logo = logo_md;
            }
            break;
        default:
            switch(theme) {
                case "dark":  logo = logo_dark_lg; break;
                default: logo = logo_lg;
            }
    }
    return (
        <img alt="QuickComp" src={logo} className={className} {...props}/>
    );
};

export default Logo;
