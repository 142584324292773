import React, {forwardRef, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {NumericFormat} from "react-number-format";
import parse from 'html-react-parser';
import {concat, filter} from "lodash";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import Stack from '@mui/material/Stack';
import IconButton from "@mui/material/IconButton";
import AssignmentIcon from '@mui/icons-material/Assignment';
import ClearIcon from '@mui/icons-material/Clear';
import Tooltip from "@mui/material/Tooltip";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import {DatePicker} from '@mui/x-date-pickers';
import SelectWithLabel from "../../util/SelectWithLabel";
import {Comp} from "../../model/objects";
import {Field, FieldType, FieldLabel} from "../../model/fields";
import {clearField, setField, toggleDetachedField} from '../state';
import Badge from '@mui/material/Badge';
import {styled} from '@mui/material';


const Highlight = styled(Badge)({
    '&&': {
        width: '100%',
        display: 'block'
    },
    '.MuiBadge-badge': {
        width: '16px',
        height: '16px',
        borderRadius:'100%',
        opacity: 0.75
    }
})
const DateField = forwardRef(({onChange, value, ...props}, ref) => {
    const handleChange = m => {
      onChange(m ? m.toDate() : null);
    };

    return (
        <DatePicker
            renderInput={(params) => <TextField {...params} name={props.name} fullWidth={props.fullWidth===true}/>}
            onChange={handleChange}
            invalidDateMessage=""
            emptyLabel="Choose a date"
            clearable
            value={Comp.date(value)}
            inputFormat="MMM Do, YYYY"
            inputVariant="outlined"
            InputLabelProps={{shrink: true}}
            {...props}
        />
    );
});

const NumberField = forwardRef(({field, comp, onChange, ...props}, ref) => {
    const p = {...props};
    if (!props.disabled) {
        if (field.isDerived(comp)) {
            p.onChange = evt => {
                let v = evt.target.value;
                if (v) {
                    v = Field.NUMBER_PARSER.parse(v);
                }

                onChange(v);
            }
        }
        else {
            p.onValueChange = v => onChange(v.floatValue);
        }
    }
    if (field.hasUnit) {
        p.InputProps = {
            endAdornment: <InputAdornment position="end">{parse(field.unit(comp).symbol)}</InputAdornment>
        }
    }

    return (<NumericFormat {...p} customInput={TextField}/>);
});

const IntegerField = forwardRef(({...props}, ref) => {
    return (
        <NumberField {...props}/>
    )
});

const PercentField = forwardRef(({...props}, ref) => {
    return (
        <NumberField {...props} decimalScale={2} fixedDecimalScale={true}
                 InputProps={{
                     endAdornment: <InputAdornment position="end">%</InputAdornment>,
                 }}
        />
    );
});

const CurrencyField = forwardRef(({field ,...props}, ref) => {
    return (
        <NumberField field={field} {...props} decimalScale={field.numDecimals || 2} fixedDecimalScale={true} thousandSeparator={true}
                InputProps={{
                    startAdornment: <InputAdornment position="start">{`${field.isNegative?"-":""}$`}</InputAdornment>,
                }}/>
    );
});

const BooleanField = forwardRef(({value, label, useSwitch, fullWidth, onChange,  ...props}, ref) => {
    const handleChange = (evt) => onChange(evt.target.checked === true);
    const p = {checked: value || false, onChange: handleChange, ...props };

    return (
        <FormControlLabel
            control={
                useSwitch || false ?
                    <Switch {...p} color="primary"/> :
                    <Checkbox {...p} color="primary" />
            }
            label={label}

    />);
});

const SelectField = forwardRef(({value, multiple, fullWidth, ...props}, ref) => {
    const dispatch = useDispatch();
    return (
        <Stack direction="row" gap={1} alignItems="center" width={fullWidth?'100%':'initial'}>
            <SelectWithLabel value={value || (multiple === true ? [] : '')} multiple={multiple} fullWidth={fullWidth} {...props}/>
            <IconButton size="small" disabled={!value} onClick={() => dispatch(clearField({field:props.name}))}>
                <ClearIcon/>
            </IconButton>
        </Stack>
    )

});

const ChoiceField = forwardRef(({value, choices, label, onChange, ...props}, ref) => {
    const checked = value || [];
    const handleCheck = v => evt => {
        const on = evt.target.checked;
        if (on) {
            onChange(concat(checked, v));
        }
        else {
            onChange(filter(checked, it => it !== v));
        }
    };
    return (
        <FormControl>
            <FormLabel>{label}</FormLabel>
            <FormGroup sx={{ ml: 1, flexDirection: "row"}}>
                {choices.map(it => <FormControlLabel key={it} label={it}
                    control={<Checkbox name={it} checked={checked.includes(it)} onChange={handleCheck(it)}/>}/>)}
            </FormGroup>
        </FormControl>
    );
});

const CompField = ({field, comp, highlight, withCopy, ...props}) => {
    let ref = useRef();
    let [copied, setCopied] = useState(false);
    const dispatch = useDispatch();

    const handleChange = value => {
        if (value) {
            if (typeof value === "object" && "target" in value) {
                value = value.target.value;
            }
        }
        dispatch(setField({field, value}));
    };

    const isHighlighted = highlight?.includes(field.path) || false;
    const isDerived = field.isDerived(comp);
    const isDisabled = isDerived && !(comp.detached||[]).includes(field.path);
    const isDetached = (comp.detached||[]).includes(field.path);
    let fieldProps = {
        name: field.path,
        value: field.get(comp),
        comp,
        fullWidth: true,
        disabled: isDisabled,
        variant: "outlined",
        placeholder: field.placeholder,
        onChange: handleChange,
        ref,
        ...props
    };
    if (field.showFormLabel) {
        fieldProps.label = field.label(FieldLabel.FORM, comp);
    }
    if (copied) {
        fieldProps.helperText = "Copied.";
    }
    if (field.placeholder) {
        fieldProps.InputLabelProps={shrink: true}
    }
    if (field.isMultiple) {
        fieldProps.multiple = true;
    }

    let f;
    switch(field.type) {
        case FieldType.DATE:
            f = <DateField {...fieldProps} />;
            break;

        case FieldType.INTEGER:
            f = <IntegerField {...fieldProps} {...{field}} thousandSeparator={field.formatWithComma}/>;
            break;

        case FieldType.NUMBER:
            f =<NumberField {...fieldProps} {...{field}} fixedDecimalScale={true} thousandSeparator={field.formatWithComma}
                    decimalScale={typeof field.numDecimals === 'undefined' ? 2 : field.numDecimals}/>;
            break;

        case FieldType.PERCENT:
            f =<PercentField {...fieldProps} {...{field}} />;
            break;

        case FieldType.CURRENCY:
            f =<CurrencyField {...fieldProps} {...{field}} />;
            break;

        case FieldType.BOOLEAN:
            f =<BooleanField {...fieldProps} />;
            break;

        default:
            if (field.hasValues) {
                const values = field.values(comp);
                f = field.isChoice ? <ChoiceField {...fieldProps} choices={values}/> :  <SelectField {...fieldProps} values={values}/>;
            }
            else if (field.hasUnit) {
                f = <TextField {...fieldProps} InputProps={{
                    endAdornment: <InputAdornment position="end">{parse(field.unit(comp).symbol)}</InputAdornment>
                }}/>;
            }
            else {
                f = <TextField {...fieldProps} value={fieldProps.value || ""}/>;
            }
    }

    if (withCopy || isDerived || isHighlighted) {
        const r = <Box style={{
            display: "flex",
        }}>
            {f}
            {isDerived && <IconButton
                style={{alignSelf: "center"}}
                name={`${field.path}_detach`}
                onClick={() => dispatch(toggleDetachedField(field))}
                size="large">
                <Tooltip title={`${isDetached?"Attach":"Detach"} Field`}>
                    {isDetached?<LinkIcon/>:<LinkOffIcon/>}
                </Tooltip>
            </IconButton>}
            {withCopy && <IconButton
                style={{alignSelf: "start"}}
                onClick={e => {
                    ref.current.querySelector("input").select();
                    document.execCommand('copy');
                    e.target.closest("button").focus();
                    setCopied(true);
                }}
                size="large">
                <AssignmentIcon/>
            </IconButton>}
        </Box>;
        return isHighlighted ? <Highlight color="secondary" variant="dot" badgeContent=" " anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}>
            {r}
        </Highlight> : r;
    }
    else return f;
};

export default CompField;
