import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SelectWithLabel from "../../util/SelectWithLabel";
import TextBuilder from '../../util/TextBuilder';
import { FieldLabel } from "../../model/fields";
import { addExtraStyle, removeExtraStyle, updateStyle, updateView } from '../state';
import { Accordion, AccordionDetails, AccordionSummary, Button, IconButton } from "@mui/material";


export const FieldStyles = (props) => {
    const dispatch = useDispatch();
    const sheet = useSelector(state => state.sheet.curr);
    const panel = useSelector(state =>state.sheet.view.tabs.layout.accordion);
    const handlePanelChange = (p) => {
        dispatch(updateView({path: 'tabs.layout.accordion', value: panel === p ? null : p}))
    }

    const fields = sheet.fields;
    const styles = sheet.styles;
    const hasAdjusts = sheet.adjusts?.enabled === true;
    const hasTime = sheet.time?.enabled === true;
    const hasMetrics = sheet.metrics?.enabled === true;

    const fieldPanelStyles = {
        label: {
            fontStyle: "italic",
            fontSize: '0.9rem'
        },
        section: {
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
            borderBottomColor: 'grey.300',
            pb: 1
        }
    }

    const fontFamily = styles?.base?.font?.family;
    const textBuilderOpts = {
        showAlign: true,
        showBackground: true,
        showFamily: false,
        withFamily: fontFamily
    };

    const isExpanded = key => key === panel;
    return (
        <>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="body1" sx={fieldPanelStyles.label} gutterBottom>
                    Fields
                </Typography>
                <Button onClick={() => dispatch(addExtraStyle())} startIcon={<AddCircleIcon/>}>Add</Button>
            </Box>
            {styles.extra.map((e,i) => <Accordion key={i} elevation={isExpanded(e.field)?1:0} expanded={isExpanded(e.field)} onChange={() => handlePanelChange(e.field)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{alignItems: 'center'}}>
                    <IconButton size="small" onClick={() => dispatch(removeExtraStyle(i))}>
                        <DeleteIcon/>
                    </IconButton>
                    <SelectWithLabel label="Field" size="small" value={e.field??""} fullWidth={false}
                            values={fields.map(f => [f.path, f.label(FieldLabel.SHORT)])}
                            onChange={evt => dispatch(updateStyle({path: `extra[${i}].field`, value: evt.target.value}))}
                    />
                </AccordionSummary>
                <AccordionDetails>
                    {e.field && <Grid container>
                        <Grid item md={6}>
                            <Typography variant="body1" sx={fieldPanelStyles.label} gutterBottom>
                                Header
                            </Typography>
                            <TextBuilder config={e.config.head} size="small" {...textBuilderOpts}
                                onFontChange={(k, v) => dispatch(updateStyle({ path: `extra[${i}].config.head.font.${k}`, value: v }))}
                                onBackgroundChange={(v) => dispatch(updateStyle({ path: `extra[${i}].config.head.background`, value: v }))} />
                        </Grid>
                        <Grid item md={6}>
                            <Typography variant="body1" sx={fieldPanelStyles.label} gutterBottom>
                                Body
                            </Typography>
                            <TextBuilder config={e.config.body} size="small" {...textBuilderOpts}
                                onFontChange={(k, v) => dispatch(updateStyle({ path: `extra[${i}].config.body.font.${k}`, value: v }))}
                                onBackgroundChange={(v) => dispatch(updateStyle({ path: `extra[${i}].config.body.background`, value: v }))} />
                        </Grid>
                    </Grid>}
                    {!e.field && <Typography fontStyle="italic">Please choose a field first.</Typography>}
                </AccordionDetails>
            </Accordion>)}
            <Accordion elevation={isExpanded('data')?2:0} expanded={isExpanded('data')} onChange={() => handlePanelChange('data')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="body1" gutterBottom>
                        Data Fields
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid item md={6}>
                            <Typography variant="body1" sx={fieldPanelStyles.label} gutterBottom>
                                Header
                            </Typography>
                            <TextBuilder config={styles.field.head} size="small" {...textBuilderOpts}
                                onFontChange={(k, v) => dispatch(updateStyle({ path: `field.head.font.${k}`, value: v }))}
                                onBackgroundChange={(v) => dispatch(updateStyle({ path: `field.head.background`, value: v }))} />
                        </Grid>
                        <Grid item md={6}>
                            <Typography variant="body1" sx={fieldPanelStyles.label} gutterBottom>
                                Body
                            </Typography>
                            <TextBuilder config={styles.field.body} size="small" {...textBuilderOpts}
                                onFontChange={(k, v) => dispatch(updateStyle({ path: `field.body.font.${k}`, value: v }))}
                                onBackgroundChange={(v) => dispatch(updateStyle({ path: `field.body.background`, value: v }))} />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion elevation={isExpanded('index')?2:0} expanded={isExpanded('index')} onChange={() => handlePanelChange('index')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="body1" gutterBottom>
                        Index Fields
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid item md={6}>
                            <Typography variant="body1" sx={fieldPanelStyles.label} gutterBottom>
                                Header
                            </Typography>
                            <TextBuilder config={styles.index.head} size="small" {...textBuilderOpts}
                                onFontChange={(k, v) => dispatch(updateStyle({ path: `index.head.font.${k}`, value: v }))}
                                onBackgroundChange={(v) => dispatch(updateStyle({ path: `index.head.background`, value: v }))} />
                        </Grid>
                        <Grid item md={6}>
                            <Typography variant="body1" sx={fieldPanelStyles.label} gutterBottom>
                                Body
                            </Typography>
                            <TextBuilder config={styles.index.body} size="small" {...textBuilderOpts}
                                onFontChange={(k, v) => dispatch(updateStyle({ path: `index.body.font.${k}`, value: v }))}
                                onBackgroundChange={(v) => dispatch(updateStyle({ path: `index.body.background`, value: v }))} />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion elevation={isExpanded('unit')?2:0} expanded={isExpanded('unit')} onChange={() => handlePanelChange('unit')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="body1" gutterBottom>
                        Unit of Comparison Field
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid item md={6}>
                            <Typography variant="body1" sx={fieldPanelStyles.label} gutterBottom>
                                Header
                            </Typography>
                            <TextBuilder config={styles.unit.head} size="small" {...textBuilderOpts}
                                onFontChange={(k, v) => dispatch(updateStyle({ path: `unit.head.font.${k}`, value: v }))}
                                onBackgroundChange={(v) => dispatch(updateStyle({ path: `unit.head.background`, value: v }))} />
                        </Grid>
                        <Grid item md={6}>
                            <Typography variant="body1" sx={fieldPanelStyles.label} gutterBottom>
                                Body
                            </Typography>
                            <TextBuilder config={styles.unit.body} size="small" {...textBuilderOpts}
                                onFontChange={(k, v) => dispatch(updateStyle({ path: `unit.body.font.${k}`, value: v }))}
                                onBackgroundChange={(v) => dispatch(updateStyle({ path: `unit.body.background`, value: v }))} />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            {hasAdjusts && <Accordion elevation={isExpanded('adjust')?2:0} expanded={isExpanded('adjust')} onChange={() => handlePanelChange('adjust')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="body1" gutterBottom>
                        Adjustment Fields
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid item md={6}>
                            <Typography variant="body1" sx={fieldPanelStyles.label} gutterBottom>
                                Header
                            </Typography>
                            <TextBuilder config={styles.adjust.head} size="small" {...textBuilderOpts}
                                onFontChange={(k, v) => dispatch(updateStyle({ path: `adjust.head.font.${k}`, value: v }))}
                                onBackgroundChange={(v) => dispatch(updateStyle({ path: `adjust.head.background`, value: v }))} />
                        </Grid>
                        <Grid item md={6}>
                            <Typography variant="body1" sx={fieldPanelStyles.label} gutterBottom>
                                Body
                            </Typography>
                            <TextBuilder config={styles.adjust.body} size="small" {...textBuilderOpts}
                                onFontChange={(k, v) => dispatch(updateStyle({ path: `adjust.body.font.${k}`, value: v }))}
                                onBackgroundChange={(v) => dispatch(updateStyle({ path: `adjust.body.background`, value: v }))} />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>}
            {hasTime && <Accordion elevation={isExpanded('time')?2:0} expanded={isExpanded('time')} onChange={() => handlePanelChange('time')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="body1" gutterBottom>
                        Time Adjustment Field
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid item md={6}>
                            <Typography variant="body1" sx={fieldPanelStyles.label} gutterBottom>
                                Header
                            </Typography>
                            <TextBuilder config={styles.time.head} size="small" {...textBuilderOpts}
                                onFontChange={(k, v) => dispatch(updateStyle({ path: `time.head.font.${k}`, value: v }))}
                                onBackgroundChange={(v) => dispatch(updateStyle({ path: `time.head.background`, value: v }))} />
                        </Grid>
                        <Grid item md={6}>
                            <Typography variant="body1" sx={fieldPanelStyles.label} gutterBottom>
                                Body
                            </Typography>
                            <TextBuilder config={styles.time.body} size="small" {...textBuilderOpts}
                                onFontChange={(k, v) => dispatch(updateStyle({ path: `time.body.font.${k}`, value: v }))}
                                onBackgroundChange={(v) => dispatch(updateStyle({ path: `time.body.background`, value: v }))} />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>}
            {hasAdjusts && <Accordion elevation={isExpanded('total')?2:0} expanded={isExpanded('total')} onChange={() => handlePanelChange('total')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="body1" gutterBottom>
                        Total Adjustment Field
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid item md={6}>
                            <Typography variant="body1" sx={fieldPanelStyles.label} gutterBottom>
                                Header
                            </Typography>
                            <TextBuilder config={styles.total.head} size="small" {...textBuilderOpts}
                                         onFontChange={(k, v) => dispatch(updateStyle({ path: `total.head.font.${k}`, value: v }))}
                                         onBackgroundChange={(v) => dispatch(updateStyle({ path: `total.head.background`, value: v }))} />
                        </Grid>
                        <Grid item md={6}>
                            <Typography variant="body1" sx={fieldPanelStyles.label} gutterBottom>
                                Body
                            </Typography>
                            <TextBuilder config={styles.total.body} size="small" {...textBuilderOpts}
                                         onFontChange={(k, v) => dispatch(updateStyle({ path: `total.body.font.${k}`, value: v }))}
                                         onBackgroundChange={(v) => dispatch(updateStyle({ path: `total.body.background`, value: v }))} />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>}
            {(hasAdjusts || hasTime) && <Accordion elevation={isExpanded('final')?2:0} expanded={isExpanded('final')} onChange={() => handlePanelChange('final')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="body1" gutterBottom>
                        Final Result Field
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid item md={6}>
                            <Typography variant="body1" sx={fieldPanelStyles.label} gutterBottom>
                                Header
                            </Typography>
                            <TextBuilder config={styles.final.head} size="small" {...textBuilderOpts}
                                onFontChange={(k, v) => dispatch(updateStyle({ path: `final.head.font.${k}`, value: v }))}
                                onBackgroundChange={(v) => dispatch(updateStyle({ path: `final.head.background`, value: v }))} />
                        </Grid>
                        <Grid item md={6}>
                            <Typography variant="body1" sx={fieldPanelStyles.label} gutterBottom>
                                Body
                            </Typography>
                            <TextBuilder config={styles.final.body} size="small" {...textBuilderOpts}
                                onFontChange={(k, v) => dispatch(updateStyle({ path: `final.body.font.${k}`, value: v }))}
                                onBackgroundChange={(v) => dispatch(updateStyle({ path: `final.body.background`, value: v }))} />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>}
            {hasMetrics && <Accordion elevation={isExpanded('metric')?2:0} expanded={isExpanded('metric')} onChange={() => handlePanelChange('metric')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="body1" gutterBottom>
                        Metrics
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid item md={6}>
                            <Typography variant="body1" className={styles.label} gutterBottom>
                                Header
                            </Typography>
                            <TextBuilder config={styles.metric.head} size="small" {...textBuilderOpts}
                                onFontChange={(k, v) => dispatch(updateStyle({ path: `metric.head.font.${k}`, value: v }))}
                                onBackgroundChange={(v) => dispatch(updateStyle({ path: `metric.head.background`, value: v }))} />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>}
        </>
    )
};

// export default AdjustSheet;

