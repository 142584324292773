import Box from '@mui/material/Box';
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {TabPanel} from "../../util/Tab";
import {FormSection} from "../../util/Form";
import FontControl from "../../util/FontControl";
import ColorPicker from "../../util/ColorPicker";
import TextPreview from "./TextPreview";
import {LIGHT_GRAY} from '../../model/colors';
import {updateConfig} from '../state';


const TableStylePanel = ({configName, ...props}) => {
    const style = useSelector(state => state.report.curr[configName].style);
    const dispatch = useDispatch();

    const handleFontChange = path => {
        return (key, value) => {
            dispatch(updateConfig({path:`${configName}.style.${path}.font.${key}`, value}));
        };
    };
    const handleColorChange = path => {
        return color => {
            dispatch(updateConfig({path:`${configName}.style.${path}`, value:color}));
        }
    };
    const handleChange = path => {
        return evt => {
            dispatch(updateConfig({path:`${configName}.style.${path}`, value:evt.target.value}));
        }
    };

    const isBodyAlt = style.body.alt?.enabled === true;
    const isIndexAlt = style.index.alt?.enabled === true;

    return (
        <TabPanel {...props}>
            <FormSection label="Header" help="The font and background of the table header.">
                <Box>
                    <FontControl font={style.header.font} onChange={handleFontChange("header")} showAlign={true}/>
                </Box>
                <Box py={2}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={3}>
                            <ColorPicker label="Background" value={style.header.background || ""}
                                         onChange={handleColorChange("header.background")}/>
                        </Grid>
                        <Grid item>
                            <TextPreview font={style.header.font} background={style.header.background}/>
                        </Grid>
                    </Grid>
                </Box>
            </FormSection>
            <FormSection label="Body" help="The font and background of the rows of the table. This can be changed for individual fields on the Layout tab.">
                <Box>
                    <FontControl font={style.body.font} onChange={handleFontChange("body")} showAlign={true}/>
                </Box>
                <Box pt={2}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={3}>
                            <ColorPicker label="Background" value={style.body.background || ""}
                                         onChange={handleColorChange("body.background")}/>
                        </Grid>
                        <Grid item>
                            <TextPreview font={style.body.font} background={style.body.background}/>
                        </Grid>
                    </Grid>
                </Box>
                <Box py={2}>
                    <Grid container spacing={2} alignItems="flex-start">
                        <Grid item md={6}>
                            <FormControlLabel
                                control={<Checkbox name="alt" checked={isBodyAlt} onChange={() => dispatch(updateConfig({
                                    path:`${configName}.style.body.alt.enabled`, value:!isBodyAlt}))}/>}

                                label={`Alternate row background color`}
                            />
                        </Grid>
                        <Grid item md={6}>
                            {isBodyAlt && <ColorPicker label="Alternate Background" value={style.body.alt?.background || LIGHT_GRAY}
                                         onChange={handleColorChange("body.alt.background")}/>}
                        </Grid>
                    </Grid>
                </Box>
            </FormSection>
            <FormSection label="Index" help="The font and background of the index column.">
                <Box>
                    <FontControl font={style.index.font} onChange={handleFontChange("index")} showAlign={true}/>
                </Box>
                <Box pt={2}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={3}>
                            <ColorPicker label="Background" value={style.index.background || ""}
                                         onChange={handleColorChange("index.background")} withClear={true}/>
                        </Grid>
                        <Grid item>
                            <TextPreview font={style.index.font} background={style.index.background}/>
                        </Grid>
                    </Grid>
                </Box>
                <Box py={2}>
                    <Grid container spacing={2} alignItems="flex-start">
                        <Grid item md={6}>
                            <FormControlLabel
                                control={<Checkbox name="alt" checked={isIndexAlt} onChange={() => dispatch(updateConfig({
                                    path:`${configName}.style.index.alt.enabled`, value:!isIndexAlt}))}/>}

                                label={`Alternate row background color`}
                            />
                        </Grid>
                        <Grid item md={6}>
                            {isIndexAlt && <ColorPicker label="Alternate Background" value={style.index.alt?.background || LIGHT_GRAY}
                                                         onChange={handleColorChange("index.alt.background")}/>}
                        </Grid>
                    </Grid>
                </Box>
            </FormSection>
            <FormSection label="Caption" help="The font and background of the table caption.">
                <Box>
                    <FontControl font={style.caption.font} onChange={handleFontChange("caption")} />
                </Box>
                <Box py={2}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={3}>
                            <ColorPicker label="Caption" value={style.caption.background || ""}
                                         onChange={handleColorChange("caption.background")}/>
                        </Grid>
                        <Grid item>
                            <TextPreview font={style.caption.font} background={style.caption.background}/>
                        </Grid>
                    </Grid>
                </Box>
            </FormSection>
            <FormSection label="Border" help="The border color and width of the table.">
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <ColorPicker label="Color" value={style.border.color || ""}
                                     onChange={handleColorChange("border.color")}/>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField label="Width" name="width" value={style.border.width} onChange={handleChange("border.width")}
                                   variant="outlined"
                                   InputProps={{
                                       endAdornment: <InputAdornment position="end">px</InputAdornment>,
                                   }}/>
                    </Grid>
                </Grid>
            </FormSection>
            <FormSection label="Spacing" help="The amount of space around cells of content in the table.">
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <TextField label="Padding" name="padding" value={style.padding} onChange={handleChange("padding")}
                                   variant="outlined"/>
                    </Grid>
                </Grid>
            </FormSection>
        </TabPanel>
    );
};

export default TableStylePanel;
