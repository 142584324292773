import React from "react";
import PaymentIcon from "@mui/icons-material/Payment";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import ReceiptIcon from "@mui/icons-material/Receipt";
import {TabPanel, TabSideBar} from "../../util/Tab";
import PlanPanel from "./PlanPanel";
import CardPanel from "./CardPanel";
import InvoicePanel from "./InvoicePanel";


const BillingPanel = (props) => {
    return (
        <TabPanel {...props}>
            <TabSideBar tabs={[
                [{label: "Plan", icon: <ViewColumnIcon/>}, (p) => <PlanPanel {...p} />],
                [{label: "Payment", icon: <PaymentIcon/>}, (p) => <CardPanel {...p} />],
                [{label: "Invoices", icon: <ReceiptIcon/>}, (p) => <InvoicePanel {...p} />],
            ]}/>
        </TabPanel>
    );
}

export default BillingPanel;
