import React, {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {get, throttle} from "lodash";
import Grid from "@mui/material/Grid";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from "@mui/material/Box";
import { DEFAULT_ZOOM, STYLE_STREETS, STYLE_SATELLITE, STYLE_LIGHT, STYLE_SATELLITE_STREETS, STYLE_DARK, STYLE_OUTDOORS} from "../mapbox"
import SelectWithLabel from "../util/SelectWithLabel";
import {FormRow} from "../util/Form";
import {TabPanel} from "../util/Tab";
import PrefMap from './PrefMap';
import {updateOrgSetting} from './state';


const MapPanel = ({...props}) => {
    const org = useSelector(state => state.pref.org);
    const dispatch = useDispatch();

    const applyChange = (field, value) => {
        dispatch(updateOrgSetting({group:"map", key:field, value}));
    };

    const minZoom = 6;
    const maxZoom = 24;
    const handleChange = useMemo(
        // eslint-disable-next-line react-hooks/exhaustive-deps
        () => throttle(applyChange, 100), []
    );
    const zoom = get(org, "settings.map.zoom", DEFAULT_ZOOM);
    const basemap = get(org, "settings.map.basemap", STYLE_STREETS);

    return (
        <TabPanel {...props} className="MapPanel">
            {org &&
            <form>
                <Grid container spacing={4}>
                    <Grid item md={6}>
                        <FormRow>
                            <Grid item xs={12}>
                                <SelectWithLabel label="Default Basemap" values={[
                                    [STYLE_STREETS, "Street"],
                                    [STYLE_SATELLITE, "Satellite"],
                                    [STYLE_SATELLITE_STREETS, "Satellite Streets"],
                                    [STYLE_OUTDOORS, "Outdoors"],
                                    [STYLE_LIGHT, "Light"],
                                    [STYLE_DARK, "Dark"],
                                ]}
                                 value={basemap} onChange={evt => handleChange("basemap", evt.target.value)}
                                 helperText="The default basemap to use for all maps in QuickComp."
                                />
                            </Grid>
                        </FormRow>
                        <FormRow>
                            <Grid item xs={12}>
                                <Typography gutterBottom>
                                    Default Map Zoom
                                </Typography>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item md>
                                        <Slider
                                            value={zoom}
                                            // step={1}
                                            // marks
                                            step={0.1}
                                            min={minZoom}
                                            max={maxZoom}
                                            onChange={(evt, v) => handleChange("zoom", v)}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography>{zoom}</Typography>
                                    </Grid>
                                </Grid>

                                <FormHelperText>The default zoom level of the map. Lower values represent the map zoomed out.</FormHelperText>
                            </Grid>
                        </FormRow>
                    </Grid>
                    <Grid item md={6}>
                        <PrefMap zoom={zoom} setZoom={z => handleChange('zoom', z)} minZoom={minZoom} maxZoom={maxZoom} style={basemap}/>
                        <Box mt={1}>
                            <Alert severity="info" variant="outlined">
                                <AlertTitle>Tip</AlertTitle>
                                <Typography>You use the zoom capabilities of the map, or the slider, to choose the desired map zoom.</Typography>
                            </Alert>
                        </Box>
                    </Grid>
                </Grid>
            </form>
            }
        </TabPanel>
    );
};

export default MapPanel;
