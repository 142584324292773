import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Dialog} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import {pushSnack} from '../app/state';
import ErrorAlert from "../util/ErrorAlert";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";
import {addSearch, openSearch, updateSearch} from './state';


const SaveSearchDialog = ({open, onClose}) => {
    const search = useSelector(state => state.find.search);
    const [name, setName] = useState("");
    const [isWorking, setWorking] = useState(false);
    const [error, setError] = useState(null);
    const [saveAs, setSaveAs] = useState(true);
    const dispatch = useDispatch();

    const handleSave = () => {
        setWorking(true);

        dispatch(saveAs ? addSearch(name) : updateSearch(search.id))
            .unwrap()
            .then(search => {
                onClose();
                setWorking(false);
                dispatch(pushSnack({message: `Saved Search ${name} added.`}));
                dispatch(openSearch(search));
            })
            .catch(error => {
                setError(error);
                setWorking(false);
            })
    }

    const isReady = saveAs ? !!name : true;
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle >Save Search</DialogTitle>
            <DialogContent dividers>
                {search.name &&
                    <>
                        <Typography gutterBottom={true}>The currently chosen search has been modified, how would you like to proceeed?</Typography>
                        <RadioGroup value={saveAs.toString()}
                                    onChange={evt => setSaveAs(evt.target.value === "true")}>
                            <FormControlLabel value="true" control={<Radio />} label="Save a New Search" />
                            <FormControlLabel value="false" control={<Radio />} label={`Update ${search.name}`} />
                        </RadioGroup>
                    </>
                }
                {saveAs && <TextField
                    value={name} onChange={evt => setName(evt.target.value)}
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    type="email"
                    placeholder="What would you like to call this new search?"
                    fullWidth
                />}
                {error && <ErrorAlert title="Error" error={error}/>}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary" disabled={!isReady || isWorking}>
                    {isWorking ? <CircularProgress size={24}/> : "Save"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default SaveSearchDialog;
