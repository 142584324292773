import CardActionArea from '@mui/material/CardActionArea';
import React, {forwardRef, Fragment, useCallback, useImperativeHandle, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import moment from "moment";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import CardActions from "@mui/material/CardActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import GetAppIcon from '@mui/icons-material/GetApp';
import DeleteIcon from '@mui/icons-material/Delete';
import LaunchIcon from '@mui/icons-material/Launch';
import Tooltip from "@mui/material/Tooltip";
import {pushSnack} from '../app/state';
import {replaceCart} from '../cart/state';
import {loadRecentReports} from '../dash/state';
import ReportAvatar from "./ReportAvatar";
import {PdfIcon, WordIcon, ZipIcon} from "../util/icons";
import ButtonMenu from "../util/ButtonMenu";
import {EXPORT_WORD, EXPORT_PDF, EXPORT_ZIP, EXPORT_WORD_TABLES} from "../model/reportTypes";
import {deleteReport, exportReport, openReport} from './state';



export const ReportCardActions = forwardRef(({report, showOpen=true}, ref) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const comps = useSelector(state => state.cart.comps || []);
    const [showConfirmDelete, setShowConfirmDelete] = React.useState(false);
    const [showConfirmOpen, setShowConfirmOpen] = React.useState(false);
    const [isDeleting, setIsDeleting] = React.useState(false);
    const [isOpening, setIsOpening] = React.useState(false);
    const [isExporting, setIsExporting] = React.useState(false);

    const handleClose = confirmed => {
        setShowConfirmDelete(false);
        if (confirmed) {
            setIsDeleting(true);
            dispatch(deleteReport(report))
              .unwrap()
              .then(() => {
                    dispatch(pushSnack({
                        message: `Report ${report.title} removed.`
                    }));
                    dispatch(loadRecentReports());
                })
              .finally(() => setIsDeleting(false))
        }
    };

    const confirmOpen = () => {
        if (comps.length > 0) {
            setShowConfirmOpen(true);
        } else {
            handleOpen(true);
        }
    }

    const handleOpen = useCallback((confirmed) => {
        setShowConfirmOpen(false);
        if (confirmed) {
            setIsOpening(true);
            dispatch(replaceCart(report.comps || [])).unwrap()
                .then(() => {
                    dispatch(openReport(report));
                    setIsOpening(false);
                    history.replace("/report");
                })
                .finally(() => setIsOpening(false))
        }
    }, [dispatch, history, report]);

    const handleExport = format => {
        setIsExporting(true);
        dispatch(pushSnack({
            message: `Export of ${report.title} started.`,
        }));
        dispatch(exportReport({report, format: format.key}))
          .unwrap()
          .then(url => {
            dispatch(pushSnack({
                autoHide: false,
                clickaway: false,
                message: `${format.title} export of ${report.title} completed.`,
                actions: [{
                    name: "download",
                    href: url
                }]
            }));
        })
          .finally(() => setIsExporting(false));
    };

    useImperativeHandle(ref, () => ({
        open: handleOpen
    }), [handleOpen]);

    return (
        <Fragment>
            <ButtonMenu icon={<Tooltip title="Export Report"><GetAppIcon/></Tooltip>} disabled={isExporting||isDeleting}>
                <MenuItem onClick={() => handleExport(EXPORT_WORD)}>
                    <ListItemIcon>
                        <WordIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">{EXPORT_WORD.title}</Typography>
                </MenuItem>
                <MenuItem onClick={() => handleExport(EXPORT_WORD_TABLES)}>
                    <ListItemIcon>
                        <WordIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">{EXPORT_WORD_TABLES.title}</Typography>
                </MenuItem>
                <MenuItem onClick={() => handleExport(EXPORT_PDF)}>
                    <ListItemIcon>
                        <PdfIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">{EXPORT_PDF.title}</Typography>
                </MenuItem>
                <MenuItem onClick={() => handleExport(EXPORT_ZIP)}>
                    <ListItemIcon>
                        <ZipIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">{EXPORT_ZIP.title}</Typography>
                </MenuItem>
            </ButtonMenu>
            {showOpen && <IconButton
                onClick={confirmOpen}
                disabled={isDeleting || isOpening}
                size="large">
                {isOpening === false ? <Tooltip title="Open Report">
                    <LaunchIcon />
                </Tooltip> : <CircularProgress size={24}/>}
            </IconButton>}
            <IconButton
                onClick={() => setShowConfirmDelete(true)}
                disabled={isDeleting || isOpening}
                size="large">
                {isDeleting === false ? <Tooltip title="Delete Report">
                    <DeleteIcon />
                </Tooltip> : <CircularProgress size={24}/>}
            </IconButton>
            <Dialog
                open={showConfirmDelete}
                onClose={handleClose}
            >
                <DialogTitle>{"Delete " + report.title + "?"}</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        Are you sure you want to delete this report? This action is permanent and can't be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose(true)} color="primary">
                        Proceed
                    </Button>
                    <Button onClick={() => handleClose(false)} color="primary" autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={showConfirmOpen}
                onClose={() => handleOpen(false)}
            >
                <DialogTitle>{"Discard current cart?"}</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        Opening a report with comps in your cart will discard and replace them with the comps in this report. Do you want to proceed?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleOpen(true)} color="primary">
                        Proceed
                    </Button>
                    <Button onClick={() => handleOpen(false)} color="primary" autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
                <DialogContent dividers>
                    <Alert severity="info" variant="standard">
                        <AlertTitle>Lists</AlertTitle>
                        You can save your current cart as a List to preserve it before opening a report.
                    </Alert>
                </DialogContent>
            </Dialog>
        </Fragment>
    );
});

const ReportCard = ({report}) => {
    const ref = useRef();
    return (
        <Card elevation={1} sx={{display: 'flex', flexDirection: 'row', width: '100%'}}>
            <CardActionArea onClick={() => ref.current.open()}>
                <CardHeader title={report.title} subheader={"Created " + moment(report.created.seconds * 1000, "x").fromNow()}
                            avatar={<ReportAvatar type={report.type} numComps={report.comps.length} />} sx={{flexGrow:1}}
                />
            </CardActionArea>
            {/*<CardMedia className={classes.media} image={thumb} />*/}
            {/*<CardContent sx={{*/}
            {/*    borderColor: 'grey.300',*/}
            {/*    borderTopWidth: "1px",*/}
            {/*    borderTopStyle: "solid"*/}
            {/*}}>*/}
            {/*    <List dense={true}>*/}
            {/*        {report.comps &&*/}
            {/*            <ListItem disableGutters>*/}
            {/*                <ListItemIcon sx={{minWidth: "32px"}}>*/}
            {/*                    <FileCopyIcon />*/}
            {/*                </ListItemIcon>*/}
            {/*                <ListItemText primary={pluralize(report.comps.length, "Comp")}/>*/}
            {/*            </ListItem>*/}
            {/*        }*/}
            {/*        <ListItem disableGutters>*/}
            {/*            <ListItemIcon sx={{minWidth: "32px"}}>*/}
            {/*                <AccessTimeIcon />*/}
            {/*            </ListItemIcon>*/}
            {/*            <ListItemText secondary={"Created " + moment(report.created.seconds * 1000, "x").fromNow()}/>*/}
            {/*        </ListItem>*/}
            {/*    </List>*/}
            {/*</CardContent>*/}
            <CardActions sx={{
                flexDirection: "row-reverse",
                bgcolor: 'grey.100'
            }}>
                <ReportCardActions report={report} showOpen={false} ref={ref}/>
            </CardActions>
        </Card>
    );
};

export default ReportCard;
