import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {bbox, booleanPointInPolygon, envelope, featureCollection, point} from '@turf/turf';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  limit, orderBy,
  query,
  setDoc,
  where
} from 'firebase/firestore';
import * as nanoid from 'nanoid';
import {ConfirmationSource, Field, Fields} from '../model/fields';
import {Comp, Search} from '../model/objects';
import {ICI, LEASE, MULTI_FAMILY, RESIDENTIAL_RENT, VACANT_LAND} from '../model/propertyTypes';
import {isSomething} from '../util/utils';
import {
  DATE_CUSTOM, MODE_ID,
  MODE_QUERY, MODE_SAVED,
  NUMBER_BETWEEN, NUMBER_GREATER, NUMBER_LESSER,
  SHORTCUT_FAMILY,
  SHORTCUT_ICI,
  SHORTCUT_LAND,
  SHORTCUT_LEASE,
  SHORTCUT_RENT
} from './constants';

const searchByQuery = (comps, filters, docLimit, post) => {
    let hasBetween = false;
    let hasIndexed = false;

    const constraints = [];
    for (let filter of filters) {
      const p = filter.field.path;
      const sendPost = hasBetween || hasIndexed || !filter.field.isIndexed;
      const isArray = Array.isArray(filter.value);
      if (!isArray && typeof filter.value === "object") {
        if (filter.field === Field.GEO) {
          post.push(d => {
            let geo = Comp.geo(d);
            if (geo) {
              let p = point(geo);
              return booleanPointInPolygon(p, filter.value);
            }
            return false;
          })
        }
        else if (filter.value.range) {
          const r = filter.value.range;
          if (!isSomething(r[0])) continue;

          if (filter.value.type) {
            if (filter.value.type === NUMBER_BETWEEN && !isSomething(r[1])) continue;
            if (sendPost) {
              // add as a post filter
              post.push(doc => {
                const v = filter.field.get(doc);
                if (v) {
                  switch(filter.value.type) {
                    case NUMBER_GREATER:
                      return v >= r[0];
                    case NUMBER_LESSER:
                      return v <= r[0];
                    default:
                      return v >= r[0] && v <= r[1];
                  }
                }
                return false;
              });
            }
            else {
              hasBetween = true;
              hasIndexed = true;
              switch(filter.value.type) {
                case NUMBER_GREATER:
                  constraints.push(where(p, ">=", r[0]));
                  break;
                case NUMBER_LESSER:
                  constraints.push(where(p, "<=", r[0]));
                  break;
                default:
                  constraints.push(
                    where(filter.field.path, ">=", r[0]),
                    where(filter.field.path, "<=", r[1])
                  );
              }
            }

          }
          else if (filter.value.mode) {
            if (!isSomething(r[0]) || !isSomething(r[1])) continue;

            const d1 = Comp.date(r[0]);
            const d2 = Comp.date(r[1]);

            if (sendPost) {
              // add as a post filter
              post.push(doc => {
                const v = filter.field.get(doc);
                if (v) {
                  const d = Comp.date(v);
                  return d >= d1 && d <= d2;
                }
              });
            }
            else {
              hasBetween = true;
              hasIndexed = true;
              constraints.push(where(filter.field.path, ">=", d1), where(filter.field.path, "<=", d2));
            }
          }
        }
      }
      else {
        if (!isSomething(filter.value)) continue;
        if (sendPost) {
          post.push(doc => {
            const v = filter.field.get(doc);
            if (isSomething(v)) {
              // eslint-disable-next-line eqeqeq
              return isArray ? filter.value.includes(v) : filter.value == v;
            }
          })
        }
        else {
          constraints.push(where(filter.field.path, isArray?"in":"==", filter.value));
          hasIndexed = filter.field.path !== Field.TYPE.path;
        }
      }
    }

    constraints.push(limit(docLimit));
    return getDocs(query(comps, ...constraints))
      .then(result => Promise.resolve(result.docs));
};

const searchByIdOrAddress = (comps, idOrAddr, exact, docLimit) => {
  return Promise.all([
    getDocs(query(comps, where(Field.COMP_ID.path, "==", idOrAddr), limit(docLimit))),
    getDocs(query(comps, where(Field.ADDRESS_STREET.path, "==", idOrAddr), limit(docLimit))),
    exact !== true ? getDocs(query(comps,
        where("terms", "array-contains-any", idOrAddr.split(/\s+/).map(it => it.toLowerCase())),
        limit(docLimit)
    )) : Promise.resolve({docs:[]})
  ]).then(([r1, r2, r3]) => {
    return Promise.resolve(r1.docs.concat(r2.docs).concat(r3.docs));
  });
};

export const runSearch = createAsyncThunk(
  'find/runSearch',
  async (search, {getState, rejectWithValue}) => {
    const {auth} = getState();
    try {
      const comps = collection(getFirestore(), 'orgs', auth.org.id, 'comps');
      const limit = 250;
      const post = [];

      let docs = await (search.mode === MODE_ID ?
        searchByIdOrAddress(comps, search.compId, search.exact, limit*2) :
        searchByQuery(comps, search.filters, limit*2, post)
      );
      if (post.length > 0) {
        docs = docs.filter(doc => {
          for (const p of post) {
            if (!p(doc.data())) return false;
          }
          return true;
        });
      }
      const overflow = docs.length > limit;
      if (overflow) {
        docs = docs.slice(0,limit);
      }

      let features = null;
      let bounds = null;
      if (docs.length > 0) {
        features = featureCollection(
          docs.filter((d) => d.get("geo") !== undefined).map((d,i) => {
            const comp = Comp.create(d);
            const photos = d.get("photos") || [];
            return point(Comp.geo(d), {
              type: comp.type,
              name: comp.title,
              comp_id: Field.COMP_ID.get(comp),
              value: Field.VALUE.render(comp),
              date: Field.DATE.render(comp),
              photo: photos.length > 0 ? photos[0].url : undefined,
              is_unconfirmed: ConfirmationSource.isUnconfirmed(comp).toString(),
              is_listing: ConfirmationSource.isCurrentListing(comp).toString(),
              // in_cart: cart.comps.some(it => it.id === d.id).toString(),
              index: i,
              id: d.id
            });
          })
        );
        bounds = bbox(envelope(features));
      }

      return {
        comps: docs.map(Comp.create), features, bounds, overflow
      }
    }
    catch(err) {
      console.error(err);
      return rejectWithValue(err);
    }
  }
);

export const addSearch = createAsyncThunk(
  'find/addSearch',
  async (name, {getState, rejectWithValue}) => {
    const {find, auth} = getState();
    try {
      const now = new Date();
      const data = {
        name,
        created: now,
        filters: find.search.filters.map(f => {
          const g = {...f, field: f.field.path, requires: (f.requires||[]).map(it => it.path)};
          if (f.field === Field.GEO && typeof f.value === "object") {
            g.value = JSON.stringify(f.value);
          }
          return g;
        }),
        updated: now
      }
      const d = await addDoc(collection(getFirestore(), 'orgs', auth.org.id, 'searches'), data);
      return {
        ...data,
        id: d.id
      };

    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const loadSearches = createAsyncThunk(
  'find/loadSearches',
  async (arg, {getState, rejectWithValue}) => {
    const org = getState().auth.org.id;
    try {
      const result = await getDocs(query(
        collection(getFirestore(), 'orgs', org, 'searches'), orderBy('created', 'desc')
      ));
      return result.docs.map(Search.create);
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const updateSearch = createAsyncThunk(
  'find/updateSearch',
  async (id, {getState, rejectWithValue}) => {
    const {find, auth} = getState();
    try {
      const data = {
        filters: find.search.filters.map(f => {
          return {...f, field: f.field.path}
        }),
        updated: new Date()
      };
      await setDoc(doc(getFirestore(), 'orgs', auth.org.id, 'searches', id), data, {merge: true});
      return {
        ...find.searches.find(it => it.id === id),
        ...data
      }
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const removeSearch = createAsyncThunk(
  'find/removeSearch',
  async (search, {getState, rejectWithValue}) => {
    const org = getState().auth.org.id;
    try {
      await deleteDoc(doc(getFirestore(), 'orgs', org, 'searches', search.id))
      return search;
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

const initFilters = [
  {
    id: nanoid(),
    field: Field.TYPE,
    value: ICI,
    fixed: true,
    multi: false
  }
];

const initResults = {
  found: -1,
  comps: null,
  features: null,
  bounds: null
};

const find = createSlice({
  name: 'find',
  initialState: {
    searching: false,
    search: {
      mode: null,
      id: null,
      name: null,
      filters: initFilters,
      spatial: null,
      compId: "",
      exact: false,
      shortcut: "",
      dirty: false
    },
    results: initResults,
    current: null,
    searches: [],
  },
  reducers: {
    setMode: (state, action) => {
      state.search.mode = action.payload;
    },
    addFilter: (state, action) => {
      const {field, requires} = action.payload;
      state.search.dirty = true;
      state.search.filters.push({
        id: nanoid(),
        field,
        requires: requires||[],
        value: "",
        fixed: false,
        other: !field,
        multi: true
      });
    },

    updateFilter: (state, action) => {
      const {filter, key, value} = action.payload;

      const i = state.search.filters.findIndex(it => it.id === filter.id);
      state.search.dirty = true;
      state.search.filters[i][key] = value;
    },

    updateGeoFilter: (state, action) => {
      const {key, value} = action.payload;

      const i = state.search.filters.findIndex(it => it.field === Field.GEO);
      state.search.dirty = true;
      state.search.filters[i][key] = value;
    },

    removeFilter: (state, action) => {
      const i = state.search.filters.findIndex(it => it.id === action.payload.id);
      state.search.dirty = true;
      state.search.filters.splice(i,1);
    },

    setSpatial: (state, action) => {
      state.search.dirty = true;
      state.search.spatial = action.payload;
    },

    updateCompId: (state, action) => {
      state.search.dirty = true;
      state.search.compId = action.payload;
    },

    updateExact: (state, action) => {
      state.search.dirty = true;
      state.search.exact = action.payload;
    },

    updateShortcut: (state, action) => {
      const filters = state.search.filters.slice(0, 1);
      switch(action.payload) {
        case SHORTCUT_LEASE:
          filters[0].value = LEASE;
          filters.push({
            id: nanoid(),
            field: Field.LEASE_AVERAGE_RATE_OVER_TERM,
            value: { type: NUMBER_BETWEEN, range: ["", ""] }
          });
          filters.push({
            id: nanoid(),
            field: Field.CREATED,
            value: { mode: DATE_CUSTOM, range: [null, null] }
          });
          break;
        case SHORTCUT_RENT:
          filters[0].value = RESIDENTIAL_RENT;
          filters.push({
            id: nanoid(),
            field: Field.FINANCIAL_MONTHLY_RENT,
            value: { type: NUMBER_BETWEEN, range: ["", ""] }
          });
          filters.push({
            id: nanoid(),
            field: Field.CREATED,
            value: { mode: DATE_CUSTOM, range: [null, null] }
          });
          break;
        case SHORTCUT_ICI:
        case SHORTCUT_FAMILY:
        case SHORTCUT_LAND:
        default:
          filters[0].value = action.payload === SHORTCUT_FAMILY ? MULTI_FAMILY :
            action.payload === SHORTCUT_LAND ? VACANT_LAND : ICI;
          filters.push({
            id: nanoid(),
            field: Field.SALES_PRICE,
            value: { type: NUMBER_BETWEEN, range: ["", ""] }
          });
          filters.push({
            id: nanoid(),
            field: Field.SALES_DATE,
            value: { mode: DATE_CUSTOM, range: [null, null] }
          });
          break;
      }
      state.search.dirty = false;
      state.search.filters = filters;
      state.search.shortcut = action.payload;
      state.search.mode = MODE_QUERY;
    },
    clearSearch: (state, action) => {
      state.search.mode = state.searches.length > 0 ? MODE_SAVED : MODE_QUERY;
      state.search.name = null;
      state.search.id = null;
      state.search.filters = initFilters;
      state.search.spatial = null;
      state.results = initResults
    },
    pickResult: (state, action) => {
      state.curr = state.results.comps[action.payload];
    },

    openSearch: (state, action) => {
      const search = action.payload;
      state.search.id = search.id;
      state.search.name = search.name;
      state.search.dirty = false;
      state.filters = state.search.filters.map(f => {
        const g = {...f, field: Fields.lookup(f.field), requires: (f.requires||[]).map(it => Fields.lookup(it))};
        if (g.field === Field.GEO && typeof f.value === "string") {
          g.value = JSON.parse(f.value);
        }
        return g;
      })
    }
  },
  extraReducers: builder => {
    builder
      .addCase(runSearch.pending, (state, action) => {
        state.searching = true;
      })
      .addCase(runSearch.fulfilled, (state, action) => {
        const {comps, features, bounds, overflow} = action.payload;
        state.searching = false
        state.results = {
          comps, features, bounds, overflow, found: comps.length
        };
      })
      .addCase(addSearch.fulfilled, (state, action) => {
        state.searches.splice(0, 0, action.payload);
      })
      .addCase(removeSearch.fulfilled, (state, action) => {
        state.searches = (state.searches||[]).filter(it => it.id !== action.payload.id);
      })
  }
});

export const {
  setMode, addFilter, updateFilter, removeFilter, updateCompId, updateGeoFilter, updateExact, updateShortcut,
  setSpatial, openSearch, clearSearch, pickResult
} = find.actions;

export default find.reducer;
