import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import { find, uniq } from "lodash";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Menu } from "@mui/material";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import SaveIcon from '@mui/icons-material/Save';
import RefreshIcon from '@mui/icons-material/Refresh';
import RestoreIcon from '@mui/icons-material/Restore';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {FormRow, FormSection} from "../util/Form";
import Page from "../util/Page";
import ToolBar from "../util/ToolBar";
import ErrorAlert from "../util/ErrorAlert";
import {isNotBlank} from "../util/utils";
import SheetEditor from "./editor/SheetEditor";
import { pushSnack } from "../app/state";
import {addTemplate, chooseTemplate, loadTemplates, newSheet, openSheet, removeTemplate, saveTemplate, setValue} from './state';

const TemplateSaveMenu = ({sheet}) => {
    const dispatch = useDispatch();
    const isDirty = useSelector(state => state.sheet.curr.dirty === true);
    const hasTemplate = useSelector(state => !!state.sheet.templates.curr.id);
    const currentTemplate = useSelector(state => state.sheet.templates.curr.id);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showNewDialog, setShowNewDialog] = useState(false);
    const [newTemplateName, setNewTemplateName] = useState('');
    const [addOrSaveTemplateError, setAddOrSaveTemplateError] = useState(null);
    
    const showMenu = Boolean(anchorEl);
    const openMenu = (evt) => {
      setAnchorEl(evt.currentTarget);
    };
    const closeMenu = () => {
      setAnchorEl(null);
    };

    const openDialog = () => {
        closeMenu();
        setShowNewDialog(true);
    };

    const closeDialog = (proceed) => {
        if (proceed === true) {
            dispatch(addTemplate({name: newTemplateName, sheet})).unwrap()
                .then((templateId) => {
                    dispatch(pushSnack({message: `Template ${newTemplateName} added.`}));
                    dispatch(loadTemplates(templateId));
                    setShowNewDialog(false);
                })
                .catch(setAddOrSaveTemplateError)
        }
        else {
            setShowNewDialog(false);
        }
    };

    const handleSave = () => {
        dispatch(saveTemplate({sheet, templateId: currentTemplate})).unwrap()
            .then((templateId) => {
                dispatch(pushSnack({message: `Template ${newTemplateName} saved.`}));
                dispatch(loadTemplates(templateId));
            })
            .catch(setAddOrSaveTemplateError)
        closeMenu();
    };


    return (
        <>
            <Button onClick={openMenu} startIcon={<SaveIcon/>} endIcon={<KeyboardArrowDownIcon/>} disabled={!isDirty}>
                Save Template
            </Button>
            <Menu anchorEl={anchorEl} open={showMenu} onClose={closeMenu}>
                <MenuItem onClick={openDialog}>As New Template</MenuItem>
                {hasTemplate && <MenuItem onClick={handleSave}>Current Template</MenuItem>}
            </Menu>
            <Dialog open={showNewDialog} onClose={closeDialog}>
                <DialogTitle>Add New Templete</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        Please specify a name for the new Spreadsheet template:
                    </DialogContentText>
                    <TextField
                        value={newTemplateName} onChange={evt => setNewTemplateName(evt.target.value)}
                        autoFocus
                        margin="dense"
                        label="Template Name"
                        fullWidth
                        variant="outlined"
                        sx={{mt: 2}}
                    />
                    {addOrSaveTemplateError && <ErrorAlert error={addOrSaveTemplateError}/>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog}>Cancel</Button>
                    <Button onClick={() => closeDialog(true)} variant="contained" disabled={!newTemplateName.trim()}>Save</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const TemplatePanel = ({sheet}) => {
    const dispatch = useDispatch();
    const compTypes = useSelector(state => uniq(state.cart.comps.map(it => it.type)));
    const template = useSelector(state => state.sheet.templates.curr);
    const templates = useSelector(state => (state.sheet.templates.all||[]).filter(it => it.archived !== true));
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [deleteError, setDeleteError] = useState(null);
    const [templatesLoaded, setTemplatesLoaded] = useState(false);

    useEffect(() => {
        if ((templates === null || templates.length === 0) && !templatesLoaded) {
            setTemplatesLoaded(true);
            dispatch(loadTemplates());
        }
    }, [dispatch, templates, templatesLoaded]);

    const compTypeMatchesTemplate = t => {
        for (const ct of compTypes) {
            if (!(t.types||[]).includes(ct)) return false;
        }
        return true;
    };
    useEffect(() => {
        if (!template.id && templates?.length > 0 && compTypes?.length > 0) {
            const t = find(templates, it => compTypeMatchesTemplate(it));
            if (t) handleChoose({target: {value: t.id}})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template, templates, compTypes]);

    const handleRefresh = () => {
        dispatch(loadTemplates())
    };

    const openDeleteDialog = (templateId) => {
        setShowDeleteDialog(true);
    }

    const closeDeleteDialog = confirm => {
        if (confirm === true) {
            dispatch(removeTemplate(template.id)).unwrap()
                .then(() => setShowDeleteDialog(false))
                .catch(setDeleteError);
        }
        else setShowDeleteDialog(false);
    }

    const handleChoose = (evt) => {
        dispatch(chooseTemplate(find(templates, t => t.id === evt.target.value)));
    }

    return (
        <FormRow spacing={0} alignItems="center">
            <Grid item sx={{flexGrow:1}}>
                <Select value={template.id} onChange={handleChoose} sx={{textAlign: "left"}} displayEmpty fullWidth>
                    <MenuItem value=""><em>Choose a Template</em></MenuItem>
                    {(templates||[]).map(t => <MenuItem key={t.id} value={t.id}>{t.title}</MenuItem>)}
                </Select>
            </Grid>
            <Grid item>
                <Tooltip title="Reload templates">
                    <IconButton onClick={handleRefresh}>
                        <RefreshIcon/>
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid item>
                <Tooltip title="Delete selected template">
                    <span>
                        <IconButton onClick={openDeleteDialog} disabled={!template.id}>
                            <DeleteIcon/>
                        </IconButton>
                    </span>
                </Tooltip>
            </Grid>
            <Grid item>
                <TemplateSaveMenu sheet={sheet}/>
            </Grid>
            <Dialog open={showDeleteDialog} onClose={closeDeleteDialog}>
                <DialogTitle>Remove Template</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        Are you sure you want to delete <em>{template.title}?</em>
                    </DialogContentText>
                    {deleteError && <ErrorAlert error={deleteError}/>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteDialog}>Cancel</Button>
                    <Button onClick={() => closeDeleteDialog(true)} variant="contained">Delete</Button>
                </DialogActions>
            </Dialog>
        </FormRow>
    )
}

const SheetPage = () => {
    let {id} = useParams();
    const dispatch = useDispatch();
    const sheet = useSelector(state => state.sheet.curr);
    const template = useSelector(state => state.sheet.templates.curr);
    const error = useSelector(state => state.sheet.create.error)

    useEffect(() => {
        if (dispatch) {
            if (!id) {
                dispatch(newSheet());
            }
            else {
                dispatch(openSheet(id))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, id]);

    const handleReset = () => {
        dispatch(newSheet());
        if (template && template.id) dispatch(chooseTemplate(template.id));
    };

    const isNew = !isNotBlank(id);
    return (
        <Page className="SheetPage">
            <ToolBar title={isNew?"Create Spreadsheet":sheet.title}>
                {isNew && <Tooltip title="Reset Settings">
                    <IconButton onClick={handleReset} size="large">
                        <RestoreIcon />
                    </IconButton>
                </Tooltip>}
            </ToolBar>
            <Container fixed>
                <Grid container>
                    <Grid item>
                        {error && <ErrorAlert error={error}/> }
                    </Grid>
                </Grid>
                <FormSection label="Title" help="Enter a title for the spreadsheet.">
                    <FormRow spacing={0}>
                        <Grid item xs={12}>
                            <TextField value={sheet.title} onChange={evt => dispatch(setValue({key:"title", value:evt.target.value}))}
                                       label="Spreadsheet Title" variant="outlined" fullWidth required/>
                        </Grid>
                    </FormRow>
                </FormSection>
                <FormSection label="Template" help="Choose a template for the spreadsheet.">
                    <TemplatePanel sheet={sheet}/>
                </FormSection>
                <SheetEditor />
            </Container>
        </Page>
    );
}

export default SheetPage;
