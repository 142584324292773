import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import SaveIcon from '@mui/icons-material/Save';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ErrorAlert from '../util/ErrorAlert';
import Page from '../util/Page';
import PasswordInput from '../util/PasswordInput';
import ToolBar from '../util/ToolBar';
import {UserWithRoleAvatar} from '../util/UserAvatar';
import {changePassword, updateDisplayName} from './state';
import {Stack} from '@mui/material';


const ProfilePage = () => {
  const auth = useSelector(state => state.auth);
  const [name,setName] = useState(auth.user.displayName);
  const [currPass, setCurrPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [error, setError] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [success, setSuccess] = useState();
  const [isWorking, setWorking] = useState(false);
  const [copied, setCopied] = useState(false);
  const dispatch = useDispatch();

  const changeName = () => {
    setWorking(true);
    setError(null);
    setSuccess(null);
    dispatch(updateDisplayName(name))
      .then(() => setSuccess("Display name successfully changed."))
      .catch(setError)
      .finally(() => setWorking(false))

  }

  const updatePassword = () => {
    setWorking(true);
    setError(null);
    setSuccess(null);
    dispatch(changePassword({currPass, newPass}))
      .then(() => setSuccess("Password successfully changed."))
      .catch(setError)
      .finally(() => setWorking(false))
  }

  const copyUserAgent = () => {
    navigator.clipboard.writeText(navigator.userAgent);
    setCopied(true);
  }

  return (
    <Page>
      <ToolBar title="Profile">
      </ToolBar>
      <Container fixed>
        <Box mb={1}>
          {error && <ErrorAlert error={error}/>}
          {success && <Alert color="success">{success}</Alert> }
        </Box>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <UserWithRoleAvatar auth={auth} style={{
              width: '64px',
              height: '64px',
              fontSize: '1.5rem'
            }}/>
          </Grid>
          <Grid item xs={12} md={6}>
              <TextField label="Display Name" value={name} variant="outlined" fullWidth
                         onChange={evt => setName(evt.target.value)}/>
          </Grid>
          <Grid item>
            <IconButton
              disabled={isWorking && (name === auth.user.displayName || !name.trim())}
              onClick={changeName}
              size="large">
              <SaveIcon/>
            </IconButton>
          </Grid>
        </Grid>
        <Box mt={4}>
          {!showPassword && <Button variant="outlined" size="small" color="primary" onClick={() => setShowPassword(true)}>Change Password</Button>}
          {showPassword && <Box mt={2}>
            <Grid container spacing={1}>
              <Grid item md={6}>
                <TextField label="Current Password" variant="outlined" fullWidth autoFocus type="password"
                  value={currPass} onChange={evt => setCurrPass(evt.target.value)}/>
              </Grid>
            </Grid>
            <Box mt={1}>
              <Grid container spacing={1}>
                <PasswordInput onChange={setNewPass}/>
              </Grid>
            </Box>
            <Box mt={1}>
              <Button variant="contained" color="primary" disabled={!currPass || !newPass || isWorking} onClick={updatePassword}
                endIcon={isWorking ? <CircularProgress size={24}/> : null}
              >
                Change
              </Button>
            </Box>
          </Box>}
        </Box>
        <Box mt={4}>
          <Stack direction="row" spacing={2} alignItems="flex-start">
            <TextField fullWidth label="Browser" disabled={true} value={navigator.userAgent}
                       helperText={copied ? 'Copied.' : null}/>
            <IconButton
                onClick={copyUserAgent}
                size="large">
              <ContentCopyIcon/>
            </IconButton>
          </Stack>
        </Box>
      </Container>
    </Page>
  );
}

export default ProfilePage;
