import moment from "moment";

export const capitalize = (text) => {
    if (typeof text !== 'string') return text;
    return text.charAt(0).toUpperCase() + text.slice(1)
};

export const pluralize = (count, singular, options) => {
    const opts = options || {};
    const text = count === 1 ? singular : opts.plural || (singular + "s");
    return `${opts.withCount !== false ? `${count} ` : ""}${text}`
};

export const fromNow = obj => {
    return moment(obj.seconds * 1000, "x").fromNow();
};

export const formatDateTime = obj => {
    return moment(obj.seconds * 1000, "x").format("MMM Do YYYY, h:mm a");
};

export const isNotBlank = obj => {
    if (!obj) return false;
    if (typeof obj === "string") return obj.trim();
    return true;
}

export const isSomething = obj => {
    if (obj === null || typeof obj === "undefined") return false;
    return !(typeof obj === "string" && obj.trim().length === 0);
}

export const splice = (arr, start, deleteCount, item1) => {
    const s = arr.slice();
    s.splice(start, deleteCount, item1);
    return s;
}

export const unsubscribe = (name, cleanup) => () => {
    //console.log(`Unsubscribing from ${name}`);
    cleanup();
};

export const splitFileName = (fileName) => {
    const split = fileName.split(".");
    const ext = split.pop();
    return [split.join("."), ext];
}
