import React, {useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {pickBy, debounce} from "lodash";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Stack from '@mui/material/Stack';
import InfoIcon from '@mui/icons-material/Info';
import Alert from '@mui/material/Alert';
import {mapOptsForOrg, MapWithMarker, MarkerIcon as MarkerIconURL} from "../util/Map";
import PlaceInput from "./GooglePlaceInput";
import {propertyNoun} from "../model/propertyTypes";
import {Field} from "../model/fields";
import {checkAddress, clearAddress, clearGeo, renderMap, setAddress, setCopyData, setField, setGeo} from './state';


const MarkerIcon = () => {
    return (
      <Box component="span" style={{backgroundImage: 'url("' + MarkerIconURL + '")'}} sx={{
          height: 24,
          width: 24,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          border: "1px solid #c7c7c7",
          borderRadius: 4,
          margin: "0 5px"
      }}/>
    );
};

const AddressStep = () => {
    const org = useSelector(state => state.app.org);
    const comp = useSelector(state => state.comp.curr);
    const address = useSelector(state =>  state.comp.curr.address);
    const geo = useSelector(state =>  state.comp.curr.geo);
    const isCopyData = useSelector(state => state.comp.copyData === true);
    const dispatch = useDispatch();
    const attributionRef = useRef();

    const [map, setMap] = React.useState();
    const [isMoving, setIsMoving] = React.useState(false);
    const [addressExists, setAddressExists] = React.useState(false);

    const showGeoHint = !geo && Object.keys(pickBy(address, v => !v)).length === 0;
    const isResale = Field.SALES_IS_RESALE.get(comp) === true;

    React.useEffect(() => {
        window.map = map;
        return () => window.map = null;
    }, [map]);

    React.useEffect(() => {
      if (map && geo && !isMoving) {
        dispatch(renderMap())
      }
    }, [geo, map, isMoving, dispatch]);

    const runCheckAddress = useRef(debounce((addr, callback) => {
        dispatch(checkAddress({addr, type: comp.type})).unwrap().then(callback);
    }, 500)).current;

    React.useEffect(() => {
        runCheckAddress(address, setAddressExists);
    }, [address, runCheckAddress]);

    const initMap = (map) => {
        map.addControl(new AddMarkerControl(g => dispatch(setGeo(g))));
        setMap(map);
    };

    const handleGeoChange = ll => {
        dispatch(setGeo(ll.toArray()));
    };

    const handlePlace = place => {
        if (place && typeof place === "object") {
            dispatch(setAddress({
                street: place.type === "address" ? place.street : null,
                unit: "",
                city: place.type === "address" ? place.place : place.street,
                region: place.region,
                postal: place.postcode,
                country: place.country
            }));
            dispatch(setGeo(place.center));
        }
        else {
            dispatch(clearAddress());
            dispatch(clearGeo());
        }
    };

    const handleAddressChange = evt => {
        dispatch(setAddress({
            [evt.target.id]: evt.target.value
        }));
    };

    const handleMarkAsResale = () => {
        dispatch(setField({field: Field.SALES_IS_RESALE, value: true}));
    }

    const mapOpts = {
        preserveDrawingBuffer: true,
        ...mapOptsForOrg(org)
    };

    return (
        <div>
            <Stack sx={{mb: 2}}>
                {attributionRef.current && <PlaceInput attributionEl={attributionRef.current} onPlace={handlePlace}/>}
                <div ref={attributionRef}></div>
            </Stack>
            {addressExists && !isResale &&
            <Alert severity="warning" variant="outlined" sx={{mb: 1}} action={
                <Button color="inherit" size={"small"} onClick={handleMarkAsResale}>
                  Add as Re-{propertyNoun(comp.type)}
                </Button>
            }>
                A comp with this address already exists.
            </Alert>
            }
            {addressExists && <Grid container>
                <Grid item>
                    <FormControlLabel
                        control={<Checkbox name="copyData" checked={isCopyData} onChange={() => dispatch(setCopyData(!isCopyData))}/>}
                        label={`Copy data from most recent ${propertyNoun(comp.type)}`}
                    />
                </Grid>
            </Grid>}
            <Grid container >
                <Grid item md={8} sx={{position: "relative", height: "416px"}}>
                    <MapWithMarker geo={geo} onGeoChange={handleGeoChange} onMove={setIsMoving} initMap={initMap} {...mapOpts}/>
                </Grid>
                <Grid item md sx={{
                    px: 2,
                    "& > form > div": {
                        mb: 2
                    }
                }}>
                    <form>
                        <div>
                            <TextField id="street" label="Street Address" value={address.street||""} onChange={handleAddressChange} fullWidth />
                        </div>
                        <div>
                            <TextField id="unit" label="Unit" value={address.unit||""} onChange={handleAddressChange} fullWidth />
                        </div>
                        <div>
                            <TextField id="city" label="City" value={address.city||""} onChange={handleAddressChange} fullWidth />
                        </div>
                        <div>
                            <TextField id="region" label="Province" value={address.region||""} onChange={handleAddressChange} fullWidth />
                        </div>
                        <div>
                            <TextField id="postal" label="Postal Code" value={address.postal||""} onChange={handleAddressChange} fullWidth />
                        </div>
                        <div>
                            <TextField id="country" label="Country" value={address.country||""} onChange={handleAddressChange} fullWidth />
                        </div>
                    </form>
                </Grid>
            </Grid>
            {showGeoHint &&
            <Typography variant="subtitle1" sx={{
                mt: 2,
                display: "flex",
                "& > svg": {
                    mr: 5
                }
            }}>
                <InfoIcon color="primary"/> You can specify the map location for the property by clicking <MarkerIcon/> and dragging the marker to
                the desired location.
            </Typography>
            }
        </div>

    );
};

export class AddMarkerControl {
    constructor(setGeo) {
        this.setGeo = setGeo;
    }

    onAdd(map){
        this.map = map;

        const button = document.createElement("button");
        button.className = "mapbox-gl-draw_ctrl-draw-btn add-marker-ctrl";
        button.style.setProperty("background-image", 'url(' + MarkerIconURL + ')')
        button.addEventListener("click", () => {
            this.setGeo(this.map.getBounds().getCenter().toArray());
        });

        this.container = document.createElement("div");
        this.container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
        this.container.appendChild(button);

        return this.container;
    }
    onRemove(){
        this.container.parentNode.removeChild(this.container);
        this.map = undefined;
        this.marker = undefined;
    }
}

export default AddressStep;
