import React, {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {Prompt} from "react-router";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import BusinessIcon from "@mui/icons-material/Business";
import PeopleIcon from "@mui/icons-material/People";
import SaveIcon from "@mui/icons-material/Save";
import PaymentIcon from "@mui/icons-material/Payment";
import MapIcon from '@mui/icons-material/Map';
import {pushSnack} from '../app/state';
import Page from "../util/Page";
import ToolBar from "../util/ToolBar";
import {TabBar} from "../util/Tab";
import OrgPanel from "./OrgPanel";
import {loadOrg, saveOrg} from './state';
import TeamPanel from "./TeamPanel";
import MapPanel from "./MapPanel";
import BillingPanel from "./billing/BillingPanel";
import {hasRole, ROLE_OWNER} from "../auth/roles";
import {planSelector} from "./selectors";
import Spinner from "../util/Spinner";


const PrefPage = () => {
    const isOwner = useSelector(state => hasRole(state.auth, ROLE_OWNER));
    const plan = useSelector(planSelector);
    const isDirty = useSelector(state => state.pref.dirty);
    const org = useSelector(state => state.app.org);
    const dispatch = useDispatch();
    const [openTab, setOpenTab] = useState(-1);

    React.useEffect(() => {
        dispatch(loadOrg(org));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [org, loadOrg]);

    React.useEffect(() => {
        const t = new URLSearchParams(window.location.search).get("open");
        setOpenTab(t ? parseInt(t) : 0);
    }, []);

    const [isSaving, setIsSaving] = React.useState(false);
    const handleSave = () => {
        setIsSaving(true);
        dispatch(saveOrg()).unwrap().then(() => {
            dispatch(pushSnack({message: "Setting Saved"}));
            setIsSaving(false);
        });
    };

    const tabs = [
        [{label: "Organization", icon: <BusinessIcon/>}, (p) => <OrgPanel {...p} />],
        [{label: "Team", icon: <PeopleIcon/>}, (p) => <TeamPanel {...p} />],
        [{label: "Mapping", icon: <MapIcon/>}, (p) => <MapPanel {...p} />]
    ];

    if (isOwner && plan && plan.customer) {
        tabs.push([{label: "Billing", icon: <PaymentIcon/>}, (p) => <BillingPanel {...p} />])
    }

    return (
        <Page className="PrefPage">
            <Prompt when={isDirty} message="You have unsaved changes, leaving the page will cause changes to be lost."/>
            <ToolBar title="Settings">
                <Tooltip title="Save Changes">
                    <IconButton disabled={isSaving} onClick={handleSave} size="large">
                        {isSaving ? <CircularProgress size={24}/> : <SaveIcon />}
                    </IconButton>
                </Tooltip>
            </ToolBar>
            {openTab > -1 ? <Container fixed>
                <TabBar tabs={tabs} activeTab={openTab}/>
            </Container> : <Spinner/>}
        </Page>
    );
};

export default PrefPage;
