import {useDispatch, useSelector} from "react-redux";
import { TextField } from "@mui/material";
import { FormSection } from "../../util/Form";
import SheetEditorPanel from "./SheetEditorPanel";
import { setValue } from "../state";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import React from 'react';


const GeneralPanel = ({sheet, ...props}) => {
    const dispatch = useDispatch();
    const isRoundToDollar = useSelector(state => state.sheet.curr?.roundToDollar === true);

    return (
        <SheetEditorPanel {...props} sheet={sheet} name="GeneralPanel">
                {/* <FormSection label="Type" help="Choose the type of spreadsheet.">
                    <RadioGroup value={sheet.type} onChange={evt => dispatch(setValue({key:"type", value:evt.target.value}))}>
                        <Grid container>
                            <Grid item md={6}>
                                <FormControlLabel value={SHEET_TYPE_SIMPLE} label={"Simple"} control={<Radio />}/>
                                <Typography variant="subtitle1">Simple static spreadsheet</Typography>
                            </Grid>
                            <Grid item md={6}>
                                <FormControlLabel value={SHEET_TYPE_ADJUST} label={"Adjustment Table"} control={<Radio />}/>
                                <Typography variant="subtitle1">Dynamic adjustment table</Typography>
                            </Grid>
                        </Grid>
                    </RadioGroup>
                </FormSection> */}
                <FormSection label="Footer" help="Configure footer text for the spreadsheet.">
                    <TextField value={sheet.footer} onChange={evt => dispatch(setValue({key:"footer", value:evt.target.value}))} InputProps={{className:"FooterInput"}}
                        multiline={true} rows={2} fullWidth placeholder="Text to display at the bottom of the spreadsheet"></TextField>
                </FormSection>
                <FormSection label="Comp Index Prefix" help="Configure how comp numbers are presented in the spreadsheet">
                    <TextField value={sheet.noun} onChange={evt => dispatch(setValue({key:"noun", value:evt.target.value}))}
                        fullWidth placeholder="Comp, Sale, Index, etc..."></TextField>
                </FormSection>
                <FormSection>
                    <FormControlLabel
                        control={<Checkbox name="roundDollars" checked={isRoundToDollar}
                            onChange={() => dispatch(setValue({key:"roundToDollar", value:!isRoundToDollar}))}/>}
                        label={`Round all monetary values to nearest dollar`}
                    />
                </FormSection>
                {/* <FormSection label="Preset" help="Choose a preset configuration to start from, or create from scratch.">
                    <Grid container spacing={1}>
                        {PROPERTY_TYPES.map(p => <Grid item xs={2} key={p}>
                            <TypeButton type={p} selected={p === preset} onClick={() => dispatch(choosePreset(p))}/>
                        </Grid>)}
                        <Grid item>
                            <Button onClick={() => dispatch(choosePreset("none"))}>None</Button>
                        </Grid>
                    </Grid>
                </FormSection> */}
        </SheetEditorPanel>
    )
};

export default GeneralPanel;
