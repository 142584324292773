import Grid from '@mui/material/Grid';
import React, {Fragment, useRef, useState} from "react";
import {useDrag, useDrop} from "react-dnd";
import {useDispatch, useSelector} from "react-redux";
import {uniq} from "lodash";
import InputAdornment from '@mui/material/InputAdornment';
import Menu from '@mui/material/Menu';
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Autocomplete from '@mui/material/Autocomplete';
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PhotoIcon from "@mui/icons-material/Photo";
import MapIcon from "@mui/icons-material/Map";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import RemoveIcon from '@mui/icons-material/Remove';
import TextFieldsIcon from "@mui/icons-material/TextFields";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import CropDinIcon from "@mui/icons-material/CropDin";
import DeleteIcon from "@mui/icons-material/Delete";
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import EditableLabel from "../../util/EditableLabel";
import FieldSelect from "../../util/FieldSelect";
import AvatarWithMenu from "../../util/AvatarWithMenu";
import {hoverXY} from "../../util/dnd";
import {
    addFieldToSection, moveFieldInSection, moveSection, moveSectionToPage, removeFieldFromSection,
    removeSection, setFieldInSection, setFieldLabelInSection, setFieldStyleInSection,
    setSectionCategory, setSectionHeight, setSectionOrientation, setSectionTags,
    setSectionTitle, setSectionType, toggleSectionBorder, toggleSectionSpan
} from '../state';
import Field from "./Field";
import FieldDialog from "./FieldDialog";
import {SECTION_DIVIDER, SECTION_FIELD_LIST, SECTION_MAP, SECTION_PHOTO, SECTION_SINGLE_FIELD, SECTION_TYPES, sectionTypeName} from "../sectionTypes";
import SelectWithLabel from "../../util/SelectWithLabel";
import {ALIGN_CENTER, ALIGN_JUSTIFY, ALIGN_LEFT, ALIGN_RIGHT} from '../../model/fonts';
import {PhotoCategory, PhotoOrientation} from "../../model/fields";


const DRAG_TYPE_SECTION = "section";

export const SectionIcon = ({type}) => {
    let icon;
    switch(type) {
        case SECTION_FIELD_LIST:
            icon = <FormatListBulletedIcon/>;
            break;
        case SECTION_PHOTO:
            icon = <PhotoIcon/>;
            break;
        case SECTION_MAP:
            icon = <MapIcon/>;
            break;
        case SECTION_DIVIDER:
            icon = <RemoveIcon/>;
            break;
        default:
            icon = <TextFieldsIcon/>;
            break;
    }
    return (icon);
}

const SectionTypeAvatar = ({type, ...props}) => {
    return (
        <AvatarWithMenu items={SECTION_TYPES.map(s => [<MenuItem>{sectionTypeName(s)}</MenuItem>, s])} {...props}>
            <SectionIcon type={type}/>
        </AvatarWithMenu>
    );
};

const FieldList = ({page, section}) => {
    const s = useSelector(state => state.report.curr.detail.pages[page].sections[section]);
    const ref = useRef();
    const dispatch = useDispatch();

    return (
        <Grid container wrap="wrap" ref={ref}>
            {(s.fields||[]).map((f, i) => {
                const style = (s.styles || {})[f.path] || {};
                const label = (s.labels || {})[f.path];
                const span = (s.spans || {})[f.path] || 2;
                return <Grid key={`${f.path}_${i}`} item xs={s.span === 2 && span === 1 ? 6 : 12}>
                    <Field page={page} section={section} field={f} style={style} label={label} span={span} index={i} withSpan={s.span===2}
                        onMove={(from, to, place) => dispatch(moveFieldInSection({from, to, place, page, section}))}
                        onRemove={() => dispatch(removeFieldFromSection({page, section, field:i}))}
                        onEdit={value => dispatch(setFieldLabelInSection({field:f, section, page, value}))}
                        onStyle={(style, value) => dispatch(setFieldStyleInSection({field: f, section, page, style, value}))}
                        sectionRef={ref}
                    />
                </Grid>
            })}
        </Grid>
    )
};

const SingleField = ({section, page, index}) => {
    const dispatch = useDispatch();
    const field = section.fields && section.fields[0];
    const align = ((section.styles||{})[field && field.path] || {})["align"] || ALIGN_JUSTIFY;

    const updateAlign = (evt, value) => {
        dispatch(setFieldStyleInSection({field, section:index ,style:'align', value}));
    }

    return <>
      <FieldSelect value={field} label="Choose Field"
                   onChange={f => dispatch(setFieldInSection({field: f, section:index, page}))}/>
        <ToggleButtonGroup size="small" exclusive value={align} onChange={updateAlign} sx={{mt:1}}>
            <ToggleButton
              size="small"
              value={ALIGN_LEFT}
            >
                <FormatAlignLeftIcon/>
            </ToggleButton>
            <ToggleButton
              size="small"
              value={ALIGN_CENTER}
            >
                <FormatAlignCenterIcon/>
            </ToggleButton>
            <ToggleButton
              size="small"
              value={ALIGN_RIGHT}
            >
                <FormatAlignRightIcon/>
            </ToggleButton>
            <ToggleButton
              size="small"
              value={ALIGN_JUSTIFY}
            >
                <FormatAlignJustifyIcon/>
            </ToggleButton>

        </ToggleButtonGroup>

  </>
};

const MoveToPageButton = ({section, page}) => {
    const dispatch = useDispatch();
    const numPages = useSelector(state => (state.report.curr.detail.pages||[]).length);
    const [el, setEl] = useState(null);
    const isOpen = Boolean(el);
    const open = evt => setEl(evt.currentTarget);
    const close = () => setEl(null);

    return (
      <>
          <Tooltip title="Move section to a different page">
              <span>
                <IconButton  size="large" disabled={numPages < 2} onClick={open}>
                      <ArrowCircleDownIcon sx={{rotate: "-90deg"}}/>
                  </IconButton>
              </span>
          </Tooltip>
          <Menu
            open={isOpen}
            anchorEl={el}
            onClose={close}
          >
              {new Array(numPages).fill().map((x,i) => i).filter(i => i !== page)
                .map(p => <MenuItem key={p} onClick={() => dispatch(moveSectionToPage({section, from:page, to:p}))}>
                  Page {p+1}
                </MenuItem>)}
          </Menu>
      </>
    )
}

const Section = ({section, index, page}) => {
    const allTags = useSelector(state => uniq(state.cart.comps.map(c => (c.photos||[]).map(p => p.tags || []).flat() ).flat()));
    const dispatch = useDispatch();

    const ref = useRef(null);
    const [, drop] = useDrop({
        accept: DRAG_TYPE_SECTION,
        hover(item, monitor) {
            hoverXY(ref, index, item, monitor, (from,to) => dispatch(moveSection({from,to, page})));
        }
    });
    const [{ isDragging }, drag] = useDrag({
        item: { type: DRAG_TYPE_SECTION, id: section.id, index },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    const [isAddingField, setIsAddingField] = React.useState(false);
    const handleAddFields = fields => {
        if (fields) dispatch(addFieldToSection({section:index, page, fields}));
        setIsAddingField(false);
    };

    return (
        <Card ref={ref} style={{opacity}}>
            <CardHeader title={section.type !== SECTION_DIVIDER ? <EditableLabel value={section.title} tooltip={"Change Section Title"}
                        onChange={(v) => dispatch(setSectionTitle({page, section:index, title:v}))}/> : null}
                        avatar={<SectionTypeAvatar type={section.type} onSelect={v => dispatch(setSectionType({page, section:index, type:v}))}/>}
                        action={
                            <Tooltip title="Delete section">
                                <IconButton onClick={() => dispatch(removeSection({page, section:index}))} size="large">
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        }
            />
            {section.type !== SECTION_DIVIDER && <CardContent>
                {[SECTION_PHOTO,SECTION_MAP].includes(section.type) &&
                    <List disablePadding={true}>
                        {section.type === SECTION_PHOTO && <>
                        <ListItem>
                            <SelectWithLabel label="Photo Category" value={section.category||""} values={PhotoCategory.values()} addNone={true}
                                             onChange={evt => dispatch(setSectionCategory({page, section:index, category:evt.target.value}))} size="small"/>
                        </ListItem>
                        <ListItem>
                            <SelectWithLabel label="Photo Orientation" value={section.orientation||""} values={PhotoOrientation.values()} addNone={true}
                                             onChange={evt => dispatch(setSectionOrientation({page, section: index, orientation:evt.target.value}))} size="small"/>
                        </ListItem>
                        <ListItem>
                            <Autocomplete
                                multiple
                                options={allTags}
                                value={section.tags || []}
                                onChange={(evt, tags) => dispatch(setSectionTags({page, section:index, tags}))}
                                fullWidth
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Photo Tags"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    />
                                )}
                            />
                        </ListItem>
                        </>}
                        <ListItem>
                            <TextField value={section.height || ""} type="number"
                               onChange={evt => dispatch(setSectionHeight({page, section:index, height:parseInt(evt.target.value)}))}
                               placeholder="Height in pixels" size="small" InputProps={{
                                    endAdornment: <InputAdornment position="end">px</InputAdornment>
                               }}/>
                        </ListItem>
                    </List>
                }
                {section.type === SECTION_FIELD_LIST &&
                    <FieldList page={page} section={index} />}
                {section.type === SECTION_SINGLE_FIELD && <SingleField section={section} page={page} index={index}/>}
            </CardContent>}
            <CardActions sx={{
                flexDirection: "row-reverse",
                bgcolor: 'grey.100',
            }}>
                <ToggleButtonGroup size="small">
                    <Tooltip title="Toggle border">
                        <ToggleButton
                            size="small"
                            value="border"
                            selected={section.border === true}
                            onChange={() => dispatch(toggleSectionBorder({page, section:index}))}
                        >
                            <CropDinIcon />
                        </ToggleButton>
                    </Tooltip>
                    <Tooltip title="Toggle between full/half page">
                        <ToggleButton
                            value="full"
                            selected={section.span > 1}
                            onChange={() => dispatch(toggleSectionSpan({page, section:index}))}
                        >
                            <AspectRatioIcon />
                        </ToggleButton>
                    </Tooltip>
                </ToggleButtonGroup>
                <Box sx={{flexGrow:1}}/>
                <MoveToPageButton page={page} section={index}/>
                {section.type === SECTION_FIELD_LIST &&
                    <Tooltip title="Add a field">
                        <IconButton onClick={() => setIsAddingField(true)} size="large">
                            <AddCircleOutlineIcon />
                        </IconButton>
                    </Tooltip>
                }
            </CardActions>
            {section.type === SECTION_FIELD_LIST && <FieldDialog isOpen={isAddingField} onClose={handleAddFields}/>}
        </Card>
    );
};

export default Section;
