import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { NumericFormat } from "react-number-format";
import { Alert, AlertTitle, Box, Checkbox, FormControlLabel, Grid, InputAdornment, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import {FormLabel, FormRow, FormSection} from "../../util/Form";
import FieldGrid from "./FieldGrid";
import AdjustGrid from "./AdjustGrid";
import SelectWithLabel from "../../util/SelectWithLabel";
import MetricGrid from "./MetricGrid";
import SheetEditorPanel from "./SheetEditorPanel";
import { FieldLabel, FieldType, Fields } from "../../model/fields";
import { SHEET_TYPE_ADJUST, SHEET_TYPE_SIMPLE } from "../constants";
import {setTimeAdjustValue, setLabel, toggleAdjusts, toggleMetrics, toggleTimeAdjust, setAdjustValue} from "../state"


const FieldPanel = ({sheet, ...props}) => {
    const dispatch = useDispatch();
    const dateFields = sheet.fields.filter(f => f.type === FieldType.DATE);

    const time = sheet.time;
    useEffect(() => {
        if ((!time.field && dateFields.length > 0) ||
            (time.field && dateFields.length > 1 && !dateFields.includes(time.field))) {
            dispatch(setTimeAdjustValue({key:"field", value: dateFields[0]}));
            return;
        }
        if (time.field && dateFields.length === 0) {
            dispatch(setTimeAdjustValue({key:"field", value: null}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateFields]);

    const adjusts = sheet.adjusts;
    const metrics = sheet.metrics;
    const useQualitativeSymbols = adjusts.qualitative?.symbols === true;

    return (
        <SheetEditorPanel {...props} sheet={sheet} name="FieldPanel">
            <FormSection label="Fields" help="Configure the fields to include in the spreadsheet.">
                {sheet.type === SHEET_TYPE_SIMPLE && <FieldGrid fields={sheet.fields}/>}
                {sheet.type === SHEET_TYPE_ADJUST && <>
                    {!sheet.unit && <Alert severity="warning" variant="outlined" sx={{mb: 1}}>
                    Please choose a unit of comparison field by clicking the dot to the left of the field.
                </Alert>}
                    <FieldGrid fields={sheet.fields} labels={sheet.labels||{}} selectable={true} selected={sheet.unit}/>
                </>}
            </FormSection>
            {sheet.type === SHEET_TYPE_ADJUST && <>
                <FormSection label="Adjustments" help="Configure adjustment columns to apply to the unit of comparison." className="AdjustmentsSection">
                    <FormRow spacing={1} gutterBottom={true}>
                        <Grid item>
                            <FormControlLabel
                                control={<Checkbox checked={adjusts.enabled} onChange={() => dispatch(toggleAdjusts())}/>}
                                label="Include Adjustment Columns"
                            />
                        </Grid>
                    </FormRow>
                    {adjusts.enabled && <>
                        <FormRow spacing={1} gutterBottom={true}>
                            <Grid item xs={12}>
                                <AdjustGrid/>
                            </Grid>
                        </FormRow>
                        <FormRow spacing={1} sx={{pt:2}}>
                            <Grid item xs={6}>
                                <TextField value={sheet.labels?.total_quantitative||""} onChange={evt => dispatch(setLabel({
                                    key: 'total_quantitative',
                                    value: evt.target.value
                                }))} label="Quantitative Total Title" variant="outlined" size="small" fullWidth/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField value={sheet.labels?.total_qualitative||""} onChange={evt => dispatch(setLabel({
                                    key: 'total_qualitative',
                                    value: evt.target.value
                                }))} label="Qualitative Total Title" variant="outlined" size="small" fullWidth/>
                            </Grid>
                        </FormRow>
                    </>}
                </FormSection>
                {adjusts.enabled && <FormSection label="Qualitative Adjustments" help="Configure the presentation of qualitative adjustment columns." className="QualitativeAdjustmentsSecetion">
                    <FormLabel sx={{mb:1}}>
                        Thresholds
                    </FormLabel>
                    <Box ml={-1}>
                        <FormRow spacing={2}>
                            <Grid item xs={4}>
                                <NumericFormat value={sheet.adjusts.qualitative?.similar_threshold} onValueChange={v => dispatch(setAdjustValue({
                                        path: 'qualitative.similar_threshold', value: v.floatValue
                                    }))} label="Similar Threshold" decimalScale={0} allowNegative={false}
                                    style={{width:'100%'}} helperText="The percentage at which adjustment values are considered qualitatively equal."
                                    customInput={TextField} InputProps={{
                                        fullWidth: true,
                                        size: "small",
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>

                                <NumericFormat value={sheet.adjusts.qualitative?.far_threshold} onValueChange={v => dispatch(setAdjustValue({
                                        path: 'qualitative.far_threshold', value: v.floatValue
                                    }))}
                                    label="Far Threshold"  decimalScale={0} allowNegative={false} style={{width:'100%'}}
                                    helperText="The percentage at which adjustment values are considered qualitatively far superior/inferior."
                                    customInput={TextField} InputProps={{
                                        fullWidth: true,
                                        size: "small",
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                />
                            </Grid>
                        </FormRow>
                    </Box>

                    <FormLabel sx={{mt: 2, mb:1}}>Labels</FormLabel>
                    <Box ml={-1}>
                        <FormRow spacing={2}>
                            <Grid item xs={4}>
                                <TextField value={sheet.labels?.qualitative_similar||"Similar"} onChange={evt => dispatch(setLabel({
                                    key: 'qualitative_similar',
                                    value: evt.target.value
                                }))} label="Similar Label" variant="outlined" size="small" disabled={useQualitativeSymbols} fullWidth/>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField value={sheet.labels?.qualitative_superior||"Superior"} onChange={evt => dispatch(setLabel({
                                    key: 'qualitative_superior',
                                    value: evt.target.value
                                }))} label="Superior Label" variant="outlined" size="small" disabled={useQualitativeSymbols} fullWidth/>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField value={sheet.labels?.qualitative_far_superior||"Far Superior"} onChange={evt => dispatch(setLabel({
                                    key: 'qualitative_far_superior',
                                    value: evt.target.value
                                }))} label="Far Superior Label" variant="outlined" size="small" disabled={useQualitativeSymbols} fullWidth/>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField value={sheet.labels?.qualitative_inferior||"Inferior"} onChange={evt => dispatch(setLabel({
                                    key: 'qualitative_inferior',
                                    value: evt.target.value
                                }))} label="Inferior Label" variant="outlined" size="small" disabled={useQualitativeSymbols} fullWidth/>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField value={sheet.labels?.qualitative_far_inferior||"Far Inferior"} onChange={evt => dispatch(setLabel({
                                    key: 'qualitative_far_inferior',
                                    value: evt.target.value
                                }))} label="Far Inferior Label" variant="outlined" size="small" disabled={useQualitativeSymbols} fullWidth/>
                            </Grid>
                        </FormRow>
                        <FormRow spacing={2}>
                            <Grid item>
                                <FormControlLabel
                                    control={<Checkbox checked={useQualitativeSymbols} onChange={() => dispatch(setAdjustValue({
                                        path: "qualitative.symbols",
                                        value: !(adjusts.qualitative?.symbols || false)
                                    }))}/>}
                                    label="Use Symbols Rather than Labels"
                                />
                            </Grid>
                        </FormRow>
                    </Box>

                </FormSection>}
                <FormSection label="Time Adjustment" help="Configure an time adjustment column." className="TimeSection">
                    <FormRow spacing={1} gutterBottom={true}>
                        <Grid item>
                            <FormControlLabel
                                control={<Checkbox checked={time.enabled} onChange={() => dispatch(toggleTimeAdjust())}/>}
                                label="Include a Time Adjustment Column"
                            />
                        </Grid>
                    </FormRow>
                    <FormRow spacing={1}>
                        {time.enabled && dateFields.length === 0 &&
                        <Grid item>
                            <Alert severity="warning">
                                <AlertTitle>No Date Field</AlertTitle>
                                No date field is present in this spreadsheet. Please add one in the Fields section.
                            </Alert>
                        </Grid>
                        }
                        {time.enabled && dateFields.length > 0 &&
                        <>
                            <Grid item xs={3}>
                                <TextField value={time.name} label="Column Title" variant="outlined" size="small" fullWidth
                                        onChange={evt => dispatch(setTimeAdjustValue({key:"name", value:evt.target.value}))}/>
                            </Grid>
                            <Grid item xs={3}>
                                <SelectWithLabel label="Date Field" size="small" value={(time.field && time.field.path)||""}
                                    values={dateFields.map(f => [f.path, f.label(FieldLabel.SHORT)])}
                                    onChange={evt => dispatch(setTimeAdjustValue({key:"field", value:Fields.lookup(evt.target.value)}))}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <DatePicker
                                    renderInput={(params) => <TextField {...params} size="small" name="EffectiveDateInput"/>}
                                    onChange={v => dispatch(setTimeAdjustValue({key:"date", value:v.toDate()}))}
                                    invalidDateMessage=""
                                    label="Effective Date"
                                    clearable
                                    value={time.date}
                                    inputFormat="MMM Do, YYYY"
                                    inputVariant="outlined"
                                    InputLabelProps={{shrink: true}}
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <NumericFormat value={time.appreciation} decimalScale={2} fixedDecimalScale={true} customInput={TextField}
                                            variant="outlined" size="small" label="Monthly Appreciation Rate" fullWidth
                                            onValueChange={evt => dispatch(setTimeAdjustValue({key: "appreciation", value:evt.floatValue}))}
                                            InputProps={{
                                                name: "AppreciationRateInput",
                                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                            }}/>
                            </Grid>
                        </>
                        }
                    </FormRow>
                </FormSection>

                <FormSection label="Metrics" help="Configure additional field statistics to calculate." className="MetricsSection">
                    <FormRow spacing={1} gutterBottom={true}>
                        <Grid item>
                            <FormControlLabel
                            control={<Checkbox checked={metrics && metrics.enabled} onChange={() => dispatch(toggleMetrics())}/>}
                            label="Include Field Metrics"
                            />
                        </Grid>
                    </FormRow>
                    {metrics && metrics.enabled && <FormRow spacing={1}>
                        <MetricGrid/>
                    </FormRow>}
                </FormSection>
            </>}
        </SheetEditorPanel>
    )
};

export default FieldPanel;
