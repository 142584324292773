import IconButton from '@mui/material/IconButton';
import React, {Fragment} from "react";
import ReactCrop from 'react-image-crop';
import "react-image-crop/dist/ReactCrop.css";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CustomFormField from "../util/CustomFormField";
import ImageWithItemBar from "../util/ImageWithItemBar";
import Typography from "@mui/material/Typography";


const CropDialog = ({image, isOpen, onClose}) => {
    const [img, setImg] = React.useState(null);
    const handleImageLoaded = i => setImg(i);

    const [crop, setCrop] = React.useState({ aspect: 1, x: 100, y: 100, width: 256, height: 256 });
    const handleCrop = newCrop => {
        setCrop(newCrop);
    };

    const [isFinishing, setIsFinishing] = React.useState(false);
    const handleFinish = () => {
        if (crop) {
            setIsFinishing(true);

            // // scale it
            let canvas = document.createElement("canvas");
            canvas.width = 256;
            canvas.height = 256;

            const scx = img.naturalWidth / img.width;
            const scy = img.naturalHeight / img.height;

            let g = canvas.getContext("2d");
            g.drawImage(
                img,
                crop.x * scx,
                crop.y * scy,
                crop.width * scx,
                crop.height * scy,
                0,
                0,
                canvas.width,
                canvas.height
            );
            setTimeout(() => {
                onClose(canvas.toDataURL());
                setIsFinishing(false);
            }, 1000);
        }
    };

    return (
        <Dialog aria-labelledby="customized-dialog-title" open={isOpen} maxWidth={"lg"}>
            <DialogTitle id="customized-dialog-title" >
                Crop Logo
            </DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                    Logos must be a square image. Please select the region of the image that best represents
                    the desired logo.
                </Typography>
                <ReactCrop src={image} crop={crop} onImageLoaded={handleImageLoaded} onChange={handleCrop} />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => onClose(null)} color="secondary">
                    Cancel
                </Button>
                 {isFinishing ? <CircularProgress size={24}/> :
                     <Button disabled={!crop || !img} onClick={handleFinish} color="primary" autoFocus>
                         Crop
                     </Button>
                }
            </DialogActions>
        </Dialog>
    )
};
const LogoField = ({value, onChange}) => {
    const [image, setImage] = React.useState();
    const [isCropping, setIsCropping] = React.useState(false);
    const handleCrop = imgData => {
        setIsCropping(false);
        if (imgData) {
            onChange(imgData);
        }
    };
    const handleFile = evt => {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            setImage(reader.result);
            setIsCropping(true);
        });
        reader.readAsDataURL(evt.target.files[0]);
    };
    return (
        <CustomFormField label="Organization Logo">
            <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 256,
                height: 256,
            }}>
                {value &&
                    <ImageWithItemBar src={value} itemBarProps={{
                        actionIcon: <IconButton onClick={() => onChange(null)} size="large" sx={{
                            color: 'white',
                            "&:hover": {
                                backgroundColor: "rgb(255,255,255,0.25)"
                            }
                        }}>
                            <DeleteOutlineIcon/>
                        </IconButton>
                    }}/>
                }
                {!value &&
                    <Fragment>
                        <input id="logo-upload" type="file" hidden onChange={handleFile}/>
                        <label htmlFor="logo-upload">
                            <Button
                                component="span"
                                startIcon={<AddCircleOutlineIcon />}
                            >
                                Add Logo
                            </Button>
                        </label>
                    </Fragment>
                }

            </Box>
            <CropDialog image={image} isOpen={isCropping} onClose={handleCrop}/>
        </CustomFormField>
    );
};

export default LogoField;

