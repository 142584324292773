import React, {useState} from 'react';
import Box from '@mui/material/Box';
import mapboxgl from "mapbox-gl";
import {MAPBOX_TOKEN} from "../mapbox";

const PrefPanel = ({style, zoom, setZoom, minZoom, maxZoom, center}) => {
    const [map, setMap] = useState();

    React.useEffect(() => {
        mapboxgl.accessToken = MAPBOX_TOKEN;
        const map = new mapboxgl.Map({
            container: 'map',
            style: `mapbox://styles/mapbox/${style}`,
            height: "100%",
            minZoom, maxZoom, zoom,
            center: center || [-119.4960, 49.8880]
        });
        map.dragRotate.disable();
        map.touchZoomRotate.disableRotation();
        map.on('zoomend', () => {
            setZoom(Math.round(map.getZoom() * 10) / 10);
        })

        setMap(map);
        return () => { map.remove() }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (map) {
            map.zoomTo(zoom);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zoom])

    React.useEffect(() => {
        if (map) {
            map.setStyle(`mapbox://styles/mapbox/${style}`);
            setTimeout(() => {
                map.fire("stylechange");
            }, 1000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [style])


    return (
        <Box id="map" sx={{height: "512px", borderRadius: "4px"}}></Box>
    )
};

export default PrefPanel;
