import React from "react";
import Box from '@mui/material/Box';
import {Field, FieldLabel} from "../../model/fields";

const FieldValue = ({field, comp, style, options}) => {
    const isConfidential = ((field.path === Field.ADDRESS_STREET.path) || (field.path === Field.ADDRESS_POSTAL.path) || (
      (field.path === Field.TITLE.path) && Field.TITLE.get(comp) === Field.ADDRESS_STREET.get(comp)
    )) && Field.CONFIRMATION_IS_CONFIDENTIAL.get(comp) === true;
    const value = isConfidential?"confidential":field.render(comp, {
        nullValue: options.emptyField == null ? "n/a" : options.emptyField,
        zeroAsNull: options.zeroAsEmpty === true,
        target: FieldLabel.REPORT_BODY, ...(options||{})
    });
    const isMultiLine = Array.isArray(value);
    return (
        <Box component="span" sx={{
            ...(isConfidential && {color: 'secondary.main', textTransform: "uppercase"})
        }} style={style}>
            {isMultiLine && value.map((v,i) => <p key={i} style={{margin:0}}>{v}</p>)}
            {!isMultiLine && value}
        </Box>
    )
};

export default FieldValue;
