import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { first, last } from 'lodash';
import Badge from '@mui/material/Badge';
import Chip from '@mui/material/Chip';
import Fab from '@mui/material/Fab';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import LocationDisabledIcon from '@mui/icons-material/LocationDisabled';
import {FieldLabel, Fields, FieldType} from '../model/fields';
import FilterDialog from './FilterDialog';
import {Comp} from '../model/objects';
import {
  removeFilter,
  runSearch,
  FILTER_TERMS,
  FILTER_RANGE,
  setFilters,
  FILTER_MATCH,
  toggleGeoFilter
} from './state';


const filterToString = (filter, field) => {
  const label = field.label(FieldLabel.SHORT);
  if (filter.label) return `${label}: ${filter.label}`
  switch(filter.type) {
    case FILTER_TERMS:
    case FILTER_MATCH:
      return `${label}: ${filter.values.map(v => field.render(v)).join(',')}`;
    case FILTER_RANGE:
      const f = first(first(filter.values));
      const l = last(last(filter.values));

      const render = field.type === FieldType.DATE ? v => {
        const d = Comp.date(v);
        const m = new Date();
        m.setMonth(d.getUTCMonth());

        return `${m.toLocaleString('en-US', {month:'short'})} ${d.getUTCDate().toString().padStart(2, '0')}, ${d.getUTCFullYear()}`;
      } : field.render.bind(field);

      return `${label}: ` + ((!f && l) ? `< ${render(l)}` :
        ((f && !l) ? `> ${render(f)}` : `${render(f)}...${render(l)}`))
      
    default:
      return label;
  }
}

const FilterChip = ({filter, ...props}) => {
  const dispatch = useDispatch();
  const field = Fields.lookup(filter.field);
  if (!field) return null;

  return (
    <Chip label={`${filterToString(filter, field)}`} sx={{ml:0}}
          onDelete={() => dispatch(removeFilter({filter}))} {...props}/>
  )
};

const FilterPanel = ({...props}) => {
  const dispatch = useDispatch();
  const filters = useSelector(state => state.search.query.filters || []);
  const filterWithGeo = useSelector(state => state.search.query.geo.enabled === true);
  const dirty = useSelector(state => state.search.query.dirty || []);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  
  const closeFilterDialog = (newFilters) => {
    setShowFilterDialog(false);
    if (newFilters) {
      dispatch(setFilters(newFilters));
    }
  }

  useEffect(() => {
    if (dirty.includes('filters')) dispatch(runSearch());
  }, [filters, dirty, dispatch]);

  
  return (
    <Stack gap={1} direction="row" justifyContent="flex-start" alignItems="center" flexWrap="wrap" {...props}>
      <Stack direction="row" gap={1} sx={{flexShrink:0}} alignItems="center">
        <Tooltip title="Filter the Search" >
          <Badge color="secondary" badgeContent={filters.length} anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
            <Fab size="small" onClick={() => setShowFilterDialog(true)}>
              <FilterListIcon/>
            </Fab>
          </Badge>
        </Tooltip>
        <Tooltip title="Run a search when the map changes">
          <Fab size="small" color={filterWithGeo ? 'primary' : null} onClick={() => dispatch(toggleGeoFilter())}>
            {filterWithGeo ? <LocationSearchingIcon/> : <LocationDisabledIcon/>}
          </Fab>
        </Tooltip>
      </Stack>
      
      {filters.map(f => <FilterChip filter={f} key={f.field}/>)}
      <FilterDialog open={showFilterDialog} onClose={closeFilterDialog} maxWidth="lg"/>
    </Stack>
  )
}

export default FilterPanel;
