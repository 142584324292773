import {Pagination} from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import PaletteIcon from "@mui/icons-material/Palette";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SettingsIcon from '@mui/icons-material/Settings';
import PostAddIcon from "@mui/icons-material/PostAdd";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Tooltip from "@mui/material/Tooltip";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import {TabPanel, TabSideBar} from "../../util/Tab";
import {FormSection} from "../../util/Form";
import FontControl from "../../util/FontControl";
import ColorPicker from "../../util/ColorPicker";
import ToggleButton from "../../util/ToggleButton";
import Section, {SectionIcon} from "./Section";
import Instruction from "./Instruction";
import Sheet from "../content/Sheet";
import Fonts from "../content/Fonts";
import TextPreview from "./TextPreview";
import {addPage, addSection, removePage, updateConfig, updateView} from '../state';
import {SECTION_TYPES, sectionTypeName} from "../sectionTypes";

const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
       ref.current = value;
    });
    return ref.current;
};

const LayoutPanel = (props) => {
    const pages = useSelector(state => state.report.curr.detail.pages || []);
    const page = useSelector(state => state.report.view.detail.page || 0);
    const dispatch = useDispatch();

    return (
      <TabPanel {...props} className="DetailPanel">
          <Box display="flex" justifyContent="center" alignItems="end" mb={2}>
              <Pagination count={pages.length} page={page+1} variant="outlined" color="primary"
                          onChange={(evt, p) => dispatch(updateView({path: 'detail.page', value: p-1}))}/>
              <Tooltip title="Add a Page">
                  <IconButton size="small" color="primary" onClick={() => dispatch(addPage())}>
                      <PostAddIcon/>
                  </IconButton>
              </Tooltip>
              <Tooltip title="Delete this Page">
                  <span>
                      <IconButton size="small" color="primary" onClick={() => dispatch(removePage())} disabled={pages.length < 2}>
                          <DeleteOutlineIcon/>
                      </IconButton>
                  </span>
              </Tooltip>
          </Box>
          <SectionPanel page={page}/>
      </TabPanel>
    );
};

const SectionPanel = ({page}) => {
    const sections = useSelector(state => state.report.curr.detail.pages[page].sections);
    const prevSections = usePrevious(sections);
    const [dialOpen, setDialOpen] = useState(false);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (prevSections && sections && sections.length === prevSections.length+1) {
            window.scrollTo(0,document.body.scrollHeight);
        }
    }, [prevSections, sections]);

    return (
      <FormSection label="Sections"
                   help="Configure the sections to be displayed on the detail sheet. Sections and be added, removed, and re-arranged."
                   action={
                       <Tooltip title="Add Section">
                           <SpeedDial
                             ariaLabel="Add Section"
                             sx={{position: "absolute"}}
                             icon={<AddIcon />}
                             onClose={() => setDialOpen(false)}
                             onOpen={() => setDialOpen(true)}
                             open={dialOpen}
                             direction="down"
                             FabProps={{size: "small"}}
                           >
                               {SECTION_TYPES.map(s => <SpeedDialAction
                                   key={s}
                                   icon={<SectionIcon type={s}/>}
                                   tooltipTitle={sectionTypeName(s)}
                                   tooltipPlacement="right"
                                   onClick={() => dispatch(addSection({page, type:s}))}
                                 />
                               )}
                           </SpeedDial>
                       </Tooltip>}>
          <Grid container spacing={2} sx={{
              // textAlign: "right",
              // height: 0
          }}>
              {sections.map((s, i) =>
                <Grid item key={s.id} xs={s.span*6}>
                    <Section section={s} index={i} page={page}/>
                </Grid>
              )}
          </Grid>
      </FormSection>

    );
};

const StylesPanel = (props) => {
    const style = useSelector(state => state.report.curr.detail.style);
    const dispatch = useDispatch();

    const handleFontChange = path => {
        return (key, value) => {
            dispatch(updateConfig({path:`detail.style.${path}.font.${key}`, value}));
        };
    };
    const handleColorChange = path => {
        return color => {
            dispatch(updateConfig({path:`detail.style.${path}`, value: color}));
        }
    };
    const handleChange = path => {
        return evt => {
            dispatch(updateConfig({path: `detail.style.${path}`, value: evt.target.value}));
        }
    };

    const ulEnabled = style.section.heading.underline.enabled;
    return (
        <TabPanel {...props}>
            <FormSection label="Banner" help="The font and background of the detail sheet banner.">
                <Box>
                    <FontControl font={style.banner.font} onChange={handleFontChange("banner")} />
                </Box>
                <Box pt={2}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={3}>
                            <ColorPicker label="Background" value={style.banner.background || ""}
                                         onChange={handleColorChange("banner.background")}/>
                        </Grid>
                        <Grid item>
                            <TextPreview font={style.banner.font} background={style.banner.background}/>
                        </Grid>
                    </Grid>
                </Box>
            </FormSection>
            <FormSection label="Section Heading" help="The display of a section heading.">
                <Box>
                    <FontControl font={style.section.heading.font} onChange={handleFontChange("section.heading")} />
                </Box>
                <Box pt={2}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={3}>
                            <ColorPicker label="Background" value={style.section.heading.background || ""}
                                         onChange={handleColorChange("section.heading.background")}/>
                        </Grid>
                        <Grid item>
                            <TextPreview font={style.section.heading.font} background={style.section.heading.background}/>
                        </Grid>
                    </Grid>
                </Box>
                <Box pt={2}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <ToggleButton value="underline" selected={ulEnabled}
                                          onChange={() => updateConfig("detail.style.section.heading.underline.enabled", !ulEnabled)}>
                                <FormatUnderlinedIcon/>
                            </ToggleButton>
                        </Grid>
                        <Grid item xs={2}>
                            <ColorPicker label="Underline" value={style.section.heading.underline.color}
                                         onChange={handleColorChange("section.heading.underline.color")}
                                         disabled={!ulEnabled}/>

                        </Grid>
                        <Grid item xs={2}>
                            <TextField label="Width" name="width" value={style.section.heading.underline.width}
                                       variant="outlined" disabled={!ulEnabled} onChange={handleChange("section.heading.underline.width")}
                                       InputProps={{
                                           endAdornment: <InputAdornment position="end">px</InputAdornment>,
                                       }}/>
                        </Grid>
                    </Grid>
                </Box>
            </FormSection>
            <FormSection label="Section Body" help="The display of a section body.">
                <Box>
                    <FontControl font={style.section.body.font} onChange={handleFontChange("section.body")} />
                </Box>
                <Box pt={2}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={3}>
                            <ColorPicker label="Background" value={style.section.body.background || ""}
                                         onChange={handleColorChange("section.body.background")}/>
                        </Grid>
                        <Grid item>
                            <TextPreview font={style.section.body.font} background={style.section.body.background}/>
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                        </Grid>
                    </Grid>
                </Box>
            </FormSection>
            <FormSection label="Section Border" help="The width and color of a section body.">
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <ColorPicker label="Color" value={style.section.border.color}
                                     onChange={handleColorChange("section.border.color")}/>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField label="Width" name="width" value={style.section.border.width} onChange={handleChange("section.border.width")}
                                   variant="outlined"
                                   InputProps={{
                                       endAdornment: <InputAdornment position="end">px</InputAdornment>,
                                   }}/>
                    </Grid>
                </Grid>
            </FormSection>
            <FormSection label="Spacing" help="The space around content of the detail sheet.">
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <TextField label="Padding" name="padding" value={style.section.padding} onChange={handleChange("section.padding")}
                                   variant="outlined"/>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField label="Margin" name="margin" value={style.margin} onChange={handleChange("margin")}
                                   variant="outlined"/>
                    </Grid>
                </Grid>
            </FormSection>
            <FormSection label="Dividers" help="The display of section dividers.">
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <ColorPicker label="Color" value={style.divider.color || ""}
                                     onChange={handleColorChange("divider.color")}/>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField label="Height" name="height" value={style.divider.height}
                                   variant="outlined" disabled={!ulEnabled} onChange={handleChange("divider.height")}
                                   InputProps={{
                                       endAdornment: <InputAdornment position="end">px</InputAdornment>,
                                   }}/>
                    </Grid>
                </Grid>
            </FormSection>
        </TabPanel>
    );
};

const SettingsPanel = ({...props}) => {
    const options = useSelector(state => state.report.curr.detail.options);
    const dispatch = useDispatch();

    return (
      <TabPanel {...props}>
          <FormSection label="Empty Fields" help="How to handle fields with no value.">
              <FormControlLabel
                control={<Checkbox name="emptyFieldMode"  checked={options.skipEmptyFields}
                           onChange={() => dispatch(updateConfig({path: "detail.options.skipEmptyFields", value: !options.skipEmptyFields}))} />}
                label={`Skip fields with no value`}
              />
          </FormSection>
          <FormSection label="Comp Index Prefix" help="Specify the prefix for comp numbers in the report.">
              <TextField value={options.noun} placeholder="Index"  size="small"
                         onChange={evt => dispatch(updateConfig({
                             path:"detail.options.noun",
                             value:(evt.target.value)
                         }))} variant="outlined" />
          </FormSection>
      </TabPanel>
    );
};

const PreviewPanel = ({...props}) => {
    const comp = useSelector(state => state.cart.comps[0]);
    return (
        <TabPanel {...props}>
            <Fonts/>
            <Sheet comp={comp} index={0} isPreview={true}/>
        </TabPanel>
    );
};

const DetailPanel = (props) => {
    const activeTab = useSelector(state => state.report.view.detail.tab);
    const dispatch = useDispatch();
    return (
        <TabPanel {...props}>
            <Instruction>
                The detail sheet displays detailed data about a comp in the report. The sheet can is made up of pages,
                each page containing sections arranged in a two-column layout.
            </Instruction>
            <TabSideBar tabs={[
                [{label: "Preview", icon: <VisibilityIcon/>}, (p) => <PreviewPanel {...p} />],
                [{label: "Layout", icon: <ViewColumnIcon/>}, (p) => <LayoutPanel {...p} />],
                [{label: "Styles", icon: <PaletteIcon/>}, (p) => <StylesPanel {...p} />],
                [{label: "Settings", icon: <SettingsIcon/>}, (p) => <SettingsPanel {...p} />],
            ]} activeTab={activeTab} onTabChange={t => dispatch(updateView({path:"detail.tab", value:t}))}/>
        </TabPanel>
    );
};

export default DetailPanel;

