import React from "react";
import Box from "@mui/material/Box";
import PublicPage from "../util/PublicPage";
import ResetForm from "./ResetForm";


const PasswordPage = () => {
    return (
        <PublicPage>
            <Box margin="auto" sx={{maxWidth: 'breakpoints.values.sm'}}>
                <ResetForm/>
            </Box>
        </PublicPage>
    );
};

export default PasswordPage;
