import React from "react";
import {useSelector} from "react-redux";
import FontInclude from "../../util/Fonts";

const Fonts = () => {
    const config = useSelector(state => state.report.curr);
    const families = new Set();

    const addFamily = obj => {
        if (obj && obj.font && obj.font.family) {
            families.add(obj.font.family);
        }
    }

    addFamily(config.summary.style.header);
    addFamily(config.summary.style.body);
    addFamily(config.summary.style.caption);
    addFamily(config.detail.style.banner);
    addFamily(config.detail.style.section.heading);
    addFamily(config.detail.style.section.body);

    addFamily(config.map.popup?.index?.style);
    addFamily(config.map.popup?.distance?.style);
    config.map.popup?.fields?.map((field) => addFamily(field.style));

    return (
        <FontInclude families={families}/>
    )
};

export default Fonts;
