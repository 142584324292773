import React, {cloneElement} from "react";
import { ReactComponent as AmexLogo } from './american-express.svg';
import { ReactComponent as MasterCardLogo } from './master-card.svg';
import { ReactComponent as VisaLogo } from './visa.svg';
import { ReactComponent as CardLogo } from './credit-card.svg';


const CreditCardIcon = ({type, height}) => {
    let logo;
    switch(type.toLowerCase()) {
        case "american express":
        case "amex":
            logo = <AmexLogo/>;
            break;
        case "mastercard":
        case "mc":
            logo = <MasterCardLogo/>;
            break;
        case "visa":
            logo = <VisaLogo/>;
            break;
        default:
            logo = <CardLogo/>;
    }
    return (
        <div style={{height}}>
            {cloneElement(logo, {style: {height: "100%"}})}
        </div>
    )
};

export default CreditCardIcon;
