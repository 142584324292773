import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import Fab from "@mui/material/Fab";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from "@mui/icons-material/Delete";
import GetAppIcon from '@mui/icons-material/GetApp';
import {pushSnack} from '../app/state';
import ToolBar from "../util/ToolBar";
import Page from "../util/Page";
import {Field} from "../model/fields";
import {deleteSheet, exportSheet, pageSheets, sortSheets} from './state';
import DataTable2, {Cell} from '../util/DataTable2';
import Typography from '@mui/material/Typography';
import {UserAvatar} from '../util/UserAvatar';
import LaunchIcon from '@mui/icons-material/Launch';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import {replaceCart} from '../cart/state';


const SheetActions = ({sheet}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const comps = useSelector(state => state.cart.comps || []);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [showConfirmOpen, setShowConfirmOpen] = useState(false);
    const [isOpening, setIsOpening] = useState(false);

    const handleDelete = () => {
        setIsDeleting(true);
        dispatch(deleteSheet(sheet))
            .then(() => {
                pushSnack({message: `Spreadsheet ${sheet.title} has been deleted.`})
            })
            .finally(() => setIsDeleting(false));
    }

    const handleExport = () => {
        setIsDownloading(true);
        dispatch(exportSheet(sheet))
            .unwrap()
            .then((url) => {
                setIsDownloading(false);
                window.open(url);
            })
            .catch((error) => {
                setIsDownloading(false)
            });
    }

    const doOpen = (confirmed) => {
        setShowConfirmOpen(false);
        if (confirmed) {
            setIsOpening(true);
            dispatch(replaceCart(sheet.comps || [])).unwrap()
                .then(() => {
                    history.replace(`/sheet/${sheet.id}`);
                })
                .finally(() => setIsOpening(false))
        }
    }
    const handleOpen = () => {
        if (comps.length > 0) {
            setShowConfirmOpen(true);
        }
        else {
            doOpen(true);
        }
    }

    const isWorking = isDeleting || isDownloading || isOpening;
    return <>
        {/*{sheet.num_exports > 0 &&*/}
        {/*<Button color="primary" component={Link} to={`/sheet/${sheet.id}/exports`}>*/}
        {/*    {pluralize(sheet.num_exports, "spreadsheet")}*/}
        {/*</Button>}*/}
        <IconButton
            disabled={isWorking}
            onClick={handleExport}
            size="large">
            {isDownloading === false ? <Tooltip title={"Download Spreadsheet"}>
                <GetAppIcon />
            </Tooltip> : <CircularProgress size={24}/>}
        </IconButton>
        <IconButton
            disabled={isWorking}
            onClick={handleOpen}
            size="large">
            {isOpening === false ? <Tooltip title="Open Spreadsheet">
                <LaunchIcon />
            </Tooltip> : <CircularProgress size={24}/>}
        </IconButton>
        <IconButton
            disabled={isWorking}
            onClick={handleDelete}
            size="large">
            {isDeleting === false ? <Tooltip title="Delete Spreadsheet">
                <DeleteIcon />
            </Tooltip> : <CircularProgress size={24}/>}
        </IconButton>
        <Dialog
            open={showConfirmOpen}
            onClose={() => doOpen(false)}
        >
            <DialogTitle>{"Discard current cart?"}</DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    Opening a spreadsheet with comps in your cart will discard and replace them with the comps in this report. Do you want to proceed?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => doOpen(true)} color="primary">
                    Proceed
                </Button>
                <Button onClick={() => doOpen(false)} color="primary" autoFocus>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    </>;
}

const FIELDS =  [Field.TITLE, Field.CREATED];

const SheetsPage = ({firebase}) => {
    const auth = useSelector(state => state.auth);
    const list = useSelector(state => state.sheet.list);
    const numSheets = useSelector(state => state.app.org.num_sheets || 0);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (list.page === -1) {
            dispatch(pageSheets(true));
        }
    }, [list, dispatch, auth, firebase]);

    const handlePage = forward => {
        return dispatch(pageSheets(forward));
    };

    const handleSort = (field, order) => {
        return dispatch(sortSheets({field, order}));
    };

    return (
        <Page className="SheetsPage">
            <ToolBar title="Spreadsheets">
                <Tooltip title="Create a Spreadsheet">
                    <Fab onClick={() => history.push("/sheet")} color="primary" size="small">
                        <AddIcon />
                    </Fab>
                </Tooltip>
            </ToolBar>
            <Container fixed>
                <DataTable2 data={list} noun="sheet" fields={FIELDS} totalCount={numSheets} onPage={handlePage} onSort={handleSort}
                            cellRenderer={(sheet, field, props) => {
                                if (field === null) {
                                    return <Cell />;
                                }
                                if (field === Field.TITLE) {
                                    return <Cell primary={field.get(sheet)} {...props}/>
                                }
                                if (field === Field.CREATED) {
                                    return <Cell {...props}
                                                 primary={<Typography>{Field.CREATED.render(sheet)}</Typography>}
                                                 secondary={<Typography variant="subtitle2">{Field.CREATED.render(sheet, {fromNow: true})}</Typography>}
                                                 avatar={<UserAvatar user={sheet.created_by} sx={{ width: 32, height: 32, fontSize: "1rem" }}/> }
                                    />;
                                }
                            }}
                            actionRenderer={sheet => <SheetActions sheet={sheet} />}/>
            </Container>
        </Page>
    );
}

export default SheetsPage;
