import {useTheme} from '@mui/material';
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TypeAvatar from "./TypeAvatar";
import {PROPERTY_TYPES, propertyNameTitle} from '../model/propertyTypes'
import {setType} from './state';


const TypeStep = () => {
    const theme = useTheme();
    const type = useSelector(state => state.comp.curr.type);
    const dispatch = useDispatch();

    const title = t => propertyNameTitle(t);
    return (
        <div className={`TypeStep`}>
            <Box sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                "& > *": {
                    margin: theme.spacing(1),
                    width: theme.spacing(16),
                    height: theme.spacing(16),
                },
            }}>
                {PROPERTY_TYPES.map((t) => (
                    <Paper key={t} variant="outlined" square className={`${t}Button`} onClick={() => dispatch(setType(t))} sx={{
                        height: "200px",
                        width: "200px",
                        position: "relative",
                        cursor: "pointer",
                        "&:hover": {
                            boxShadow: theme.shadows[1]
                        }
                    }}>
                        <Box sx={{
                            position: "absolute",
                            top: "20%",
                            left: "50%",
                            transform: "translate(-50%,0)",
                            textAlign: "center",
                        }}>
                            <TypeAvatar type={t} size="large" disabled={t !== type} />
                        </Box>
                        <Typography variant="h4" sx={{
                            position: "absolute",
                            top: "60%",
                            left: "50%",
                            transform: "translate(-50%,0)",
                            width: "100%",
                            textAlign: "center",
                            color: "text.disabled",
                            ...(t === type && {color: "text.primary"})
                        }}>
                            {title(t)}
                        </Typography>
                    </Paper>
                ))}
            </Box>
        </div>
    );
};

export default TypeStep
