import {Field} from "../model/fields";

export const SHEET_TYPE_SIMPLE = "simple";
export const SHEET_TYPE_ADJUST = "adjust";

export const SHEET_LAYOUT_ROW = "row";
export const SHEET_LAYOUT_COL = "col";
export const SHEET_LAYOUT_BOTH = "both";

export const SHEET_TEMPLATE_CUSTOM = "custom";

export const SHEET_STAT_MIN = "min";
export const SHEET_STAT_MAX = "max";
export const SHEET_STAT_AVG = "average";

export const FIELD_COMP_TYPES = new Field("comps[0].type", "Comps")
