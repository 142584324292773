import React, {Fragment, useEffect, useMemo, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import Typography from "@mui/material/Typography";
import CommentIcon from '@mui/icons-material/Comment';
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HomeIcon from "@mui/icons-material/Home";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import MoneyIcon from "@mui/icons-material/Money";
import NoteIcon from "@mui/icons-material/Note";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import OpacityIcon from "@mui/icons-material/Opacity";
import SaveIcon from "@mui/icons-material/Save";
import AssignmentIcon from '@mui/icons-material/Assignment';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HistoryIcon from '@mui/icons-material/History';
import Button from "@mui/material/Button";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Box from "@mui/material/Box";
import {addOrSaveComp, setHighlight, setPreview} from '../state';
import GeneralPanel from "./GeneralPanel";
import ConfirmationPanel from "./ConfirmationPanel";
import NotesPanel from './NotesPanel';
import UtilityPanel from "./UtilityPanel";
import PhysicalPanel from "./PhysicalPanel";
import ImprovementPanel from "./ImprovementPanel";
import SalesPanel from "./SalesPanel";
import FinancialPanel from "./FinancialPanel";
import LeasePanel from "./LeasePanel";
import PhotoPanel from "./PhotoPanel";
import DocPanel from "./DocPanel";
import MapPanel from "./MapPanel";
import {LEASE, RESIDENTIAL_RENT, VACANT_LAND} from "../../model/propertyTypes";
import {TabBar} from "../../util/Tab";
import CommentPanel from "./CommentPanel";
import HistoryPanel from "./HistoryPanel";
import {fromNow} from "../../util/utils";
import ErrorAlert from '../../util/ErrorAlert';
import {Template} from '../../model/objects';
import {useLocalStorage} from '../../util/useLocalStorage';


const CompTabBar = ({comp, tabs, ...props}) => {
    const isDirty = useSelector(state => state.comp.dirty.length > 0);
    const dispatch = useDispatch();
    const history = useHistory();
    const error = useSelector(state => state.comp.error);

    const ref = useRef();
    const handleSave = () => {
        if (isDirty) dispatch(addOrSaveComp(comp));
    };

    const handleNext = () => {
        ref.current.next();
    };

    const [hasNext, setHasNext] = React.useState(true);
    const handleTabChange = t => {
        setHasNext(t < (tabs.length-1));
        dispatch(setPreview(false));
    };

    const styles = {
        buttons: {
            display: "flex",
            justifyContent: "flex-end",
            px: 4,
            "& > button": {
                ml: 1
            }
        }
    };
    return (
        <Fragment>
            {error && <ErrorAlert error={error} sx={{mb:1}}/>}
            <TabBar ref={ref} tabs={tabs} comp={comp} onTabChange={handleTabChange} {...props}/>
            <Box sx={styles.buttons}>
                <Button
                    variant="contained"
                    disabled={!isDirty}
                    color="primary"
                    onClick={handleSave}
                    startIcon={hasNext ? <SaveIcon/> : null}
                    className="SaveButton"
                >
                    Save
                </Button>
                {hasNext &&
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleNext}
                    endIcon={<NavigateNextIcon/>}
                    className="NextButton"
                >
                    {hasNext ? "Next" : "Finish"}
                </Button>}
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => history.goBack()}
                    className="FinishButton"
                >
                    Finish
                </Button>
            </Box>
            {comp.updated_by &&
            <Box sx={styles.buttons} mt={2}>
                <Typography variant="subtitle2">Last updated by {comp.updated_by.name} {fromNow(comp.updated)}.</Typography>
            </Box>}
            {error && <ErrorAlert error={error} sx={{mt:1}}/>}
        </Fragment>
    );
};

const CompEditor = () => {
    const dispatch = useDispatch();
    const isHighlight = useSelector(state => state.comp.highlight === true);
    const template = useSelector(state => state.report.curr);
    const comp = useSelector(state => state.comp.curr);
    const [highlightFields,] = useLocalStorage("quickcomp.compEditor.highlightFields", false);

    const fieldsToHighlight = useMemo(() => {
        if (template) return Template.listFields(template);
        return [];
    }, [template]);

    useEffect(() => {
        dispatch(setHighlight(highlightFields));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [highlightFields]);

    const tabs = [
        [{label: "General", icon: <InfoIcon/>}, (p) => <GeneralPanel {...p} />],
        [{label: "Confirmation", icon: <CheckCircleIcon/>}, (p) => <ConfirmationPanel {...p} />]
    ];

    if (comp.type !== RESIDENTIAL_RENT) {
        tabs.push([{label: "Utilities", icon: <OpacityIcon/>}, (p) => <UtilityPanel {...p} />]);
    }
    tabs.push([{label: "Physical", icon: <HomeIcon/>}, (p) => <PhysicalPanel {...p} />]);
    tabs.push([{label: "Improvement", icon: <HomeWorkIcon/>}, (p) => <ImprovementPanel {...p} />]);
    
    if (comp.type !== RESIDENTIAL_RENT && comp.type !== LEASE) {
        tabs.push([{label: "Sales", icon: <MonetizationOnIcon/>}, (p) => <SalesPanel {...p} />]);
    }
    if (comp.type !== LEASE && comp.type !== VACANT_LAND) {
        tabs.push([{label: "Financial", icon: <MoneyIcon/>}, (p) => <FinancialPanel {...p} />]);
    }
    if (comp.type === LEASE) {
        tabs.push([{label: "Lease", icon: <AssignmentIcon/>}, (p) => <LeasePanel {...p} />]);
    }

    tabs.push(
        [{label: "Comments", icon: <NoteIcon/>}, (p) => <CommentPanel {...p} />],
    );

    tabs.push(
        [{label: "Photos", icon: <PhotoLibraryIcon/>}, (p) => <PhotoPanel {...p} />],
        [{label: "Documents", icon: <FileCopyIcon/>}, (p) => <DocPanel {...p} />]
    );

    tabs.push(
        [{label: "Map", icon: <LocationOnIcon/>}, (p) => <MapPanel {...p} />]
    );

    tabs.push([{label: "Notes", icon: <CommentIcon/>}, (p) => <NotesPanel {...p} />]);

    if (comp.id) {
        tabs.push(
            [{label: "History", icon: <HistoryIcon/>}, (p) => <HistoryPanel {...p} />]
        );
    }

    return (
        <CompTabBar tabs={tabs} comp={comp} highlight={isHighlight?fieldsToHighlight:[]}/>
    );
};

export default CompEditor;
