import React, {useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Page from "../util/Page";
import Wizard from "../util/Wizard";
import PlanLimitAlert from "../util/PlanLimitAlert";
import TitleStep from "./TitleStep";
import ConfigStep from "./ConfigStep";
import ExportStep from "./ExportStep";
import TemplateStep from "./TemplateStep";
import SubjectStep from "./SubjectStep";
import {isReportLimitReachedSelector} from "../app/selectors";
import {clearReport, loadTemplates} from './state';

const pageSteps = () => ["title", "subject", "template", "config", "export"];

const stepInstruct = (step) => {
    switch(step) {
        case 0:
            return "Enter a title for the report.";
        case 1:
            return "Would you like to choose a subject property for the report?";
        case 2:
            return "What template would you like to use for this report?";
        case 3:
            return "Configure the appearance of the report.";
        default:
            return "";
    }
};

const ReportPage = () => {
    const report = useSelector(state => state.report);
    const templates = useSelector(state => state.report.templates.all);
    const isReportLimitReached = useSelector(isReportLimitReachedSelector);
    const dispatch = useDispatch();
    const wizardRef = useRef();

    React.useEffect(() => {
        dispatch(clearReport());
    }, [dispatch]);
    React.useEffect(() => {
        if (!templates || templates.length === 0) dispatch(loadTemplates());
    }, [dispatch, templates])
    // React.useEffect(() => {
    //     dispatch(loadTemplates());
    // }, [dispatch, auth]);

    const handleReset = () => {
        dispatch(clearReport());
        wizardRef.current.reset();
    };

    const stepContent = (step) => {
        switch(step) {
            case 0:
                return <TitleStep/>;
            case 1:
                return <SubjectStep/>;
            case 2:
                return <TemplateStep/>;
            case 3:
                return <ConfigStep/>;
            case 4:
                return <ExportStep reset={handleReset}/>;
            default:
                return "Unknown";
        }
    };

    const stepLabel = (step, activeStep) => {
        switch(step) {
            case 0:
                return activeStep > step ? report.curr.title : "Title";
            case 1:
                return activeStep > step && report.curr.hasSubject && report.curr.subject ? report.curr.subject.title : "Subject";
            case 2:
                const isDirty = report.templates.curr.id && report.templates.curr.dirty === true;
                return activeStep > step ? report.templates.curr.title + (isDirty ? "*":"") : "Template";
            case 3:
                return "Configure";
            case 4:
                return "Generate";
            default:
                return "";
        }
    };

    const stepIsComplete = (step) => {
        switch(step) {
            case 0:
                return report.curr.title;
            case 1:
                return !report.curr.hasSubject || report.curr.subject;
            case 2:
                return report.templates.curr.id || report.templates.curr.title;
            case 3:
                return true;
            default:
                return false;
        }
    };

    const nextStep= (step) => {
        if (step === 1 && report.custom === false) return step+2;
        return step + 1;
    };

    const prevStep = (step) => {
        if (step === 3 && report.custom === false) return step-2;
        return step - 1;
    };

    const finish = () => {

    };
    return (
        <Page sx={{pt: 2}}>
            <Container>
                {isReportLimitReached &&
                    <Box pt={4}>
                        <PlanLimitAlert title="Report Limit Reached"
                             message="You have reached the maximum number of reports that your plan allows for."
                             upgradeMessage="Upgrade your plan to create more reports."/>
                    </Box>
                }
                {!isReportLimitReached && <Wizard ref={wizardRef} steps={pageSteps(report)} hideNavOnLastStep={report.isDone}
                        {...{stepContent, stepInstruct, stepLabel, stepIsComplete, nextStep, prevStep, finish}}/>}
            </Container>
        </Page>
    )
};

export default ReportPage;
