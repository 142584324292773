import {styled} from '@mui/material';
import RadioGroup from "@mui/material/RadioGroup";

export default styled(RadioGroup)({
    '&&': {
        flexDirection: "row",
        flexWrap: "nowrap",
        width: "fit-content"
    }
});
