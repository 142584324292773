const ICI = "ici";
const MULTI_FAMILY = "multi-family";
const VACANT_LAND = "vacant-land";
const LEASE = "lease";
const RESIDENTIAL_RENT = "residential-rent";

const PROPERTY_TYPES = [ICI, MULTI_FAMILY, VACANT_LAND, LEASE, RESIDENTIAL_RENT];

const propertyNameTitle = (type) => {
    switch(type?.toLowerCase()) {
        case ICI:
            return "IC&I (Commercial Comparable)";
        case MULTI_FAMILY:
            return "Multi-Family";
        case VACANT_LAND:
            return "Vacant Land";
        case LEASE:
            return "Lease";
        case RESIDENTIAL_RENT:
            return "Residential Rent";
        default:
            return "Unknown";
    }
};

const propertyNameShort = (type) => {
    switch(type?.toLowerCase()) {
        case ICI:
            return "IC&I";
        case MULTI_FAMILY:
            return "Multi-Family";
        case VACANT_LAND:
            return "Vacant Land";
        case LEASE:
            return "Lease";
        case RESIDENTIAL_RENT:
            return "Residential Rent";
        default:
            return "Unknown";
    }
};

const propertyTypeAbbrev = (type) => {
    switch(type?.toLowerCase()) {
        case ICI:
            return "ICI";
        case MULTI_FAMILY:
            return "MF";
        case VACANT_LAND:
            return "VL";
        case LEASE:
            return "LE";
        case RESIDENTIAL_RENT:
            return "RR";
        default:
            return "?";
    }
};

const propertyNoun = type => {
    switch(type?.toLowerCase()) {
        case LEASE:
            return "lease"
        case RESIDENTIAL_RENT:
            return "rental";
        default:
            return "sale";
    }
}

module.exports = {
    ICI, MULTI_FAMILY, VACANT_LAND, LEASE, RESIDENTIAL_RENT, PROPERTY_TYPES, propertyNameTitle, propertyNameShort, propertyTypeAbbrev, propertyNoun
}
