import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import {HeadCell, TableToolbar} from "../util/Table";
import Spinner from "../util/Spinner";
import {pluralize} from "../util/utils";
import {Comp} from "../model/objects";
import {applySearch, loadSearches, removeSearch} from "./state";


const SearchesDialog = ({open, onClose, ...props}) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setDeleting] = useState(false);

    const searches = useSelector(state => state.search.saved.all || []);

    useEffect(() => {
        setIsLoading(true);
        dispatch(loadSearches()).unwrap()
            .finally(() => setIsLoading(false));
    }, [dispatch]);

    const handleDelete = search => {
        setDeleting(true);
        dispatch(removeSearch(search)).unwrap()
            .finally(() => setDeleting(false))
    }

    const handleApply = search => {
        dispatch(applySearch(search.id)).unwrap()
            .then(onClose)
    }

    const isWorking = isDeleting || isLoading;
    return (
        <Dialog open={open} onClose={onClose} fullWidth={true} {...props}>
            <DialogTitle>
                Saved Searches
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.secondary.contrastText,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                {isLoading && <Spinner/>}
                {!isLoading && searches.length === 0 && <DialogContentText>You currently have no saved searches. To save a search execute a search, and select "Save this Search" from the results menu.</DialogContentText>}
                {!isLoading && searches.length > 0 &&
                    <Stack gap={2}>
                        <DialogContentText>Manage your list of saved searches below.</DialogContentText>
                        <Paper>
                            <TableToolbar sx={{bgcolor: 'grey.100'}} title={pluralize(searches.length, 'Saved Search', {plural: 'Saved Searches'})}/>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <HeadCell>Name</HeadCell>
                                            <HeadCell>Last Updated</HeadCell>
                                            <HeadCell></HeadCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {searches.map(search => (
                                            <TableRow key={search.id}>
                                                <TableCell>{search.name}</TableCell>
                                                <TableCell>{moment(Comp.date(search.updated)).fromNow()}</TableCell>
                                                <TableCell>
                                                    <Stack direction="row">
                                                        <Tooltip title="Delete this search">
                                                            <IconButton onClick={() => handleDelete(search)} enabled={!isDeleting}>
                                                                <DeleteIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Load this search">
                                                            <IconButton onClick={() => handleApply(search)} enabled={!isWorking}>
                                                                <OpenInBrowserIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Stack>}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={isWorking}>Close</Button>
            </DialogActions>
        </Dialog>
    )
};

export default SearchesDialog;