import React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";


const Spinner = ({message, absolute}) => {
    return <Box display="flex" alignItems="center" justifyContent="center" sx={{
        ...(absolute===true && {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)"
        })
    }}>
        <CircularProgress /> <Typography component="span" sx={{pl:1}}>{message}</Typography>
    </Box>
};

export default Spinner;
