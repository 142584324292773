import React, {Fragment} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {FormRow} from "../../util/Form";
import CompEditorPanel from './CompEditorPanel';
import CompField from "./CompField";
import AreaField from "./AreaField";
import FieldWithHelp from "./FieldWithHelp";
import ShowAllFieldsToggle from "./ShowAllFieldsToggle";
import {ICI, LEASE, MULTI_FAMILY, RESIDENTIAL_RENT, VACANT_LAND} from "../../model/propertyTypes";
import {BasementFinish, Field, PhysicalPropertySubType, PhysicalPropertyType} from "../../model/fields";
import {Stack} from '@mui/material';


const BuildingClassHelp = () => {
    return (
        <Fragment>
            <Typography variant="h5">
                Class A: Fireproof Structural Steel Frame
            </Typography>
            <Typography>
                The primary feature of Class A buildings is the fireproofed structural steel frame, which may be welded, bolted or riveted together. The fireproofing may be masonry, poured concrete, plaster, sprayed fiber or any other method which gives a high fire-resistance rating.
            </Typography>
            <Typography>
                Floor and roof in Class A structures are normally reinforced concrete on steel decking or formed slabs resting on the frame or poured to become integral with it. They may also be composed of prefabricated panels and may be mechanically stressed.
            </Typography>
            <Typography>
                Exterior walls are curtain walls of masonry, concrete, steel studs and stucco, or one of the many types of panels of metal, glass, masonry or concrete. Interior partitions frequently are of masonry or gypsum block, although many movable and lightweight steel partitions are used.
            </Typography>

            <Typography variant="h5">
                Class B: Reinforced Concrete Frame
            </Typography>
            <Typography>
                The primary characteristic of Class B buildings is the reinforced concrete frame in which the columns and beams can be either formed or precast concrete. They may be mechanically stressed. Class B buildings are fire-resistant structures.
            </Typography>
            <Typography>
                Floors and roofs in Class B structures are formed or precast concrete slabs. The exterior walls, generally, are masonry or reinforced concrete curtain walls or any of the many types of wall panels of concrete, metal, glass or stone. In some Class B buildings the walls may be partially load bearing. Interior partitions are often masonry, reinforced concrete or gypsum block. Many lightweight and movable partitions are used where structural walls are not needed.
            </Typography>

            <Typography variant="h5">
                Class C: Masonry Bearing Walls
            </Typography>
            <Typography>
                Class C buildings are characterized by masonry or reinforced concrete (including tilt-up) construction. The walls may be4loadbearing, i.e., supporting roof and upper floor loads, or nonbearing with concrete, steel or wood columns, bents or arches supporting the load. Upper floors and roofs are supported by wood or steel joists or trusses. Ground floors may be concrete slabs. Upper floors may be of concrete plank, steel deck or wood. Bearing walls are frequently strengthened by concrete bond beams and pilasters. Class C buildings are not fire-resistant structures.
            </Typography>

            <Typography variant="h5">
                Class D: Wood or Steel-Framed Exterior Walls
            </Typography>
            <Typography>
                Class D buildings are characterized by combustible construction. The exterior walls may be made up of closely spaced wood or steel studs as in the case of a typical frame house. Exterior covers may be wood siding, shingles, stucco, brick or stone veneer or some other type of material.
            </Typography>
            <Typography>
                Floors and roofs are supported on wood or steel joists or trusses. The floor may be a concrete slab on the ground.
            </Typography>
        </Fragment>
    );
};

const ImprovementPanel = ({comp, highlight, ...props}) => {
    const fieldProps = {comp, highlight};

    const [showAll, setShowAll] = React.useState(false);
    const toggleShowAll = (evt) => setShowAll(evt.target.checked);

    const basementFinishIsSpecify = Field.IMPROVEMENT_BASEMENT_FINISH.get(comp) === BasementFinish.INPUT_SPECIFY;
    const basementFinishMode = basementFinishIsSpecify && Field.IMPROVEMENT_BASEMENT_FINISH_MODE.get(comp);
    const showAcres = comp.type === VACANT_LAND;
    const parkingIsOther = Field.IMPROVEMENT_PARKING_TYPE.get(comp) === "Other";
    const isIndustrial = PhysicalPropertyType.isIndustrial(comp);
    const isMobileHomePark = PhysicalPropertySubType.isMobileHomePark(comp);
    const isCarWash = PhysicalPropertySubType.isCarWash(comp);
    const isHotelOrMotel = PhysicalPropertySubType.isHotelOrMotel(comp);
    const isWareHouse = PhysicalPropertySubType.isWarehouse(comp);
    const isGolfCourse = PhysicalPropertySubType.isGolfCourse(comp);
    const isSingleFamilyDwelling = PhysicalPropertySubType.isSingleFamilyDwelling(comp);

    return (
        <CompEditorPanel name="ImprovementPanel" comp={comp} {...props}
          extra={<ShowAllFieldsToggle value={showAll} onChange={toggleShowAll}/>}>
              {!isSingleFamilyDwelling && ![RESIDENTIAL_RENT, VACANT_LAND, LEASE].includes(comp.type) ?
                  <Fragment>
                      <FormRow>
                          <Grid item md={6}>
                              <AreaField field={Field.IMPROVEMENT_GROSS_BUILDING_AREA} {...fieldProps} showConversion={showAcres}/>
                          </Grid>
                          <Grid item md={6}>
                              <CompField field={Field.IMPROVEMENT_BUILDING_AREA_SOURCE} {...fieldProps}/>
                          </Grid>
                      </FormRow>
                      <FormRow>
                          <Grid item md={6}>
                              <AreaField field={Field.IMPROVEMENT_RENTABLE_AREA} {...fieldProps} showConversion={showAcres}/>
                          </Grid>
                      </FormRow>
                      <FormRow>
                          <Grid item md={6}>
                              <AreaField field={Field.IMPROVEMENT_MAIN_FLOOR_PLATE_AREA} {...fieldProps} showConversion={showAcres}/>
                          </Grid>
                          {showAll && isIndustrial && <Grid item md={6}>
                            <AreaField field={Field.IMPROVEMENT_MEZZANINE_AREA} {...fieldProps} showConversion={showAcres}/>
                          </Grid>}
                      </FormRow>
                      {showAll && <FormRow>
                          <Grid item md={6}>
                              <AreaField field={Field.IMPROVEMENT_BASEMENT_AREA} {...fieldProps} showConversion={showAcres}/>
                          </Grid>
                          <Grid item md={6}>
                              <CompField field={Field.IMPROVEMENT_BASEMENT_FINISH} {...fieldProps}/>
                              {basementFinishIsSpecify &&
                                  <Stack direction="row" columnGap={2} mt={2}>
                                      <CompField field={Field.IMPROVEMENT_BASEMENT_FINISH_PERCENT} {...fieldProps}
                                                 disabled={basementFinishMode === BasementFinish.MODE_SF} />
                                      <AreaField field={Field.IMPROVEMENT_BASEMENT_FINISH_SF} {...fieldProps}
                                                 disabled={basementFinishMode === BasementFinish.MODE_PERCENT}
                                      />
                                  </Stack>
                              }
                          </Grid>
                      </FormRow>}
                      {isWareHouse && <FormRow>
                        <Grid item md={6}>
                          <AreaField field={Field.IMPROVEMENT_OFFICE_AREA} {...fieldProps} showConversion={false}/>
                        </Grid>
                        <Grid item md={6}>
                          <CompField field={Field.IMPROVEMENT_OFFICE_FINISH_PERCENT} {...fieldProps}/>
                        </Grid>
                      </FormRow>}
                      {isGolfCourse && <FormRow>
                          <Grid item md={4}>
                              <CompField field={Field.IMPROVEMENT_NUM_HOLES} {...fieldProps} />
                          </Grid>
                          <Grid item md={4}>
                              <CompField field={Field.IMPROVEMENT_COURSE_PAR} {...fieldProps} />
                          </Grid>
                          <Grid item md={4}>
                              <CompField field={Field.IMPROVEMENT_COURSE_YARDAGE} {...fieldProps} />
                          </Grid>
                      </FormRow>}
                      <FormRow>
                          <Grid item md={6}>
                              <FieldWithHelp field={Field.IMPROVEMENT_BUILDING_CLASS} help={<BuildingClassHelp/>}>
                                  <CompField field={Field.IMPROVEMENT_BUILDING_CLASS} {...fieldProps}/>
                              </FieldWithHelp>
                          </Grid>
                      </FormRow>
                      {!isCarWash && <FormRow>
                          <Grid item md={4}>
                              <CompField field={Field.IMPROVEMENT_NUM_BUILDINGS} {...fieldProps}/>
                          </Grid>
                          <Grid item md={4}>
                              {isHotelOrMotel && <CompField field={Field.IMPROVEMENT_NUM_ROOMS} {...fieldProps}/>}
                              {!isHotelOrMotel && <CompField field={Field.IMPROVEMENT_NUM_UNITS} {...fieldProps}/>}
                          </Grid>
                          <Grid item md={4}>
                              <CompField field={Field.IMPROVEMENT_NUM_STORIES} {...fieldProps}/>
                          </Grid>
                      </FormRow>}
                      {(comp.type === MULTI_FAMILY) && <>
                        <FormRow>
                          <Grid item md={4}>
                            <CompField field={Field.IMPROVEMENT_NUM_ROOMS} {...fieldProps}/>
                          </Grid>
                          <Grid item md={4}>
                            <CompField field={Field.IMPROVEMENT_NUM_BEDROOMS} {...fieldProps}/>
                          </Grid>
                          <Grid item md={4}>
                            <CompField field={Field.IMPROVEMENT_NUM_RENOVATED_UNITS} {...fieldProps}/>
                          </Grid>
                        </FormRow>
                        <FormRow>
                          <Grid item md={4}>
                            <CompField field={Field.IMPROVEMENT_ROOMS_PER_UNIT} {...fieldProps}/>
                          </Grid>
                        </FormRow>
                      </>}
                      {isCarWash && <FormRow>
                          <Grid item md={4}>
                              <CompField field={Field.IMPROVEMENT_NUM_SELF_SERVICE_BAYS} {...fieldProps}/>
                          </Grid>
                          <Grid item md={4}>
                              <CompField field={Field.IMPROVEMENT_NUM_AUTO_TUNNEL_BAYS} {...fieldProps}/>
                          </Grid>
                          <Grid item md={4}>
                              <CompField field={Field.IMPROVEMENT_NUM_BAYS} {...fieldProps}/>
                          </Grid>
                      </FormRow>}
                      {isWareHouse && <FormRow>
                        <Grid item md={4}>
                          <CompField field={Field.IMPROVEMENT_LOADING_BAYS} {...fieldProps}/>
                        </Grid>
                      </FormRow>}

                      {isMobileHomePark && <FormRow>
                          <Grid item md={4}>
                              <CompField field={Field.IMPROVEMENT_NUM_PADS} {...fieldProps}/>
                          </Grid>
                      </FormRow>}
                    {comp.type === MULTI_FAMILY && showAll && 
                      <FormRow>
                        <Grid item md={12}>
                          <CompField field={Field.IMPROVEMENT_AMENITIES} {...fieldProps}/>
                        </Grid>
                      </FormRow>
                    }
                      {comp.type === MULTI_FAMILY &&
                      <FormRow>
                          <Grid item md={4}>
                              <CompField field={Field.IMPROVEMENT_HAS_SUITE_MIX} {...fieldProps} useSwitch={true}/>
                          </Grid>
                      </FormRow>
                      }
                      {comp.type === MULTI_FAMILY && Field.IMPROVEMENT_HAS_SUITE_MIX.get(comp) === true &&
                          <FormRow>
                              <Grid item md={3}>
                                  <CompField field={Field.IMPROVEMENT_NUM_BACHELOR_UNITS} {...fieldProps} />
                              </Grid>
                              <Grid item md={3}>
                                  <CompField field={Field.IMPROVEMENT_NUM_1BEDROOM_UNITS} {...fieldProps} />
                              </Grid>
                              <Grid item md={3}>
                                  <CompField field={Field.IMPROVEMENT_NUM_2BEDROOM_UNITS} {...fieldProps} />
                              </Grid>
                              <Grid item md={3}>
                                  <CompField field={Field.IMPROVEMENT_NUM_3BEDROOM_UNITS} {...fieldProps} />
                              </Grid>
                              <Grid item md={3}>
                                <CompField field={Field.IMPROVEMENT_NUM_4BEDROOM_UNITS} {...fieldProps} />
                              </Grid>
                          </FormRow>
                      }
                      <FormRow>
                          <Grid item md={6}>
                              <CompField field={Field.IMPROVEMENT_LAND_TO_BUILDING_RATIO} {...fieldProps}/>
                          </Grid>
                          {[ICI, MULTI_FAMILY].includes(comp.type) &&
                          <Grid item md={6}>
                              <CompField field={Field.IMPROVEMENT_FLOOR_SPACE_RATIO} {...fieldProps}/>
                          </Grid>
                          }
                      </FormRow>
                      <FormRow>
                          <Grid item md={5}>
                              <CompField field={Field.IMPROVEMENT_PROPERTY_CONDITION} {...fieldProps}/>
                          </Grid>
                          <Grid item md={5}>
                              <CompField field={Field.IMPROVEMENT_CONSTRUCTION_QUALITY} {...fieldProps}/>
                          </Grid>
                          <Grid item md={2}>
                              <CompField field={Field.IMPROVEMENT_YEAR_BUILT} {...fieldProps}/>
                          </Grid>
                      </FormRow>
                      {[ICI, MULTI_FAMILY].includes(comp.type) && <FormRow>
                        <Grid item md={4}>
                          <CompField field={Field.IMPROVEMENT_HAS_ELEVATOR} {...fieldProps}/>
                        </Grid>
                      </FormRow>}
                      <FormRow>
                          <Grid item md={6}>
                              <CompField field={Field.IMPROVEMENT_PARKING_TYPE} {...fieldProps}/>
                          </Grid>
                          {parkingIsOther &&
                              <Grid item md={6}>
                                  <CompField field={Field.IMPROVEMENT_PARKING_COMMENTS} {...fieldProps}/>
                              </Grid>
                          }
                      </FormRow>
                      <FormRow>
                          <Grid item md={6}>
                              <CompField field={Field.IMPROVEMENT_NUM_PARKING_SPACES} {...fieldProps}/>
                          </Grid>
                          {isWareHouse && <Grid item md={6}>
                            <CompField field={Field.IMPROVEMENT_CLEARING_HEIGHT} {...fieldProps}/>
                          </Grid>}
                      </FormRow>
                    <FormRow>
                      <Grid item md={6}>
                        <CompField field={Field.IMPROVEMENT_PARKING_RATIO} {...fieldProps}/>
                      </Grid>
                    </FormRow>
                  </Fragment>
                  :
                  <Fragment>
                      {comp.type === LEASE ?
                          <FormRow>
                              <Grid item md={6}>
                                  <AreaField field={Field.IMPROVEMENT_LEASED_AREA} {...fieldProps} showConversion={showAcres}/>
                              </Grid>
                              <Grid item md={6}>
                                  <CompField field={Field.IMPROVEMENT_LEASED_AREA_SOURCE} {...fieldProps}/>
                              </Grid>
                          </FormRow>
                      :
                          <FormRow>
                              <Grid item md={6}>
                                  <AreaField field={Field.IMPROVEMENT_RENTABLE_AREA} {...fieldProps} showConversion={showAcres}/>
                              </Grid>
                              <Grid item md={6}>
                                  <CompField field={Field.IMPROVEMENT_RENTABLE_AREA_SOURCE} {...fieldProps}/>
                              </Grid>
                          </FormRow>
                      }
                      {showAll && ([LEASE, RESIDENTIAL_RENT, VACANT_LAND].includes(comp.type) || isSingleFamilyDwelling) &&
                      <Fragment>
                          <FormRow>
                              <Grid item md={6}>
                                  <AreaField field={Field.IMPROVEMENT_BASEMENT_AREA} {...fieldProps} showConversion={showAcres}/>
                              </Grid>
                              <Grid item md={6}>
                                  <CompField field={Field.IMPROVEMENT_BASEMENT_FINISH} {...fieldProps}/>
                              </Grid>
                          </FormRow>
                          {showAll && (comp.type === VACANT_LAND || isSingleFamilyDwelling) && <FormRow>
                              <Grid item md={6}>
                                  <AreaField field={Field.IMPROVEMENT_TOTAL_PROPOSED_GROSS_FLOOR_AREA} {...fieldProps} />
                              </Grid>
                          </FormRow>}
                          {basementFinishIsSpecify &&
                              <Grid container>
                                  <Grid item md={6}/>
                                  <Grid item md={6}>
                                      <FormRow style={{marginTop: 0}}>
                                          <Grid item md={6}>
                                              <CompField field={Field.IMPROVEMENT_BASEMENT_FINISH_PERCENT} {...fieldProps}
                                                         disabled={basementFinishMode === BasementFinish.MODE_SF} />
                                          </Grid>
                                          <Grid item md={6}>
                                              <AreaField field={Field.IMPROVEMENT_BASEMENT_FINISH_SF} {...fieldProps}
                                                         disabled={basementFinishMode === BasementFinish.MODE_PERCENT}
                                              />
                                          </Grid>
                                      </FormRow>
                                  </Grid>
                              </Grid>
                          }
                      </Fragment>
                      }
                      <FormRow>
                          <Grid item md={6}>
                              <CompField field={Field.IMPROVEMENT_YEAR_BUILT} {...fieldProps}/>
                          </Grid>
                          <Grid item md={6}>
                              <CompField field={Field.IMPROVEMENT_PROPERTY_CONDITION} {...fieldProps}/>
                          </Grid>
                      </FormRow>
                      {([RESIDENTIAL_RENT, VACANT_LAND].includes(comp.type) || isSingleFamilyDwelling) &&
                      <Fragment>
                          <FormRow>
                              <Grid item md={6}>
                                  <CompField field={Field.IMPROVEMENT_NUM_BEDROOMS} {...fieldProps}/>
                              </Grid>
                              <Grid item md={6}>
                                  <CompField field={Field.IMPROVEMENT_NUM_BATHROOMS} {...fieldProps}/>
                              </Grid>
                          </FormRow>
                          <FormRow>
                              <Grid item md={6}>
                                  <CompField field={Field.IMPROVEMENT_FEATURES} {...fieldProps}/>
                              </Grid>
                          </FormRow>
                          <FormRow>
                              <Grid item md={6}>
                                  <CompField field={Field.IMPROVEMENT_PARKING_TYPE} {...fieldProps}/>
                              </Grid>
                              {parkingIsOther &&
                                  <Grid item md={6}>
                                      <CompField field={Field.IMPROVEMENT_PARKING_COMMENTS} {...fieldProps}/>
                                  </Grid>
                              }
                          </FormRow>
                        {showAll && comp.type === VACANT_LAND &&
                        <>
                          <FormRow>
                            <Grid item md={8}>
                              <CompField field={Field.IMPROVEMENT_DWELLING_COMMENTS} {...fieldProps} multiline={true}/>
                            </Grid>
                            <Grid item md={4}>
                              <CompField field={Field.IMPROVEMENT_DWELLING_CONTRIBUTORY_VALUE} {...fieldProps}/>
                            </Grid>
                          </FormRow>
                          <FormRow>
                            <Grid item md={8}>
                              <CompField field={Field.IMPROVEMENT_OUTBUILDING_COMMENTS} {...fieldProps} multiline={true}/>
                            </Grid>
                            <Grid item md={4}>
                              <CompField field={Field.IMPROVEMENT_OUTBUILDING_CONTRIBUTORY_VALUE} {...fieldProps}/>
                            </Grid>
                          </FormRow>
                        </>}
                      </Fragment>

                      }
                  </Fragment>}
        </CompEditorPanel>
    );
};

export default ImprovementPanel;
