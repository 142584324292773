import React, {Fragment} from "react";
import {useSelector} from 'react-redux';
import prettyBytes from "pretty-bytes";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import BusinessIcon from '@mui/icons-material/Business';
import HomeIcon from '@mui/icons-material/Home';
import StorageIcon from '@mui/icons-material/Storage';
import {capitalize, pluralize} from "../util/utils";
import UsageBar from "./UsageBar";


const AccountCard = () => {
    const org = useSelector(state => state.app.org);
    const hasPlan = !!(org && org.plan);

    const ratioText = (count, max, singular) => {
        const c = count || 0;
        if ((max||-1) > 0) {
            return `${c} of ${pluralize(max, singular)}`;
        }
        else {
            return pluralize(c, singular);
        }
    };

    const styles = {
        inset: {
            pt: 0,
            "& > .MuiListItemText-inset": {
                mt: 0
            }
        },
    }
    return (
        <Fragment>
            {hasPlan &&
            <Card>
                <CardHeader title={org.name}
                            avatar={<Avatar><BusinessIcon/></Avatar>}
                />
                <CardContent sx={{
                    borderTop: "1px solid",
                    borderColor: 'grey.300'
                }}>
                    <List dense={true}>
                        <ListItem disableGutters>
                            <ListItemIcon >
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText primary="Comps Created"
                            />
                        </ListItem>
                        <ListItem disableGutters sx={styles.inset}>
                            <ListItemText className="NumComps" inset
                                  primary={ratioText(org.num_comps, org.plan.max_comps, "Comp")}
                                  primaryTypographyProps={{color: "textSecondary"}}
                                  secondary={org.plan.max_comps > 0 ? <UsageBar value={org.num_comps / org.plan.max_comps * 100}/> : null}
                                  secondaryTypographyProps={{component: "span"}}
                            />
                        </ListItem>
                        <ListItem disableGutters>
                            <ListItemIcon >
                                <StorageIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="Storage Used"
                            />
                        </ListItem>
                        <ListItem disableGutters sx={styles.inset}>
                            <ListItemText inset
                                          primary={`${prettyBytes(org.num_bytes || 0)}${org.plan.max_bytes > 0 ? ` of ${prettyBytes(org.plan.max_bytes)}`:""}`}
                                          primaryTypographyProps={{color: "textSecondary"}}
                                          secondary={org.plan.max_bytes > 0 ? <UsageBar value={(org.num_bytes||0) / org.plan.max_bytes * 100}/> : null}
                                          secondaryTypographyProps={{component: "span"}}
                            />

                        </ListItem>
                        <ListItem disableGutters>
                            <ListItemIcon>
                                <DescriptionOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="Reports Created"
                            />
                        </ListItem>
                        <ListItem disableGutters sx={styles.inset}>
                            <ListItemText inset
                                          //primary={`${org.num_reports || 0}${org.plan.max_reports > 0 ? ` of `:""} ${pluralize(org.plan.max_reports||0, 'Report')}`}
                                          primary={ratioText(org.num_reports, org.plan.max_reports, "Report")}
                                          primaryTypographyProps={{color: "textSecondary"}}
                                          secondary={org.plan.max_reports > 0 ? <UsageBar value={(org.num_reports||0) / org.plan.max_reports * 100}/> : null}
                                          secondaryTypographyProps={{component: "span"}}
                            />

                        </ListItem>
                        {org.plan &&
                        <ListItem disableGutters>
                            <ListItemIcon>
                                <CreditCardIcon/>
                            </ListItemIcon>
                            <ListItemText
                                primary="Current Plan"
                                secondary={capitalize(org.plan.name)}
                            />
                        </ListItem>
                        }
                    </List>
                </CardContent>
            </Card>}
            {!hasPlan && <Typography variant="subtitle2">Account data is unavailable.</Typography>}

        </Fragment>
    );
};

export default AccountCard;
