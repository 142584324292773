import React, {Fragment, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import SendIcon from "@mui/icons-material/Send";
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import {cancelInvite, enableUser, grantRole, inviteUser, suspendUser} from '../auth/state';
import HelpDialog from '../comp/editor/HelpDialog';
import ErrorAlert from '../util/ErrorAlert';
import {FormRow} from "../util/Form";
import {TabPanel} from "../util/Tab";
import Spinner from "../util/Spinner"
import ButtonMenu from "../util/ButtonMenu";
import PlanLimitAlert from "../util/PlanLimitAlert";
import {getHigherRoles, getLowerRoles, hasRole, ROLE_OWNER, roleTitle} from "../auth/roles";
import {planLimitSelector} from "../app/selectors";
import {HeadCell, TableToolbar} from "../util/Table";
import {statusTitle, STATUS_ACTIVE, STATUS_CANCELLED, STATUS_PENDING, STATUS_DEACTIVATED} from './constants';
import {cancelUser, loadUsers} from './state';

const FormField = ({...props}) => {
    return (
      <TextField variant="outlined" size="small" fullWidth {...props}></TextField>
    );
};

const TeamPanel = (props) => {
    const isOwner = useSelector(state => hasRole(state.auth, ROLE_OWNER));
    const users = useSelector(state =>  state.pref.users || []);
    const maxUsers = useSelector(planLimitSelector("max_users", Number.MAX_SAFE_INTEGER));
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [isWorking, setWorking] = useState(true);
    const [showSuspended, setShowSuspended] = useState(false);
    const [showRoleHelp, setShowRoleHelp] = useState(false);
    const [error, setError] = useState(null);
    const [status, setStatus] = useState(null);

    const clearAlert = () => {
        setStatus(null);
        setError(null);
    }

    const isUserLimitReached = users.filter(u => ![STATUS_DEACTIVATED, STATUS_CANCELLED].includes(u.status)).length >= maxUsers;

    React.useEffect(() => {
        dispatch(loadUsers())
          .unwrap()
          .finally(() => setWorking(false))
    }, [dispatch]);

    const [invite, setInvite] = useState({
        email: "",
        firstName: "",
        lastName: "",
        invitedBy: {
            name: auth.user.displayName,
            email: auth.user.email,
        }
    });
    const [workingOn, setWorkingOn] = useState([]);
    const [isInviting, setIsInviting] = useState(false);

    const handleUpdate = (e) => {
        setInvite({
            ...invite,
            [e.target.name]: e.target.value
        });
    };
    const handleInvite = () => {
        clearAlert();
        setIsInviting(true);
        dispatch(inviteUser(invite)).unwrap()
            .then(() => {
               dispatch(loadUsers());
               setStatus(`An invite to your QuickComp team has been sent to ${invite.firstName} ${invite.lastName} at ${invite.email}.`)
            })
            .catch(setError)
            .finally(() => {
                setIsInviting(false);
            })
    };

    const workOn = email => setWorkingOn( [...workingOn, email]);
    const workOff = email => setWorkingOn(workingOn.filter(it => it !== email));

    const handleCancelInvite = email => {
        clearAlert()
        workOn(email);
        dispatch(cancelInvite(email))
          .unwrap()
          .then(() => {
            dispatch(cancelUser(email));
            workOff(email);
            setStatus(`The team invite for ${email} has been cancelled.`)
          }).catch(setError)
    };
    const handleGrantRole = (email, role) => {
        clearAlert()
        workOn(email);
        dispatch(grantRole({email, role}))
          .unwrap()
          .then(() => {
            workOff(email);
            dispatch(loadUsers());
            setStatus(`${email} now has the role of ${roleTitle(role)}.`)
          })
          .catch(setError);
    }

    const handleSuspend = email => {
        clearAlert();
        workOn(email);
        dispatch(suspendUser(email))
          .unwrap()
          .then(() => {
            workOff(email);
            dispatch(loadUsers());
            setStatus(`The account for ${email} has been successfully deactivated.`)
          })
          .catch(setError)
    };
    const handleReactivate = email => {
        workOn(email);
        dispatch(enableUser(email))
          .unwrap()
          .then(() => {
            workOff(email);
            dispatch(loadUsers());
            setStatus(`The account for ${email} has been successfully reactivated.`)
          });
    };

    return (
        <TabPanel {...props} className="TeamPanel">
            {isWorking ?
                <Spinner message="Loading Team Members"/> :
                <Fragment>
                    {isUserLimitReached && <PlanLimitAlert title="User Limit Reached"
                        message="You have reached the maximum number of users that your plan allows for."
                        upgradeMessage="Upgrade your plan to add more users." gutterBottom/>}
                    <Paper>
                        <TableToolbar title="Team Members">
                            <FormControlLabel
                              control={<Switch checked={showSuspended} onChange={() => setShowSuspended(!showSuspended)}/>}
                              label="Show deactivated users"
                            />
                        </TableToolbar>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <HeadCell width="30%">Name</HeadCell>
                                        <HeadCell width="25%" align="left">Email</HeadCell>
                                        <HeadCell width="20%" align="left">
                                            Role <IconButton size="small" onClick={() => setShowRoleHelp(true)}>
                                            <HelpIcon style={{fontSize: "1rem"}}/>
                                        </IconButton>
                                        </HeadCell>
                                        <HeadCell width="20%" align="right"></HeadCell>
                                        <HeadCell width="5%" align="right"></HeadCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users.filter(u => showSuspended || u.status !== STATUS_DEACTIVATED).map((u,i) => {
                                        const isWorking = workingOn.includes(u.email);
                                        const cellStyles = {
                                          ...(u.status === STATUS_DEACTIVATED && {color: 'text.disabled'}),
                                          ...(u.status === STATUS_PENDING && {fontStyle: 'italic'})
                                        };
                                        let upRoles = getHigherRoles(u.role);
                                        if (upRoles.includes(ROLE_OWNER) && !isOwner) {
                                            upRoles = upRoles.filter(it => it !== ROLE_OWNER);
                                        }
                                        let downRoles = getLowerRoles(u.role);

                                        return (
                                            <TableRow key={i}>
                                                <TableCell component="th" scope="row" sx={cellStyles}>
                                                    {u.first_name} {u.last_name}
                                                </TableCell>
                                                <TableCell align="left" sx={cellStyles}>
                                                    {u.email}
                                                </TableCell>
                                                <TableCell align="left" sx={cellStyles}>
                                                    {u.status === STATUS_ACTIVE ? roleTitle(u.role) : statusTitle(u.status)}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {u.role !== ROLE_OWNER && <Box>
                                                        {u.status === STATUS_PENDING && <Tooltip title="Cancel invite">
                                                            <IconButton onClick={() => handleCancelInvite(u.email)} size="large">
                                                                <CancelScheduleSendIcon/>
                                                            </IconButton>
                                                        </Tooltip>}
                                                        {u.status === STATUS_DEACTIVATED && <Tooltip title={isUserLimitReached?"Unable to reinstate user due to user limit":"Reactivate user"}>
                                                            <IconButton
                                                                onClick={() => handleReactivate(u.email)}
                                                                disabled={isUserLimitReached || isWorking}
                                                                size="large">
                                                                <PlayCircleOutlineIcon/>
                                                            </IconButton>
                                                        </Tooltip>}
                                                        {u.status === STATUS_ACTIVE && upRoles.length > 0 &&
                                                            <ButtonMenu disabled={isWorking} icon={<PlayCircleOutlineIcon style={{rotate: '270deg'}}/>} tooltip={"Upgrade user to"}>
                                                                {upRoles.map(r => <MenuItem key={r} onClick={() => handleGrantRole(u.email, r)}>
                                                                    <Typography variant="inherit">{roleTitle(r)}</Typography>
                                                                </MenuItem>)}
                                                            </ButtonMenu>
                                                        }
                                                        {u.status === STATUS_ACTIVE && downRoles.length > 0 &&
                                                            <ButtonMenu disabled={isWorking} icon={<PlayCircleOutlineIcon style={{rotate: '90deg'}}/>} tooltip={"Downgrade user to"}>
                                                                {downRoles.map(r => <MenuItem key={r} onClick={() => handleGrantRole(u.email, r)}>
                                                                    <Typography variant="inherit">{roleTitle(r)}</Typography>
                                                                </MenuItem>)}
                                                            </ButtonMenu>
                                                        }
                                                        {u.status === STATUS_ACTIVE &&
                                                          <Tooltip title={"Deactivate user"}>
                                                              <IconButton onClick={() => handleSuspend(u.email)} disabled={isWorking} size="large">
                                                                  <PauseCircleOutlineIcon/>
                                                              </IconButton>
                                                          </Tooltip>
                                                        }
                                                    </Box>}
                                                </TableCell>
                                                <TableCell>
                                                    {workingOn.includes(u.email) && <CircularProgress size={24}/>}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                    {error && <ErrorAlert error={error} sx={{mt:1}}/>}
                    {status && <Alert severity="success" onClose={() => setStatus(null)} sx={{mt:1}}>
                        <AlertTitle>Success</AlertTitle>
                        <Typography>{status}</Typography>
                    </Alert>}
                    <Box component="form" sx={{mt: 4}}>
                        <Typography gutterBottom>Invite a Team Member</Typography>
                        <FormRow sx={{alignItems: "center"}}>
                            <Grid item md={3}>
                                <FormField label="First Name" name="firstName" value={invite.firstName} onChange={handleUpdate}/>
                            </Grid>
                            <Grid item md={3}>
                                <FormField label="Last Name" name="lastName" value={invite.lastName} onChange={handleUpdate}/>
                            </Grid>
                            <Grid item md={5}>
                                <FormField label="Email Address" name="email" value={invite.email} onChange={handleUpdate}/>
                            </Grid>
                            <Grid item md={1}>
                                <Button
                                    disabled={isUserLimitReached || isInviting || workingOn.length > 0}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    startIcon={isInviting ? <CircularProgress size={24}/> : <SendIcon />}
                                    onClick={!isUserLimitReached ? handleInvite : null}
                                >
                                    Invite
                                </Button>
                            </Grid>
                        </FormRow>
                    </Box>
                </Fragment>

            }
            <HelpDialog title="Roles" open={showRoleHelp} onClose={() => setShowRoleHelp(false)}>
                <Typography>
                    Roles in Quickcomp control what functions that individual team members can perform in the app. The following describes each
                    role that it's associated capabilities.
                </Typography>
                <Typography variant="h5">
                    Creator
                </Typography>
                <Typography>
                    The <em>Creator</em> role is the most basic role, that allows team members to create comps, and generate reports. Users assigned this role can not
                    create templates, or manage any organizational settings. This role is suitable for assistants or junior appraisers who
                    require confirmation by a senior appraiser after initial data entry.
                </Typography>
                <Typography variant="h5">
                    Editor
                </Typography>
                <Typography>
                    The <em>Editor</em> role encompasses everything covered by the <em>Creator</em> role, with the addition of allowing the team member
                    to create templates for reports and spreadsheets. This role does not allow for managing any organizational settings.
                </Typography>
                <Typography variant="h5">
                    Administrator
                </Typography>
                <Typography>
                    The <em>Administrator</em> role allows the team member to perform all functions of the system, including the managing of all organizational settings
                    except for billing and payment information.
                </Typography>
                <Typography variant="h5">
                    Owner
                </Typography>
                <Typography>
                    The <em>Owner</em> role allows the team member to perform all functions of the system.
                </Typography>

            </HelpDialog>
        </TabPanel>
    );
};

export default TeamPanel;
