import {useTheme} from '@mui/material';
import React from "react";
import Avatar from "@mui/material/Avatar";
import {propertyTypeAbbrev, ICI, LEASE, MULTI_FAMILY, RESIDENTIAL_RENT, VACANT_LAND} from "../model/propertyTypes";
import Badge from "@mui/material/Badge";
import { red, cyan, teal, blue, green } from '@mui/material/colors';
import {ConfirmationSource} from "../model/fields";
import {CURRENT_LISTING, UNCONFIRMED} from "../model/colors";


const TypeAvatar = ({type, size, disabled, clickable=false}) => {
    const theme = useTheme();
    const shade = 500;
    const colorRamp = type === ICI ? blue :
        type === MULTI_FAMILY ? red :
        type === VACANT_LAND ? teal :
        type === LEASE ? cyan :
        type === RESIDENTIAL_RENT ? green : 'grey';
    return (
        <Avatar sx={{
            bgcolor: colorRamp[shade],
            '&:hover': {
                bgcolor: colorRamp[clickable ? shade+100 : shade],
                cursor: clickable ? 'pointer' : 'default',
            },
           ...(disabled === true && {
              bgcolor: 'grey.400',
             '&:hover': {
               bgcolor: 'grey.400',
             }
           }),
           ...(size === 'large' && {width: theme.spacing(8), height: theme.spacing(8)}),
           ...(size === 'small' && {width: theme.spacing(4), height: theme.spacing(4), fontSize: "1rem"}),
        }}>
            {propertyTypeAbbrev(type)}
        </Avatar>
    )
};

export const TypeAvatarWithStatus = ({comp, ...props}) => {
    const isCurrentListing = ConfirmationSource.isCurrentListing(comp);
    const isUnconfirmed = ConfirmationSource.isUnconfirmed(comp);
    return (
        <Badge invisible={!(isCurrentListing||isUnconfirmed)}
           sx={{
             '&& .MuiBadge-badge': {
               border: `1px solid white !important`,
               width: 16,
               height: 16,
               borderRadius: '100%',
               left: 4,
               bottom: 4,
             },
             ...(isCurrentListing && {
               '&& > .MuiBadge-badge': {
                 bgcolor: CURRENT_LISTING
               }
             }),
             ...(isUnconfirmed && {
               '&& > .MuiBadge-badge': {
                 bgcolor: UNCONFIRMED
               }
             })
           }}
           overlap="circular"
           variant="dot"
           anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
           }}>
            <TypeAvatar type={comp.type} {...props}/>
        </Badge>
    )
};

export default TypeAvatar;
