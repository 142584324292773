import React from "react";
import { useDispatch } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {MICROSOFT_FONTS, lookupFont} from '../../model/fonts';
import {FieldLabel} from "../../model/fields";
import {ALMOST_BLACK, ALMOST_WHITE, BLUE} from "../../model/colors";
import {cellStyle} from '../utils';
import TextBuilder from "../../util/TextBuilder";
import {updateStyle} from "../state"


const styles = {
    headCell: {
      textTransform: "uppercase",
      whiteSpace: "nowrap",
      fontWeight: "bold",
      color: ALMOST_WHITE,
      backgroundColor: BLUE,
    }
  }

export const RowLayout = ({sheet, comps}) => {
    const font = lookupFont(sheet.styles.base.font.family, MICROSOFT_FONTS);
    const headStyle = {
      ...cellStyle(sheet.styles.field.head, font),
      borderBottom: `1px solid ${ALMOST_BLACK}`
    };
    const bodyStyle = cellStyle(sheet.styles.field.body, font);

    return (
      <>
          <TableHead>
              <TableRow>
                  <TableCell sx={styles.headCell} style={headStyle}>#</TableCell>
                  {sheet.fields.map((f, i) =>
                    <TableCell key={f.path} align={i>0?"right":"left"} sx={styles.headCell} style={headStyle}>
                        {f.label(FieldLabel.REPORT_HEADER)}
                    </TableCell>)}
              </TableRow>
          </TableHead>
          <TableBody>
              {comps.slice(0,3).map((comp,i) => <TableRow key={comp.id}>
                  <TableCell component="th" style={bodyStyle}>{i+1}</TableCell>
                  {sheet.fields.map((f, i) =>
                    <TableCell key={f.path} align={i>0?"right":"left"} style={bodyStyle}>
                        {f.render(comp)}
                    </TableCell>)}
              </TableRow>)}
          </TableBody>
      </>
    )
}
export const ColLayout = ({sheet, comps}) => {
    const font = lookupFont(sheet.styles.base.font.family, MICROSOFT_FONTS);

    const borderBottom = {
      borderBottom: `1px solid ${ALMOST_BLACK}`
    }
    const headStyle = {
      ...cellStyle(sheet.styles.field.head, font),
      borderRight: `1px solid ${ALMOST_BLACK}`
    };
    const bodyStyle = cellStyle(sheet.styles.field.body, font);
    const indexHeadStyle = {
      ...headStyle,
      ...borderBottom
    }
    const indexBodyStyle = {
      ...bodyStyle,
      ...borderBottom
    }
    return (
      <>
          <TableHead>
              <TableRow>
                <TableCell component="th" sx={styles.headCell} style={indexHeadStyle}>#</TableCell>
                  {comps.slice(0,3).map((c, i) => <TableCell key={c.id} style={indexBodyStyle}>
                      {i+1}
                  </TableCell>)}
              </TableRow>
          </TableHead>
          <TableBody>
            {sheet.fields.map((f, i) =>  <TableRow key={f.path}>
              <TableCell component="th" sx={styles.headCell} style={headStyle}>
                {f.label(FieldLabel.REPORT_HEADER)}
              </TableCell>
              {comps.slice(0,3).map((comp, i) => <TableCell key={comp.id} style={bodyStyle}>
                {f.render(comp)}
              </TableCell>)}
            </TableRow>)}
          </TableBody>
      </>
    )
}

export const FieldStyles = ({sheet}) => {
  const dispatch = useDispatch();
  return (
    <Grid container>
      <Grid item md={6}>
          <Box mb={2}>
          <Typography variant="body2" sx={styles.label}>Header Cells</Typography>
          {/*<Typography variant="caption">Style for header cells.</Typography>*/}
          </Box>
          <TextBuilder config={sheet.styles.field.head} size="small" showFamily={false}
                      onFontChange={(k,v) => dispatch(updateStyle({path:`field.head.font.${k}`, value:v}))}
                      showBackground={true} onBackgroundChange={(v) => dispatch(updateStyle({path:'field.head.background', value:v}))}
                      colorPickerOpts={{anchor:'bottom'}}
                      />
      </Grid>
      <Grid item md={6}>
          <Box mb={2}>
          <Typography variant="body2" sx={styles.label}>Body Cells</Typography>
          {/*<Typography variant="caption">Style for body cells.</Typography>*/}
          </Box>
          <TextBuilder config={sheet.styles.field.body} size="small" showFamily={false}
                      onFontChange={(k,v) => dispatch(updateStyle({path:`field.body.font.${k}`, value:v}))}
                      showBackground={true} onBackgroundChange={(v) => dispatch(updateStyle({path:'field.body.background', value:v}))}
                      colorPickerOpts={{anchor:'bottom'}}
                      />
      </Grid>
  </Grid>
  )
}