import React from "react";
import {styled, Typography} from "@mui/material";
import Box from '@mui/material/Box';
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import List from "@mui/material/List";
import CardActions from "@mui/material/CardActions";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import Avatar from "@mui/material/Avatar";
import {green} from "@mui/material/colors";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";


const Header = styled(CardHeader)(({theme}) => ({
    root: {
        justifyContent: "center"
    },
    content: {
        fontSize: theme.typography.h5.fontSize,
        flex: 0
    }
}));

const PlanCard = ({plan, chosen, onChoose, disabled, buttonText}) => {
    return (
        <Card elevation={1} className={`PlanCard`} sx={{flex: "1 0"}}>
            <Header title={plan.name} subheader={null} disableTypography={true}
                        avatar={ chosen ?
                          <Avatar
                            sx={{
                                color: "white",
                                backgroundColor: green[500],
                                height: "24px",
                                width: "24px"
                            }}><CheckIcon size="small"/></Avatar>
                          : null }
            />
            <Box sx={{
                bgcolor: 'contrast.main',
                color: 'primary.main',
                py: 4, px: 1,
                textAlign: "center"
            }}>
                <Typography variant="h1" sx={{
                    fontWeight: 700,
                    textTransform: "uppercase"
                }}>{plan.price}</Typography>
                <Typography variant="subtitle2" sx={{
                    fontWeight: 100,
                    color: 'primary.contrastText',
                    textTransform: "uppercase"
                }}>{plan.period}</Typography>
            </Box>
            <CardContent>
                <List dense={true}>
                    {plan.limits.map((d, i) => <ListItem key={i} disableGutters={true}>
                        <ListItemText primary={d.text}/>
                    </ListItem>)}
                </List>
            </CardContent>
            <CardActions sx={{
                bgcolor: 'grey.100',
                justifyContent: "center"
            }}>
                <Button variant={chosen?"contained":"outlined"} color="primary" size="large"
                        onClick={() => onChoose(plan)} disabled={disabled}>
                    {buttonText || "Choose"}
                </Button>
            </CardActions>
        </Card>
    );
};

export default PlanCard;
