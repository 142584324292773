import React from "react";
import MUIDivider from "@mui/material/Divider";

const Divider = ({style}) => {
    return (
        <MUIDivider style={{backgroundColor: style.divider.color, height: `${style.divider.height}px`}}/>
    )
}

export default Divider;
