import React from "react";
import {useDispatch, useSelector} from "react-redux";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import PaletteIcon from "@mui/icons-material/Palette";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SettingsIcon from '@mui/icons-material/Settings';
import Grid from "@mui/material/Grid";
import Fonts from "../content/Fonts";
import {FormSection} from "../../util/Form";
import {TabPanel, TabSideBar} from "../../util/Tab";
import {updateConfig, updateView} from '../state';
import Instruction from "./Instruction";
import SingleTable from "../content/SingleTable";
import SelectWithLabel from "../../util/SelectWithLabel";
import {THUMBNAIL_END, THUMBNAIL_NONE, THUMBNAIL_START} from "../../model/reportTypes";
import ColumnBuilder from "./ColumnBuilder";
import TableStylePanel from "./TableStylePanel";
import TableSettingsPanel from './TableSettingsPanel';
import RowSplitter from "./RowSplitter";


const LayoutPanel = ({...props}) => {
    const config = useSelector(state => state.report.curr.single);
    const dispatch = useDispatch();

    return (
        <TabPanel {...props}>
            <ColumnBuilder config={config} configName="single"/>
            <FormSection label="Thumbnail" help="Configure the location of the comp thumbnail in the table.">
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <SelectWithLabel label="Position" value={config.thumbnail||THUMBNAIL_END}
                                         values={[THUMBNAIL_START, THUMBNAIL_END, THUMBNAIL_NONE]}
                                         onChange={evt => dispatch(updateConfig({
                                           path: "single.thumbnail",
                                           value:evt.target.value
                                         }))}/>
                    </Grid>
                </Grid>
            </FormSection>
            <RowSplitter config={config} configName="single"/>
        </TabPanel>
    );
};

const PreviewPanel = ({...props}) => {
    return (
        <TabPanel {...props}>
            <Fonts/>
            <SingleTable/>
        </TabPanel>
    );
};

const SinglePanel = ({...props}) => {
    const activeTab = useSelector(state => state.report.view.single.tab);
    const dispatch = useDispatch();
    return (
        <TabPanel {...props}>
            <Instruction>The comp table lists all of the comps in the report.</Instruction>
            <TabSideBar tabs={[
                [{label: "Preview", icon: <VisibilityIcon/>}, (p) => <PreviewPanel {...p} />],
                [{label: "Layout", icon: <ViewColumnIcon/>}, (p) => <LayoutPanel {...p} />],
                [{label: "Styles", icon: <PaletteIcon/>}, (p) => <TableStylePanel configName="single" {...p} />],
                [{label: "Settings", icon: <SettingsIcon/>}, (p) => <TableSettingsPanel configName="single" {...p} />],
            ]} activeTab={activeTab} onTabChange={t => dispatch(updateView({path:"summary.tab", value:t}))}/>
        </TabPanel>
    );
};

export default SinglePanel;
