import React from "react";
import {groupBy} from "lodash";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from '@mui/material/AvatarGroup';
import Badge from '@mui/material/Badge';
import {getRole} from '../auth/roles';

export const UserAvatar = ({user, ...props}) => {
  const name = user ? (typeof user === "string" ? user : user.displayName || user.name || user.email) : "?";
  const initials = name ? name.split(" ", 2).filter(it => !!it).map(it => it[0].toUpperCase()).join("") : "?";
  return (
      <Avatar {...props}>{initials}</Avatar>
  )
};

export const UserGroupAvatar = ({users, sx, ...props}) => {
  const uniqueUsers = groupBy(users.filter(it => it), u => typeof u === "string" ? u : u.email);
  return (
      <AvatarGroup sx={sx}>
        {Object.keys(uniqueUsers).map(k => <UserAvatar key={k} user={uniqueUsers[k][0]} {...props}/>)}
      </AvatarGroup>
  )
};


export const UserWithRoleAvatar = ({auth, ...props}) => {
  return (
    <Badge overlap="circular" color="primary" badgeContent={getRole(auth).charAt(0).toUpperCase()}
           anchorOrigin={{
             vertical: 'top',
             horizontal: 'right',
           }}>
      <UserAvatar user={auth.user} {...props}/>
    </Badge>
  )
}
