import React, {useEffect, useState} from "react";
import {getFunctions, httpsCallable} from 'firebase/functions';
import Grid from "@mui/material/Grid";
import PlanCard from "./PlanCard";


const PlanGrid = ({plan, onChange}) => {
    const [plans, setPlans] = useState(null);

    useEffect(() => {
        httpsCallable(getFunctions(), 'getPlans')()
          .then(result => setPlans(result.data.plans));
    }, []);

    return (
        <>
            {plans && <Grid container spacing={1}>
                {plans.map(p => <Grid key={p.id} item style={{flex: "1 0"}}>
                    <PlanCard plan={p} chosen={p.name === plan} onChoose={onChange} />
                </Grid> )}
            </Grid>}
        </>
    );
};

export default PlanGrid;
