import React from "react";
import { ToggleButton as MUIToggleButton } from '@mui/material';

const ToggleButton = ({size, sx, ...props}) => {
    return (
        <MUIToggleButton size={size} {...props} sx={{
            ...(size === 'medium' && {height: '56px', px:2}),
            ...sx
        }}/>
    );
};

export default ToggleButton;
