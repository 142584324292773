import React, {Children} from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Tooltip from '@mui/material/Tooltip';
import MenuIcon from "@mui/icons-material/Menu";


const ButtonMenu = ({icon, disabled, tooltip="", children}) => {
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

    const handleOpen = evt => setMenuAnchorEl(evt.currentTarget);
    const handleClose = () => setMenuAnchorEl(null);

    return <>
      <Tooltip title={tooltip}>
          <IconButton onClick={handleOpen} disabled={disabled===true} size="large">
              {icon || <MenuIcon />}
          </IconButton>
      </Tooltip>
        <Menu
            anchorEl={menuAnchorEl}
            keepMounted
            open={Boolean(menuAnchorEl)}
            onClose={handleClose}
            sx={{marginTop: "48px"}}
        >
            {Children.map(children.filter(it => it !== false), child => React.cloneElement(child, {
                onClick: () => {
                    handleClose();
                    child.props.onClick();
                }
            }))}
        </Menu>
      </>;
};

export default ButtonMenu;
