import React from "react";
import {SketchPicker} from 'react-color';
import { useTheme } from '@mui/material/styles';
import Box from "@mui/material/Box";
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import CustomFormField from "./CustomFormField";


const ColorPicker = ({value, label, onChange, size, disabled, anchor="top", withClear=false}) => {
    const theme = useTheme();
    const [show, setShow] = React.useState(false);

    const isDisabled = disabled === true;
    const c = isDisabled ? theme.palette.grey["400"] : (value || "#fff");

    return (
          <CustomFormField label={label} onClick={ isDisabled ? null : () => setShow(!show)} fullWidth disabled={disabled}>
              <Stack direction="row" alignItems="center" gap={0.5}>
                <Box sx={{
                  backgroundColor: c,
                  width: "100%",
                  height: "38px",
                  borderRadius: "1px",
                  ...(size==="small" && {height: "22px"})
                }}/>
                {withClear && <IconButton sx={{p: 0.5}} onClick={(evt) => {
                  evt.stopPropagation();
                  onChange(null);
                }}>
                  <ClearIcon style={{fontSize: "0.65rem"}}/>
                </IconButton>}
              </Stack>

              {show &&
                  <Box sx={{
                      position: "absolute",
                      zIndex: 2,
                      ...(anchor === 'top' ? {top: '58px'} : {bottom: '58px'}),
                      left: 0,
                      ...(size==="small" && (anchor === 'top' ? {top: '42px'} : {bottom: '42px'}))
                  }} onClick={evt => evt.stopPropagation()}>
                      <Box onClick={ () => setShow(false) } sx={{
                          position: "fixed",
                          top: 0,
                          right: 0,
                          bottom: 0,
                          left: 0}
                      }/>
                      <SketchPicker color={ c } onChange={ v => onChange(v.hex) } />
                  </Box>
              }
          </CustomFormField>
    )
};

export default ColorPicker;
