export const isCompLimitReachedSelector = state => {
    const org = state.app.org;
    const maxComps = planLimitSelector("max_comps", 0)(state);
    return maxComps > 0 && (maxComps <= org.num_comps);
};

export const isStorageLimitReachedSelector = state => {
    const org = state.app.org;
    const maxBytes = planLimitSelector("max_bytes", 0)(state);
    return maxBytes > 0 && (maxBytes <= org.num_bytes);
};

export const isReportLimitReachedSelector = state => {
    const org = state.app.org;
    const maxReports = planLimitSelector("max_reports", 0)(state);
    return maxReports > 0 && (maxReports <= org.num_reports);
};

export const planLimitSelector = (prop, def) => state => {
    const org = state.app.org;
    if (org && org.plan) {
        const l = org.plan[prop];
        return l > 0 ? l : def;
    }
    return def;
};
