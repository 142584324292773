import React, {Fragment, useState} from "react";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";


const AvatarWithMenu = ({children, items, onSelect, ...props}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = val => {
        setAnchorEl(null);
        if (val) onSelect(val);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'popover' : undefined;
    return (
        <Fragment>
            <Avatar onClick={handleClick} sx={{cursor: "pointer"}} {...props}>{children}</Avatar>
            <Menu id={id} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => handleClose(null)} keepMounted>
                {items.map((it, i) => React.cloneElement(it[0], {key: i, onClick: () => handleClose(it[1])}))}
            </Menu>

        </Fragment>

    );
};

export default AvatarWithMenu;
