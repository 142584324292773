import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {collection, doc, getDocs, getFirestore, orderBy, query, setDoc} from 'firebase/firestore';
import {getFunctions, httpsCallable} from 'firebase/functions';
import {merge} from 'lodash';
import {STATUS_CANCELLED} from './constants';

export const loadUsers = createAsyncThunk(
  'pref/loadUsers',
  async (arg, {getState, rejectWithValue}) => {
    const org = getState().auth.org.id;
    try {
      return (await (getDocs(query(
        collection(getFirestore(), 'orgs', org, 'users'),
        orderBy("created", "asc")
      )))).docs.map(d => d.data());
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const saveOrg = createAsyncThunk(
  'pref/saveOrg',
  async (arg, {getState, rejectWithValue}) => {
    const {auth, pref} = getState();
    try {
      await setDoc(doc(getFirestore(), 'orgs', auth.org.id), pref.org, {merge: true});
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const loadPlans = createAsyncThunk(
  'pref/loadPlans',
  async (arg, {getState, rejectWithValue}) => {
    const currentPlan = getState().app.org.plan;
    try {
      const result = await httpsCallable(getFunctions(), 'getPlans')();
      const plans = result.data.plans;
      return {plans, currentPlan: plans.map(it => it.name).indexOf(currentPlan.name)};
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const changePlan = createAsyncThunk(
  'pref/changePlan',
  async ({newPlan, oldPlan}, { rejectWithValue}) => {
    try {
      await httpsCallable(getFunctions(), 'changePlan')({
        plan: newPlan.name,
        subscription: oldPlan.subscription
      });
      return newPlan;
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const loadCards = createAsyncThunk(
  'pref/loadCards',
  async (arg, { getState, rejectWithValue}) => {
    const plan = getState().app.org.plan;
    try {
      const result = await httpsCallable(getFunctions(), 'getCards')({
        customer: plan.customer
      });
      return result.data.cards;
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const changeCard = createAsyncThunk(
  'pref/changeCard',
  async ({newCard, billingName, stripe}, { getState, rejectWithValue}) => {
    const email = getState().auth.user.email;
    const plan = getState().app.org.plan;
    try {
      const pm = await stripe.createPaymentMethod({
        type: 'card',
        card: newCard,
        billing_details: {
          email: email,
          name: billingName
        },
      });
      if (!pm.error) {
        const result = await httpsCallable(getFunctions(), 'changeCard')({
          customer: plan.customer,
          oldPayment: plan.payment,
          newPayment: pm.paymentMethod.id,
        });
        if (result.data.status !== "success") {
          return rejectWithValue(result.error);
        }
      }
      else return rejectWithValue(pm.error);
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const loadInvoices = createAsyncThunk(
  'pref/loadInvoices',
  async (arg, { getState, rejectWithValue}) => {
    const plan = getState().app.org.plan;
    try {
      const result = await httpsCallable(getFunctions(), 'getInvoices')({
        customer: plan.customer
      });
      if (result.data.status !=='success') {
        return rejectWithValue(result.data.error);
      }
      return result.data.invoices;
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

const pref = createSlice({
  name: 'pref',
  initialState: {
    dirty: false,
    error: null,
    org: {
      settings: {
        map: {}
      }
    },
    billing: {
      plans: null,
      currentPlan: -1,
      newPlan: -1,
      planChanged: false,
      cards: null,
      name: "",
      cardChanged: false,
      invoices: null,
    },
    users: []
  },
  reducers: {
    cancelUser: (state, action) => {
      const email = action.payload;
      const u = state.users.find(it => it.email === email);
      if (u) {
        u.status = STATUS_CANCELLED
      }
    },

    loadOrg: (state, action) => {
      const org = action.payload;
      state.org = Object.assign({}, state.org, org);
    },

    updateOrg: (state, action) => {
      const org = action.payload;
      state.dirty = true;
      state.org = merge({}, state.org, org);
    },

    updateOrgSetting: (state, action) => {
      const {group, key, value} = action.payload;
      state.dirty = true;
      state.org.settings[group] = {
        ...state.org.settings[group],
        ...({[key]: value})
      }
    },

    choosePlan: (state, action) => {
      state.billing.newPlan = action.payload;
      state.billing.planChanged = false;
    },

    setBillingName: (state, action) => {
      state.billing.name = action.payload || '';
    }
  },
  extraReducers: builder => {
    builder
      .addCase(loadUsers.fulfilled, (state, action) => {
        state.users = action.payload;
      })
      .addCase(saveOrg.fulfilled, (state, action) => {
        state.dirty = false;
      })
      .addCase(loadPlans.fulfilled, (state, action) => {
        const {plans, currentPlan} = action.payload;
        state.billing.plans = plans;
        state.billing.currentPlan = currentPlan;
        state.billing.planChanged = false;
      })
      .addCase(changePlan.pending, (state, action) => {
        state.billing.planChanged = false;
      })
      .addCase(changePlan.fulfilled, (state, action) => {
        const plan = action.payload;
        state.billing.currentPlan = state.billing.plans.map(it => it.name).indexOf(plan.name);
        state.billing.newPlan = -1;
        state.billing.planChanged = true;
      })
      .addCase(loadCards.fulfilled, (state,action) => {
        state.billing.cards = action.payload;
      })
      .addCase(changeCard.pending, (state, action) => {
        state.billing.cardChanged = false;
      })
      .addCase(changeCard.fulfilled, (state, action) => {
        state.billing.cardChanged = true;
      })
      .addCase(changeCard.rejected, (state, action) => {
        state.error = action.error;
      })
      .addCase(loadInvoices.pending, (state, action) => {
        state.billing.invoices = null;
      })
      .addCase(loadInvoices.fulfilled, (state, action) => {
        state.billing.invoices = action.payload;
      })
  }
});

export const {cancelUser, loadOrg, updateOrg, updateOrgSetting, choosePlan, setBillingName} = pref.actions;

export default pref.reducer;
