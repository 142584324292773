import {updateInCart} from './state';

const CartMiddleware = store => next => action => {

    next(action);

    switch(action.type) {
        case 'comp/compAdded':
        case 'comp/compModified':
            store.dispatch(updateInCart(action.payload));
            break;
        case 'cart/addToCart':
        case 'cart/updateInCart':
        case 'cart/removeFromCart':
        case 'cart/clearCart':
        case 'cart/':
        case 'cart/setSortField':
        case 'cart/toggleSortOrder':
            const {auth, cart} = store.getState();
            if (auth && auth.user && cart) {
                localStorage.setItem(`quickcomp.${auth.user.uid}.cart`, JSON.stringify({
                    sort: cart.sort,
                    comps: cart.comps.map(it => it.id),
                }));
            }
            break;
        default:
            break;
    }
};

export default CartMiddleware;
