import React from "react";
import AlertTitle from '@mui/material/AlertTitle';
import Alert from '@mui/material/Alert';

const ErrorAlert = ({error, title, fullWidth, sx, ...props}) => {
    const t = title || "Whoops, an error occurred.";
    const message = error && (typeof error === "string" ? error :
                    "error" in error ? error.error :
                    "error_" in error ? error.error_ :
                    "message" in error ? error.message :
                    "message_" in error ? error.message_ :
                    "errorInfo" in error ? error.errorInfo.message :
                    JSON.stringify(error))

    return (
        <Alert severity="error" sx={{
          textAlign: "left",
          ...(fullWidth===true && {width:"100%"}),
          ...sx
        }} {...props}>
            <AlertTitle>{t}</AlertTitle>
            {message}
        </Alert>
    );
};

export default ErrorAlert;
