import React, {Fragment} from "react";
import {pluralize} from "./utils";

const Pluralize = ({singular, plural, count}) => {
    return (
        <Fragment>
            {pluralize(count, singular, plural)}
        </Fragment>
    )
};

export default Pluralize;
