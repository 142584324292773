import React from "react";
import {FormHelperText, OutlinedInput, styled} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";


const SmallSelect = styled(Select)(({theme}) => ({
    '&& .MuiSelect-select': {
        paddingTop: 8.5,
        paddingBottom: 8.5,
    },
}));

const SmallLabel = styled(InputLabel)(({theme}) => ({
    '&&': {
        transform: "translate(14px, 12px) scale(1)"
    }
}));

const SelectWithLabel = ({label, values, size, className, helperText, helperTextProps, addNone, fullWidth=true, multiple, ...props}) => {
    const inputLabel = React.useRef(null);

    let vals = values;
    if (addNone === true) {
        vals = [""].concat(values);
    }
    const S = size === "small" ? SmallSelect : Select;
    const L = (size === "small" && false) ? SmallLabel : InputLabel;
    return (
      <FormControl variant="outlined" className={className} fullWidth={fullWidth}>
          {/*<InputLabel shrink>{label}</InputLabel>*/}
          {label && <L ref={inputLabel} shrink>{label}</L>}
          {/*<Select input={<OutlinedInput notched label={label} />} />*/}
          <S input={<OutlinedInput label={label} notched />} multiple={multiple} {...props}>
              {vals.map((it,i) => {
                  if (it === null) return <Divider key={i}/>
                  if (typeof it === "object" && it.type === "divider") return <Divider key={i} textAlign="right" sx={{
                    color: "grey.600",
                    fontSize: "0.8em",
                  }}>{it.label}</Divider>
                  let [value, display] = Array.isArray(it) ? it : [it, it !== "" ? it : "None"];
                  return <MenuItem key={value} value={value}>{display}</MenuItem>
              })}
          </S>
          {helperText && <FormHelperText {...(helperTextProps || {})}>{helperText}</FormHelperText>}
      </FormControl>
    )
    // return (
    //   <FormControl variant="outlined" className={className} fullWidth>
    //       {label && <L ref={inputLabel} shrink>{label}</L>}
    //       <S input={<OutlinedInput label={label} notched />} {...props}>
    //           {vals.map((it,i) => {
    //               if (it === null) return <Divider key={i}/>;
    //               let [value, display] = Array.isArray(it) ? it : [it, it !== "" ? it : "None"];
    //               return <MenuItem key={value} value={value}>{display}</MenuItem>
    //           })}
    //       </S>
    //
    //   </FormControl>
    // );
};

export default SelectWithLabel;
