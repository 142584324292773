import React from "react";
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';


const TagInput = ({value, onChange, placeholder, ...props}) => {
    return (
      <Autocomplete
        multiple
        freeSolo
        value={value}
        options={[]}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip variant="filled" label={option} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            variant="standard"
            fullWidth={true}
          />
        )}
        onChange={(evt, val) => onChange(val)}
        {...props}
      />
    )
};

export default TagInput;
