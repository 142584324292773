import React from "react";
import {useDispatch, useSelector} from "react-redux";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {updateCompId, updateExact} from './state';


const IdPanel = () => {
    const id = useSelector(state => state.find.search.compId || "");
    const exact = useSelector(state => state.find.search.exact === true);
    const dispatch = useDispatch();

    return (
        <Grid container spacing={2} className="IdPanel">
            <Grid item>
                <TextField value={id} onChange={evt => dispatch(updateCompId(evt.target.value))} variant="outlined"
                           label="Comp ID or Address" size={"small"}/>
            </Grid>
            <Grid item>
                <FormControlLabel
                    control={<Checkbox checked={exact} onChange={() => dispatch(updateExact(!exact))} name="exact" />}
                    label="Perform an Exact Match"
                />
            </Grid>
        </Grid>
    );
};

export default IdPanel;
