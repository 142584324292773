import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import GridViewIcon from '@mui/icons-material/GridView';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import Tooltip from '@mui/material/Tooltip';
import {useDispatch, useSelector} from 'react-redux';
import {updateView} from './state';

const LayoutPanel = () => {
  const dispatch = useDispatch();
  const isAggsOpen = useSelector(state => state.search.view.aggs.open);
  const isResultOpen = useSelector(state => state.search.view.result.open);

  const views = [
    ...(isAggsOpen ? ["aggs"] : []),
    ...(isResultOpen ? ["result"] : []),
  ]

  return (
    <ToggleButtonGroup value={views} size="small">
      <ToggleButton value="aggs" onClick={() => dispatch(updateView({path:"aggs.open", value: !isAggsOpen, resizeMap: true}))}>
        <Tooltip title="Toggle View">
          <FormatListNumberedRtlIcon/>
        </Tooltip>
      </ToggleButton>
      <ToggleButton value="result" onClick={() => dispatch(updateView({path:"result.open", value: !isResultOpen, resizeMap: true}))}>
        <Tooltip title="Toggle Results View">
          <GridViewIcon/>
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

export default LayoutPanel;
