import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {doc, getDoc, getFirestore} from 'firebase/firestore';
import {orderBy} from 'lodash';
import {Fields} from '../model/fields';
import {Comp} from '../model/objects';
import {SORT_ASC, SORT_DESC} from './constants';

const sortedComps = (comps, sort) => {
  if (!sort.field) return comps;
  const f = Fields.lookup(sort.field);
  return orderBy(comps, [c => f.get(c)], [sort.order]);
}

export const addCompIdsToCart = createAsyncThunk(
  'cart/addCompIds',
  async (compIds, {dispatch, getState, rejectWithValue}) => {
    const org = getState().auth.org.id;
    try {
      const comps =
        await Promise.all(compIds.map(id => getDoc(doc(getFirestore(), 'orgs', org, 'comps', id))));
      dispatch(addToCart(comps.filter(it => it.exists()).map(it => Comp.create(it))));
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
);

export const loadCart = createAsyncThunk(
  'cart/load',
  async (storage, {dispatch, getState, rejectWithValue}) => {
    const auth = getState().auth;
    try {
      if (auth.user) {
        const state = storage.getItem(`quickcomp.${auth.user.uid}.cart`);
        if (state) {
          const payload = JSON.parse(state);
          if (Array.isArray(payload)) {
            // old version, jsut array of ids
            await dispatch(addCompIdsToCart(payload));
          }
          else {
            // new style, with sorting
            await dispatch(setSortField({field: payload.sort.field, order: payload.sort.order}));
            await dispatch(addCompIdsToCart(payload.comps));
          }
        }
      }
    }
    catch(err) {
      return rejectWithValue(err);
    }
  }
)

export const replaceCart = createAsyncThunk(
  'comp/replace',
  async (compIds, {dispatch, rejectWithValue}) => {
    try {
      await dispatch(clearCart());
      await dispatch(addCompIdsToCart(compIds));
    }
    catch(err) {
      return rejectWithValue(err);
    }

  }
)
const cart = createSlice({
  name: 'cart',
  initialState: {
    comps: [],
    sort: {
      field: null,
      order: SORT_DESC
    }
  },
  reducers: {
    initCart: (state, action) => {
      state.comps = [];
    },
    addToCart: (state, action) => {
      state.comps.push(...action.payload);
    },
    removeFromCart: (state, action) => {
      state.comps = state.comps.filter(it => it.id !== action.payload.id);
    },
    updateInCart: (state, action) => {
      const {comp} = action.payload;
      if (state.comps.some(it => it.id === comp.id)) {
        state.comps = sortedComps(state.comps.map(c => c.id === comp.id ? comp : c), state.sort)
      }
    },
    moveInCart: (state, action) => {
      const {from, to} = action.payload;
      const c = state.comps[from];
      state.comps.splice(from, 1);
      state.comps.splice(to, 0, c);
    },
    clearCart: (state, action) => {
      state.comps = [];
    },
    setSortField: (state, action) => {
      const {field, order} = action.payload;
      const fieldPath = field ? (typeof field === 'string' ? field : field.path) : null;
      state.sort = {
        field: fieldPath,
        order: order ? order : state.sort.order
      };
      state.comps = sortedComps(state.comps, {...state.sort, field: fieldPath});
    },
    toggleSortOrder: (state, action) => {
      const newOrder = state.sort.order === SORT_DESC ? SORT_ASC : SORT_DESC;
      state.sort.order = newOrder;
      state.comps = sortedComps(state.comps, {...state.sort, order: newOrder});
    }
  }
});

export const {
  initCart, addToCart, removeFromCart, updateInCart, moveInCart, clearCart, setSortField, toggleSortOrder
} = cart.actions;

export default cart.reducer;
