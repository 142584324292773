import {merge} from 'lodash';
import React from 'react';
import {Helmet} from 'react-helmet';
import {MICROSOFT_FONTS, fontURL, lookupFont} from '../model/fonts';
import {fontAsObject} from '../report/config';

export const cellStyle = (style, font) => {
  return {
    backgroundColor: style.background,
    ...fontAsObject(merge({}, style.font, font ? {family: font.previewId || font.id} : {}), 'pt')
  }
};

export const isUnitField = (field, sheet) => {
  return field.path === (sheet.unit||{}).path;
}

export const ExcelFonts = () => {
  return (
    <Helmet>
      {MICROSOFT_FONTS.map(it => lookupFont(it.previewId))
        .map(it => <link key={it.id} rel="stylesheet" href={fontURL(it)}/>)}
    </Helmet>
  )
}
